import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Button, Pagination as BSPagination } from 'react-bootstrap';
import { IApiResponse, IPaging } from '../../types/IApiResponse';
import { BaseModel } from '../../models/BaseModel';
import { SingleApiObject } from '../../types/api/BaseTypes';
import { useQueryParams } from '../../hooks/useQueryParams';
import { convertToNumber } from '../../utils';

const PAGE_SIZES = [10, 25, 50, 100];
type PageSizeType = 10 | 25 | 50 | 100;

interface Props<T extends SingleApiObject> {
   model: BaseModel<T>;
   modelFilter?: Partial<T>;
   orderColumn?: keyof T;
   orderDesc?: boolean;
   initialPageSize?: PageSizeType;
   children: (props: { items: T[] | null; maxItemCount: number }) => React.ReactNode;
   customLoadItems?: (pageSize: number, page: number) => Promise<IApiResponse<T>>;
}

interface PagingSearchParams extends Record<string, string> {
   page: string;
   limit: string;
}

export const Pagination = <T extends SingleApiObject>({
   model,
   modelFilter,
   initialPageSize = 10,
   orderColumn = 'id',
   orderDesc = false,
   children,
   customLoadItems,
}: Props<T>) => {
   const [queryParams, setQueryParams] = useQueryParams<PagingSearchParams>();
   const [paging, setPaging] = useState<IPaging | null>(null);
   const [items, setItems] = useState<T[] | null>(null);

   const pageSize = convertToNumber(queryParams.limit, initialPageSize);
   const page = convertToNumber(queryParams.page, 1);

   const handlePageChange = useCallback(async () => {
      let response;
      if (customLoadItems) {
         response = await customLoadItems(pageSize, page);
      } else {
         response = await model.listPaged(
            modelFilter,
            orderColumn,
            orderDesc ? 'DESC' : 'ASC',
            pageSize,
            page
         );
      }

      setItems(response.data);
      setPaging(response.paging);
   }, [customLoadItems, model, modelFilter, orderColumn, orderDesc, page, pageSize]);

   // Damit beim Aufruf direkt die erste Seite geladen und dargestellt wird
   useEffect(() => {
      handlePageChange().then();
   }, [handlePageChange]);

   const setPage = (newPage: number) => {
      setQueryParams(prev => ({
         ...prev,
         page: String(newPage),
      }));
   };

   return (
      <>
         {children({ items, maxItemCount: paging?.max_items ?? 0 })}
         {paging && (
            <div className="mb-3 d-flex flex-column-reverse flex-md-row justify-content-between align-items-center">
               <span className="d-flex align-items-center my-2 my-md-0">
                  {PAGE_SIZES.map(p => (
                     <Fragment key={p}>
                        {p === pageSize ? (
                           <span className="px-1 fw-normal">{p}</span>
                        ) : (
                           <Button
                              key={p}
                              variant="link"
                              className="p-0 px-1"
                              onClick={() =>
                                 setQueryParams(prev => ({ ...prev, limit: String(p) }))
                              }
                           >
                              {p}
                           </Button>
                        )}
                     </Fragment>
                  ))}
               </span>
               <BSPagination className="mb-0">
                  {paging.max_pages >= 11 && (
                     <BSPagination.First
                        disabled={paging.current_page <= 10}
                        onClick={() => setPage(paging.current_page - 10)}
                     />
                  )}
                  <BSPagination.Prev
                     disabled={paging.current_page === 1}
                     onClick={() => setPage(paging.current_page - 1)}
                  />
                  {paging.current_page >= 6 && (
                     <BSPagination.Item onClick={() => setPage(1)}>{1}</BSPagination.Item>
                  )}
                  {paging.current_page >= 6 && <BSPagination.Ellipsis disabled />}

                  {paging.current_page > 2 && (
                     <BSPagination.Item onClick={() => setPage(paging.current_page - 2)}>
                        {paging.current_page - 2}
                     </BSPagination.Item>
                  )}
                  {paging.current_page > 1 && (
                     <BSPagination.Item onClick={() => setPage(paging.current_page - 1)}>
                        {paging.current_page - 1}
                     </BSPagination.Item>
                  )}
                  <BSPagination.Item active>{paging.current_page}</BSPagination.Item>
                  {paging.max_pages - paging.current_page >= 1 && (
                     <BSPagination.Item onClick={() => setPage(paging.current_page + 1)}>
                        {paging.current_page + 1}
                     </BSPagination.Item>
                  )}
                  {paging.max_pages - paging.current_page >= 2 && (
                     <BSPagination.Item onClick={() => setPage(paging.current_page + 2)}>
                        {paging.current_page + 2}
                     </BSPagination.Item>
                  )}

                  {paging.max_pages - paging.current_page >= 5 && (
                     <BSPagination.Ellipsis disabled />
                  )}
                  {paging.max_pages - paging.current_page >= 5 && (
                     <BSPagination.Item onClick={() => setPage(paging.max_pages)}>
                        {paging.max_pages}
                     </BSPagination.Item>
                  )}
                  <BSPagination.Next
                     disabled={paging.current_page >= paging.max_pages}
                     onClick={() => setPage(paging.current_page + 1)}
                  />
                  {paging.max_pages >= 11 && (
                     <BSPagination.Last
                        disabled={paging.current_page >= paging.max_pages}
                        onClick={() =>
                           setPage(Math.min(paging.max_pages, paging.current_page + 10))
                        }
                     />
                  )}
               </BSPagination>
            </div>
         )}
      </>
   );
};
