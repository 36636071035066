import { Link, useNavigate } from 'react-router-dom';
import React, { useCallback, useEffect, useState } from 'react';
import { Formik, FormikErrors } from 'formik';
import { Col, Container, Form, Row, Tab, Table, Tabs } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import orderBy from 'lodash/orderBy';
import { AxiosError } from 'axios';
import { ConfirmDeleteDialog } from '../../../../components/dialogs/ConfirmDeleteDialog';
import { showBackButton } from '../../../../redux/appSlice';
import { ButtonPanel, ResponsiveButton } from '../../../../components/molecules';
import { useAppDispatch, useIdFromParams, useValidationErrors } from '../../../../hooks';
import { Category, LegoSet } from '../../../../types/api';
import { CategoryModel } from '../../../../models/CategoryModel';
import { removeCategory, upsertCategory } from '../../../../redux/dataSlice';
import { SubmitButton, TextControl } from '../../../../components/forms';
import { ContentLoader } from '../../../../components/atoms';
import { LegoSetModel } from '../../../../models/LegoSetModel';
import { notEmpty, pageLinks } from '../../../../utils';
import { PageTitle } from '../../../../components/organisms/PageTitle';

export const CategoryEditAdminPage = () => {
   const dispatch = useAppDispatch();
   const categoryId = useIdFromParams();
   const navigate = useNavigate();
   const validationErrors = useValidationErrors();
   const [category, setCategory] = useState<Partial<Category> | null>(null);
   const [legoSets, setLegoSets] = useState<LegoSet[]>();
   const [showDeleteDialog, setShowDeleteDialog] = useState(false);
   const [isDeleting, setDeleting] = useState(false);

   useEffect(() => {
      (async () => {
         dispatch(showBackButton(true));

         if (categoryId === 0) {
            setCategory({});
         } else {
            try {
               setCategory(await CategoryModel.get(categoryId));
            } catch (err) {
               const error = err as AxiosError;
               if (error.response?.status === 404) {
                  navigate('/404', { replace: true });
               }
            }
         }
      })();
   }, [categoryId, dispatch, navigate]);

   const handleSubmitForm = useCallback(
      async (values: Partial<Category>) => {
         const result = !values.id
            ? await CategoryModel.insert(values)
            : await CategoryModel.update(values);

         dispatch(upsertCategory(result));
         navigate('/admin/categories');
      },
      [dispatch, navigate]
   );

   const handleDelete = useCallback(async () => {
      if (!category) return;

      setDeleting(true);

      try {
         await CategoryModel.delete(category);
         dispatch(removeCategory(category));
         setShowDeleteDialog(false);
         navigate(-1);
      } finally {
         setDeleting(false);
      }
   }, [category, dispatch, navigate]);

   const handleTabSelect = async (key: string | null) => {
      if (key !== 'sets' || !category?.legoSets) return;

      setLegoSets(
         (
            await Promise.all(category.legoSets.map(async legoSetId => LegoSetModel.get(legoSetId)))
         ).filter(notEmpty)
      );
   };

   if (!category) return <ContentLoader />;

   return (
      <Container>
         <PageTitle
            title={`Kategorien - ${category.name}`}
            appTitle={category.name}
            subtitle="Administration > Kategorien"
            hidden
         />
         <Tabs onSelect={handleTabSelect}>
            <Tab eventKey="data" title="Stammdaten" className="px-0">
               <Formik
                  onSubmit={handleSubmitForm}
                  initialValues={category}
                  enableReinitialize
                  validate={values => {
                     const errors: FormikErrors<Category> = {};

                     if (!values?.name) errors.name = validationErrors.required;

                     return errors;
                  }}
               >
                  {formik => (
                     <Form
                        noValidate
                        onSubmit={e => {
                           e.preventDefault();
                           formik.handleSubmit();
                        }}
                     >
                        <Container>
                           <Row>
                              <Col>
                                 <fieldset>
                                    <legend>Stammdaten</legend>
                                    <TextControl formik={formik} model="category" name="name" />
                                 </fieldset>
                              </Col>
                           </Row>
                        </Container>
                        <ButtonPanel isFormFooter>
                           <ResponsiveButton
                              variant="danger"
                              onClick={() => setShowDeleteDialog(true)}
                              isLoading={isDeleting}
                              hidden={!category?.id}
                           >
                              <FormattedMessage id="button.delete" defaultMessage="Löschen" />
                           </ResponsiveButton>
                           <ResponsiveButton variant="secondary" onClick={() => navigate(-1)}>
                              <FormattedMessage id="button.cancel" defaultMessage="Abbrechen" />
                           </ResponsiveButton>
                           <SubmitButton formik={formik}>
                              <FormattedMessage id="button.save" defaultMessage="Speichern" />
                           </SubmitButton>
                        </ButtonPanel>
                     </Form>
                  )}
               </Formik>
            </Tab>
            <Tab eventKey="sets" title="Lego-Sets" className="px-0">
               {!legoSets ? (
                  <ContentLoader />
               ) : (
                  <Table bordered striped>
                     <thead>
                        <tr>
                           <th>LegoSet</th>
                        </tr>
                     </thead>
                     <tbody>
                        {orderBy(legoSets, 'number').map(s => (
                           <tr>
                              <td>
                                 <Link to={pageLinks.legoSetDetail(s)}>
                                    {s.number}: {s.name}
                                 </Link>
                              </td>
                           </tr>
                        ))}
                     </tbody>
                  </Table>
               )}
            </Tab>
         </Tabs>
         <ConfirmDeleteDialog
            show={showDeleteDialog}
            onClose={() => setShowDeleteDialog(false)}
            onDelete={handleDelete}
         >
            <p>
               Möchten Sie die Kategorie <strong>{category?.name}</strong> wirklich löschen?
            </p>
         </ConfirmDeleteDialog>
      </Container>
   );
};
