import { useEffect, useState } from 'react';
import { ChartDataType } from '../types/ChartDataType';

export const usePriceTrend = (marketValueHistory: ChartDataType): number | null => {
   const [trend, setTrend] = useState<number | null>(null);

   useEffect(() => {
      if (marketValueHistory.length === 0) {
         setTrend(null);
         return;
      }

      // Berechnet nach https://stackoverflow.com/questions/30647330/calculate-if-trend-is-up-down-or-stable
      let dataCount = 0;
      let sumX = 0;
      let sumY = 0;
      let sumXY = 0;
      let sumX2 = 0;
      marketValueHistory.forEach((d, i) => {
         if (d[1] === null) return;

         const price = Number(d[1]);

         sumX += i + 1;
         sumY += price;
         sumXY += (i + 1) * price;
         sumX2 += (i + 1) ** 2;
         dataCount += 1;
      });

      setTrend((dataCount * sumXY - sumX * sumY) / (dataCount * sumX2 - sumX ** 2));
   }, [marketValueHistory]);

   return trend;
};
