import React, { useCallback, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import orderBy from 'lodash/orderBy';
import { BrowserView, isMobile } from 'react-device-detect';
import { CardSpacer, ColoredValue } from '../../../components/atoms';
import { EditInventoryDialog } from '../../../components/dialogs/EditInventoryDialog';
import { EditSaleDialog } from '../../../components/dialogs/EditSaleDialog';
import { ImageUploadsDialog } from '../../../components/dialogs/ImageUploadsDialog';
import { ConfirmInventoryDeleteDialog } from '../../../components/dialogs/ConfirmInventoryDeleteDialog';
import { ButtonPanel, Card, ResponsiveButton } from '../../../components/molecules';
import { useAppDispatch, useAppSelector, useMemorizedIntl } from '../../../hooks';
import { Inventory, InventoryType } from '../../../types/api';
import { convertToReadableTimestamp, formatDate, pageLinks } from '../../../utils';
import { reloadLegoSet } from '../../../redux/pagesSlice';

interface InventoryListProps {
   title: string;
   type: InventoryType;
}

export const InventoryList = (props: InventoryListProps) => {
   const intl = useMemorizedIntl();
   const dispatch = useAppDispatch();
   const { legoSet } = useAppSelector(s => s.pages.legoSet);
   const inventory = (legoSet?.inventory ?? []).filter(i => i.type === props.type);

   const [selectedInventory, setSelectedInventory] = useState<Partial<Inventory>>();
   const [showOptionsDialog, setShowOptionsDialog] = useState(false);
   const [showInventoryDialog, setShowInventoryDialog] = useState(false);
   const [showSoldSetDialog, setShowSoldSetDialog] = useState(false);
   const [showImageUploadsDialog, setShowImageUploadsDialog] = useState(false);
   const [showDeleteDialog, setShowDeleteDialog] = useState(false);

   const handleEdit = useCallback((inv: Inventory) => {
      setSelectedInventory(inv ?? {});
      setShowInventoryDialog(true);
   }, []);

   const handleEditSale = useCallback((inv: Inventory) => {
      setSelectedInventory(inv ?? {});
      setShowSoldSetDialog(true);
   }, []);

   const handleShowImages = useCallback((inv: Inventory) => {
      setSelectedInventory(inv ?? {});
      setShowImageUploadsDialog(true);
   }, []);

   const handleDelete = useCallback((inv: Partial<Inventory>) => {
      setSelectedInventory(inv ?? {});
      setShowDeleteDialog(true);
   }, []);

   // Leere Inventar-Liste wird nicht angezeigt
   if (inventory.length === 0) return null;

   return (
      <>
         <h4 className="text-center mt-2">
            <span>{props.title}</span>
            {inventory.length > 0 && <span className="ms-1">({inventory.length})</span>}
         </h4>
         {orderBy(
            inventory,
            [props.type === 'sold' ? 'sold_date' : 'date', 'id'],
            ['asc', 'asc']
         ).map(i => (
            <Card
               key={i.id}
               classNameBody={`pe-0 mobile ${isMobile ? 'card-clickable' : ''}`}
               onPress={
                  !isMobile
                     ? undefined
                     : () => {
                          setSelectedInventory(i);
                          setShowOptionsDialog(true);
                       }
               }
               chevron={isMobile}
               orientation="column"
            >
               <div className="d-flex flex-row" style={{ height: '6rem' }}>
                  <div
                     className="d-flex flex-column align-items-center"
                     style={{ width: isMobile ? '8rem' : '10rem' }}
                  >
                     {i.type === 'wanted' && (
                        <>
                           <span className="small">
                              <FormattedMessage
                                 id="lego-set.inventory.table.headline.rrp"
                                 defaultMessage="UVP"
                              />
                              {': '}
                              <ColoredValue type="currency" value={i.legoSet.price} />
                           </span>
                           <span className="fs-4 flex-fill d-flex align-items-center">
                              <ColoredValue
                                 type="currency"
                                 value={i.statistics.avgMarketValue}
                                 useColor
                                 isNegative={i.price < i.statistics.avgMarketValue}
                              />
                           </span>
                           <span className="small">
                              <FormattedMessage
                                 id="lego-set.inventory.table.headline.wish"
                                 defaultMessage="Wunsch"
                              />
                              {': '}
                              <ColoredValue type="currency" value={i.price} />
                           </span>
                        </>
                     )}
                     {(i.type === 'bought' || i.type === 'sold') && (
                        <>
                           <span className="small">
                              <FormattedMessage
                                 id="lego-set.inventory.table.headline.purchase-price"
                                 defaultMessage="EK"
                              />
                              {': '}
                              <ColoredValue type="currency" value={i.price} />
                           </span>
                           <span className="fs-3 flex-fill d-flex align-items-center">
                              <ColoredValue
                                 type="currency"
                                 value={i.statistics.profit}
                                 useColor
                                 isDiff
                              />
                           </span>
                           <span className="small">
                              <ColoredValue
                                 type="percentage"
                                 value={i.statistics.profitPercentage}
                                 useColor
                                 isDiff
                              />
                           </span>
                        </>
                     )}
                  </div>
                  <CardSpacer />
                  <div className="d-flex flex-fill flex-column align-items-start">
                     {i.type === 'bought' && (
                        <>
                           <span className="small text-nowrap overflow-hidden">
                              <FormattedMessage
                                 id="lego-set.inventory.table.headline.serial-number"
                                 defaultMessage="S/N"
                              />
                              : {i.serial_number}
                           </span>
                           <span className="small text-nowrap overflow-hidden">
                              <FormattedMessage
                                 id="lego-set.inventory.table.headline.storage-location"
                                 defaultMessage="Lagerort"
                              />
                              : {i.storage_location}
                           </span>
                           <span className="small text-nowrap overflow-hidden">
                              <FormattedMessage
                                 id="lego-set.inventory.table.headline.purchase-date"
                                 defaultMessage="Kaufdatum"
                              />
                              : {formatDate(i.date, 'L')}
                           </span>
                        </>
                     )}
                     {i.type === 'sold' && (
                        <>
                           <span className="small">
                              <FormattedMessage
                                 id="lego-set.inventory.table.headline.holding-time"
                                 defaultMessage="Haltedauer"
                              />
                              :&nbsp;
                              <span
                                 className={
                                    i.statistics.holdingTimeInDays < 365
                                       ? 'text-danger'
                                       : 'text-success'
                                 }
                                 title={
                                    i.statistics.holdingTimeInDays < 365
                                       ? intl.formatMessage({
                                            id: 'lego-set.inventory.holding-time.to-short',
                                            defaultMessage:
                                               'Die Haltedauer sollte min. 1 Jahr sein, sodass du mit dem Finanzamt wegen Spekulation keine Probleme bekommst.',
                                         })
                                       : intl.formatMessage({
                                            id: 'lego-set.inventory.holding-time.ok',
                                            defaultMessage: 'Alles ok.',
                                         })
                                 }
                              >
                                 {convertToReadableTimestamp(intl, i.statistics.holdingTimeInDays)}
                              </span>
                           </span>
                           <span className="small text-nowrap overflow-hidden">
                              <FormattedMessage
                                 id="lego-set.inventory.table.headline.selling-price"
                                 defaultMessage="Verkaufspreis"
                              />
                              :&nbsp;
                              <ColoredValue type="currency" value={i.sold_price} />
                           </span>
                        </>
                     )}
                     <span
                        className={`small fst-italic ${
                           i.type === 'bought' ? 'line-clamp-1' : 'line-clamp-2'
                        }`}
                        style={{ textOverflow: 'ellipsis', width: '100%' }}
                     >
                        {i.type === 'sold' ? i.sold_comment : i.comment}
                     </span>
                  </div>
               </div>
               <BrowserView>
                  <CardSpacer orientation="horizontal" />
                  <ButtonPanel align="end">
                     <Button
                        variant="link"
                        size="sm"
                        onClick={() => handleShowImages(i)}
                        hidden={i.type === 'wanted'}
                     >
                        {i.image_upload_count === 0 ? (
                           <FormattedMessage
                              id="button.add_pictures"
                              defaultMessage="Bilder hinzufügen"
                           />
                        ) : (
                           <FormattedMessage
                              id="button.pictures"
                              defaultMessage="{count, plural, one {# Bild} other {# Bilder}}"
                              values={{ count: i.image_upload_count }}
                           />
                        )}
                     </Button>
                     <Button variant="outline-danger" size="sm" onClick={() => handleDelete(i)}>
                        <FormattedMessage id="button.delete" defaultMessage="Löschen" />
                     </Button>
                     <Button
                        variant="secondary"
                        size="sm"
                        onClick={() => handleEditSale(i)}
                        hidden={i.type === 'wanted'}
                     >
                        {i.type === 'bought' ? (
                           <FormattedMessage id="button.sell" defaultMessage="Verkaufen" />
                        ) : (
                           <FormattedMessage
                              id="button.edit-sell"
                              defaultMessage="Verkaufen bearbeiten"
                           />
                        )}
                     </Button>
                     <Button variant="primary" size="sm" onClick={() => handleEdit(i)}>
                        <FormattedMessage id="button.edit" defaultMessage="Bearbeiten" />
                     </Button>
                  </ButtonPanel>
               </BrowserView>
            </Card>
         ))}

         <Modal show={showOptionsDialog} centered onHide={() => setShowOptionsDialog(false)}>
            <Modal.Body>
               <ButtonPanel>
                  <ResponsiveButton
                     to={pageLinks.editInventory(legoSet, selectedInventory)}
                     variant="primary"
                  >
                     <FormattedMessage id="button.edit" defaultMessage="Bearbeiten" />
                  </ResponsiveButton>
                  <ResponsiveButton
                     to={`/legoSets/${legoSet?.number}/inventory/${selectedInventory?.id}/editSale`}
                     variant="primary"
                     hidden={selectedInventory?.type === 'wanted'}
                  >
                     {selectedInventory?.type === 'bought' ? (
                        <FormattedMessage id="button.sell" defaultMessage="Verkaufen" />
                     ) : (
                        <FormattedMessage
                           id="button.edit-sell"
                           defaultMessage="Verkaufen bearbeiten"
                        />
                     )}
                  </ResponsiveButton>
                  <ResponsiveButton
                     to={`/legoSets/${legoSet?.number}/inventory/${selectedInventory?.id}/pictures`}
                     variant="primary"
                  >
                     {(selectedInventory?.image_upload_count ?? 0) === 0 ? (
                        <FormattedMessage
                           id="button.add_pictures"
                           defaultMessage="Bilder hinzufügen"
                        />
                     ) : (
                        <FormattedMessage
                           id="button.pictures"
                           defaultMessage="{count, plural, one {# Bild} other {# Bilder}}"
                           values={{ count: selectedInventory?.image_upload_count ?? 0 }}
                        />
                     )}
                  </ResponsiveButton>
                  <ResponsiveButton
                     variant="danger"
                     onClick={() => {
                        if (!selectedInventory) return;

                        handleDelete(selectedInventory);
                        setShowOptionsDialog(false);
                     }}
                  >
                     <FormattedMessage id="button.delete" defaultMessage="Löschen" />
                  </ResponsiveButton>
                  <ResponsiveButton
                     className="mt-4"
                     variant="secondary"
                     onClick={() => setShowOptionsDialog(false)}
                  >
                     <FormattedMessage id="button.close" defaultMessage="Schließen" />
                  </ResponsiveButton>
               </ButtonPanel>
            </Modal.Body>
         </Modal>

         <EditInventoryDialog
            show={showInventoryDialog}
            setId={selectedInventory?.set_id ?? 0}
            inventory={selectedInventory ?? {}}
            onClose={() => setShowInventoryDialog(false)}
         />
         <EditSaleDialog
            inventory={selectedInventory ?? {}}
            show={showSoldSetDialog}
            onClose={() => setShowSoldSetDialog(false)}
         />
         <ImageUploadsDialog
            inventoryId={selectedInventory?.id ?? 0}
            show={showImageUploadsDialog}
            onClose={() => {
               dispatch(reloadLegoSet(legoSet?.id));
               setShowImageUploadsDialog(false);
            }}
         />
         <ConfirmInventoryDeleteDialog
            show={showDeleteDialog}
            inventory={selectedInventory ?? {}}
            onClose={() => setShowDeleteDialog(false)}
            afterDelete={() => setShowDeleteDialog(false)}
         />
      </>
   );
};
