import { Col, Container, Row } from 'react-bootstrap';
import React from 'react';
import { useMemorizedIntl } from '../../hooks';
import { PageTitle } from '../../components/organisms/PageTitle';
import { ColoredValue, ContentLoader } from '../../components/atoms';
import { LegoSetModel } from '../../models/LegoSetModel';
import { Pagination } from '../../components/organisms/Pagination';
import { MobileTemplate } from '../../components/organisms/LegoSetCard';

export const LegoSetsPage = () => {
   const intl = useMemorizedIntl();

   return (
      <Container>
         <Pagination model={LegoSetModel} orderColumn="number" orderDesc initialPageSize={50}>
            {({ items, maxItemCount }) => (
               <>
                  <PageTitle
                     title={intl.formatMessage({
                        id: 'lego-sets.title',
                        defaultMessage: 'Alle Lego-Sets',
                     })}
                     subtitle={intl.formatMessage(
                        {
                           id: 'lego-sets.subtitle',
                           defaultMessage:
                              '{count, plural, =0 {# Lego-Sets} one {# Lego-Set} other {# Lego-Sets}}',
                        },
                        {
                           count: maxItemCount,
                        }
                     )}
                  />
                  {items === null ? (
                     <ContentLoader />
                  ) : (
                     items.map(item => (
                        <Row>
                           <Col xs={12}>
                              <MobileTemplate key={item.id} legoSet={item}>
                                 <h4 className="mb-0 overflow-ellipsis">{`${item.number} - ${item.name}`}</h4>
                                 <div className="d-flex fst-italic overflow-ellipsis">
                                    {item.category_name}
                                 </div>
                                 <div
                                    className="fs-4 d-flex align-items-center overflow-ellipsis"
                                    style={{ flex: 1 }}
                                 >
                                    <ColoredValue type="currency" value={item.price} />
                                 </div>
                              </MobileTemplate>
                           </Col>
                        </Row>
                     ))
                  )}
               </>
            )}
         </Pagination>
      </Container>
   );
};
