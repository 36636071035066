import { Container, Form } from 'react-bootstrap';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { isMobile } from 'react-device-detect';
import { Formik, FormikErrors } from 'formik';
import { Field, formatQuery, RuleGroupType } from 'react-querybuilder';
import * as QBConfig from '../../../../../components/querybuilder/Config';
import { ButtonPanel, ResponsiveButton } from '../../../../../components/molecules';
import { showBackButton } from '../../../../../redux/appSlice';
import { SortingBuilder } from '../../../../../components/querybuilder/SortingBuilder';
import { ContentLoader } from '../../../../../components/atoms';
import {
   useAppDispatch,
   useCache,
   useIdFromParams,
   useMemorizedIntl,
   useValidationErrors,
} from '../../../../../hooks';
import { InventoryFilter } from '../../../../../types/api';
import { InventoryFilterModel } from '../../../../../models/InventoryFilterModel';
import { GenericControl, SubmitButton, TextControl } from '../../../../../components/forms';
import { QueryBuilder } from '../../../../../components/querybuilder';
import { PageTitle } from '../../../../../components/organisms/PageTitle';

export const UserFiltersEditPage = () => {
   const intl = useMemorizedIntl();
   const navigate = useNavigate();
   const dispatch = useAppDispatch();
   const filterId = useIdFromParams();
   const validationErrors = useValidationErrors();
   const [inventoryFilter, setInventoryFilter] = useState<Partial<InventoryFilter> | null>(null);
   const [fields, setFields] = useState<Field[]>([]);
   const { categories, labels } = useCache();

   useEffect(() => {
      (async () => {
         if (filterId === 0) {
            // Neuen Filter anlegen
            setInventoryFilter({
               id: 0,
               user_id: 0,
               name: '',
               select_condition: '',
               select_condition_display: '',
               order_condition: '',
               order_condition_display: '',
            });
            dispatch(showBackButton(true));
         } else {
            // Vorhandenen Filter bearbeiten
            const filter = await InventoryFilterModel.get(filterId);
            setInventoryFilter(filter);
            dispatch(showBackButton(true));
         }
      })();
   }, [filterId, dispatch]);

   useEffect(() => {
      setFields(QBConfig.fields(intl, categories, labels));
   }, [intl, categories, labels]);

   const handleSubmitForm = useCallback(
      async (values: Partial<InventoryFilter>) => {
         if (values.id) await InventoryFilterModel.update(values);
         else await InventoryFilterModel.insert(values);
         navigate('/inventory/search/manage');
      },
      [navigate]
   );

   const handleDelete = useCallback(async () => {
      if (inventoryFilter) {
         await InventoryFilterModel.delete(inventoryFilter);
         navigate(-1);
      }
   }, [inventoryFilter, navigate]);

   const convertJsonToSorting = useCallback((json: string | undefined | null) => {
      if (!json) return null;

      return JSON.parse(json);
   }, []);

   if (!inventoryFilter) return <ContentLoader />;

   return (
      <Container>
         <PageTitle
            title={
               inventoryFilter?.name ??
               intl.formatMessage({
                  id: 'my-inventory-filters.title.new-filter',
                  defaultMessage: 'Neuer Filter',
               })
            }
            appTitle={intl.formatMessage({
               id: 'my-inventory-filters.app-title',
               defaultMessage: 'Inventar-Filter',
            })}
            subtitle={inventoryFilter?.name}
            hidden
         />
         <Formik
            onSubmit={handleSubmitForm}
            initialValues={inventoryFilter}
            enableReinitialize
            validate={values => {
               const errors: FormikErrors<InventoryFilter> = {};

               if (!values.name) errors.name = validationErrors.required;

               return errors;
            }}
         >
            {formik => (
               <Form
                  style={{ marginTop: '1rem' }}
                  noValidate
                  onSubmit={e => {
                     e.preventDefault();
                     formik.handleSubmit();
                  }}
               >
                  <TextControl
                     formik={formik}
                     model="inventory_filter"
                     name="name"
                     type="compact"
                  />

                  <GenericControl
                     formik={formik}
                     model="inventory_filter"
                     name="select_condition"
                     type="compact"
                  >
                     <QueryBuilder
                        fields={fields}
                        queryAsString={formik.values.select_condition}
                        onQueryChange={(query: RuleGroupType) => {
                           formik.setFieldValue('select_condition', formatQuery(query, 'json'));
                           formik.setFieldValue(
                              'select_condition_display',
                              formatQuery(query, 'sql')
                           );
                        }}
                     />
                     <Form.Text className="text-muted">
                        {formik.values.select_condition_display}
                     </Form.Text>
                  </GenericControl>

                  <GenericControl
                     formik={formik}
                     model="inventory_filter"
                     name="select_condition"
                     type="compact"
                  >
                     <SortingBuilder
                        fields={fields}
                        query={convertJsonToSorting(formik.values.order_condition)}
                        onSortingChange={(json, text) => {
                           formik.setFieldValue('order_condition', JSON.stringify(json, null, 2));
                           formik.setFieldValue('order_condition_display', text);
                        }}
                     />
                  </GenericControl>

                  <ButtonPanel isFormFooter>
                     <ResponsiveButton
                        variant="danger"
                        onClick={handleDelete}
                        disabled={formik.isSubmitting}
                        hidden={!formik.values.id}
                     >
                        <FormattedMessage id="button.delete" defaultMessage="Löschen" />
                     </ResponsiveButton>
                     <ResponsiveButton
                        variant="secondary"
                        onClick={() => navigate(-1)}
                        hidden={isMobile}
                     >
                        <FormattedMessage id="button.cancel" defaultMessage="Abbrechen" />
                     </ResponsiveButton>
                     <SubmitButton formik={formik}>
                        <FormattedMessage id="button.save" defaultMessage="Speichern" />
                     </SubmitButton>
                  </ButtonPanel>
               </Form>
            )}
         </Formik>
      </Container>
   );
};
