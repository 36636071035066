import { FormattedMessage } from 'react-intl';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { ColoredValue } from '../../atoms';
import { InventoryWithStats } from '../../../types/api/InventoryWithStats';
import { PriceTrendIcon } from '../../molecules';
import { PriceTrendChartPopover } from './PriceTrendChartPopover';

interface Props {
   inventory?: InventoryWithStats;
}

export const CardBodyInventory = ({ inventory }: Props) => (
   <>
      <div
         className="h4 mb-0 d-flex align-items-center overflow-ellipsis gap-1"
         style={{ flex: 1 }}
      >
         <ColoredValue type="currency" value={inventory?.statistics.profit} useColor isDiff />
         {inventory?.statistics.profitPercentage !== null && (
            <>
               <span className="text-muted mx-1">/</span>
               <ColoredValue
                  type="percentage"
                  value={inventory?.statistics.profitPercentage}
                  decimalScale={1}
                  useColor
                  isDiff
               />
            </>
         )}
         {isMobile ? (
            <PriceTrendIcon
               marketValueHistory={inventory?.statistics.avgMarketValueHistory ?? []}
               size={1}
            />
         ) : (
            <PriceTrendChartPopover
               marketValueHistory={inventory?.statistics.avgMarketValueHistory ?? []}
            >
               <PriceTrendIcon
                  marketValueHistory={inventory?.statistics.avgMarketValueHistory ?? []}
                  size={1}
               />
            </PriceTrendChartPopover>
         )}
      </div>
      <small className="d-flex overflow-ellipsis text-muted fst-italic">
         <span>
            <FormattedMessage
               id="app.inventory.grid.card.invest-price"
               defaultMessage="EKP: {price}"
               values={{
                  price: <ColoredValue type="currency" value={inventory?.price} />,
               }}
            />
         </span>
         <span className="mx-1">•</span>
         <span>
            {inventory?.type === 'wanted' && (
               <FormattedMessage
                  id="inventory.grid.table.type.wanted"
                  defaultMessage="Wunschliste"
               />
            )}
            {inventory?.type === 'bought' && (
               <FormattedMessage id="inventory.grid.table.type.bought" defaultMessage="Inventar" />
            )}
            {inventory?.type === 'sold' && (
               <FormattedMessage id="inventory.grid.table.type.sold" defaultMessage="Verkauft" />
            )}
         </span>
      </small>
      <div className="overflow-ellipsis text-muted small fst-italic" />
   </>
);
