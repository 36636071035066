import React, { useEffect, useState } from 'react';
import { Col, Container, ListGroup, Row } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { round } from 'lodash';
import { ComboChart, DonutChart } from '../../components/organisms';
import { setActiveTab, showBackButton } from '../../redux/appSlice';
import ApiService from '../../services/ApiService';
import { IApiResponse } from '../../types/IApiResponse';
import { ColoredValue } from '../../components/atoms';
import { ContentWithPlaceholder } from '../../components/molecules';
import { useAppDispatch, useMemorizedIntl } from '../../hooks';
import { PageTitle } from '../../components/organisms/PageTitle';

/*
Wozu Statistiken?
    - Maximierung Gewinn!
        - Gewinnmarge maximieren: Günstig einkaufen, teuer verkaufen
            - Welche Sets werden mit größten Rabatt verkauft
            - Welche Sets werden mit dem größten Gewinn verkauft
            - Welche Sets haben die größte Spanne zwischen Rabatt und verkauf
        - Sets kaufen, welche den größten Gewinn erzielen
            - Welche Kategorie hat die Sets mit den höchsten Gewinn (Datenbasis unvollständig, da nicht alle Sets der Kategorie vorhanden sind)
        - Wert von Sets kann
            - auf tatsächlichen Verkäufen basieren (vllt Datenbasis noch zu klein)
            - auf geschätzten Verkaufswert ermittelt werden (ggf. zu optimistisch)
        - Ermittlung, wie gut geschätzter Wert mit tatsächlichen Verkaufswert ist
            - Ermittlung geschätzter Wert zum Zeitpunkt des Kaufes und in Relation setzen zum tatsächlichen Verkaufswert
            - Different in % kann Aussagen über Genauigkeit der geschätzen Verkaufspreise geben -> Wert besser schätzbar

Statistiken
    - Für mich persönlich
        - (done) Wert Legosammlung über Zeit (letztes Jahr, wieviel war die Sammlung in welchen Monat wert. Käufe erhöhen, Verkäufe reduzieren)
        - % Gewinn aktuelles Inventar nach Zeit (soll helfen zu erkennen, ob der Wert der Sammlung grundsätzlich steigt oder fällt, Erkennung von Trends, wann sich verkäufe generell lohnen)
            - Ermittlung pro Zeitintervall-Start, welche Sets dort im Inventar waren
            - Ermittlung des aktuellen Marktwertes zu diesen Sets (Durchschnitt von Idealo und Bricklink)
            - Ermittlung des prozentualen Gewinnes pro Set zu dem Intervallzeitpunkt
            - Ermittlung Durchschnittswert aller Gewinne zu einem Intervallzeitpunkt
        - (done) Anzahl Sets nach Kategorie (Pie-Chart, top 5 + Rest)
        - (done) % Gewinne durch Verkäufe nach Kategorie (Pie-Chart, Top 5 + Rest)

    - Für alle
        - Top 5 Kategorien nach höchsten Gewinn (möglichen oder tatsächlichen?)
        - Top 5 Kategorien nach größten Verlust / wenigsten Gewinn
*/

export const StatisticsPage = () => {
   const dispatch = useAppDispatch();
   const intl = useMemorizedIntl();

   useEffect(() => {
      dispatch(showBackButton(false));
      dispatch(setActiveTab('Statistic'));
   }, [dispatch]);

   return (
      <Container>
         <PageTitle
            title={intl.formatMessage({
               id: 'statistics.title',
               defaultMessage: 'Statistiken',
            })}
         />
         <InventoryStatisticsRow />
         <Row>
            <Col md="6" xs="12" className="mt-4">
               <h5>
                  <FormattedMessage
                     id="statistics.inventory-trend-by-time"
                     defaultMessage="Wert Inventar nach Zeit"
                  />
               </h5>
               <ComboChart url="stats/getInventoryTrendByTime?daysInPast=730&interval=7-days" />
            </Col>
            <Col md="6" xs="12" className="mt-4">
               <h5>
                  <FormattedMessage
                     id="statistics.profit-trend-by-time"
                     defaultMessage="Gewinn Inventar nach Zeit"
                  />
               </h5>
               <ComboChart
                  url="stats/getProfitTrendByTime?daysInPast=730&interval=7-days"
                  unit="%"
               />
            </Col>
         </Row>
         <Row>
            <Col lg="3" md="6" sm="6" xs="12" className="mt-4">
               <h5>
                  <FormattedMessage
                     id="statistics.inventory-count-by-category"
                     defaultMessage="Top Sets nach Kategorie"
                  />
               </h5>
               <DonutChart
                  url="stats/getInventoryByCategory?type=count&top=5"
                  unit=""
                  numberFormat="#"
               />
            </Col>
            <Col lg="3" md="6" sm="6" xs="12" className="mt-4">
               <h5>
                  <FormattedMessage
                     id="statistics.inventory-invest-by-category"
                     defaultMessage="Top Invest nach Kategorie"
                  />
               </h5>
               <DonutChart url="stats/getInventoryByCategory?type=invest&top=5" />
            </Col>
            <Col lg="3" md="6" sm="6" xs="12" className="mt-4">
               <h5>
                  <FormattedMessage
                     id="statistics.inventory-profit-percentage-by-category"
                     defaultMessage="Top Gewinn nach Kategorie"
                  />
               </h5>
               <DonutChart
                  url="stats/getInventoryByCategory?type=profit_percentage&top=5"
                  unit="%"
               />
            </Col>
            <Col lg="3" md="6" sm="6" xs="12" className="mt-4">
               <h5>
                  <FormattedMessage
                     id="statistics.inventory-profit-by-category"
                     defaultMessage="Top Verkauf nach Kategorie"
                  />
               </h5>
               <DonutChart url="stats/getInventoryByCategory?type=profit_real&top=5" />
            </Col>
         </Row>
      </Container>
   );
};

interface InventoryStatisticSummery {
   bought: {
      invest_sum: number;
      estimated_profit_sum: number;
      uvp_sum: number;
   };
   sold: {
      invest_sum: number;
      real_profit_sum: number;
      avg_holding_time_in_seconds: number;
   };
}

const InventoryStatisticsRow = () => {
   const [isLoading, setLoading] = useState(true);
   const [stats, setStats] = useState<InventoryStatisticSummery | null>(null);

   useEffect(() => {
      (async () => {
         try {
            setLoading(true);
            const data = (
               await ApiService.http.get<IApiResponse<InventoryStatisticSummery>>(
                  'stats/getInventoryStatisticSummary'
               )
            ).data.data[0];
            setStats(data);
         } catch (error) {
            ApiService.handleError(error);
            return await Promise.reject(error);
         } finally {
            setLoading(false);
         }
      })();
   }, []);

   const boughtProfit = (stats?.bought.invest_sum ?? 0) + (stats?.bought.estimated_profit_sum ?? 0);
   const boughtProfitPct = (boughtProfit / (stats?.bought.invest_sum ?? 0) - 1) * 100;
   const uvpProfit = (stats?.bought.uvp_sum ?? 0) - (stats?.bought.invest_sum ?? 0);
   const uvpProfitPct = ((stats?.bought.uvp_sum ?? 0) / (stats?.bought.invest_sum ?? 0) - 1) * 100;
   const soldProfitPct =
      (((stats?.sold.invest_sum ?? 0) + (stats?.sold.real_profit_sum ?? 0)) /
         (stats?.sold.invest_sum ?? 0) -
         1) *
      100;
   const holdingTimeInDays = round((stats?.sold.avg_holding_time_in_seconds ?? 0) / 86400, 0);

   return (
      <Row>
         <Col md="6" xs="12" className="mt-4">
            <h5>Inventar</h5>
            <ListGroup>
               <ListGroup.Item className="d-flex justify-content-between align-items-center">
                  <span>
                     <FormattedMessage id="inventory.stats.invest" defaultMessage="Investition" />:
                  </span>
                  <ContentWithPlaceholder isLoading={isLoading}>
                     <ColoredValue type="currency" value={stats?.bought.invest_sum} />
                  </ContentWithPlaceholder>
               </ListGroup.Item>
               <ListGroup.Item className="d-flex justify-content-between align-items-center">
                  <span>
                     <FormattedMessage
                        id="inventory.stats.avg-value"
                        defaultMessage="Ø aktueller Wert:"
                     />
                  </span>
                  <span className="text-end">
                     <div>
                        <ContentWithPlaceholder isLoading={isLoading}>
                           <ColoredValue type="currency" value={boughtProfit} />
                        </ContentWithPlaceholder>
                     </div>
                     <ContentWithPlaceholder isLoading={isLoading} width="10rem" size="small">
                        <small>
                           <ColoredValue
                              type="currency"
                              value={stats?.bought.estimated_profit_sum}
                              isDiff
                              useColor
                           />
                           <span className="mx-2">/</span>
                           <ColoredValue
                              type="percentage"
                              value={boughtProfitPct}
                              isDiff
                              useColor
                           />
                        </small>
                     </ContentWithPlaceholder>
                  </span>
               </ListGroup.Item>
               <ListGroup.Item className="d-flex justify-content-between align-items-center">
                  <span>
                     <FormattedMessage id="inventory.stats.rrp" defaultMessage="UVP" />:
                  </span>
                  <span className="text-end">
                     <div>
                        <ContentWithPlaceholder isLoading={isLoading}>
                           <ColoredValue type="currency" value={stats?.bought.uvp_sum} />
                        </ContentWithPlaceholder>
                     </div>
                     <ContentWithPlaceholder isLoading={isLoading} width="10rem" size="small">
                        <small>
                           <ColoredValue type="currency" value={uvpProfit} isDiff useColor />
                           <span className="mx-2">/</span>
                           <ColoredValue type="percentage" value={uvpProfitPct} isDiff useColor />
                        </small>
                     </ContentWithPlaceholder>
                  </span>
               </ListGroup.Item>
            </ListGroup>
         </Col>
         <Col md="6" xs="12" className="mt-4">
            <h5>Verkauf</h5>
            <ListGroup>
               <ListGroup.Item className="d-flex justify-content-between align-items-center">
                  <span>
                     <FormattedMessage
                        id="inventory.stats.total-invest"
                        defaultMessage="Gesamt Invest:"
                     />
                  </span>
                  <span>
                     <ContentWithPlaceholder isLoading={isLoading}>
                        <ColoredValue type="currency" value={stats?.sold.invest_sum} />
                     </ContentWithPlaceholder>
                  </span>
               </ListGroup.Item>
               <ListGroup.Item className="d-flex justify-content-between align-items-center">
                  <span>
                     <FormattedMessage
                        id="inventory.stats.total-profit"
                        defaultMessage="Gesamt Profit:"
                     />
                  </span>
                  <span className="text-end">
                     <div>
                        <ContentWithPlaceholder isLoading={isLoading}>
                           <ColoredValue type="currency" value={stats?.sold.real_profit_sum} />
                        </ContentWithPlaceholder>
                     </div>
                     <ContentWithPlaceholder isLoading={isLoading} width="5rem" size="small">
                        <small>
                           <ColoredValue type="percentage" value={soldProfitPct} isDiff useColor />
                        </small>
                     </ContentWithPlaceholder>
                  </span>
               </ListGroup.Item>
               <ListGroup.Item className="d-flex justify-content-between align-items-center">
                  <span>
                     <FormattedMessage
                        id="inventory.stats.avg-holding-time"
                        defaultMessage="Ø Haltedauer:"
                     />
                  </span>
                  <span>
                     <ContentWithPlaceholder isLoading={isLoading}>
                        <FormattedMessage
                           id="inventory.stats.avg-holding-time.days"
                           defaultMessage="{days, plural, one {# Tag} other {# Tage}}"
                           values={{
                              days: holdingTimeInDays,
                           }}
                        />
                     </ContentWithPlaceholder>
                  </span>
               </ListGroup.Item>
            </ListGroup>
         </Col>
      </Row>
   );
};
