import React, { ReactNode } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { InfoTooltip } from '../molecules/InfoTooltip';

export interface FormRowProps {
   /**
    * The label for the form control.
    */
   label?: string | ReactNode;
   /**
    * An optional description for the form control. Only visible when there is a label.
    */
   description?: string;
   /**
    * The form control.
    */
   children: React.ReactNode;
   /**
    * Determine the display type. Default 'responsive'.
    *
    * `compact`: The control utilized the whole width and the label is above the control.
    * `responsive`: When there is enough space, the label will be left to the control. Otherwise, the 'compact' type will be used.
    */
   type?: 'compact' | 'responsive' | 'inline';
   helpText?: ReactNode;
}

/**
 * Renders a responsive form control with a label and an optional description.
 */
export const FormRow = ({
   label,
   description,
   children,
   type = 'responsive',
   helpText,
}: FormRowProps) => {
   let colStyle = '';
   if (type === 'compact') colStyle = 'mt-0 justify-content-start';
   if (type === 'responsive') colStyle = 'mt-md-2 justify-content-md-end';
   if (type === 'inline') colStyle = 'mt-2 justify-content-end';

   return (
      <Form.Group as={Row} className="mb-3">
         <Col
            xs={type === 'inline' ? 4 : 12}
            md={type === 'compact' ? 12 : 5}
            lg={type === 'compact' ? 12 : 4}
            className={`d-flex align-items-start ${colStyle}`}
         >
            {label && (
               <Form.Label className="d-inline text-end">
                  {label}
                  {description && <InfoTooltip text={description} />}
               </Form.Label>
            )}
         </Col>
         <Col
            xs={type === 'inline' ? 8 : 12}
            md={type === 'compact' ? 12 : 7}
            lg={type === 'compact' ? 12 : 8}
            className="d-flex justify-content-center flex-column"
            style={{ minHeight: '2,375rem' }}
         >
            {children}
            {helpText}
         </Col>
      </Form.Group>
   );
};
