import { FormattedMessage } from 'react-intl';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { ColoredValue } from '../../atoms';
import { ContentWithPlaceholder, PriceTrendIcon } from '../../molecules';
import { LegoSetWithStats } from '../../../types/api/LegoSetWithStats';
import { PriceTrendChartPopover } from './PriceTrendChartPopover';

interface Props {
   legoSet: LegoSetWithStats;
}

export const CardBodyWanted = ({ legoSet }: Props) => {
   const marketPrice = legoSet.statistics.currentMarketValue.avg;
   const wantedPrice = Math.max(
      ...legoSet.inventory.filter(i => i.type === 'wanted').map(i => i.price)
   );
   const marketPricePercentage = marketPrice === 0 ? 0 : (marketPrice / legoSet.price) * 100 - 100;

   return isMobile ? (
      <>
         <div
            className="h4 mb-0 d-flex align-items-center overflow-ellipsis gap-1"
            style={{ flex: 1 }}
         >
            <ColoredValue
               type="currency"
               value={marketPrice}
               isNegative={wantedPrice < marketPrice}
               useColor
               isDiff
            />
            <span className="text-muted mx-1">/</span>
            <ColoredValue
               type="percentage"
               value={marketPricePercentage}
               decimalScale={1}
               isNegative={wantedPrice < marketPrice}
               useColor
               isDiff
            />
            <PriceTrendIcon
               marketValueHistory={legoSet.statistics.avgMarketValueHistory}
               size={1}
               type="lowerIsBetter"
            />
         </div>
         <small className="overflow-ellipsis text-muted fst-italic">
            <FormattedMessage
               id="inventory.grid.card.wanted.lego-price"
               defaultMessage="LEGO: {price}"
               values={{
                  price: <ColoredValue type="currency" value={legoSet.price} />,
               }}
            />
            <span className="mx-1">•</span>
            <FormattedMessage
               id="inventory.grid.card.wanted.price"
               defaultMessage="Wunsch: {price}"
               values={{
                  price: <ColoredValue type="currency" value={wantedPrice} />,
               }}
            />
         </small>
      </>
   ) : (
      <>
         <span className="pb-2 d-flex justify-content-between align-items-center">
            <span className="text-truncate small fst-italic">
               <ContentWithPlaceholder isLoading={!legoSet} width="5rem">
                  {legoSet.status_by_lego || <>&nbsp;</>}
               </ContentWithPlaceholder>
            </span>
         </span>
         <span className="card-body-price">
            <ColoredValue
               className="h3"
               type="currency"
               value={marketPrice}
               isNegative={wantedPrice < marketPrice}
               useColor
            />
            <PriceTrendChartPopover
               marketValueHistory={legoSet.statistics.avgMarketValueHistory}
               type="lowerIsBetter"
            >
               <PriceTrendIcon
                  marketValueHistory={legoSet.statistics.avgMarketValueHistory}
                  size={1.5}
                  type="lowerIsBetter"
               />
            </PriceTrendChartPopover>
         </span>
         <span className="card-body-price-sub">
            <ColoredValue className="me-2" type="currency" value={legoSet.price} />
            <ColoredValue
               type="percentage"
               value={marketPricePercentage}
               decimalScale={1}
               isNegative={wantedPrice < marketPrice}
               useColor
               isDiff
            />
         </span>
         <span className="flex-fill" />
         <span className="small">
            <FormattedMessage
               id="inventory.grid.card.wanted.price"
               defaultMessage="Wunsch: {price}"
               values={{
                  price: <ColoredValue type="currency" value={wantedPrice} />,
               }}
            />
         </span>
      </>
   );
};
