import { useLocation, useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import { setLanguage, setLoggedIn, setLoggedOut } from '../redux/appSlice';
import { UserModel } from '../models/UserModel';
import { User } from '../types/api';
import { setCategories, setLabels } from '../redux/dataSlice';
import { CategoryModel } from '../models/CategoryModel';
import { LabelModel } from '../models/LabelModel';
import { useAppSelector } from './useAppSelector';
import { useAppDispatch } from './useAppDispatch';
import { pageLinks } from '../utils';
import { SupportedLocalesType } from '../intlHelpers';

export const useSession = () => {
   const dispatch = useAppDispatch();
   const navigate = useNavigate();
   const location = useLocation();

   const {
      user: sessionUser,
      roles: sessionRoles,
      loginState,
   } = useAppSelector(s => s.app.session);

   const signOut = useCallback(async () => {
      await UserModel.logout();
      dispatch(setLoggedOut());
      navigate('/', { replace: true });
   }, [dispatch, navigate]);

   const loadAndCacheData = useCallback(async () => {
      // Kategorien laden und für Menü setzen
      const categories = await CategoryModel.list();
      dispatch(setCategories(categories));

      // Labels laden und für Menü setzen
      dispatch(setLabels(await LabelModel.list()));
   }, [dispatch]);

   const signIn = useCallback(
      async (loggedInUser: User) => {
         await loadAndCacheData();
         dispatch(setLoggedIn(loggedInUser));
         dispatch(setLanguage(loggedInUser.display_language as SupportedLocalesType));
         if (loggedInUser.registration_completed) navigate(location, { replace: true });
         else navigate(pageLinks.completeRegistration(), { replace: true });
      },
      [dispatch, loadAndCacheData, navigate, location]
   );

   return {
      sessionUser: sessionUser,
      sessionRoles: sessionRoles,
      loginState: loginState,
      signIn: signIn,
      signOut: signOut,
   };
};
