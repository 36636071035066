import { FormattedMessage } from 'react-intl';
import React, { useCallback, useState } from 'react';
import { Formik, FormikErrors } from 'formik';
import { Form } from 'react-bootstrap';
import { ButtonPanel, ResponsiveButton } from '../molecules';
import { BlogPostComment } from '../../types/api';
import { BlogPostCommentModel } from '../../models/BlogPostCommentModel';
import { SubmitButton, TextareaControl } from '../forms';
import { useMemorizedIntl, useValidationErrors } from '../../hooks';

interface Props {
   comment?: BlogPostComment;
   blogPostId?: number;
   afterSubmit: (comment: BlogPostComment) => Promise<void>;
   onCancel: () => void;
}

export const CommentForm = ({ comment, blogPostId, afterSubmit, onCancel }: Props) => {
   const intl = useMemorizedIntl();
   const validationErrors = useValidationErrors();
   const [editComment, setEditComment] = useState<Partial<BlogPostComment>>(comment ?? {});

   const handleSubmitForm = useCallback(
      async (values: Partial<BlogPostComment>) => {
         values.blog_post_id = blogPostId;
         let result: BlogPostComment;
         if (values.id) {
            result = await BlogPostCommentModel.update(values);
         } else {
            result = await BlogPostCommentModel.insert(values);
            setEditComment({ comment: '' });
         }

         await afterSubmit(result);
      },
      [afterSubmit, blogPostId]
   );

   return (
      <Formik
         onSubmit={handleSubmitForm}
         initialValues={editComment}
         enableReinitialize
         validate={values => {
            const errors: FormikErrors<Partial<BlogPostComment>> = {};

            if (!values.comment) errors.comment = validationErrors.required;

            return errors;
         }}
      >
         {formik => (
            <Form
               noValidate
               onSubmit={e => {
                  e.preventDefault();
                  formik.handleSubmit();
               }}
            >
               <TextareaControl
                  formik={formik}
                  name="comment"
                  type="compact"
                  placeholder={intl.formatMessage({
                     id: 'blog.blog-post-comment.form.comment.placeholder',
                     defaultMessage: 'Kommentar schreiben...',
                  })}
                  rows={5}
               />
               <ButtonPanel align="start">
                  <SubmitButton formik={formik}>
                     {comment ? (
                        <FormattedMessage
                           id="button.save-changes"
                           defaultMessage="Änderungen speichern"
                        />
                     ) : (
                        <FormattedMessage id="button.submit-comment" defaultMessage="Abschicken" />
                     )}
                  </SubmitButton>
                  <ResponsiveButton variant="secondary" onClick={onCancel}>
                     <FormattedMessage id="button.cancel" defaultMessage="Abbrechen" />
                  </ResponsiveButton>
               </ButtonPanel>
            </Form>
         )}
      </Formik>
   );
};
