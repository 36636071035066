import React, { useCallback, useEffect, useState } from 'react';
import { Badge, Container, ListGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Icon from '@mdi/react';
import { mdiChevronRight } from '@mdi/js/commonjs/mdi';
import { showBackButton } from '../../../redux/appSlice';
import { Label } from '../../../types/api';
import { SetHasLabelByUserModel } from '../../../models/SetHasLabelByUserModel';
import { useAppDispatch } from '../../../hooks';
import { PageTitle } from '../../../components/organisms/PageTitle';
import ApiService from '../../../services/ApiService';
import { IApiResponse } from '../../../types/IApiResponse';
import { Colors } from '../../../components/Colors';

export const LabelsAdminPage = () => {
   const dispatch = useAppDispatch();
   const [labels, setLabels] = useState<Label[]>();

   useEffect(() => {
      dispatch(showBackButton(true));
   }, [dispatch]);

   const loadLabels = useCallback(async () => {
      try {
         const loadedLabels = (await ApiService.http.get<IApiResponse<Label>>(`admin/labels`)).data
            .data;
         const setHasLabelByUser = await SetHasLabelByUserModel.list();

         // Wir überschreiben den Wert von lego_sets_by_user, da dieser sonst nur die eigenen Label-Zuweisungen enthält.
         //    Wir wollen hier aber die aller User haben
         const data = loadedLabels.map(l => {
            l.lego_sets_by_user = setHasLabelByUser
               .filter(shl => shl.label_id === l.id)
               .map(shl => shl.id);
            return l;
         });
         setLabels(data);
      } catch (error) {
         ApiService.handleError(error);
         return Promise.reject(error);
      }
   }, []);

   useEffect(() => {
      (async () => {
         await loadLabels();
      })();
   }, [loadLabels]);

   return (
      <Container>
         <PageTitle title="Labels" subtitle="Administration" />
         <ListGroup>
            {labels?.map(l => (
               <ListGroup.Item
                  key={l.id}
                  as={Link}
                  to={`/admin/labels/${l.id}`}
                  className="d-flex flex-row"
                  action
               >
                  <span className="flex-fill">{l.name}</span>
                  <Badge className="badge-lg ms-2" pill bg="secondary">
                     {l.lego_sets.length + l.lego_sets_by_user.length}
                  </Badge>
                  <div className="d-flex align-items-center">
                     <Icon path={mdiChevronRight} color={Colors.link} size={1} />
                  </div>
               </ListGroup.Item>
            ))}
         </ListGroup>
      </Container>
   );
};
