import { Form } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import React, { useCallback, useEffect, useState } from 'react';
import { FormikErrors, FormikProps } from 'formik';
import moment from 'moment';
import { ColoredValue } from '../components/atoms';
import { useMemorizedIntl, useSession, useValidationErrors } from '../hooks';
import { Inventory, LegoSet } from '../types/api';
import { DropdownControl } from '../components/forms/DropdownControl';
import { CheckControl, TextareaControl, TextControl } from '../components/forms';
import { DateControl } from '../components/forms/DateControl';
import ApiService from '../services/ApiService';
import { IApiResponse } from '../types/IApiResponse';
import { InventoryModel } from '../models/InventoryModel';

interface Props {
   formik: Pick<
      FormikProps<Partial<Inventory>>,
      | 'errors'
      | 'isValid'
      | 'touched'
      | 'values'
      | 'handleChange'
      | 'handleBlur'
      | 'isSubmitting'
      | 'setFieldValue'
      | 'submitCount'
   >;
   legoSet: LegoSet | null;
}

export const InventoryForm = ({ legoSet, formik }: Props) => {
   const intl = useMemorizedIntl();
   const { sessionUser } = useSession();
   const [storageLocationSuggestions, setStorageLocationSuggestions] = useState<string[] | null>(
      null
   );

   useEffect(() => {
      (async () => {
         const values = (
            await ApiService.http.get<IApiResponse<string>>(
               `data/${InventoryModel.getName()}/suggestions/storage_location`
            )
         ).data.data;
         setStorageLocationSuggestions(values);
      })();
   }, []);

   return (
      <>
         <DropdownControl
            formik={formik}
            model="inventory"
            name="type"
            options={[
               {
                  label: intl.formatMessage({
                     id: 'dialog.edit-inventory.type.bought',
                     defaultMessage: 'Gekauft',
                  }),
                  value: 'bought',
               },
               {
                  label: intl.formatMessage({
                     id: 'dialog.edit-inventory.type.wanted',
                     defaultMessage: 'Wunschliste',
                  }),
                  value: 'wanted',
               },
            ]}
         />
         {formik.values.type !== 'wanted' && (
            <DateControl formik={formik} model="inventory" name="date" />
         )}
         <TextControl
            formik={formik}
            model="inventory"
            name="price"
            inputType="number"
            label={
               formik.values.type === 'wanted'
                  ? intl.formatMessage({
                       id: 'dialog.edit-inventory.wanted-price.title',
                       defaultMessage: 'Wunschpreis',
                    })
                  : undefined
            }
            min={0}
            append="€"
         />
         {formik.values.type === 'wanted' && (
            <CheckControl
               formik={formik}
               model="inventory"
               name="alert_enabled"
               disabled={!sessionUser?.notification_wanted_enabled}
               label={intl.formatMessage(
                  {
                     id: 'dialog.edit-inventory.price-alert.text',
                     defaultMessage: 'Preisalarm für unter {price} ({percentage}) aktiv',
                  },
                  {
                     price: <ColoredValue type="currency" value={formik.values.price} />,
                     percentage: (
                        <ColoredValue
                           type="percentage"
                           value={((formik.values.price ?? 0) / (legoSet?.price ?? 1) - 1) * 100}
                        />
                     ),
                  }
               )}
               helpText={
                  !sessionUser?.notification_wanted_enabled && (
                     <Form.Text className="text-muted">
                        <FormattedMessage
                           id="dialog.edit-inventory.price-alert.disabled"
                           defaultMessage="Du kannst die Einstellung für den Preisalarm nicht einstellen, da du das Versenden von Mails für den Preisalarm in den Einstellungen deaktiviert hast."
                        />
                     </Form.Text>
                  )
               }
            />
         )}
         {formik.values.type !== 'wanted' && (
            <>
               <TextControl formik={formik} model="inventory" name="quality" />
               <TextControl formik={formik} model="inventory" name="serial_number" />
               <TextControl
                  formik={formik}
                  model="inventory"
                  name="storage_location"
                  suggestions={storageLocationSuggestions}
               />
            </>
         )}
         <TextareaControl formik={formik} model="inventory" name="comment" />
      </>
   );
};

export const newInventoryObject: Partial<Inventory> = {
   alert_enabled: false,
   date: moment().toISOString(),
   quality: 'Neu',
   type: 'bought',
};

export const useValidationHandler = () => {
   const validationErrors = useValidationErrors();

   return useCallback(
      (values: Partial<Inventory>) => {
         const errors: FormikErrors<Inventory> = {};

         if (!values.date) errors.date = validationErrors.required;
         if (!values.quality) errors.quality = validationErrors.required;
         if (values.price === undefined || values.price === null)
            errors.price = validationErrors.required;

         return errors;
      },
      [validationErrors]
   );
};
