import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Category, Label } from '../types/api';

interface DataState {
   categories: Category[];
   labels: Label[];
}

const initialState: DataState = {
   categories: [],
   labels: [],
};

const dataSlice = createSlice({
   name: 'data',
   initialState,
   reducers: {
      setCategories(state, action: PayloadAction<Category[]>) {
         state.categories = action.payload;
      },
      upsertCategory(state, action: PayloadAction<Category>) {
         state.categories = [
            ...state.categories.filter(c => c.id !== action.payload.id),
            action.payload,
         ];
      },
      removeCategory(state, action: PayloadAction<Partial<Category>>) {
         state.categories = state.categories.filter(c => c.id !== action.payload.id);
      },
      setLabels(state, action: PayloadAction<Label[]>) {
         state.labels = action.payload;
      },
      upsertLabel(state, action: PayloadAction<Label[] | null>) {
         if (!action.payload) return;

         state.labels = [
            ...state.labels.filter(c => !action.payload!.some(l => l.id === c.id)),
            ...action.payload,
         ];
      },
      removeLabel(state, action: PayloadAction<Partial<Label>>) {
         state.labels = state.labels.filter(c => c.id !== action.payload.id);
      },
   },
});

export const {
   setCategories,
   upsertCategory,
   removeCategory,
   setLabels,
   upsertLabel,
   removeLabel,
} = dataSlice.actions;
export default dataSlice.reducer;
