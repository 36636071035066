import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, ButtonGroup, Form, FormGroup, InputGroup, Modal } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';
import groupBy from 'lodash/groupBy';
import Icon from '@mdi/react';
import { mdiCalculatorVariantOutline, mdiInformationOutline } from '@mdi/js';
import { ButtonPanel, Popover, ResponsiveButton } from '../../../components/molecules';
import { ColoredValue } from '../../../components/atoms';
import { Colors } from '../../../components/Colors';
import { useAppSelector, useMemorizedIntl } from '../../../hooks';
import { FormRow } from '../../../components/forms';
import {
   CalculationResult,
   FormData,
   ReverseCalculationResult,
   useProvisionCalculator,
} from './useProvisionCalculator';

export const PriceCalculatorDialogButton = () => {
   const inventory = useAppSelector(s => s.pages.legoSet?.legoSet?.inventory ?? []);
   const [show, setShow] = useState(false);

   return (
      <>
         <ResponsiveButton
            onClick={() => setShow(true)}
            variant="primary"
            className="px-1 flex-fill d-flex flex-row align-items-center justify-content-center gap-2"
            disabled={inventory.filter(i => i.type === 'bought').length === 0}
         >
            <Icon path={mdiCalculatorVariantOutline} color={Colors.white} size={1.25} />
            <small className="text-start">
               <FormattedMessage id="profit-calculator.button" defaultMessage="Profit-Rechner" />
            </small>
         </ResponsiveButton>
         <Modal show={show} onHide={() => setShow(false)} size="lg" centered={isMobile}>
            <Modal.Header closeButton>
               <Modal.Title>
                  <FormattedMessage
                     id="profit-calculator.headline"
                     defaultMessage="Profit-Rechner"
                  />
               </Modal.Title>
            </Modal.Header>

            <Modal.Body>
               <PriceCalculatorForm />
            </Modal.Body>

            <Modal.Footer>
               <ButtonPanel>
                  <ResponsiveButton variant="secondary" onClick={() => setShow(false)}>
                     <FormattedMessage id="button.close" defaultMessage="Schließen" />
                  </ResponsiveButton>
               </ButtonPanel>
            </Modal.Footer>
         </Modal>
      </>
   );
};

const PriceCalculatorForm = () => {
   const intl = useMemorizedIntl();
   const inventory = useAppSelector(s => s.pages.legoSet?.legoSet?.inventory ?? []);
   const [calculateProfit, setCalculateProfit] = useState(true);
   const [formData, setFormData] = useState<FormData>({
      buyer_price: `${Math.min(
         ...inventory.filter(i => i.type === 'bought').map(i => i.statistics.avgMarketValue)
      )}`,
      buyer_shipping: '',
      provision: 'none',
      paypal_provision: 'none',
      shipping: '',

      rev_profit: '0',
   });
   const [result, setResult] = useState<CalculationResult>();
   const [revResult, setRevResult] = useState<ReverseCalculationResult>();
   const { calcPrice, revCalcPrice, provisions } = useProvisionCalculator(inventory);

   const handleChange = useCallback((field: string, val: string) => {
      setFormData(v => ({
         ...v,
         [field]: val,
      }));
   }, []);

   useEffect(() => {
      // Wenn es keine gekauften Sets gibt, kann auch nichts berechnet werden
      if (inventory.filter(i => i.type === 'bought').length === 0) return;

      setResult(calcPrice(formData));
      setRevResult(revCalcPrice(formData));
   }, [inventory, formData, calcPrice, revCalcPrice]);

   return (
      <>
         <ButtonGroup
            className="d-flex pb-4 button-tabs"
            style={{ maxWidth: '30rem', marginLeft: 'auto', marginRight: 'auto' }}
         >
            <Button
               variant={calculateProfit ? 'success' : 'outline-secondary'}
               onClick={() => setCalculateProfit(true)}
            >
               <FormattedMessage id="price-calculator.tab-button.profit" defaultMessage="Profit" />
            </Button>
            <Button
               variant={!calculateProfit ? 'success' : 'outline-secondary'}
               onClick={() => setCalculateProfit(false)}
            >
               <FormattedMessage
                  id="price-calculator.tab-button.sales-price"
                  defaultMessage="Verkaufspreis"
               />
            </Button>
         </ButtonGroup>
         {calculateProfit ? (
            <>
               <InputFieldRow
                  title={intl.formatMessage({
                     id: 'price-calculator.sales-price',
                     defaultMessage: 'Verkaufspreis',
                  })}
                  placeholder={intl.formatMessage({
                     id: 'price-calculator.sales-price.placeholder',
                     defaultMessage: 'für Käufer',
                  })}
                  value={formData.buyer_price}
                  fieldName="buyer_price"
                  onChange={handleChange}
               />
               <InputFieldRow
                  title={intl.formatMessage({
                     id: 'price-calculator.buyer-shipping-cost',
                     defaultMessage: 'Versandkosten',
                  })}
                  placeholder={intl.formatMessage({
                     id: 'price-calculator.buyer-shipping-cost.placeholder',
                     defaultMessage: 'für Käufer',
                  })}
                  value={formData.buyer_shipping}
                  fieldName="buyer_shipping"
                  onChange={handleChange}
               />
               <Spacer />
               <FormRow
                  label={intl.formatMessage({
                     id: 'price-calculator.provision',
                     defaultMessage: 'Provision',
                  })}
                  type="inline"
               >
                  <Form.Group>
                     <Form.Control
                        as="select"
                        value={formData.provision}
                        onChange={e => handleChange('provision', e.target.value)}
                     >
                        {Object.entries(groupBy(provisions, 'groupName')).map(
                           ([groupName, provisionsGroup]) => (
                              <optgroup key={groupName} label={groupName}>
                                 {provisionsGroup.map(p => (
                                    <option key={p.key} value={p.key}>
                                       {p.display}
                                    </option>
                                 ))}
                              </optgroup>
                           )
                        )}
                     </Form.Control>
                     <small className="form-text text-muted d-flex align-content-center">
                        <ColoredValue
                           type="currency"
                           className="me-2"
                           value={result?.eBayProvision}
                        />
                        <EBayProvisioningTooltip />
                     </small>
                  </Form.Group>
               </FormRow>
               <FormRow
                  label={intl.formatMessage({
                     id: 'price-calculator.paypal-provision',
                     defaultMessage: 'PayPal-Gebühren',
                  })}
                  type="inline"
               >
                  <Form.Group>
                     <Form.Control
                        as="select"
                        value={formData.paypal_provision}
                        onChange={e => handleChange('paypal_provision', e.target.value)}
                     >
                        <option value="none">
                           {intl.formatMessage({
                              id: 'price-calculator.paypal-provision.none',
                              defaultMessage: 'Ohne',
                           })}
                        </option>
                        <option value="max_2000">
                           {intl.formatMessage({
                              id: 'price-calculator.paypal-provision.max-2000',
                              defaultMessage: '0,35€ + 2,49%',
                           })}
                        </option>
                     </Form.Control>
                     <small className="form-text text-muted">
                        <ColoredValue type="currency" value={result?.paypalProvision} />
                     </small>
                  </Form.Group>
               </FormRow>
               <InputFieldRow
                  title={intl.formatMessage({
                     id: 'price-calculator.seller-shipping-cost',
                     defaultMessage: 'Versandkosten',
                  })}
                  placeholder={intl.formatMessage({
                     id: 'price-calculator.seller-shipping-cost.placeholder',
                     defaultMessage: 'für Verkäufer',
                  })}
                  value={formData.shipping}
                  fieldName="shipping"
                  onChange={handleChange}
               />
               <Spacer />
               <FormRow
                  label={intl.formatMessage({
                     id: 'price-calculator.income',
                     defaultMessage: 'Einnahme',
                  })}
                  type="inline"
               >
                  <ColoredValue className="fs-5" type="currency" value={result?.revenue} />
               </FormRow>
               <FormRow
                  label={intl.formatMessage({
                     id: 'price-calculator.profit',
                     defaultMessage: 'Profit',
                  })}
                  type="inline"
               >
                  <span className="d-flex gap-1 fs-5">
                     <span>
                        {result?.profit_range_min === result?.profit_range_max ? (
                           <ColoredValue type="currency" value={result?.profit_range_min} />
                        ) : (
                           <FormattedMessage
                              id="price-calculator.profit-range"
                              defaultMessage="{min} bis {max}"
                              values={{
                                 min: (
                                    <ColoredValue
                                       type="currency"
                                       value={result?.profit_range_min}
                                    />
                                 ),
                                 max: (
                                    <ColoredValue
                                       type="currency"
                                       value={result?.profit_range_max}
                                    />
                                 ),
                              }}
                           />
                        )}
                     </span>
                     <span>
                        (
                        {result?.profit_percentage_min === result?.profit_percentage_max ? (
                           <ColoredValue type="percentage" value={result?.profit_percentage_min} />
                        ) : (
                           <FormattedMessage
                              id="price-calculator.profit-range"
                              defaultMessage="{min} bis {max}"
                              values={{
                                 min: (
                                    <ColoredValue
                                       type="percentage"
                                       value={result?.profit_percentage_min}
                                    />
                                 ),
                                 max: (
                                    <ColoredValue
                                       type="percentage"
                                       value={result?.profit_percentage_max}
                                    />
                                 ),
                              }}
                           />
                        )}
                        )
                     </span>
                  </span>
               </FormRow>
            </>
         ) : (
            <>
               <InputFieldRow
                  title={intl.formatMessage({
                     id: 'price-calculator.profit',
                     defaultMessage: 'Profit',
                  })}
                  placeholder={intl.formatMessage({
                     id: 'price-calculator.profit',
                     defaultMessage: 'Profit',
                  })}
                  value={formData.rev_profit}
                  fieldName="rev_profit"
                  onChange={handleChange}
                  unit="%"
               />
               <InputFieldRow
                  title={intl.formatMessage({
                     id: 'price-calculator.buyer-shipping-cost',
                     defaultMessage: 'Versandkosten',
                  })}
                  placeholder={intl.formatMessage({
                     id: 'price-calculator.buyer-shipping-cost.placeholder',
                     defaultMessage: 'für Käufer',
                  })}
                  value={formData.buyer_shipping}
                  fieldName="buyer_shipping"
                  onChange={handleChange}
               />
               <Spacer />
               <FormRow
                  label={intl.formatMessage({
                     id: 'price-calculator.provision',
                     defaultMessage: 'Provision',
                  })}
                  type="inline"
               >
                  <Form.Group>
                     <Form.Control
                        as="select"
                        value={formData.provision}
                        onChange={e => handleChange('provision', e.target.value)}
                     >
                        {Object.entries(groupBy(provisions, 'groupName')).map(
                           ([groupName, provisionsGroup]) => (
                              <optgroup key={groupName} label={groupName}>
                                 {provisionsGroup.map(p => (
                                    <option key={p.key} value={p.key}>
                                       {p.display}
                                    </option>
                                 ))}
                              </optgroup>
                           )
                        )}
                     </Form.Control>
                     <small className="form-text text-muted d-flex align-content-center">
                        <span className="me-2">
                           {revResult?.add_provision_min === revResult?.add_provision_max ? (
                              <>
                                 {'~ '}
                                 <ColoredValue
                                    type="currency"
                                    value={revResult?.add_provision_min}
                                 />
                              </>
                           ) : (
                              <>
                                 {'~ '}
                                 <FormattedMessage
                                    id="price-calculator.profit-range"
                                    defaultMessage="{min} bis {max}"
                                    values={{
                                       min: (
                                          <ColoredValue
                                             type="currency"
                                             value={revResult?.add_provision_min}
                                          />
                                       ),
                                       max: (
                                          <ColoredValue
                                             type="currency"
                                             value={revResult?.add_provision_max}
                                          />
                                       ),
                                    }}
                                 />
                              </>
                           )}
                        </span>
                        <EBayProvisioningTooltip />
                     </small>
                  </Form.Group>
               </FormRow>
               <FormRow
                  label={intl.formatMessage({
                     id: 'price-calculator.paypal-provision',
                     defaultMessage: 'PayPal-Gebühren',
                  })}
                  type="inline"
               >
                  <Form.Group>
                     <Form.Control
                        as="select"
                        value={formData.paypal_provision}
                        onChange={e => handleChange('paypal_provision', e.target.value)}
                     >
                        <option value="none">
                           {intl.formatMessage({
                              id: 'price-calculator.paypal-provision.none',
                              defaultMessage: 'Ohne',
                           })}
                        </option>
                        <option value="max_2000">
                           {intl.formatMessage({
                              id: 'price-calculator.paypal-provision.max-2000',
                              defaultMessage: '0,35€ + 2,49%',
                           })}
                        </option>
                     </Form.Control>
                     <small className="form-text text-muted">
                        <span>
                           {revResult?.add_paypal_min === revResult?.add_paypal_max ? (
                              <ColoredValue type="currency" value={revResult?.add_paypal_min} />
                           ) : (
                              <FormattedMessage
                                 id="price-calculator.profit-range"
                                 defaultMessage="{min} bis {max}"
                                 values={{
                                    min: (
                                       <ColoredValue
                                          type="currency"
                                          value={revResult?.add_paypal_min}
                                       />
                                    ),
                                    max: (
                                       <ColoredValue
                                          type="currency"
                                          value={revResult?.add_paypal_max}
                                       />
                                    ),
                                 }}
                              />
                           )}
                        </span>
                     </small>
                  </Form.Group>
               </FormRow>
               <InputFieldRow
                  title={intl.formatMessage({
                     id: 'price-calculator.seller-shipping-cost',
                     defaultMessage: 'Versandkosten',
                  })}
                  placeholder={intl.formatMessage({
                     id: 'price-calculator.seller-shipping-cost.placeholder',
                     defaultMessage: 'für Verkäufer',
                  })}
                  value={formData.shipping}
                  fieldName="shipping"
                  onChange={handleChange}
               />
               <Spacer />
               <FormRow
                  label={intl.formatMessage({
                     id: 'price-calculator.sales-price',
                     defaultMessage: 'Verkaufspreis',
                  })}
                  type="inline"
               >
                  <span className="fs-5">
                     {revResult?.rev_buyer_price_min === revResult?.rev_buyer_price_max ? (
                        <ColoredValue type="currency" value={revResult?.rev_buyer_price_min} />
                     ) : (
                        <FormattedMessage
                           id="price-calculator.profit-range"
                           defaultMessage="{min} bis {max}"
                           values={{
                              min: (
                                 <ColoredValue
                                    type="currency"
                                    value={revResult?.rev_buyer_price_min}
                                 />
                              ),
                              max: (
                                 <ColoredValue
                                    type="currency"
                                    value={revResult?.rev_buyer_price_max}
                                 />
                              ),
                           }}
                        />
                     )}
                  </span>
               </FormRow>
               <FormRow
                  label={intl.formatMessage({
                     id: 'price-calculator.profit',
                     defaultMessage: 'Profit',
                  })}
                  type="inline"
               >
                  <span className="fs-5">
                     {revResult?.rev_profit_range_min === revResult?.rev_profit_range_max ? (
                        <ColoredValue type="currency" value={revResult?.rev_profit_range_min} />
                     ) : (
                        <FormattedMessage
                           id="price-calculator.profit-range"
                           defaultMessage="{min} bis {max}"
                           values={{
                              min: (
                                 <ColoredValue
                                    type="currency"
                                    value={revResult?.rev_profit_range_min}
                                 />
                              ),
                              max: (
                                 <ColoredValue
                                    type="currency"
                                    value={revResult?.rev_profit_range_max}
                                 />
                              ),
                           }}
                        />
                     )}
                  </span>
               </FormRow>
            </>
         )}
      </>
   );
};

type InputFieldRowProps = {
   title: string;
   placeholder: string;
   value?: string;
   fieldName: string;
   onChange: (field: string, val: string) => void;
   unit?: string;
};

const InputFieldRow = (props: InputFieldRowProps) => (
   <FormRow label={props.title} type="inline">
      <FormGroup style={{ marginBottom: 0 }}>
         <InputGroup>
            <Form.Control
               type="text"
               placeholder={props.placeholder}
               value={props.value}
               onChange={e => props.onChange(props.fieldName, e.target.value)}
            />
            <InputGroup.Text>{props.unit ?? '€'}</InputGroup.Text>
         </InputGroup>
      </FormGroup>
   </FormRow>
);

const Spacer = () => (
   <hr
      style={{
         margin: 0,
         marginTop: '0.5rem',
         marginBottom: '0.5rem',
         borderTopWidth: 5,
         borderTopStyle: 'double',
         borderTopColor: '#444',
      }}
   />
);

const EBayProvisioningTooltip = () => {
   const intl = useMemorizedIntl();

   const popoverContent = () => (
      <div>
         <h5 className="text-center mb-3">
            <FormattedMessage
               id="price-calculator.provision.tooltip.popover.ebay.headline"
               defaultMessage="Zahlungsabwicklung durch eBay"
            />
         </h5>
         <div>
            <FormattedMessage
               id="price-calculator.provision.tooltip.popover.ebay.description"
               defaultMessage="Bei der Abwicklung der Zahlung direkt über eBay fallen folgende Gebühren an:"
            />
         </div>
         <div className="m-2 text-center fw-bold">
            <FormattedMessage
               id="price-calculator.provision.tooltip.popover.ebay.provision"
               defaultMessage="11% des Kaufpreises (inkl. Versandkosten), zzgl. 0,35€"
            />
         </div>
         <div className="text-muted fst-italic">
            <FormattedMessage
               id="price-calculator.provision.tooltip.popover.ebay.details"
               defaultMessage="eBay-Verkaufsaktionen reduzieren die prozentualen Gebühren entsprechend, wobei 0,35€ anschließend immer dazukommen."
            />
         </div>
      </div>
   );

   return (
      <Popover
         popoverTitle={intl.formatMessage({
            id: 'price-calculator.provision.tooltip.title',
            defaultMessage: 'Berechnung der eBay Provision',
         })}
         popoverContent={popoverContent()}
      >
         <Icon path={mdiInformationOutline} size={0.65} />
      </Popover>
   );
};
