import { useCallback } from 'react';
import { useCache } from './useCache';
import { LegoSet, LegoSetChangeRequest } from '../types/api';
import { LegoSetPropertyChange } from '../types/LegoSetPropertyChange';
import { LegoSetChangeRequestModel } from '../models/LegoSetChangeRequestModel';
import { useSession } from './useSession';

export const useLegoSetChangeRequest = () => {
   const { sessionUser } = useSession();
   const { categories } = useCache();

   const createRequest = useCallback(
      async (
         legoSet: Partial<LegoSet> | null,
         updatedLegoSet: Partial<LegoSet>
      ): Promise<LegoSetChangeRequest | null> => {
         if (!legoSet) return null;

         // folgende Felder werden nicht verglichen
         const propertiesToIgnore: (keyof LegoSet)[] = [
            'labels',
            'x_date_created',
            'x_date_updated',
         ];
         const propertyChanges: LegoSetPropertyChange[] = [];

         Object.entries(legoSet).forEach(([property, value]) => {
            if (propertiesToIgnore.includes(property as unknown as keyof LegoSet)) return;

            const prop = property as unknown as keyof LegoSet;
            const otherVal = updatedLegoSet[prop];
            if (value !== otherVal) {
               const entry: LegoSetPropertyChange = {
                  name: prop,
                  currentValue: value,
                  newValue: otherVal ?? null,
               };
               if (prop === 'category_id') {
                  entry.currentValueDisplay = categories.find(c => c.id === value)?.name;
                  entry.newValueDisplay = categories.find(c => c.id === otherVal)?.name;
               }

               propertyChanges.push(entry);
            }
         });

         // Es gab keine Änderungen, also wird auch nichts angelegt
         if (propertyChanges.length === 0) return null;

         // Objekt als JSON serialisieren und dann abspeichern
         const changeRequest: Partial<LegoSetChangeRequest> = {
            user_id: sessionUser?.id,
            set_id: updatedLegoSet.id,
            json_data: JSON.stringify(propertyChanges),
         };

         return LegoSetChangeRequestModel.insert(changeRequest);
      },
      [categories, sessionUser]
   );

   return {
      createRequest,
   };
};
