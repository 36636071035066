import { Dropdown } from 'react-bootstrap';
import React, { useCallback } from 'react';
import { SupportedLocales, SupportedLocalesType } from '../../intlHelpers';
import { setLanguage } from '../../redux/appSlice';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { LOCALSTORAGE_LANGUAGE } from '../../utils/constants';

/**
 * DropDown to select the language.
 */
export const LanguageDropdown = () => {
   const dispatch = useAppDispatch();
   const language = useAppSelector(s => s.app.language);

   const handleLanguageChange = useCallback(
      async (lang: string) => {
         localStorage.setItem(LOCALSTORAGE_LANGUAGE, lang);
         dispatch(setLanguage(lang as SupportedLocalesType));
      },
      [dispatch]
   );

   return (
      <Dropdown>
         <Dropdown.Toggle variant="secondary" size="sm">
            <img
               src={SupportedLocales[language].icon}
               alt={SupportedLocales[language].name}
               style={{
                  marginBottom: '0.25rem',
                  marginRight: '0.375rem',
                  height: '0.75rem',
                  width: '1.125rem',
               }}
            />
            {SupportedLocales[language].name}
         </Dropdown.Toggle>
         <Dropdown.Menu>
            {Object.entries(SupportedLocales).map(([key, val]) => (
               <Dropdown.Item
                  key={key}
                  active={key === language}
                  onClick={() => handleLanguageChange(key)}
               >
                  <img
                     src={val.icon}
                     alt={val.name}
                     style={{
                        marginBottom: '0.25rem',
                        marginRight: '0.375rem',
                        height: '0.75rem',
                        width: '1.125rem',
                     }}
                  />
                  {val.name}
               </Dropdown.Item>
            ))}
         </Dropdown.Menu>
      </Dropdown>
   );
};
