import React, { useMemo } from 'react';
import { Chart } from 'react-google-charts';
import { ChartLoader } from '../atoms';
import ApiService from '../../services/ApiService';
import { useChartData } from '../../hooks/useChartData';
import { useAppSelector, useMemorizedIntl } from '../../hooks';

interface Props {
   /**
    * The url part to load the data.
    */
   url: string;
   /**
    * The number format to use for the values (e.g. #.##).
    */
   numberFormat?: string;
   /**
    * The unit to display behind the value (e.g. €).
    */
   unit?: string;
   /**
    * Width of the chart. (e.g. 100%, 20rem)
    */
   width?: string;
   /**
    * Height of the chart. (e.g. 100%, 20rem)
    */
   height?: string;
}

/**
 * Creates a donut chart.
 */
export const DonutChart = ({
   url,
   numberFormat,
   unit,
   width = '100%',
   height = '15rem',
}: Props) => {
   const intl = useMemorizedIntl();
   const stateLanguage = useAppSelector(s => s.app.language);
   const columnNames = useMemo(
      () => [
         intl.formatMessage({
            id: 'chart.donut-generic.legend.date',
            defaultMessage: 'Datum',
         }),
         intl.formatMessage({
            id: 'chart.donut-generic.legend.value',
            defaultMessage: 'Wert',
         }),
      ],
      [intl]
   );
   const { isLoading, rows, columns, donutChartOptions } = useChartData({
      url: `${ApiService.apiEndpoint}/${url}`,
      columnNames: columnNames,
      unit: unit,
      numberFormat: numberFormat,
   });

   const wrapperStyle = useMemo(
      () => ({
         width,
         height,
      }),
      [width, height]
   );

   return (
      <div className="d-flex" style={wrapperStyle}>
         {isLoading ? (
            <ChartLoader />
         ) : (
            <Chart
               width="100%"
               height="100%"
               chartType="PieChart"
               loader={<ChartLoader />}
               data={[columns, ...rows]}
               legendToggle
               options={donutChartOptions}
               chartLanguage={stateLanguage}
            />
         )}
      </div>
   );
};
