import { useMemo } from 'react';
import { useMemorizedIntl } from './useMemorizedIntl';

export interface PropertyTranslation {
   title: string;
   description?: string;
   placeholder?: string;
}

export interface ColumnTranslation {
   [key: string]: PropertyTranslation;
}

export interface TableTranslation {
   [key: string]: ColumnTranslation;
}

export const useModelTranslation = (table?: string): ColumnTranslation | undefined => {
   const intl = useMemorizedIntl();

   const data = useMemo(
      (): TableTranslation => ({
         lego_set: {
            number: {
               title: intl.formatMessage({
                  id: 'model.lego_set.number.title',
                  defaultMessage: 'Nummer',
               }),
               description: intl.formatMessage({
                  id: 'model.lego_set.number.description',
                  defaultMessage: 'Nummer, welche LEGO offiziell vergibt.',
               }),
            },
            name: {
               title: intl.formatMessage({
                  id: 'model.lego_set.name.title',
                  defaultMessage: 'Name',
               }),
               description: intl.formatMessage({
                  id: 'model.lego_set.name.description',
                  defaultMessage: 'Der englische Name des LEGO-Sets',
               }),
            },
            parts: {
               title: intl.formatMessage({
                  id: 'model.lego_set.parts.title',
                  defaultMessage: 'Teile',
               }),
               description: intl.formatMessage({
                  id: 'model.lego_set.parts.description',
                  defaultMessage: 'Anzahl der Teile',
               }),
            },
            remarks: {
               title: intl.formatMessage({
                  id: 'model.lego_set.remarks.title',
                  defaultMessage: 'Beschreibung',
               }),
            },
            category_id: {
               title: intl.formatMessage({
                  id: 'model.lego_set.category_id.title',
                  defaultMessage: 'Kategorie',
               }),
            },
            price: {
               title: intl.formatMessage({
                  id: 'model.lego_set.price.title',
                  defaultMessage: 'Preis',
               }),
               description: intl.formatMessage({
                  id: 'model.lego_set.price.description',
                  defaultMessage: 'Offizieller Verkaufspreis in Euro.',
               }),
            },
            is_gwp: {
               title: intl.formatMessage({
                  id: 'model.lego_set.is_gwp.title',
                  defaultMessage: 'Kostenlose Zugabe bei Kauf',
               }),
               description: intl.formatMessage({
                  id: 'model.lego_set.is_gwp.description',
                  defaultMessage:
                     'GWP - Gift with Purchase. Gibt an, dass dieses LEGO-Set einzeln nicht erworben werden kann. Es liegt als kostenlose Zugabe in Rahmen einer Aktion beim Kauf anderer LEGO-Sets dabei.',
               }),
            },
            availability: {
               title: intl.formatMessage({
                  id: 'model.lego_set.availability.title',
                  defaultMessage: 'Verfügbarkeit',
               }),
               description: intl.formatMessage({
                  id: 'model.lego_set.availability.description',
                  defaultMessage:
                     'Gibt an, wie das LEGO-Sets am Markt verfügbar war (z.B. Freier/Limitierter Verkauf oder kostenlose Zugabe).',
               }),
            },
            retired: {
               title: intl.formatMessage({
                  id: 'model.lego_set.retired.title',
                  defaultMessage: 'Kein offizieller Verkauf mehr',
               }),
               description: intl.formatMessage({
                  id: 'model.lego_set.retired.description',
                  defaultMessage:
                     'Dieses Set ist von LEGO nicht mehr im offiziellen Verkauf. Alle kaufbaren Sets sind Weiterverkäufe oder Restbestände.',
               }),
            },
            status_by_lego: {
               title: intl.formatMessage({
                  id: 'model.lego_set.status_by_lego.title',
                  defaultMessage: 'Lego-Status',
               }),
               description: intl.formatMessage({
                  id: 'model.lego_set.status_by_lego.description',
                  defaultMessage: 'Status des LEGO-Sets wie angegeben im Online-Shop.',
               }),
            },
            publish_date: {
               title: intl.formatMessage({
                  id: 'model.lego_set.publish_date.title',
                  defaultMessage: 'Verkauft ab',
               }),
               description: intl.formatMessage({
                  id: 'model.lego_set.publish_date.description',
                  defaultMessage:
                     'Datum, ab wann das LEGO-Sets in den offiziellen Verkauf in Deutschland ging.',
               }),
            },
            eol_date: {
               title: intl.formatMessage({
                  id: 'model.lego_set.eol_date.title',
                  defaultMessage: 'Verkauft bis',
               }),
               description: intl.formatMessage({
                  id: 'model.lego_set.eol_date.description',
                  defaultMessage:
                     'Datum, ab wann das LEGO-Sets aus dem offiziellen Verkauf in Deutschland ging.',
               }),
            },
            bricklink_not_available: {
               title: intl.formatMessage({
                  id: 'model.lego_set.bricklink_not_available.title',
                  defaultMessage: 'Auf Bricklink nicht verfügbar',
               }),
            },
            bricklink_id: {
               title: intl.formatMessage({
                  id: 'model.lego_set.bricklink_id.title',
                  defaultMessage: 'Bricklink-ID',
               }),
               description: intl.formatMessage({
                  id: 'model.lego_set.bricklink_id.description',
                  defaultMessage:
                     'Interne ID von Bricklink.com für das Set. Wird für das Abrufen von Daten von Bricklink verwendet.',
               }),
            },
            bricklink_type: {
               title: intl.formatMessage({
                  id: 'model.lego_set.bricklink_type.title',
                  defaultMessage: 'Bricklink-Typ',
               }),
               description: intl.formatMessage({
                  id: 'model.lego_set.bricklink_type.description',
                  defaultMessage:
                     'Typ des Sets seitens Bricklink. Wird für das Abrufen von Daten von Bricklink verwendet.',
               }),
            },
            bricklink_url: {
               title: intl.formatMessage({
                  id: 'model.lego_set.bricklink_url.title',
                  defaultMessage: 'Bricklink-URL',
               }),
               description: intl.formatMessage({
                  id: 'model.lego_set.bricklink_url.description',
                  defaultMessage: 'URL für die Übersicht des LEGO-Sets bei Bricklink.',
               }),
            },
            lego_not_available: {
               title: intl.formatMessage({
                  id: 'model.lego_set.lego_not_available.title',
                  defaultMessage: 'Auf Lego-Homepage nicht verfügbar',
               }),
            },
            lego_url: {
               title: intl.formatMessage({
                  id: 'model.lego_set.lego_url.title',
                  defaultMessage: 'Lego-URL',
               }),
               description: intl.formatMessage({
                  id: 'model.lego_set.lego_url.description',
                  defaultMessage: 'URL zum LEGO-Shop.',
               }),
            },
            idealo_not_available: {
               title: intl.formatMessage({
                  id: 'model.lego_set.idealo_not_available.title',
                  defaultMessage: 'Auf Idealo nicht verfügbar',
               }),
            },
            idealo_price_url: {
               title: intl.formatMessage({
                  id: 'model.lego_set.idealo_price_url.title',
                  defaultMessage: 'Idealo Preis-URL',
               }),
               description: intl.formatMessage({
                  id: 'model.lego_set.idealo_price_url.description',
                  defaultMessage: 'URL für das Abrufen der aktuellen Idealo-Preise.',
               }),
            },
            idealo_url: {
               title: intl.formatMessage({
                  id: 'model.lego_set.idealo_url.title',
                  defaultMessage: 'Idealo-URL',
               }),
               description: intl.formatMessage({
                  id: 'model.lego_set.idealo_url.description',
                  defaultMessage: 'URL zur Angebotsseite des Sets bei Idealo.',
               }),
            },
            brickset_not_available: {
               title: intl.formatMessage({
                  id: 'model.lego_set.brickset_not_available.title',
                  defaultMessage: 'Auf Brickset nicht verfügbar',
               }),
            },
            brickset_id: {
               title: intl.formatMessage({
                  id: 'model.lego_set.brickset_id.title',
                  defaultMessage: 'Brickset-ID',
               }),
               description: intl.formatMessage({
                  id: 'model.lego_set.brickset_id.description',
                  defaultMessage:
                     'ID von Bricklink.com für das Set (Nummer & Variante). Wird für das Abrufen von Daten von Brickset verwendet.',
               }),
            },
            weight: {
               title: intl.formatMessage({
                  id: 'model.lego_set.weight.title',
                  defaultMessage: 'Gewicht',
               }),
               description: intl.formatMessage({
                  id: 'model.lego_set.weight.description',
                  defaultMessage: 'Das Gewicht des LEGO-Sets in Gramm.',
               }),
            },
            size: {
               title: intl.formatMessage({
                  id: 'model.lego_set.size.title',
                  defaultMessage: 'Karton-Größe',
               }),
               description: intl.formatMessage({
                  id: 'model.lego_set.size.description',
                  defaultMessage:
                     'Die Größe des LEGO-Sets-Karton (Länge x Breite x Höhe) in cm. Maße sind absteigend sortiert.',
               }),
            },
         },
         user: {
            type: {
               title: intl.formatMessage({
                  id: 'model.user.type.title',
                  defaultMessage: 'Benutzertyp',
               }),
            },
            enabled: {
               title: intl.formatMessage({
                  id: 'model.user.enabled.title',
                  defaultMessage: 'Benutzer ist aktiv?',
               }),
            },
            notification_wanted_enabled: {
               title: intl.formatMessage({
                  id: 'model.user.notification_wanted_enabled.title',
                  defaultMessage: 'Emails im Rahmen von Preis-Alarm versenden?',
               }),
            },
            blog_updates_enabled: {
               title: intl.formatMessage({
                  id: 'model.user.blog_updates_enabled.title',
                  defaultMessage: 'Emails im Rahmen von neuen Blog-Einträgen versenden?',
               }),
            },
            registration_completed: {
               title: intl.formatMessage({
                  id: 'model.user.registration_completed.title',
                  defaultMessage: 'Registrierung vollständig abgeschlossen?',
               }),
            },
            display_language: {
               title: intl.formatMessage({
                  id: 'model.user.display_language.title',
                  defaultMessage: 'Anzeigesprache',
               }),
            },
            sub: {
               title: intl.formatMessage({
                  id: 'model.user.sub.title',
                  defaultMessage: 'Google User-ID',
               }),
            },
            name: {
               title: intl.formatMessage({
                  id: 'model.user.name.title',
                  defaultMessage: 'Name',
               }),
            },
            public_name: {
               title: intl.formatMessage({
                  id: 'model.user.public_name.title',
                  defaultMessage: 'Nickname',
               }),
            },
            email: {
               title: intl.formatMessage({
                  id: 'model.user.email.title',
                  defaultMessage: 'Email-Adresse',
               }),
            },
            locale: {
               title: intl.formatMessage({
                  id: 'model.user.locale.title',
                  defaultMessage: 'Sprache',
               }),
            },
            picture_url: {
               title: intl.formatMessage({
                  id: 'model.user.picture_url.title',
                  defaultMessage: 'Avatar-URL',
               }),
            },
            last_logon: {
               title: intl.formatMessage({
                  id: 'model.user.last_logon.title',
                  defaultMessage: 'Letzter Login',
               }),
            },
            home_screen: {
               title: intl.formatMessage({
                  id: 'model.user.home_screen.title',
                  defaultMessage: 'Startseite',
               }),
               placeholder: intl.formatMessage({
                  id: 'model.user.home_screen.placeholder',
                  defaultMessage: 'Startseite auswählen',
               }),
               description: intl.formatMessage({
                  id: 'model.user.home_screen.description',
                  defaultMessage:
                     'Gibt an, welche Seite als Startseite angezeigt wird. Die Startseite kann über das Klicken auf Brickfolio oben links jederzeit erreicht werden.',
               }),
            },
         },
         category: {
            name: {
               title: intl.formatMessage({
                  id: 'model.category.name.title',
                  defaultMessage: 'Name',
               }),
            },
         },
         label: {
            name: {
               title: intl.formatMessage({
                  id: 'model.label.name.title',
                  defaultMessage: 'Name',
               }),
            },
         },
         image_upload: {
            file: {
               title: intl.formatMessage({
                  id: 'model.image_upload.file.title',
                  defaultMessage: 'Bild',
               }),
            },
            name: {
               title: intl.formatMessage({
                  id: 'model.image_upload.name.title',
                  defaultMessage: 'Name',
               }),
               placeholder: intl.formatMessage({
                  id: 'model.image_upload.name.placeholder',
                  defaultMessage: 'Name',
               }),
            },
            section: {
               title: intl.formatMessage({
                  id: 'model.image_upload.section.title',
                  defaultMessage: 'Kategorie',
               }),
               placeholder: intl.formatMessage({
                  id: 'model.image_upload.section.placeholder',
                  defaultMessage: 'Kategorie',
               }),
            },
            description: {
               title: intl.formatMessage({
                  id: 'model.image_upload.description.title',
                  defaultMessage: 'Kommentar',
               }),
               placeholder: intl.formatMessage({
                  id: 'model.image_upload.description.placeholder',
                  defaultMessage: 'Kommentar',
               }),
            },
         },
         inventory: {
            type: {
               title: intl.formatMessage({
                  id: 'model.inventory.type.title',
                  defaultMessage: 'Inventar-Typ',
               }),
            },
            date: {
               title: intl.formatMessage({
                  id: 'model.inventory.date.title',
                  defaultMessage: 'Kaufdatum',
               }),
               placeholder: intl.formatMessage({
                  id: 'model.inventory.date.placeholder',
                  defaultMessage: 'Kaufdatum',
               }),
            },
            price: {
               title: intl.formatMessage({
                  id: 'model.inventory.price.title',
                  defaultMessage: 'Kaufpreis',
               }),
               placeholder: intl.formatMessage({
                  id: 'model.inventory.price.placeholder',
                  defaultMessage: 'Preis',
               }),
            },
            quality: {
               title: intl.formatMessage({
                  id: 'model.inventory.quality.title',
                  defaultMessage: 'Zustand',
               }),
            },
            serial_number: {
               title: intl.formatMessage({
                  id: 'model.inventory.serial_number.title',
                  defaultMessage: 'Seriennummer',
               }),
            },
            storage_location: {
               title: intl.formatMessage({
                  id: 'model.inventory.storage_location.title',
                  defaultMessage: 'Lagerort',
               }),
            },
            comment: {
               title: intl.formatMessage({
                  id: 'model.inventory.comment.title',
                  defaultMessage: 'Kommentar',
               }),
            },
         },
         sold_set: {
            date: {
               title: intl.formatMessage({
                  id: 'model.sold_set.date.title',
                  defaultMessage: 'Verkaufsdatum',
               }),
            },
            price: {
               title: intl.formatMessage({
                  id: 'model.sold_set.price.title',
                  defaultMessage: 'Preis',
               }),
            },
            comment: {
               title: intl.formatMessage({
                  id: 'model.sold_set.comment.title',
                  defaultMessage: 'Kommentar',
               }),
            },
         },
         blog_post: {
            subject: {
               title: intl.formatMessage({
                  id: 'model.blog_post.subject.title',
                  defaultMessage: 'Überschrift',
               }),
               placeholder: intl.formatMessage({
                  id: 'model.blog_post.subject.placeholder',
                  defaultMessage: 'Überschrift des Beitrages',
               }),
            },
            status: {
               title: intl.formatMessage({
                  id: 'model.blog_post.status.title',
                  defaultMessage: 'Status',
               }),
            },
            body: {
               title: intl.formatMessage({
                  id: 'model.blog_post.body.title',
                  defaultMessage: 'Beitrag',
               }),
            },
         },
         inventory_filter: {
            name: {
               title: intl.formatMessage({
                  id: 'model.inventory_filter.name.title',
                  defaultMessage: 'Name',
               }),
               placeholder: intl.formatMessage({
                  id: 'model.inventory_filter.name.placeholder',
                  defaultMessage: 'Name des Filters',
               }),
            },
            select_condition: {
               title: intl.formatMessage({
                  id: 'model.inventory_filter.select_condition.title',
                  defaultMessage: 'Filter',
               }),
            },
            order_condition: {
               title: intl.formatMessage({
                  id: 'model.inventory_filter.order_condition.title',
                  defaultMessage: 'Sortierung',
               }),
            },
         },
      }),
      [intl]
   );

   return table ? data[table] : undefined;
};
