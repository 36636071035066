import React, { useEffect } from 'react';
import { Alert, Container } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { setTitle } from '../redux/appSlice';
import { useAppDispatch, useHomeRoute, useMemorizedIntl, useSession } from '../hooks';
import { LinkButton } from '../components/molecules';
import { ContentLoader } from '../components/atoms';

// TODO: Welcome-Seite komplett neu gestalten
export const WelcomePage = () => {
   const intl = useMemorizedIntl();
   const dispatch = useAppDispatch();
   const navigate = useNavigate();
   const { loginState } = useSession();
   const { homeRoutePath } = useHomeRoute();

   useEffect(() => {
      dispatch(
         setTitle(
            intl.formatMessage({
               id: 'welcome.title',
               defaultMessage: 'Willkommen',
            })
         )
      );
   }, [dispatch, intl]);

   useEffect(() => {
      if (homeRoutePath) navigate(homeRoutePath, { replace: true });
   }, [homeRoutePath, navigate]);

   if (loginState === 'unknown') return <ContentLoader />;

   return (
      <Container>
         {loginState === 'loggedIn' && (
            <Alert variant="info">
               <p>
                  <FormattedMessage
                     id="welcome.banner.define-home-screen.text"
                     defaultMessage="Du kannst die Startseite in deinen Profil einstellen."
                  />
               </p>
               <LinkButton to="/user/profile">
                  <FormattedMessage
                     id="welcome.banner.define-home-screen.button"
                     defaultMessage="Gehe zum Profil"
                  />
               </LinkButton>
            </Alert>
         )}
         <div className="p-5 mb-4 bg-dark rounded-3">
            <h1 className="display-4">
               <FormattedMessage
                  id="welcome.headline"
                  defaultMessage="Willkommen auf Brickfolio.de"
               />
            </h1>
            <hr className="my-4" />
            <p className="lead">
               <FormattedMessage
                  id="welcome.text1"
                  defaultMessage="Brickfolio.de bietet dir die kostenlose Möglichkeit, deine LEGO-Investment einfach und übersichtlich zu verwalten."
               />
            </p>
            <p className="lead">
               <FormattedMessage
                  id="welcome.text2"
                  defaultMessage="Dabei stehen die folgende Funktionen zur Verfügung:"
               />
            </p>
            <ul>
               <li>
                  <FormattedMessage
                     id="welcome.list1"
                     defaultMessage="Verwaltung von gekauften Sets als Inventar inkl. aktueller erzielbarer Verkaufspreise."
                  />
               </li>
               <li>
                  <FormattedMessage
                     id="welcome.list2"
                     defaultMessage="Hinterlegung von Verkäufen und Berechnung der tatsächlich erzielten Profite je LEGO-Set."
                  />
               </li>
               <li>
                  <FormattedMessage
                     id="welcome.list3"
                     defaultMessage="Pflege einer Wunschliste mit Wunschpreis inkl. eines Preisalarms, wenn das LEGO-Set den Wunschpreis unterschritten hat."
                  />
               </li>
               <li>
                  <FormattedMessage
                     id="welcome.list4"
                     defaultMessage="Umfangreiche Statistiken bzgl. einzelner LEGO-Sets und des eigenen Investments."
                  />
               </li>
               <li>
                  <FormattedMessage id="welcome.list5" defaultMessage="uvm." />
               </li>
            </ul>
            <br />
            <p className="lead">
               <FormattedMessage
                  id="welcome.text3"
                  defaultMessage="Interesse geweckt? Dann logge dich ein und eröffne dadurch dein kostenloses Konto bei Brickfolio.de."
               />
            </p>
         </div>
      </Container>
   );
};
