import React, { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { useMemorizedIntl } from '../../hooks';
import config from '../../utils/config';

/**
 * Shows the current version number.
 */
export const VersionNumber = () => {
   const intl = useMemorizedIntl();
   const [versionClickCounter, setVersionClickCounter] = useState(0);

   const easterEgg = useCallback(() => {
      setVersionClickCounter(v => v + 1);
      // 7 Klicks, aber der letzte wurde gerade gesetzt, weswegen der counter eines weniger hat
      if (versionClickCounter === 6) {
         toast(
            intl.formatMessage({
               id: 'easter-egg.version-number',
               defaultMessage: '🐰🥚 Glückwunsch, du bist nun im Entwicklermodus. 🤪',
            })
         );
      }
   }, [intl, versionClickCounter]);

   return (
      <span className="fst-italic" onClick={easterEgg}>
         {`v${config.appVersion}`}
      </span>
   );
};
