import React, { useCallback, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { isMobile } from 'react-device-detect';
import { ButtonPanel, ResponsiveButton } from '../molecules';

interface Props {
   show: boolean;
   children: React.ReactNode;
   onClose: () => void;
   onDelete: () => Promise<void>;
}

export const ConfirmDeleteDialog = (props: Props) => {
   const [isDeleting, setDeleting] = useState(false);

   const handleDelete = useCallback(async () => {
      setDeleting(true);
      try {
         await props.onDelete();
      } finally {
         setDeleting(false);
      }
   }, [props]);

   return (
      <Modal show={props.show} onHide={props.onClose} centered={isMobile}>
         <Modal.Header closeButton>
            <Modal.Title>
               <FormattedMessage
                  id="dialog.confirm-delete.title"
                  defaultMessage="Löschen bestätigen"
               />
            </Modal.Title>
         </Modal.Header>

         <Modal.Body>{props.children}</Modal.Body>

         <Modal.Footer>
            <ButtonPanel>
               <ResponsiveButton variant="secondary" onClick={props.onClose} disabled={isDeleting}>
                  <FormattedMessage id="button.cancel" defaultMessage="Abbrechen" />
               </ResponsiveButton>
               <ResponsiveButton variant="danger" onClick={handleDelete} isLoading={isDeleting}>
                  <FormattedMessage id="button.delete" defaultMessage="Löschen" />
               </ResponsiveButton>
            </ButtonPanel>
         </Modal.Footer>
      </Modal>
   );
};
