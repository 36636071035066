import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

export const useMemorizedIntl = () => {
   const intl = useIntl();
   const [cachedIntl, setCachedIntl] = useState(intl);

   useEffect(() => {
      setCachedIntl(intl);
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [intl.locale]);

   return cachedIntl;
};
