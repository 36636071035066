import { ValueEditorProps } from 'react-querybuilder';
import { FormGroup } from 'react-bootstrap';
import React from 'react';
import { DateInput } from '../forms/controls';

export const ValueEditor = ({
   operator,
   value,
   handleOnChange,
   title,
   className,
   type,
   inputType,
   values,
}: ValueEditorProps) => {
   if (operator === 'null' || operator === 'notNull') {
      return null;
   }

   switch (type) {
      case 'select':
         return (
            <select
               className={className}
               title={title}
               onChange={e => handleOnChange(e.target.value)}
               value={value}
            >
               {values!.map(v => (
                  <option key={v.name} value={v.name}>
                     {v.label}
                  </option>
               ))}
            </select>
         );

      case 'checkbox':
         return (
            <input
               type="checkbox"
               className="form-check-input"
               title={title}
               onChange={e => handleOnChange(e.target.checked)}
               checked={!!value}
            />
         );

      case 'radio':
         return (
            <span title={title}>
               {values!.map(v => (
                  <div key={v.name} className="form-check-inline">
                     <input
                        className="form-check form-check-input"
                        type="radio"
                        id={v.name}
                        value={v.name}
                        checked={value === v.name}
                        onChange={e => handleOnChange(e.target.value)}
                     />
                     <label className="form-check-label" htmlFor={v.name}>
                        {v.label}
                     </label>
                  </div>
               ))}
            </span>
         );

      default:
         if (inputType === 'date') {
            return (
               <FormGroup className="inline-block d-inline-block">
                  <DateInput
                     value={value ?? ''}
                     className={className}
                     onChange={dateAsISOString => handleOnChange(dateAsISOString)}
                  />
               </FormGroup>
            );
         }
         return (
            <input
               type={inputType || 'text'}
               value={value}
               title={title}
               className={className}
               onChange={e => handleOnChange(e.target.value)}
            />
         );
   }
};

ValueEditor.displayName = 'ValueEditor';
