import ApiService from '../services/ApiService';
import { SingleApiObject } from '../types/api/BaseTypes';
import { IApiResponse } from '../types/IApiResponse';

export abstract class BaseModel<T extends SingleApiObject> {
   public abstract getName(): string;

   public async count(filter?: Partial<T>): Promise<number> {
      return ApiService.count<T>(this.getName(), filter);
   }

   public async list(
      filter?: Partial<T>,
      orderBy?: keyof T,
      orderDirection?: 'ASC' | 'DESC',
      limit?: number
   ): Promise<T[]> {
      return (await ApiService.list<T>(this.getName(), filter, orderBy, orderDirection, limit))
         .data;
   }

   public async listPaged(
      filter?: Partial<T>,
      orderBy?: keyof T,
      orderDirection?: 'ASC' | 'DESC',
      limit?: number,
      page?: number
   ): Promise<IApiResponse<T>> {
      return ApiService.list<T>(this.getName(), filter, orderBy, orderDirection, limit, page);
   }

   protected async search(
      text: string,
      orderBy?: keyof T,
      orderDirection?: 'ASC' | 'DESC',
      limit?: number
   ): Promise<T[]> {
      return (await ApiService.search<T>(this.getName(), text, orderBy, orderDirection, limit))
         .data;
   }

   protected async searchPaged(
      text: string,
      orderBy?: keyof T,
      orderDirection?: 'ASC' | 'DESC',
      limit?: number,
      page?: number
   ): Promise<IApiResponse<T>> {
      return ApiService.search<T>(this.getName(), text, orderBy, orderDirection, limit, page);
   }

   public async get(id: number): Promise<T | null> {
      return ApiService.get<T>(this.getName(), id);
   }

   public async insert(obj: Partial<T>): Promise<T> {
      return ApiService.insert<T>(this.getName(), obj);
   }

   public async update(obj: Partial<T>): Promise<T> {
      return ApiService.update<T>(this.getName(), obj);
   }

   public async delete(obj: Partial<T>): Promise<void> {
      return ApiService.delete<T>(this.getName(), obj.id ?? 0);
   }
}
