import React, { useCallback } from 'react';
import { FormikErrors, FormikProps } from 'formik';
import { invalidateInventory, reloadLegoSet } from '../redux/pagesSlice';
import store from '../redux';
import { Inventory, SoldSet } from '../types/api';
import { SoldSetModel } from '../models/SoldSetModel';
import { InventoryModel } from '../models/InventoryModel';
import { TextareaControl, TextControl } from '../components/forms';
import { DateControl } from '../components/forms/DateControl';
import { useValidationErrors } from '../hooks';

interface Props {
   formik: Pick<
      FormikProps<Partial<SoldSet>>,
      | 'errors'
      | 'isValid'
      | 'touched'
      | 'values'
      | 'handleChange'
      | 'handleBlur'
      | 'isSubmitting'
      | 'setFieldValue'
      | 'submitCount'
   >;
}

export const SoldSetForm = ({ formik }: Props) => (
   <>
      <DateControl formik={formik} model="sold_set" name="date" />
      <TextControl
         formik={formik}
         model="sold_set"
         name="price"
         inputType="number"
         min={0}
         append="€"
      />
      <TextareaControl formik={formik} model="sold_set" name="comment" />
   </>
);

export const useValidationHandler = () => {
   const validationErrors = useValidationErrors();

   return useCallback(
      (values: Partial<SoldSet>) => {
         const errors: FormikErrors<SoldSet> = {};

         if (!values.date) errors.date = validationErrors.required;
         if (!values.price) errors.price = validationErrors.required;

         return errors;
      },
      [validationErrors]
   );
};

export const handleSubmit = async (values: Partial<SoldSet>, inventory: Partial<Inventory>) => {
   if (!values.id) {
      values.inventory_id = inventory.id;
      await SoldSetModel.insert(values);
   } else {
      await SoldSetModel.update(values);
   }

   await InventoryModel.update({
      ...inventory,
      type: 'sold',
   });
   store.dispatch(reloadLegoSet(inventory.set_id));
   store.dispatch(invalidateInventory());
};
