import React, { useCallback, useEffect, useState } from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import { Formik } from 'formik';
import { showBackButton } from '../../../redux/appSlice';
import { LegoSetForm, useValidationHandler } from '../../../forms/LegoSetForm';
import { ButtonPanel, ResponsiveButton } from '../../../components/molecules';
import { useAppDispatch, useMemorizedIntl } from '../../../hooks';
import { LegoSet } from '../../../types/api';
import { LegoSetModel } from '../../../models/LegoSetModel';
import { ContentLoader } from '../../../components/atoms';
import { SubmitButton } from '../../../components/forms';
import { PageTitle } from '../../../components/organisms/PageTitle';
import { pageLinks } from '../../../utils';
import { useLegoSetChangeRequest } from '../../../hooks/useLegoSetChangeRequest';

export const LegoSetChangeRequestPage = () => {
   const dispatch = useAppDispatch();
   const navigate = useNavigate();
   const intl = useMemorizedIntl();
   const { createRequest } = useLegoSetChangeRequest();
   const routeParams = useParams<{ number?: string }>();
   const handleValidate = useValidationHandler();
   const [legoSet, setLegoSet] = useState<Partial<LegoSet> | null>(null);

   useEffect(() => {
      (async () => {
         const legoSets = await LegoSetModel.list({ number: routeParams.number });
         // Das angegebene LegoSet existiert nicht, also hier abbrechen
         if (legoSets.length === 0) {
            navigate('/404', { replace: true });
            return;
         }

         setLegoSet(legoSets[0]);
         dispatch(showBackButton(true));
      })();
   }, [routeParams.number, dispatch, navigate, intl]);

   const handleFormSubmit = useCallback(
      async (values: Partial<LegoSet>) => {
         const requestCreated = await createRequest(legoSet, values);
         if (!requestCreated) return;

         navigate(pageLinks.legoSetDetail(values));
      },
      [createRequest, legoSet, navigate]
   );

   if (!legoSet) return <ContentLoader />;

   return (
      <Container>
         <PageTitle
            title={intl.formatMessage({
               id: 'legoset-change-request.title',
               defaultMessage: 'Stammdaten ändern',
            })}
            subtitle={`${legoSet.number}: ${legoSet.name}`}
         />
         <p className="text-muted">
            <FormattedMessage
               id="legoset-change-request.page-description"
               defaultMessage="Hier hast du die Möglichkeit falsche Stammdaten eines LEGO-Sets zu melden. Ändere dazu einfach den falschen Wert und sende alles ab. Anschließend wird deine Änderung geprüft und übernommen. Berücksichtige, dass die Prüfung ein paar Tage dauern kann."
            />
         </p>
         <Row>
            <Col>
               <Formik
                  onSubmit={handleFormSubmit}
                  initialValues={legoSet}
                  enableReinitialize
                  validate={handleValidate}
               >
                  {formik => (
                     <Form
                        noValidate
                        onSubmit={e => {
                           e.preventDefault();
                           formik.handleSubmit();
                        }}
                     >
                        <LegoSetForm formik={formik} />
                        <ButtonPanel isFormFooter>
                           <ResponsiveButton variant="secondary" onClick={() => navigate(-1)}>
                              <FormattedMessage id="button.cancel" defaultMessage="Abbrechen" />
                           </ResponsiveButton>
                           <SubmitButton formik={formik}>
                              <FormattedMessage
                                 id="button.report-changes"
                                 defaultMessage="Änderungen einreichen"
                              />
                           </SubmitButton>
                        </ButtonPanel>
                     </Form>
                  )}
               </Formik>
            </Col>
         </Row>
      </Container>
   );
};
