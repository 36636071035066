import React, { ReactNode } from 'react';
import { FormRow } from './FormRow';
import { FormFieldError } from './FormFieldError';
import { FormikControl } from '../../types/FormikControl';
import { useModelTranslation } from '../../hooks';

export interface ControlProps<T> extends FormikControl<T> {
   /**
    * The label for the form control.
    */
   label?: string | ReactNode;
   /**
    * An optional description for the form control.
    */
   description?: string;
   /**
    * When `true` any available validation errors will be hidden.
    */
   hideValidationErrors?: boolean;
   /**
    * Determine the display type. Default 'responsive'.
    *
    * `compact`: The control utilized the whole width and the label is above the control.
    * `responsive`: When there is enough space, the label will be left to the control. Otherwise, the 'compact' type will be used.
    */
   type?: 'compact' | 'responsive';
   helpText?: ReactNode;
}

export interface GenericControlProps<T> extends ControlProps<T> {
   /**
    * The form control.
    */
   children: React.ReactNode;
}

/**
 * Renders a responsive form control with a label and an optional description.
 * Additional any available validation errors will be displayed when enabled.
 */
export const GenericControl = <T,>({
   formik,
   model,
   name,
   label,
   description,
   children,
   hideValidationErrors = false,
   type,
   helpText,
}: GenericControlProps<T>) => {
   const modelTranslation = useModelTranslation(model);

   return (
      <FormRow
         label={modelTranslation?.[name as string]?.title ?? label}
         description={modelTranslation?.[name as string]?.description ?? description}
         type={type}
         helpText={helpText}
      >
         {children}
         {!hideValidationErrors && <FormFieldError name={name} formik={formik} />}
      </FormRow>
   );
};
