import React from 'react';
import { InlineLoader } from '../atoms';
import { BadgeButton, BadgeButtonProps } from './BadgeButton';

export interface LoadingButtonProps extends BadgeButtonProps {
   /**
    * If `true` then button will be disabled and a loading spinner is appended to its content.
    */
   isLoading?: boolean;
   /**
    * If `true` the provided content of the button will be hidden and only the spinner is visible during loading.
    */
   hideContentWhenLoading?: boolean;
}

/**
 * Creates a button with an optional loading spinner.
 * If {@link LoadingButtonProps.isLoading} is set to `true` the button is disabled and
 * a loading spinner is appended to its content.
 */
export const LoadingButton = ({
   variant = 'primary',
   children,
   disabled,
   isLoading = false,
   hideContentWhenLoading = false,
   ...props
}: LoadingButtonProps) => (
   <BadgeButton {...props} variant={variant} disabled={disabled || isLoading}>
      <InlineLoader show={isLoading} style={{ marginRight: '0.25rem' }} />
      {(!isLoading || !hideContentWhenLoading) && children}
   </BadgeButton>
);
