// https://github.com/formatjs/formatjs/blob/main/packages/react-intl/examples/StaticTypeSafetyAndCodeSplitting/StaticTypeSafetyAndCodeSplitting.tsx

import React from 'react';
import { IntlProvider as OrigIntlProvider } from 'react-intl';

// "import type" ensures en messages aren't bundled by default
import * as sourceOfTruth from './assets/compiled-lang/en.json';
import FlagEN from './assets/img/lang/en.png';
import FlagDE from './assets/img/lang/de.png';

export type LocaleMessages = typeof sourceOfTruth;
export type LocaleKey = keyof LocaleMessages;

export const SupportedLocales = {
   en: {
      name: 'English',
      icon: FlagEN,
   },
   de: {
      name: 'Deutsch',
      icon: FlagDE,
   },
};

export type SupportedLocalesType = keyof typeof SupportedLocales;

// return type on this signature enforces that all languages have the same translations defined
export function importMessages(locale: SupportedLocalesType): Promise<LocaleMessages> {
   switch (locale) {
      case 'de':
         return import('./assets/compiled-lang/de.json');
      case 'en':
      default:
         return import('./assets/compiled-lang/en.json');
   }
}

export const IntlProvider: React.FC<
   Omit<React.ComponentProps<typeof OrigIntlProvider>, 'messages'> & {
      messages: LocaleMessages;
   }
> = props => <OrigIntlProvider {...props} />;
