import { NotToggleProps } from 'react-querybuilder';
import React from 'react';
import { Form } from 'react-bootstrap';

export const NotToggle = ({ className, handleOnChange, title, checked }: NotToggleProps) => (
   <div className={`form-check-inline ${className}`}>
      <Form.Check
         type="checkbox"
         label={title}
         checked={!!checked}
         onChange={e => handleOnChange(e.target.checked)}
      />
   </div>
);

NotToggle.displayName = 'BootstrapNotToggle';
