import { useMemorizedIntl } from './useMemorizedIntl';

export const useValidationErrors = () => {
   const intl = useMemorizedIntl();

   return {
      required: intl.formatMessage({
         id: 'validation-error.required',
         defaultMessage: 'Bitte gebe einen Wert an.',
      }),
      legoSetAlreadyExist: intl.formatMessage({
         id: 'validation-error.legoSetAlreadyExist',
         defaultMessage: 'Ein LEGO-Set mit dieser Nummer existiert bereits.',
      }),
   };
};
