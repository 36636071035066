import { useSearchParams } from 'react-router-dom';
import { useCallback } from 'react';

export const useQueryParams = <T extends Record<string, string>>() => {
   const [rawQueryParams, setRawQueryParams] = useSearchParams();
   const queryParams = Object.fromEntries(rawQueryParams) as T;

   const setQueryParams = useCallback(
      (newQueryParams: (prev: T) => T) => {
         setRawQueryParams(new URLSearchParams(newQueryParams(queryParams)));
      },
      [queryParams, setRawQueryParams]
   );

   return [queryParams, setQueryParams] as const;
};
