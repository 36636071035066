import React from 'react';
import { mdiTrendingDown, mdiTrendingNeutral, mdiTrendingUp } from '@mdi/js';
import Icon from '@mdi/react';
import { Colors } from '../Colors';
import { usePriceTrend } from '../../utils';

interface Props {
   /**
    * The history of the market value of a lego set.
    */
   marketValueHistory: [string, number | null][];
   /**
    * The size of the icon.
    */
   size?: number;
   /**
    * Defines the color of the trend arrow (good = green, bad = red).
    */
   type?: 'higherIsBetter' | 'lowerIsBetter' | 'neutral';
}

/**
 * Creates an icon which represent the trend of the price of the given lego set statistics.
 * The icon will be color coded (ascending = green, descending = red, neutral = white).
 * @constructor
 */
export const PriceTrendIcon = ({
   marketValueHistory,
   size = 1,
   type = 'higherIsBetter',
}: Props) => {
   const trend = usePriceTrend(marketValueHistory);
   const getArrowColor = (trendDirection: 'up' | 'down') => {
      if (type === 'neutral') return Colors.white;

      if (trendDirection === 'up') return type === 'higherIsBetter' ? Colors.good : Colors.bad;
      return type === 'higherIsBetter' ? Colors.bad : Colors.good;
   };

   if (trend === null) return null;

   if (trend < -0.1)
      return <Icon path={mdiTrendingDown} color={getArrowColor('down')} size={size} />;

   if (trend > 0.1) return <Icon path={mdiTrendingUp} color={getArrowColor('up')} size={size} />;

   return <Icon path={mdiTrendingNeutral} color={Colors.white} size={size} />;
};
