import { Badge, Button, ButtonProps } from 'react-bootstrap';
import React from 'react';

export interface BadgeButtonProps extends ButtonProps {
   /**
    * Optional badge counter to show on the button.
    */
   badge?: number;
   /**
    * The position of the badge if present.
    */
   badgePosition?: 'inline' | 'topRight';
   block?: boolean;
}

/**
 * Creates a button with a badge.
 *
 * The badge will only been shown if the value of {@link BadgeButtonProps.badge} is greater than zero. If the value is
 * greater than 100, only `+99` will be shown.
 */
export const BadgeButton = ({
   className,
   badge = 0,
   badgePosition = 'topRight',
   children,
   block = false,
   ...props
}: BadgeButtonProps) => {
   // Wir müssen die Badge-Farbe abhängig von der Button-Farbe anpassen
   const badgeVariant = props.variant === 'primary' ? 'light' : 'primary';

   return (
      <Button {...props} className={`${className} position-relative ${block ? 'w-100' : ''}`}>
         {children}
         {badge > 0 && (
            <>
               {badgePosition === 'topRight' && (
                  <Badge
                     bg={badgeVariant}
                     className="position-absolute"
                     style={{ top: '-0.5rem', right: '-0.5rem' }}
                     pill
                  >
                     {badge < 100 ? `${badge}` : '+99'}
                  </Badge>
               )}
               {badgePosition === 'inline' && (
                  <Badge bg={badgeVariant} className="ms-2">
                     {badge < 100 ? `${badge}` : '+99'}
                  </Badge>
               )}
            </>
         )}
      </Button>
   );
};
