import React, { useMemo } from 'react';
import { OverlayTrigger, Popover as BsPopover } from 'react-bootstrap';

import './Popover.scss';

interface Props {
   /**
    * Element which will have a popover.
    */
   children: React.ReactElement;
   /**
    * Title of popover. If `null` the title will not be shown.
    */
   popoverTitle?: string;
   /**
    * Content of the popover.
    */
   popoverContent: React.ReactElement;
   /**
    * The position of the popover.
    */
   placement?:
      | 'auto-start'
      | 'auto'
      | 'auto-end'
      | 'top-start'
      | 'top'
      | 'top-end'
      | 'right-start'
      | 'right'
      | 'right-end'
      | 'bottom-end'
      | 'bottom'
      | 'bottom-start'
      | 'left-end'
      | 'left'
      | 'left-start';
}

/**
 * Creates an element with a popover.
 */
export const Popover = ({ popoverTitle, popoverContent, children, placement = 'top' }: Props) => {
   const popover = useMemo(
      () => (
         <BsPopover id="popover-basic" onClick={e => e.preventDefault()}>
            {popoverTitle && (
               <BsPopover.Header as="h3" className="text-center">
                  {popoverTitle}
               </BsPopover.Header>
            )}
            <BsPopover.Body>{popoverContent}</BsPopover.Body>
         </BsPopover>
      ),
      [popoverContent, popoverTitle]
   );

   return (
      <OverlayTrigger trigger={['hover', 'focus']} placement={placement} overlay={popover}>
         <span className="d-flex align-items-center" onClick={e => e.preventDefault()}>
            {children}
         </span>
      </OverlayTrigger>
   );
};
