import React, { useEffect } from 'react';
import { Badge, Container, ListGroup } from 'react-bootstrap';
import orderBy from 'lodash/orderBy';
import { Link } from 'react-router-dom';
import Icon from '@mdi/react';
import { mdiChevronRight } from '@mdi/js/commonjs/mdi';
import { showBackButton } from '../../redux/appSlice';
import { useAppDispatch, useAppSelector, useMemorizedIntl } from '../../hooks';
import { Colors } from '../../components/Colors';
import { PageTitle } from '../../components/organisms/PageTitle';
import { pageLinks } from '../../utils';

export const AllLabelsPage = () => {
   const intl = useMemorizedIntl();
   const dispatch = useAppDispatch();
   const allLabels = useAppSelector(s => s.data.labels);

   useEffect(() => {
      (async () => {
         dispatch(showBackButton(false));
      })();
   }, [dispatch]);

   return (
      <Container>
         <PageTitle
            title={intl.formatMessage({
               id: 'label.title.all',
               defaultMessage: 'Alle Labels',
            })}
         />
         <ListGroup>
            {orderBy(allLabels, 'name')
               .filter(l => l.lego_sets.length + l.lego_sets_by_user.length > 0)
               .map(l => (
                  <ListGroup.Item
                     key={l.id}
                     as={Link}
                     to={pageLinks.legoSetsByLabel(l)}
                     className="d-flex flex-row align-items-center pe-0"
                     action
                  >
                     <span className="flex-fill">{l.name}</span>
                     <Badge className="badge-lg" pill bg="secondary">
                        {l.lego_sets.length + l.lego_sets_by_user.length}
                     </Badge>
                     <div className="d-flex align-items-center px-1">
                        <Icon path={mdiChevronRight} color={Colors.link} size={1} />
                     </div>
                  </ListGroup.Item>
               ))}
         </ListGroup>
      </Container>
   );
};
