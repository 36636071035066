import { Container } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { showBackButton } from '../../../redux/appSlice';
import { Card } from '../../../components/molecules';
import { SetImportModel } from '../../../models/SetImportModel';
import { User } from '../../../types/api';
import { formatDate, pageLinks } from '../../../utils';
import { UserModel } from '../../../models/UserModel';
import { useAppDispatch, useCache } from '../../../hooks';
import { PageTitle } from '../../../components/organisms/PageTitle';
import { Pagination } from '../../../components/organisms/Pagination';
import { ContentLoader } from '../../../components/atoms';

export const LegoSetImportsAdminPage = () => {
   const dispatch = useAppDispatch();
   const [users, setUsers] = useState<User[]>([]);
   const { categories } = useCache();

   useEffect(() => {
      (async () => {
         dispatch(showBackButton(true));
         setUsers(await UserModel.list());
      })();
   }, [dispatch]);

   return (
      <Container>
         <PageTitle title="LegoSets-Importe" subtitle="Administration" />
         <Pagination
            model={SetImportModel}
            orderColumn="x_date_created"
            orderDesc
            initialPageSize={50}
         >
            {({ items }) =>
               items === null ? (
                  <ContentLoader />
               ) : (
                  items.map(l => (
                     <Card key={l.id} classNameCard={`import-status-${l.status.toLowerCase()}`}>
                        <div className="d-flex flex-column">
                           <div>
                              <span>{`${formatDate(l.x_date_created, 'L LTS')} by `}</span>
                              <Link to={`/admin/users/${l.user_id}`}>
                                 {users.find(u => u.id === l.user_id)?.name}
                              </Link>
                           </div>
                           <div>
                              <Link
                                 to={pageLinks.legoSetsByCategory(
                                    categories.find(c => c.id === l.category_id)
                                 )}
                              >
                                 {categories.find(c => c.id === l.category_id)?.name}
                              </Link>
                              <span className="mx-1">•</span>
                              {l.set_id ? (
                                 <Link
                                    to={pageLinks.legoSetDetail({
                                       number: l.number,
                                       name: l.set_name ?? '',
                                    })}
                                 >{`${l.number}: ${l.set_name}`}</Link>
                              ) : (
                                 <span>{l.number}</span>
                              )}
                           </div>
                           <div className="fst-italic text-muted">{l.message}</div>
                        </div>
                     </Card>
                  ))
               )
            }
         </Pagination>
      </Container>
   );
};
