import React from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import Icon from '@mdi/react';
import { mdiHelpCircleOutline } from '@mdi/js';
import { isMobile } from 'react-device-detect';
import { Colors } from '../Colors';

interface Props {
   /**
    * The text for inside the tooltip.
    */
   text: string;
   /**
    * An optional element which has the tooltip.
    */
   children?: React.ReactElement;
   /**
    * An optional icon wich differs from the question mark.
    */
   icon?: string;
}

/**
 * Renders the given text as a tooltip for the given children. If no children is provided, an info icon is used.
 */
export const InfoTooltip = ({ text, children, icon }: Props) => (
   <OverlayTrigger
      trigger={isMobile ? ['click'] : ['focus', 'hover']}
      placement="auto"
      rootClose
      overlay={
         <Popover id="popover-meta">
            <Popover.Body className="d-flex flex-column">{text}</Popover.Body>
         </Popover>
      }
   >
      {children ?? (
         <Icon
            className="mx-1 cursor-pointer align-middle"
            path={icon ?? mdiHelpCircleOutline}
            size={0.75}
            color={Colors.light}
         />
      )}
   </OverlayTrigger>
);
