import React, { CSSProperties } from 'react';
import { Button, ButtonProps, Dropdown } from 'react-bootstrap';
import { useScrollIndicator } from '../../utils/useScrollIndicator';

interface Props {
   children: React.ReactNode;
   style?: CSSProperties;
   className?: string;
}

/**
 * Creates a Button with a popup container like a drop down.
 * ```jsx
 *    <CustomDropDown>
 *       <CustomDropDown.Trigger>
 *          <Icon path={mdiBellOutline} color={Colors.white} size={1} />
 *       </CustomDropDown.Trigger>
 *       <CustomDropDown.Content>
 *          <div>This is the content</div>
 *       </CustomDropDown.Content>
 *    </CustomDropDown>
 * ```
 */
export const CustomDropDown = ({ children, style, className }: Props) => (
   <Dropdown style={style} className={className} align="end">
      {children}
   </Dropdown>
);

/**
 * The trigger element for the drop down (e.g. a Button)
 */
CustomDropDown.Trigger = ({ children, style, className }: Props) => (
   <Dropdown.Toggle as={NotificationsDropDownButton} style={style} className={className}>
      {children}
   </Dropdown.Toggle>
);

/**
 * The content element for the dropdown (aka the popup)
 */
const CustomDropDownContent = ({ children, style, className }: Props) => {
   const [state] = useScrollIndicator({ onElement: true });

   return (
      <Dropdown.Menu as={NotificationsDropDownMenu} className="p-0">
         <div ref={state.targetElement} style={style} className={`${className} p-3`}>
            {state.value > 0 && <div className="scrollable-top-indicator" />}
            {children}
            {state.value < 100 && <div className="scrollable-bottom-indicator" />}
         </div>
      </Dropdown.Menu>
   );
};
CustomDropDown.Content = CustomDropDownContent;

const NotificationsDropDownButton = React.forwardRef<HTMLButtonElement, ButtonProps>(
   ({ children, onClick, style }, ref) => (
      <Button
         ref={ref}
         style={style}
         variant="primary"
         onClick={e => {
            e.preventDefault();
            onClick?.(e);
         }}
      >
         {children}
      </Button>
   )
);

interface NotificationsDropDownMenuProps {
   style?: CSSProperties;
   className?: string;
}

const NotificationsDropDownMenu = React.forwardRef<HTMLDivElement, NotificationsDropDownMenuProps>(
   ({ children, style, className }, ref) => (
      <div ref={ref} style={style} className={`${className}`}>
         {children}
      </div>
   )
);
