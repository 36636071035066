import { useCallback, useMemo } from 'react';
import { useMemorizedIntl } from '../../../hooks';
import { InventoryWithStats } from '../../../types/api/InventoryWithStats';

interface Provision {
   key: string;
   groupName: string;
   display: string;
   calcFunc?: (amount: number) => number;
   calcFuncReverse?: (amount: number) => number;
}

export interface FormData {
   buyer_price: string;
   buyer_shipping: string;
   provision: string;
   paypal_provision: string;
   shipping: string;
   rev_profit: string;
}

export interface CalculationResult {
   eBayProvision: number;
   paypalProvision: number;
   revenue: number;
   profit_range_min: number;
   profit_range_max: number;
   profit_percentage_min: number;
   profit_percentage_max: number;
}

export interface ReverseCalculationResult {
   add_provision_min: number;
   add_provision_max: number;
   add_paypal_min: number;
   add_paypal_max: number;
   rev_buyer_price_min: number;
   rev_buyer_price_max: number;
   rev_profit_range_min: number;
   rev_profit_range_max: number;
}

export const useProvisionCalculator = (inventory: InventoryWithStats[]) => {
   const intl = useMemorizedIntl();

   const calcBasicEbayProvision = useCallback(
      (price: number, provision = 0.11) => price * provision,
      []
   );
   const getFixEBayProvision = useCallback((price: number) => (price <= 10 ? 0.05 : 0.35), []);

   const eBayProvisions: Provision[] = useMemo(() => {
      const groupGeneric = intl.formatMessage({
         id: 'price-calculator.provision.group.generic',
         defaultMessage: 'Allgemein',
      });
      const groupEbay = intl.formatMessage({
         id: 'price-calculator.provision.group.ebay',
         defaultMessage: 'eBay',
      });
      const groupEbay8PercentCampaing = intl.formatMessage({
         id: 'price-calculator.provision.group.ebay_8_percent_campaign',
         defaultMessage: 'eBay (8% Aktion)',
      });

      return [
         {
            key: 'none',
            groupName: groupGeneric,
            display: intl.formatMessage({
               id: 'price-calculator.provision.none',
               defaultMessage: 'Ohne',
            }),
            calcFunc: () => 0,
            calcFuncReverse: () => 0,
         },
         {
            key: '8_percent',
            groupName: groupEbay8PercentCampaing,
            display: intl.formatMessage({
               id: 'price-calculator.provision.8_percent',
               defaultMessage: '8% + 0,35€',
            }),
            calcFunc: price => calcBasicEbayProvision(price, 0.08) + getFixEBayProvision(price),
            calcFuncReverse: price => price / (1 - 0.08) + getFixEBayProvision(price) - price,
         },
         {
            key: '8_percent_minus_30_percent',
            groupName: groupEbay8PercentCampaing,
            display: intl.formatMessage({
               id: 'price-calculator.provision.8_percent_minus_30_percent',
               defaultMessage: '8% - 30% + 0,35€',
            }),
            calcFunc: price =>
               calcBasicEbayProvision(price, 0.08) * (1 - 0.3) + getFixEBayProvision(price),
            calcFuncReverse: price =>
               price / (1 - 0.08 * (1 - 0.3)) + getFixEBayProvision(price) - price,
         },
         {
            key: '8_percent_minus_40_percent',
            groupName: groupEbay8PercentCampaing,
            display: intl.formatMessage({
               id: 'price-calculator.provision.8_percent_minus_40_percent',
               defaultMessage: '8% - 40% + 0,35€',
            }),
            calcFunc: price =>
               calcBasicEbayProvision(price, 0.08) * (1 - 0.4) + getFixEBayProvision(price),
            calcFuncReverse: price =>
               price / (1 - 0.08 * (1 - 0.4)) + getFixEBayProvision(price) - price,
         },
         {
            key: '8_percent_minus_50_percent',
            groupName: groupEbay8PercentCampaing,
            display: intl.formatMessage({
               id: 'price-calculator.provision.8_percent_minus_50_percent',
               defaultMessage: '8% - 50% + 0,35€',
            }),
            calcFunc: price =>
               calcBasicEbayProvision(price, 0.08) * (1 - 0.5) + getFixEBayProvision(price),
            calcFuncReverse: price =>
               price / (1 - 0.08 * (1 - 0.5)) + getFixEBayProvision(price) - price,
         },
         {
            key: 'max_one_new',
            groupName: groupEbay,
            display: intl.formatMessage({
               id: 'price-calculator.provision.max-one-new',
               defaultMessage: 'max. 1€ + 0,35€',
            }),
            calcFunc: price =>
               (calcBasicEbayProvision(price) >= 1.0 ? 1.0 : calcBasicEbayProvision(price)) +
               getFixEBayProvision(price),
            calcFuncReverse: price =>
               Math.min(price / 0.89, price + 1) + getFixEBayProvision(price) - price,
         },
         {
            key: 'max_two_new',
            groupName: groupEbay,
            display: intl.formatMessage({
               id: 'price-calculator.provision.max-two-new',
               defaultMessage: 'max. 2€ + 0,35€',
            }),
            calcFunc: price =>
               (calcBasicEbayProvision(price) >= 2.0 ? 2.0 : calcBasicEbayProvision(price)) +
               getFixEBayProvision(price),
            calcFuncReverse: price =>
               Math.min(price / 0.89, price + 2) + getFixEBayProvision(price) - price,
         },
         {
            key: 'max_three_new',
            groupName: groupEbay,
            display: intl.formatMessage({
               id: 'price-calculator.provision.max-three-new',
               defaultMessage: 'max. 3€ + 0,35€',
            }),
            calcFunc: price =>
               (calcBasicEbayProvision(price) >= 3.0 ? 3.0 : calcBasicEbayProvision(price)) +
               getFixEBayProvision(price),
            calcFuncReverse: price =>
               Math.min(price / 0.89, price + 3) + getFixEBayProvision(price) - price,
         },
         {
            key: 'max_five_new',
            groupName: groupEbay,
            display: intl.formatMessage({
               id: 'price-calculator.provision.max-five-new',
               defaultMessage: 'max. 5€ + 0,35€',
            }),
            calcFunc: price =>
               (calcBasicEbayProvision(price) >= 4.0 ? 5.0 : calcBasicEbayProvision(price)) +
               getFixEBayProvision(price),
            calcFuncReverse: price =>
               Math.min(price / 0.89, price + 5) + getFixEBayProvision(price) - price,
         },
         {
            key: 'less_50_percent',
            groupName: groupEbay,
            display: intl.formatMessage({
               id: 'price-calculator.provision.less-fifty-percent',
               defaultMessage: '-50% + 0,35€',
            }),
            calcFunc: price => calcBasicEbayProvision(price) * 0.5 + getFixEBayProvision(price),
            calcFuncReverse: price => price / (1 - 0.11 * 0.5) + getFixEBayProvision(price) - price,
         },
         {
            key: 'less_60_percent',
            groupName: groupEbay,
            display: intl.formatMessage({
               id: 'price-calculator.provision.less-sixty-percent',
               defaultMessage: '-60% + 0,35€',
            }),
            calcFunc: price => calcBasicEbayProvision(price) * 0.4 + getFixEBayProvision(price),
            calcFuncReverse: price => price / (1 - 0.11 * 0.4) + getFixEBayProvision(price) - price,
         },
         {
            key: 'less_70_percent',
            groupName: groupEbay,
            display: intl.formatMessage({
               id: 'price-calculator.provision.less-seventy-percent',
               defaultMessage: '-70% + 0,35€',
            }),
            calcFunc: price => calcBasicEbayProvision(price) * 0.3 + getFixEBayProvision(price),
            calcFuncReverse: price => price / (1 - 0.11 * 0.3) + getFixEBayProvision(price) - price,
         },
      ];
   }, [intl, calcBasicEbayProvision, getFixEBayProvision]);

   const paypalProvisions: Provision[] = useMemo(
      () => [
         {
            key: 'max_2000',
            groupName: '',
            display: '',
            calcFunc: price => price * 0.0249 + 0.35,
            calcFuncReverse: price => price / (1 - 0.0249) + 0.35 - price,
         },
      ],
      []
   );

   const convertToNumber = (numberAsString?: string, fallback = 0) => {
      const n = parseFloat(numberAsString?.replace(',', '.') ?? `${fallback}`);
      return Number.isNaN(n) ? fallback : n;
   };

   const calcPrice = useCallback(
      (formData: FormData): CalculationResult => {
         const priceForTheBuyerForTheLegoSet = convertToNumber(formData.buyer_price);
         const shippingPriceTheBuyerPays = convertToNumber(formData.buyer_shipping);
         const moneyTheBuyerHasToPay = priceForTheBuyerForTheLegoSet + shippingPriceTheBuyerPays;
         const shippingPriceTheSellerHasToPay = convertToNumber(formData.shipping);

         // ======================================================

         const eBayProvision =
            eBayProvisions
               .find(v => v.key === formData.provision)
               ?.calcFunc?.(moneyTheBuyerHasToPay) ?? 0;
         const paypalProvision =
            paypalProvisions
               .find(v => v.key === formData.paypal_provision)
               ?.calcFunc?.(moneyTheBuyerHasToPay) ?? 0;

         const moneyTheSellerCanKeep =
            moneyTheBuyerHasToPay -
            eBayProvision -
            paypalProvision -
            shippingPriceTheSellerHasToPay;

         // ======================================================

         const profitPerInventory = inventory
            .filter(i => i.type === 'bought')
            .map(i => moneyTheSellerCanKeep - i.price);
         const profitPercentagePerInventory = inventory
            .filter(i => i.type === 'bought')
            .map(i => (moneyTheSellerCanKeep / i.price - 1) * 100);

         return {
            eBayProvision: eBayProvision,
            paypalProvision: paypalProvision,
            revenue: moneyTheSellerCanKeep,
            profit_range_min: Math.min(...profitPerInventory),
            profit_range_max: Math.max(...profitPerInventory),
            profit_percentage_min: Math.min(...profitPercentagePerInventory),
            profit_percentage_max: Math.max(...profitPercentagePerInventory),
         };
      },
      [eBayProvisions, inventory, paypalProvisions]
   );

   const revCalcPrice = useCallback(
      (formData: FormData): ReverseCalculationResult => {
         const profitPercentageTheSellerWantsToHave = convertToNumber(formData.rev_profit) / 100;
         const shippingPriceTheBuyerPays = convertToNumber(formData.buyer_shipping);
         const shippingPriceTheSellerHasToPay = convertToNumber(formData.shipping);

         const arrProfitPercentage = inventory
            .filter(i => i.type === 'bought')
            .map(inv => {
               const revenueWanted = inv.price * (1 + profitPercentageTheSellerWantsToHave);

               // ======================================================

               const eBayProvision = eBayProvisions.find(v => v.key === formData.provision);
               const paypalProvision = paypalProvisions.find(
                  v => v.key === formData.paypal_provision
               );

               let buyerPrice = 0;
               let revProvision = 0;
               let revPaypalProvision = 0;

               let lastIteration = revenueWanted;
               for (let i = 0; i < 10; i += 1) {
                  revProvision =
                     eBayProvision?.calcFuncReverse?.(lastIteration + shippingPriceTheBuyerPays) ??
                     0;
                  revPaypalProvision =
                     paypalProvision?.calcFuncReverse?.(
                        lastIteration + shippingPriceTheBuyerPays
                     ) ?? 0;
                  const provision = revProvision + revPaypalProvision;

                  buyerPrice = lastIteration + provision; // Mein gewünschter Preis + Provisionen

                  // Zu zahlende Provisionen auf errechneten Verkaufspreis
                  const provisionRev =
                     (eBayProvision?.calcFunc?.(buyerPrice + shippingPriceTheBuyerPays) ?? 0) +
                     (paypalProvision?.calcFunc?.(buyerPrice + shippingPriceTheBuyerPays) ?? 0);
                  // Preis den ich erhalte nach Abzug der Provisionen
                  const revSales = buyerPrice - provisionRev;

                  // Differenz zwischen gewünschten und realen Preis den ich erhalte
                  const diff = revenueWanted - revSales;

                  // Wenn die Differenz größer als 0,01 € ist, fügen wir die Differenz auf unseren Wunschpreis hinzu
                  //    und berechnen die Provisionen neu. Liegt die Differenz unterhalb von 0,01 € brechen wir ab, da
                  //    wir uns nah genug an genährt haben.
                  if (Math.abs(diff) < 0.01) break;
                  else lastIteration += diff;
               }

               const sellerProfit =
                  buyerPrice -
                  inv.price -
                  revProvision -
                  revPaypalProvision +
                  shippingPriceTheBuyerPays -
                  shippingPriceTheSellerHasToPay;
               return [revProvision, revPaypalProvision, buyerPrice, sellerProfit];
            });

         return {
            add_provision_min: Math.min(...arrProfitPercentage.map(a => a[0])),
            add_provision_max: Math.max(...arrProfitPercentage.map(a => a[0])),
            add_paypal_min: Math.min(...arrProfitPercentage.map(a => a[1])),
            add_paypal_max: Math.max(...arrProfitPercentage.map(a => a[1])),
            rev_buyer_price_min: Math.min(...arrProfitPercentage.map(a => a[2])),
            rev_buyer_price_max: Math.max(...arrProfitPercentage.map(a => a[2])),
            rev_profit_range_min: Math.min(...arrProfitPercentage.map(a => a[3])),
            rev_profit_range_max: Math.max(...arrProfitPercentage.map(a => a[3])),
         };
      },
      [inventory, eBayProvisions, paypalProvisions]
   );

   return {
      calcPrice,
      revCalcPrice,
      provisions: eBayProvisions,
   };
};
