import React, { useCallback, useEffect, useState } from 'react';
import { CredentialResponse, GoogleLogin } from '@react-oauth/google';
import { toast } from 'react-toastify';
import { UserModel } from '../../models/UserModel';
import { useAppSelector, useMemorizedIntl, useSession } from '../../hooks';

/**
 * Creates a sign-in button for Google accounts.
 */
export const GoogleSignInButton = () => {
   const intl = useMemorizedIntl();
   const { signIn, signOut, loginState } = useSession();
   const language = useAppSelector(s => s.app.language);
   const [loginViaCookieFailed, setLoginViaCookieFailed] = useState(false);

   // Versucht ein Login mit vorhandenem Session-Cookie
   useEffect(() => {
      (async () => {
         if (loginState === 'unknown') {
            try {
               // Wir versuchen, ohne Token über Session-Cookie ein Login durchzuführen
               const loggedInUser = await UserModel.authenticate();
               await signIn(loggedInUser);
            } catch {
               setLoginViaCookieFailed(true);
               await signOut();
            }
         }
      })();
   }, [loginState, signIn, signOut]);

   const handleLogin = useCallback(
      async (credentialResponse: CredentialResponse) => {
         const { credential } = credentialResponse;
         if (credential) {
            try {
               const loggedInUser = await UserModel.authenticate(credential);
               await signIn(loggedInUser);
            } catch (error) {
               toast.error(
                  intl.formatMessage({
                     id: 'error.login-failed',
                     defaultMessage:
                        'Es gab ein Fehler beim Anmelden. Bitte versuche es später erneut.',
                  })
               );
               await signOut();
            }
         }
      },
      [intl, signIn, signOut]
   );

   const handleLoginFailure = useCallback(() => {
      toast.error(
         intl.formatMessage({
            id: 'error.login-failed',
            defaultMessage: 'Es gab ein Fehler beim Anmelden. Bitte versuche es später erneut.',
         })
      );
   }, [intl]);

   return (
      <div>
         <GoogleLogin
            text="signin"
            onSuccess={handleLogin}
            onError={handleLoginFailure}
            auto_select
            useOneTap={loginViaCookieFailed && loginState !== 'loggedOut'}
            locale={language}
         />
      </div>
   );
};
