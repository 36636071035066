import { Badge, Container, ListGroup } from 'react-bootstrap';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import orderBy from 'lodash/orderBy';
import Icon from '@mdi/react';
import { mdiChevronRight } from '@mdi/js/commonjs/mdi';
import { showBackButton } from '../../redux/appSlice';
import { useAppDispatch, useCache, useMemorizedIntl } from '../../hooks';
import { Colors } from '../../components/Colors';
import { PageTitle } from '../../components/organisms/PageTitle';
import { pageLinks } from '../../utils';

export const AllCategoriesPage = () => {
   const intl = useMemorizedIntl();
   const dispatch = useAppDispatch();
   const { categories } = useCache();

   useEffect(() => {
      (async () => {
         dispatch(showBackButton(false));
      })();
   }, [dispatch]);

   return (
      <Container>
         <PageTitle
            title={intl.formatMessage({
               id: 'category.title.all',
               defaultMessage: 'Alle Kategorien',
            })}
         />
         <ListGroup>
            {orderBy(categories, 'name')
               .filter(c => c.legoSets.length > 0)
               .map(c => (
                  <ListGroup.Item
                     as={Link}
                     to={pageLinks.legoSetsByCategory(c)}
                     key={c.id}
                     className="d-flex flex-row align-items-center pe-0"
                     action
                  >
                     <span className="flex-fill">{c.name}</span>
                     <Badge className="badge-lg" pill bg="secondary">
                        {c.legoSets.length}
                     </Badge>
                     <div className="d-flex align-items-center px-1">
                        <Icon path={mdiChevronRight} color={Colors.link} size={1} />
                     </div>
                  </ListGroup.Item>
               ))}
         </ListGroup>
      </Container>
   );
};
