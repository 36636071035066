import axios, { AxiosError, AxiosInstance } from 'axios';
import config from '../utils/config';
import { IApiResponse } from '../types/IApiResponse';
import { SingleApiObject } from '../types/api/BaseTypes';

class ApiService {
   public http: AxiosInstance;

   public apiEndpoint: string;

   public uiErrorHandler: (error: AxiosError) => void = () => {};

   constructor(endpoint: string) {
      this.apiEndpoint = endpoint;
      this.http = axios.create({
         baseURL: endpoint,
         withCredentials: true,
      });
   }

   // region helper functions

   public handleError(err: any) {
      const error = err as AxiosError;
      if (error.response?.status === 404) {
         // 404-Fehler müssen von der Page behandelt werden
         return;
      }

      if (error.response?.status === 401) {
         // alert(JSON.stringify(error.response));
         // Session ist abgelaufen, also refreshen wir die Seite
         window.location.reload();
      } else {
         this.uiErrorHandler?.(error);
      }
   }

   // endregion

   // region basic CRUD functions

   public async count<T extends { [key: string]: any }>(
      model: string,
      filter?: Partial<T>
   ): Promise<number> {
      try {
         let queryString = '';
         if (filter)
            queryString = Object.keys(filter)
               .map(f => `${f}=${encodeURIComponent(filter[f] ?? '')}`)
               .join('&');

         const response = await this.http.get<IApiResponse<number>>(
            `data/${model}/count${queryString ? `?${queryString}` : ''}`
         );

         return response.data.data[0];
      } catch (error) {
         this.handleError(error);
         return Promise.reject(error);
      }
   }

   public async list<T extends { [key: string]: any }>(
      model: string,
      filter?: Partial<T>,
      orderBy?: keyof T,
      orderDirection?: 'ASC' | 'DESC',
      limit?: number,
      page?: number
   ): Promise<IApiResponse<T>> {
      try {
         const params: { [key: string]: string | undefined | null } = { ...filter };
         if (orderBy) params.orderBy = orderBy as string;
         if (orderDirection) params.orderDirection = orderDirection;
         if (limit) params.l = `${limit}`;
         if (page) params.p = `${page}`;

         const queryString = Object.entries(params)
            .filter(kv => kv[1] !== undefined)
            .map(kv => `${kv[0]}=${encodeURIComponent(kv[1] ?? '')}`)
            .join('&');

         const response = await this.http.get<IApiResponse<T>>(
            `data/${model}${queryString ? `?${queryString}` : ''}`
         );

         return response.data;
      } catch (error) {
         this.handleError(error);
         return Promise.reject(error);
      }
   }

   public async search<T>(
      model: string,
      text: string,
      orderBy?: keyof T,
      orderDirection?: 'ASC' | 'DESC',
      limit?: number,
      page?: number
   ): Promise<IApiResponse<T>> {
      try {
         const params: { [key: string]: string | undefined } = {
            q: text,
         };
         if (orderBy) params.orderBy = orderBy as string;
         if (orderDirection) params.orderDirection = orderDirection;
         if (limit) params.l = `${limit}`;
         if (page) params.p = `${page}`;

         const queryString = Object.entries(params)
            .filter(kv => kv[1] !== null && kv[1] !== undefined)
            .map(kv => `${kv[0]}=${encodeURIComponent(kv[1] ?? '')}`)
            .join('&');

         const response = await this.http.get<IApiResponse<T>>(
            `data/${model}/search${queryString ? `?${queryString}` : ''}`
         );

         return response.data;
      } catch (error) {
         this.handleError(error);
         return Promise.reject(error);
      }
   }

   public async get<T>(model: string, id: number): Promise<T | null> {
      try {
         const response = await this.http.get<IApiResponse<T>>(`data/${model}/${id}`);

         return response.data.data[0];
      } catch (error) {
         this.handleError(error);
         return Promise.reject(error);
      }
   }

   public async insert<T>(model: string, obj: Partial<T>): Promise<T> {
      try {
         const response = await this.http.post<IApiResponse<T>>(`data/${model}`, obj);

         return response.data.data[0];
      } catch (error) {
         this.handleError(error);
         return Promise.reject(error);
      }
   }

   public async update<T extends SingleApiObject>(model: string, obj: Partial<T>): Promise<T> {
      try {
         const response = await this.http.put<IApiResponse<T>>(`data/${model}/${obj.id}`, obj);

         return response.data.data[0];
      } catch (error) {
         this.handleError(error);
         return Promise.reject(error);
      }
   }

   public async delete<T>(model: string, id: number): Promise<void> {
      try {
         await this.http.delete<IApiResponse<T>>(`data/${model}/${id}`);
      } catch (error) {
         this.handleError(error);
         return Promise.reject(error);
      }
   }

   // endregion
}

export default new ApiService(config.apiBaseUrl);
