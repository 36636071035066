import React, { useCallback, useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { showBackButton } from '../../redux/appSlice';
import { BlogPostEntry } from '../../components/blog/BlogPostEntry';
import { BlogPostComments } from '../../components/blog/BlogPostComments';
import { BlogPost } from '../../types/api';
import { BlogPostModel } from '../../models/BlogPostModel';
import { useAppDispatch, useMemorizedIntl } from '../../hooks';
import { PageTitle } from '../../components/organisms/PageTitle';
import { convertToNumber } from '../../utils';

export const BlogPostShowPage = () => {
   const dispatch = useAppDispatch();
   const intl = useMemorizedIntl();
   const routeParams = useParams<{ idAndSubject?: string }>();
   const [blogPostId] = routeParams?.idAndSubject?.split('-') ?? [];
   const [blogPost, setBlogPost] = useState<BlogPost>();

   const loadBlogPost = useCallback(async () => {
      const post = await BlogPostModel.get(convertToNumber(blogPostId));
      if (!post) {
         setBlogPost(undefined);
         return;
      }

      setBlogPost(post);
      dispatch(showBackButton(true));
   }, [blogPostId, dispatch]);

   useEffect(() => {
      (async () => {
         await loadBlogPost();
      })();
   }, [loadBlogPost]);

   return (
      <Container>
         <PageTitle
            title={intl.formatMessage(
               {
                  id: 'blog-post-show.title',
                  defaultMessage: 'Blog - {subject}',
               },
               { subject: blogPost?.subject ?? '' }
            )}
            appTitle={blogPost?.subject ?? ''}
            subtitle={intl.formatMessage({
               id: 'blog-post-show.app-title',
               defaultMessage: 'Blog',
            })}
            hidden
         />
         {blogPost && (
            <>
               <BlogPostEntry post={blogPost} onRefresh={() => loadBlogPost()} />
               <BlogPostComments blogPostId={blogPost.id} />
            </>
         )}
      </Container>
   );
};
