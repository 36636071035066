import React from 'react';
import Select from 'react-select';
import { useModelTranslation } from '../../hooks';
import { ControlProps, GenericControl } from './GenericControl';
import { FormUtils } from './FormUtils';
import { getSelectStyles } from '../../utils';

interface DropdownOption<V> {
   label: string;
   value: V;
}

interface Props<T, V> extends ControlProps<T> {
   options: DropdownOption<V>[];
   placeholder?: string;
   disabled?: boolean;
   isOptionDisabled?: (option: DropdownOption<V>) => boolean;
   isClearable?: boolean;
}

export const DropdownControl = <T, V>({
   placeholder,
   disabled = false,
   isClearable = false,
   isOptionDisabled,
   ...props
}: Props<T, V>) => {
   const modelTranslation = useModelTranslation(props.model);

   return (
      <GenericControl {...props}>
         <Select
            key={props.formik.values[props.name] as unknown as string}
            options={props.options}
            styles={getSelectStyles(FormUtils.isInvalid(props.formik, props.name))}
            placeholder={
               modelTranslation?.[props.name as string]?.placeholder ??
               modelTranslation?.[props.name as string]?.title ??
               placeholder
            }
            defaultValue={props.options.find(
               t => t.value === (props.formik.values[props.name] as unknown as V)
            )}
            onChange={value => {
               props.formik.setFieldValue(props.name as string, value?.value);
            }}
            onBlur={() => props.formik.handleBlur(props.name)}
            isDisabled={props.formik.isSubmitting || disabled}
            isOptionDisabled={isOptionDisabled}
            isClearable={isClearable}
         />
      </GenericControl>
   );
};
