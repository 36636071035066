import { useCallback, useEffect, useMemo, useState } from 'react';
import { GoogleDataTableColumn, GoogleDataTableRow } from 'react-google-charts/dist/types';
import isEqual from 'lodash/isEqual';
import merge from 'lodash/merge';
import ApiService from '../services/ApiService';
import { Colors } from '../components/Colors';
import { ChartDataType, ComboChartTheme } from '../types/ChartDataType';

interface ChartDataOptions {
   url?: string;
   preloadedChartData?: ChartDataType;
   columnNames: string[];
   columnTypes?: string[];
   unit?: string;
   numberFormat?: string;
   theme?: ComboChartTheme;
}

export const useChartData = ({
   url,
   preloadedChartData,
   columnNames,
   columnTypes,
   unit = '\u20AC',
   numberFormat = '#.##',
   theme = 'default',
}: ChartDataOptions) => {
   const [isLoading, setLoading] = useState(true);
   const [chartColumns, setChartColumns] = useState<GoogleDataTableColumn[]>([]);
   const [chartRows, setChartRows] = useState<GoogleDataTableRow[]>([]);

   const buildChartData = useCallback(
      (colNames: string[], colTypes: string[], data: ChartDataType) => {
         const newColumns = colNames.map(
            (name, index) =>
               ({
                  type: colTypes[index],
                  label: name,
               } as GoogleDataTableColumn)
         );

         const newRows = data.map(row =>
            row.map((rowEntry, index) => {
               if (rowEntry && colTypes[index] === 'date') return new Date(rowEntry);

               // HACK: Bei Darstellung von Prozenten, stimmt die Wertbeschreibung mit der Darstellung nicht.
               //    Daher in diesem Fall anpassen
               if (unit === '%' && typeof rowEntry === 'number')
                  return { v: rowEntry / 100, f: `${rowEntry} ${unit}` };

               return { v: rowEntry, f: `${rowEntry} ${unit}` };
            })
         );

         setChartColumns(v => (isEqual(v, newColumns) ? v : newColumns));
         setChartRows(v => (isEqual(v, newRows) ? v : newRows));
      },
      [unit]
   );

   useEffect(() => {
      (async () => {
         setLoading(true);

         if (url) {
            const { columns, rows } = (await ApiService.http.get(url)).data.data;
            buildChartData(columnNames, columns, rows);
         } else if (preloadedChartData && columnTypes) {
            buildChartData(columnNames, columnTypes, preloadedChartData);
         }

         setLoading(false);
      })();
   }, [columnNames, url, buildChartData, preloadedChartData, columnTypes]);

   const themeMini = useMemo(
      () => ({
         backgroundColor: 'transparent',
         legend: {
            position: 'none',
         },
         vAxis: {
            textPosition: 'none',
            gridlines: {
               color: 'none',
            },
         },
         hAxis: {
            textPosition: 'none',
            gridlines: {
               color: 'none',
            },
         },
         chartArea: {
            top: 5,
            right: 5,
            bottom: 5,
            left: 5,
            width: '100%',
            height: '100%',
         },
      }),
      []
   );

   const comboChartOptions = useMemo(
      () =>
         merge(
            {
               backgroundColor: '#222',
               seriesType: 'line',
               curveType: 'function',
               colors: [
                  '#3366CC',
                  '#FF9900',
                  '#DC3912',
                  '#109618',
                  '#990099',
                  '#3B3EAC',
                  '#0099C6',
                  '#DD4477',
                  '#66AA00',
                  '#B82E2E',
               ],
               legend: {
                  position: 'bottom',
                  textStyle: {
                     color: '#444',
                  },
               },
               vAxis: {
                  gridlines: {
                     color: '#333',
                  },
                  format: `${numberFormat} ${unit}`,
                  minValue: 0,
               },
               hAxis: {
                  gridlines: {
                     color: '#333',
                  },
               },
               chartArea: {
                  left: 50,
                  bottom: 50,
                  top: 10,
                  width: '100%',
                  height: '100%',
               },
               lineDashStyle: [1, 0],
               animation: {
                  duration: 1000,
                  easing: 'out',
                  startup: true,
               },
               series: {
                  0: {
                     type: 'area',
                     areaOpacity: 0.1,
                     lineDashStyle: [1, 0],
                     annotations: {
                        boxStyle: {
                           // Color of the box outline.
                           stroke: '#888',
                           // Thickness of the box outline.
                           strokeWidth: 1,
                           // x-radius of the corner curvature.
                           rx: 10,
                           // y-radius of the corner curvature.
                           ry: 10,
                           // Attributes for linear gradient fill.
                           gradient: {
                              // Start color for gradient.
                              color1: '#fbf6a7',
                              // Finish color for gradient.
                              color2: '#33b679',
                              // Where on the boundary to start and
                              // end the color1/color2 gradient,
                              // relative to the upper left corner
                              // of the boundary.
                              x1: '0%',
                              y1: '0%',
                              x2: '100%',
                              y2: '100%',
                              // If true, the boundary for x1,
                              // y1, x2, and y2 is the box. If
                              // false, it's the entire chart.
                              useObjectBoundingBoxUnits: true,
                           },
                        },
                     },
                  },
               },
            },
            theme === 'mini' ? themeMini : {}
         ),
      [numberFormat, theme, themeMini, unit]
   );

   const donutChartOptions = useMemo(
      () => ({
         pieHole: 0.3,
         pieSliceText: 'value',
         pieSliceTextStyle: {
            fontSize: 10,
         },
         backgroundColor: '#222',
         colors: [
            '#3366CC',
            '#FF9900',
            '#DC3912',
            '#109618',
            '#990099',
            '#3B3EAC',
            '#0099C6',
            '#DD4477',
            '#66AA00',
            '#B82E2E',
         ],
         legend: 'none',
         chartArea: {
            top: 10,
            bottom: 10,
            width: '100%',
            height: '100%',
         },
         animation: {
            duration: 1000,
            easing: 'out',
            startup: true,
         },
      }),
      []
   );

   const columnChartOptions = useMemo(
      () => ({
         backgroundColor: Colors.secondary,
         colors: [
            '#DC3912',
            '#109618',
            '#3366CC',
            '#FF9900',
            '#990099',
            '#3B3EAC',
            '#0099C6',
            '#DD4477',
            '#66AA00',
            '#B82E2E',
         ],
         legend: {
            position: 'bottom',
            textStyle: {
               color: '#ffffff',
               fontName: 'Lato',
            },
         },
         chartArea: {
            left: 60,
            top: 10,
            bottom: 50,
            width: '100%',
            height: '100%',
         },
         animation: {
            duration: 1500,
            easing: 'out',
            startup: true,
         },
         hAxis: {
            gridlines: {
               color: 'transparent',
            },
            textStyle: {
               color: '#ffffff',
               fontName: 'Lato',
            },
         },
         vAxis: {
            gridlines: {},
            textStyle: {
               color: '#ffffff',
               fontName: 'Lato',
            },
            format: 'decimal',
         },
         bar: { groupWidth: '100%' },
      }),
      []
   );

   return {
      isLoading,
      columns: chartColumns,
      rows: chartRows,
      comboChartOptions,
      donutChartOptions,
      barChartOptions: columnChartOptions,
   };
};
