import React, { useCallback } from 'react';
import { Modal } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';
import { FormattedMessage } from 'react-intl';
import { BlogPostCommentModel } from '../../models/BlogPostCommentModel';
import { BlogPostComment } from '../../types/api';
import { ButtonPanel, ResponsiveButton } from '../molecules';

interface ReportedInfosDialogProps {
   comment: BlogPostComment;
   show: boolean;
   onClose: () => void;
   refresh: () => Promise<void>;
}

export const ReportedInfosDialog = (props: ReportedInfosDialogProps) => {
   const handleRelease = useCallback(async () => {
      const updatedComment = {
         ...props.comment,
         is_reported: false,
         report_user_id: undefined,
         report_reason: undefined,
      };
      await BlogPostCommentModel.update(updatedComment);
      await props.refresh();
      props.onClose();
   }, [props]);

   const handleDelete = useCallback(async () => {
      props.comment.is_deleted = true;

      await handleRelease();
   }, [handleRelease, props.comment]);

   return (
      <Modal show={props.show} onHide={props.onClose} centered={isMobile}>
         <Modal.Header closeButton>
            <Modal.Title>
               <FormattedMessage
                  id="dialog.blog-post-comment.manage-report.title"
                  defaultMessage="Grund für die Meldung"
               />
            </Modal.Title>
         </Modal.Header>

         <Modal.Body>
            <div>
               <FormattedMessage
                  id="dialog.blog-post-comment.manage-report.user"
                  defaultMessage="Gemeldet von: {user}"
                  values={{
                     user: props.comment.report_user_name,
                  }}
               />
            </div>
            <br />
            <div>{props.comment.report_reason}</div>
         </Modal.Body>

         <Modal.Footer>
            <ButtonPanel>
               <ResponsiveButton variant="secondary" onClick={props.onClose}>
                  <FormattedMessage id="button.close" defaultMessage="Schließen" />
               </ResponsiveButton>
               <ResponsiveButton variant="success" onClick={() => handleRelease()}>
                  <FormattedMessage id="button.release-comment" defaultMessage="Freigeben" />
               </ResponsiveButton>
               <ResponsiveButton variant="danger" onClick={() => handleDelete()}>
                  <FormattedMessage id="button.delete-comment" defaultMessage="Löschen" />
               </ResponsiveButton>
            </ButtonPanel>
         </Modal.Footer>
      </Modal>
   );
};
