import React, { useEffect, useMemo, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Col, Container, Form, Row, Table } from 'react-bootstrap';
import { LinkButton } from '../../../../components/molecules';
import { Pagination } from '../../../../components/organisms/Pagination';
import { ContentLoader } from '../../../../components/atoms';
import { LegoSetChangeRequestStatusType, User } from '../../../../types/api';
import { LegoSetChangeRequestModel } from '../../../../models/LegoSetChangeRequestModel';
import { UserModel } from '../../../../models/UserModel';
import { PageTitle } from '../../../../components/organisms/PageTitle';
import { formatDate } from '../../../../utils';

interface LocationState {
   selectedStatus: LegoSetChangeRequestStatusType;
}

export const LegoSetChangeRequestsAdminPage = () => {
   const navigate = useNavigate();
   const location = useLocation();
   const locationState = (location.state as LocationState) ?? {
      selectedStatus: 'new',
   };
   const [users, setUsers] = useState<User[]>([]);

   const pagingFilter = useMemo(
      () => ({
         status: locationState.selectedStatus,
      }),
      [locationState.selectedStatus]
   );

   useEffect(() => {
      (async () => {
         setUsers(await UserModel.list());
      })();
   }, []);

   return (
      <Container>
         <PageTitle title="Änderungsanträge" subtitle="Administration" />
         <Row>
            <Col className="mb-3 d-flex align-items-center gap-2">
               <Form.Label className="mb-0">Status</Form.Label>
               <Form.Control
                  as="select"
                  name="selectedStatus"
                  value={locationState.selectedStatus}
                  onChange={e =>
                     navigate(location.pathname, {
                        replace: true,
                        state: {
                           selectedStatus: e.target
                              .value as unknown as LegoSetChangeRequestStatusType,
                        },
                     })
                  }
                  className="w-auto"
               >
                  <option value="new">Neu Anträge</option>
                  <option value="accepted">Akzeptierte Anträge</option>
                  <option value="rejected">Abgelehnte Anträge</option>
               </Form.Control>
            </Col>
         </Row>

         <Pagination
            model={LegoSetChangeRequestModel}
            modelFilter={pagingFilter}
            orderColumn="x_date_created"
            orderDesc
         >
            {({ items }) =>
               items === null ? (
                  <ContentLoader />
               ) : (
                  <Table hover className="flat">
                     <thead>
                        <tr>
                           <th>Lego-Set</th>
                           <th>von/am</th>
                           <th aria-label="Aktionen" style={{ width: 1 }} />
                        </tr>
                     </thead>
                     <tbody>
                        {items.map(r => (
                           <tr key={r.id}>
                              <td className="align-middle">
                                 <Link to={`/admin/legoSets/${r.set_id}`}>{r.set_name}</Link>
                              </td>
                              <td className="align-middle">
                                 <Link to={`/admin/users/${r.user_id}`}>
                                    {users.find(u => u.id === r.user_id)?.name}
                                 </Link>
                                 <br />
                                 <span className="text-muted">
                                    {formatDate(r.x_date_created, 'L LTS')}
                                 </span>
                              </td>
                              <td className="text-end align-middle">
                                 <LinkButton
                                    to={`/admin/legoSets/changeRequests/${r.id}`}
                                    size="sm"
                                 >
                                    Anzeigen
                                 </LinkButton>
                              </td>
                           </tr>
                        ))}
                     </tbody>
                  </Table>
               )
            }
         </Pagination>
      </Container>
   );
};
