import { Container } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { showBackButton } from '../../../redux/appSlice';
import { LegoSetsGridList } from '../../../components/organisms/LegoSetsGridList';
import { useAppDispatch, useMemorizedIntl } from '../../../hooks';
import { Category } from '../../../types/api';
import { CategoryModel } from '../../../models/CategoryModel';
import { getInventoryByCategory } from '../../../redux/pagesSlice';
import { ContentLoader } from '../../../components/atoms';
import { PageTitle } from '../../../components/organisms/PageTitle';
import { PageNotFoundPage } from '../../PageNotFoundPage';

export const LegoSetsByCategoryPage = () => {
   const intl = useMemorizedIntl();
   const dispatch = useAppDispatch();
   const routeParams = useParams<{ name?: string }>();
   const [category, setCategory] = useState<Category | undefined | null>(undefined);

   useEffect(() => {
      (async () => {
         dispatch(showBackButton(true));
         setCategory((await CategoryModel.list({ name_key: routeParams.name }))[0] ?? null);
      })();
   }, [routeParams.name, dispatch]);

   useEffect(() => {
      (async () => {
         if (!category) return;

         dispatch(getInventoryByCategory(category.id));
      })();
   }, [category, dispatch]);

   if (category === undefined) return <ContentLoader />;
   if (category === null) return <PageNotFoundPage />;

   return (
      <Container>
         <PageTitle
            title={intl.formatMessage(
               {
                  id: 'category.headline',
                  defaultMessage: 'Sets der Kategorie: {name}',
               },
               {
                  name: category.name,
               }
            )}
            appTitle={category.name}
            subtitle={intl.formatMessage({
               id: 'category.title',
               defaultMessage: 'Kategorie',
            })}
         />
         <LegoSetsGridList hideOrdering lockedCategoryId={category.id} />
      </Container>
   );
};
