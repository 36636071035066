import React, { useState } from 'react';
import { Button, Container, Dropdown, Navbar } from 'react-bootstrap';
import { mdiArrowLeft, mdiDotsVertical, mdiMagnify } from '@mdi/js';
import Icon from '@mdi/react';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { LinkContainer } from 'react-router-bootstrap';
import { GoogleSignInButton } from '../components/atoms';
import { Colors } from '../components/Colors';
import { useAppSelector, useSession } from '../hooks';
import { SearchForm } from '../components/organisms/SearchForm';
import { AddLegoSetDialog } from '../components/dialogs/AddLegoSetDialog';

export const AppTitle = () => {
   const navigate = useNavigate();
   const { loginState } = useSession();
   const appTitle = useAppSelector(s => s.app.title);
   const showBackButton = useAppSelector(s => s.app.showBackButton);
   const [showSearchBar, setShowSearchBar] = useState(false);

   const handleBackNavigation = () => {
      navigate(-1);
   };

   return (
      <Navbar
         fixed="top"
         className="d-flex justify-content-between align-items-center"
         style={{ height: '3.75rem', backgroundColor: Colors.primary, padding: 0 }}
      >
         <Container className="px-1" fluid>
            {showSearchBar ? (
               <>
                  <SearchForm
                     className="ms-2 me-2"
                     style={{ flex: 1 }}
                     focus
                     onSearchResultClick={() => setShowSearchBar(false)}
                  />
                  <Button
                     variant="primary"
                     className="my-2 my-sm-0 me-2"
                     type="button"
                     onClick={() => setShowSearchBar(false)}
                  >
                     <FormattedMessage id="button.cancel" defaultMessage="Abbrechen" />
                  </Button>
               </>
            ) : (
               <>
                  <div style={{ minWidth: '5.5rem' }}>
                     <Button
                        variant="primary"
                        onClick={handleBackNavigation}
                        hidden={!showBackButton}
                     >
                        <Icon path={mdiArrowLeft} color={Colors.white} size={1} />
                     </Button>
                  </div>
                  {loginState !== 'loggedIn' && <GoogleSignInButton />}
                  {loginState === 'loggedIn' && !appTitle.line2 && (
                     <h4 className="overflow-ellipsis mb-0">{appTitle.line1}</h4>
                  )}
                  {loginState === 'loggedIn' && appTitle.line2 && (
                     <div className="text-nowrap overflow-hidden text-center">
                        <h3 className="overflow-ellipsis mb-0">{appTitle.line1}</h3>
                        <div className="overflow-ellipsis mb-0 small">{appTitle.line2}</div>
                     </div>
                  )}
                  <div className="d-flex justify-content-end gap-1" style={{ minWidth: '5.5rem' }}>
                     {loginState === 'loggedIn' && (
                        <div className="d-flex flex-row justify-content-end pe-2">
                           <Button
                              className="px-2"
                              onClick={() => {
                                 setShowSearchBar(true);
                              }}
                           >
                              <Icon path={mdiMagnify} color={Colors.white} size={1} />
                           </Button>
                           <Dropdown>
                              <Dropdown.Toggle className="px-2 title-button">
                                 <Icon path={mdiDotsVertical} color={Colors.white} size={1} />
                              </Dropdown.Toggle>

                              <Dropdown.Menu align="end">
                                 <AddLegoSetDialog>
                                    {({ onPress }) => (
                                       <Dropdown.Item onClick={onPress}>
                                          <FormattedMessage
                                             id="button.add-lego-set"
                                             defaultMessage="Neues Set anlegen"
                                          />
                                       </Dropdown.Item>
                                    )}
                                 </AddLegoSetDialog>
                                 <Dropdown.Divider />
                                 <LinkContainer to="/categories">
                                    <Dropdown.Item>
                                       <FormattedMessage
                                          id="navbar.categories"
                                          defaultMessage="Kategorien"
                                       />
                                    </Dropdown.Item>
                                 </LinkContainer>
                                 <LinkContainer to="/labels">
                                    <Dropdown.Item>
                                       <FormattedMessage
                                          id="navbar.labels"
                                          defaultMessage="Labels"
                                       />
                                    </Dropdown.Item>
                                 </LinkContainer>
                                 <LinkContainer to="/legoSets">
                                    <Dropdown.Item>
                                       <FormattedMessage
                                          id="navbar.legoSets"
                                          defaultMessage="Alle Lego-Sets"
                                       />
                                    </Dropdown.Item>
                                 </LinkContainer>
                              </Dropdown.Menu>
                           </Dropdown>
                        </div>
                     )}
                  </div>
               </>
            )}
         </Container>
      </Navbar>
   );
};
