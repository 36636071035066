import { BaseModel } from './BaseModel';
import { BlogPost } from '../types/api';
import ApiService from '../services/ApiService';

class BlogPostModelClass extends BaseModel<BlogPost> {
   public getName(): string {
      return 'blog_post';
   }

   public async sendEmailNotifications(postId: number): Promise<void> {
      try {
         await ApiService.http.get(`data/${this.getName()}/${postId}/sendEmailNotifications`);
      } catch (error) {
         ApiService.handleError(error);
         return Promise.reject(error);
      }
   }
}
export const BlogPostModel = new BlogPostModelClass();
