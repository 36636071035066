import { FormattedMessage } from 'react-intl';
import { Form, Modal } from 'react-bootstrap';
import React, { useCallback, useEffect, useState } from 'react';
import { Formik, FormikErrors } from 'formik';
import { isMobile } from 'react-device-detect';
import { toast } from 'react-toastify';
import { setApiError } from '../../redux/appSlice';
import { ConfirmDeleteDialog } from './ConfirmDeleteDialog';
import { ButtonPanel, ResponsiveButton } from '../molecules';
import { ImageUpload } from '../../types/api';
import { ImageUploadModel } from '../../models/ImageUploadModel';
import { FormUtils, GenericControl, SubmitButton, TextareaControl, TextControl } from '../forms';
import { useAppDispatch, useMemorizedIntl, useValidationErrors } from '../../hooks';

interface Props {
   showDialog: boolean;
   inventoryId: number;
   imageToEdit: Partial<ImageUpload> | undefined;
   onDialogClose: () => void;
   onSubmit: () => void;
   onDelete: () => void;
}

interface IFormData extends Partial<ImageUpload> {
   file?: File;
}

const defaultImageUpload: IFormData = {
   file: undefined,
   description: '',
   name: '',
   section: '',
};

export const EditImageUploadDialog = (props: Props) => {
   const intl = useMemorizedIntl();
   const validationErrors = useValidationErrors();
   const dispatch = useAppDispatch();

   const [showDeleteDialog, setShowDeleteDialog] = useState(false);
   const [uploadToEdit, setUploadToEdit] = useState<IFormData>(defaultImageUpload);
   const [fileName, setFileName] = useState<string | null>(null);

   useEffect(() => {
      setUploadToEdit(props.imageToEdit ?? defaultImageUpload);
   }, [props.imageToEdit]);

   const handleValidate = useCallback(
      (values: IFormData) => {
         const errors: FormikErrors<IFormData> = {};

         if (!values.name) errors.name = validationErrors.required;

         if (!values.id) {
            if (!values.file) {
               errors.file = validationErrors.required;
               setFileName(null);
            } else {
               setFileName(values.file.name);
            }
         }

         return errors;
      },
      [validationErrors]
   );

   const handleSubmitForm = useCallback(
      async (values: IFormData) => {
         if (!values.id) {
            if (!values.file) {
               return;
            }

            values.inventory_id = props.inventoryId;
            try {
               await ImageUploadModel.insertImage(values, values.file);
            } catch (error: any) {
               // console.log(JSON.stringify(error.response, null, 2));
               switch (error.response?.data?.meta?.message) {
                  case 'Exceeded filesize limit.': // 5MB
                     toast.error(
                        intl.formatMessage({
                           id: 'http-error.filesize-limit',
                           defaultMessage:
                              'Die maximale Dateigröße von 5MB wurde überschritten. Bitte wähle ein kleineres Bild aus.',
                        })
                     );
                     break;
                  case 'Invalid file format.': // jpg, png, gif
                     toast.error(
                        intl.formatMessage({
                           id: 'http-error.invalid-file-extension',
                           defaultMessage:
                              'Die Datei hat das falsche Format. Unterstützt wird jpg, png und gif. HEIC von iPhones wird aktuell noch nicht unterstützt.',
                        })
                     );
                     break;
                  default:
                     dispatch(setApiError(error));
               }

               return;
            }
         } else {
            await ImageUploadModel.update(values);
         }

         setFileName('');
         props.onSubmit();
      },
      [dispatch, intl, props]
   );

   const handleDelete = useCallback(async () => {
      if (!uploadToEdit) return;

      await ImageUploadModel.delete(uploadToEdit);
      setShowDeleteDialog(false);

      props.onDelete();
   }, [props, uploadToEdit]);

   return (
      <>
         <Modal
            show={props.showDialog && !showDeleteDialog}
            centered={isMobile}
            onHide={() => props.onDialogClose()}
            size="lg"
         >
            <Formik
               onSubmit={handleSubmitForm}
               initialValues={uploadToEdit}
               validate={handleValidate}
               enableReinitialize
            >
               {formik => (
                  <Form
                     noValidate
                     onSubmit={e => {
                        e.preventDefault();
                        formik.handleSubmit();
                     }}
                  >
                     <Modal.Header closeButton>
                        <Modal.Title>
                           {!formik.values.id ? (
                              <FormattedMessage
                                 id="dialog.edit-upload.title.new"
                                 defaultMessage="Neues Bild hochladen"
                              />
                           ) : (
                              <FormattedMessage
                                 id="dialog.edit-upload.title.edit"
                                 defaultMessage="Bild bearbeiten"
                              />
                           )}
                        </Modal.Title>
                     </Modal.Header>

                     <Modal.Body>
                        {!formik.values.id && (
                           <GenericControl formik={formik} model="image_upload" name="file">
                              <Form.Control
                                 type="file"
                                 placeholder={
                                    fileName ||
                                    intl.formatMessage({
                                       id: 'dialog.edit-upload.image.placeholder',
                                       defaultMessage: 'Datei auswählen',
                                    })
                                 }
                                 name="file"
                                 onChange={(e: any) =>
                                    formik.setFieldValue('file', e.currentTarget.files[0])
                                 }
                                 isInvalid={FormUtils.isInvalid(formik, 'file')}
                                 disabled={formik.isSubmitting}
                              />
                           </GenericControl>
                        )}
                        <TextControl formik={formik} model="image_upload" name="name" />
                        <TextControl formik={formik} model="image_upload" name="section" />
                        <TextareaControl formik={formik} model="image_upload" name="description" />
                     </Modal.Body>

                     <Modal.Footer>
                        <ButtonPanel>
                           <ResponsiveButton
                              variant="danger"
                              onClick={() => {
                                 setShowDeleteDialog(true);
                              }}
                              disabled={formik.isSubmitting}
                              hidden={!formik.values.id}
                           >
                              <FormattedMessage id="button.delete" defaultMessage="Löschen" />
                           </ResponsiveButton>
                           <ResponsiveButton
                              variant="secondary"
                              onClick={() => {
                                 props.onDialogClose();
                                 setFileName('');
                              }}
                              disabled={formik.isSubmitting}
                           >
                              <FormattedMessage id="button.close" defaultMessage="Schließen" />
                           </ResponsiveButton>
                           <SubmitButton formik={formik}>
                              <FormattedMessage id="button.save" defaultMessage="Speichern" />
                           </SubmitButton>
                        </ButtonPanel>
                     </Modal.Footer>
                  </Form>
               )}
            </Formik>
         </Modal>
         <ConfirmDeleteDialog
            show={showDeleteDialog}
            onClose={() => {
               setShowDeleteDialog(false);
               setFileName('');
            }}
            onDelete={handleDelete}
         >
            <p>
               <FormattedMessage
                  id="dialog.delete-upload.text"
                  defaultMessage="Möchtest du das Bild ''{name}'' wirklich löschen?"
                  values={{ name: uploadToEdit?.name }}
               />
            </p>
         </ConfirmDeleteDialog>
      </>
   );
};
