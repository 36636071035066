import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Card, ResponsiveButton } from '../../../../components/molecules';
import { showBackButton } from '../../../../redux/appSlice';
import { InventoryFilter } from '../../../../types/api';
import { useAppDispatch, useMemorizedIntl, useSession } from '../../../../hooks';
import { InventoryFilterModel } from '../../../../models/InventoryFilterModel';
import { PageTitle } from '../../../../components/organisms/PageTitle';

export const UserFiltersPage = () => {
   const intl = useMemorizedIntl();
   const navigate = useNavigate();
   const dispatch = useAppDispatch();
   const { sessionUser } = useSession();
   const [filters, setFilters] = useState<InventoryFilter[]>([]);

   useEffect(() => {
      (async () => {
         dispatch(showBackButton(false));
         setFilters(await InventoryFilterModel.list({ user_id: sessionUser?.id ?? 0 }));
      })();
   }, [dispatch, sessionUser?.id]);

   return (
      <Container>
         <PageTitle
            title={intl.formatMessage({
               id: 'my-inventory-filters.title.all-filters',
               defaultMessage: 'Alle Filter',
            })}
            hidden
         />
         <div>
            <ResponsiveButton variant="success" to="/inventory/search/manage/0">
               <FormattedMessage
                  id="button.create-inventory-filter"
                  defaultMessage="Neuen Filter erstellen"
               />
            </ResponsiveButton>
         </div>
         <br />
         {filters.map(f => (
            <Card
               key={f.id}
               onPress={() => navigate(`/inventory/search/manage/${f.id}`)}
               orientation="column"
               chevron
            >
               <h4>{f.name}</h4>
               <div className="small">
                  <FormattedMessage
                     id="my-inventory-filters.filter-definition"
                     defaultMessage="Filter: <em>{filter}</em>"
                     values={{
                        filter: f.select_condition_display,
                     }}
                  />
               </div>
               <div className="small">
                  <FormattedMessage
                     id="my-inventory-filters.sorting-definition"
                     defaultMessage="Sortierung: <em>{sorting}</em>"
                     values={{
                        sorting:
                           f.order_condition_display ||
                           intl.formatMessage({
                              id: 'my-inventory-filters.sorting-definition.none',
                              defaultMessage: 'Keine',
                           }),
                     }}
                  />
               </div>
            </Card>
         ))}
         {filters.length === 0 ?? (
            <em>
               <FormattedMessage
                  id="my-inventory-filters.no-filter-available"
                  defaultMessage="Sie besitzen noch keine Filter. Klicke auf 'Neuen Filter erstellen' um einen Filter anzulegen."
               />
            </em>
         )}
      </Container>
   );
};
