import React from 'react';
import { Badge } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';
import { FormattedMessage } from 'react-intl';
import { ColoredValue } from '../../atoms';
import { ContentWithPlaceholder } from '../../molecules';
import { LegoSetWithStats } from '../../../types/api/LegoSetWithStats';

interface Props {
   legoSet: LegoSetWithStats;
}

export const CardBodySold = ({ legoSet }: Props) => {
   const soldStats = legoSet.statistics.sold;

   return isMobile ? (
      <>
         <div
            className="h4 mb-0 d-flex align-items-center overflow-ellipsis gap-1"
            style={{ flex: 1 }}
         >
            <ColoredValue type="currency" value={soldStats.profit} useColor isDiff />
            {soldStats.profitPercentage !== null && (
               <>
                  <span className="text-muted mx-1">/</span>
                  <ColoredValue
                     type="percentage"
                     value={soldStats.profitPercentage}
                     decimalScale={1}
                     useColor
                     isDiff
                  />
               </>
            )}
         </div>
         <small className="overflow-ellipsis text-muted fst-italic">
            <FormattedMessage
               id="app.inventory.grid.card.invest-price"
               defaultMessage="EKP: {price}"
               values={{
                  price: <ColoredValue type="currency" value={soldStats.invest} />,
               }}
            />
         </small>
      </>
   ) : (
      <>
         <span className="pb-2 d-flex justify-content-between align-items-center">
            <span className="text-truncate small fst-italic">
               <ContentWithPlaceholder isLoading={!legoSet} width="5rem">
                  {legoSet.status_by_lego || <>&nbsp;</>}
               </ContentWithPlaceholder>
            </span>
            {soldStats.count >= 2 && (
               <Badge className="h6 mb-0" bg="info">{`${soldStats.count}x`}</Badge>
            )}
         </span>
         <span className="card-body-price">
            <ColoredValue className="h3" type="currency" value={soldStats.profit} isDiff />
         </span>
         <span className="card-body-price-sub">
            <ColoredValue
               type="percentage"
               value={soldStats.profitPercentage}
               decimalScale={1}
               useColor
               isDiff
            />
         </span>
      </>
   );
};
