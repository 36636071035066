import React, { useEffect, useRef, useState } from 'react';
import { Badge, Navbar } from 'react-bootstrap';
import Icon from '@mdi/react';
import {
   mdiBookshelf,
   mdiChartLine,
   mdiDatabaseSearchOutline,
   mdiMenu,
   mdiNewspaperVariantOutline,
} from '@mdi/js';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { LinkButton } from '../components/molecules';
import { Colors } from '../components/Colors';
import { useAppSelector, useSession } from '../hooks';
import { pageLinks } from '../utils';
import { LOCALSTORAGE_SHOW_SAFE_AREAS } from '../utils/constants';

export const AppBottomNav = () => {
   const { sessionUser } = useSession();
   const badgeNews = useAppSelector(s => s.app.badges.news);
   const [safeAreaHeight, setSafeAreaHeight] = useState(0);
   const safeAreaRef = useRef<HTMLDivElement>(null);

   useEffect(() => {
      setSafeAreaHeight(safeAreaRef.current?.clientHeight ?? -1);
   }, []);

   return (
      <Navbar id="mobile-footer" fixed="bottom">
         {!sessionUser ? (
            <div
               id="bottom-mobile-footer"
               className="d-flex flex-column align-items-center flex-fill"
            >
               <Link to="/imprint">
                  <FormattedMessage id="footer.imprint" defaultMessage="Impressum & Datenschutz" />
               </Link>
               <Link to="/blog">
                  <FormattedMessage id="footer.news-and-blog" defaultMessage="News & Blog" />
               </Link>
            </div>
         ) : (
            <div id="bottom-tab-bar">
               <NavIconWithBadge tabKey="Statistic" to="/stats" icon={mdiChartLine} />
               <NavIconWithBadge
                  tabKey="Inventory"
                  to={pageLinks.inventory('bought')}
                  icon={mdiBookshelf}
               />
               <NavIconWithBadge
                  tabKey="InventorySearch"
                  to="/inventory/search"
                  icon={mdiDatabaseSearchOutline}
               />
               <NavIconWithBadge
                  tabKey="Blog"
                  to="/blog"
                  icon={mdiNewspaperVariantOutline}
                  badge={badgeNews}
                  hideBadgeValue
               />
               <NavIconWithBadge tabKey="More" to="/more" icon={mdiMenu} />
            </div>
         )}
         <div
            ref={safeAreaRef}
            id="safe-area-bottom"
            className={localStorage.getItem(LOCALSTORAGE_SHOW_SAFE_AREAS) ? 'show-safe-areas' : ''}
         >
            {localStorage.getItem(LOCALSTORAGE_SHOW_SAFE_AREAS)
               ? `Height: ${safeAreaHeight}px`
               : null}
         </div>
      </Navbar>
   );
};

type NavIconWithBadgeProps = {
   tabKey: string;
   to: string;
   icon: string;
   badge?: number;
   hideBadgeValue?: boolean;
};

const NavIconWithBadge = ({
   tabKey,
   to,
   icon,
   badge = 0,
   hideBadgeValue = false,
}: NavIconWithBadgeProps) => {
   const { activeTab } = useAppSelector(s => s.app);

   return (
      <LinkButton to={to} className={`${tabKey === activeTab ? 'active' : ''}`}>
         <span className="nav-icon">
            <span className="position-relative">
               <Icon path={icon} color={Colors.white} size={1} />
               {!hideBadgeValue && badge > 0 && (
                  <Badge
                     pill
                     bg="warning"
                     style={{ position: 'absolute', top: '-0.5rem', right: '-1rem' }}
                  >
                     {badge}
                  </Badge>
               )}
               {hideBadgeValue && badge > 0 && (
                  <Badge
                     pill
                     bg="warning"
                     style={{
                        position: 'absolute',
                        top: '-0.25rem',
                        right: '-0.5rem',
                        height: '1rem',
                        width: '1rem',
                     }}
                  >
                     &nbsp;
                  </Badge>
               )}
            </span>
         </span>
      </LinkButton>
   );
};
