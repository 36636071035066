import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { LegoSetChangeRequest, User } from '../types/api';
import { SupportedLocalesType } from '../intlHelpers';

interface AppState {
   title: {
      line1: string;
      line2: string;
   };
   showBackButton: boolean;
   session: {
      loginState: 'unknown' | 'loggedIn' | 'loggedOut';
      user: User | null;
      roles: string[];
   };
   apiError: any;
   language: SupportedLocalesType;
   badges: {
      news: number;
   };
   activeTab?: 'Statistic' | 'Inventory' | 'InventorySearch' | 'Blog' | 'More';
   notifications: {
      changeRequests: Partial<LegoSetChangeRequest>[];
   };
}

const initialState: AppState = {
   title: {
      line1: '',
      line2: '',
   },
   showBackButton: false,
   session: {
      loginState: 'unknown',
      user: null,
      roles: [],
   },
   apiError: null,
   language: 'en',
   badges: {
      news: 0,
   },
   activeTab: undefined,
   notifications: {
      changeRequests: [],
   },
};

const appSlice = createSlice({
   name: 'app',
   initialState,
   reducers: {
      setLoggedIn(state, action: PayloadAction<User>) {
         state.session.loginState = 'loggedIn';
         state.session.user = action.payload;
         state.session.roles = action.payload.roles;
      },
      updateSessionUser(state, action: PayloadAction<User>) {
         state.session.user = action.payload;
         state.session.roles = action.payload.roles;
      },
      setLoggedOut(state) {
         state.session.loginState = 'loggedOut';
         state.session.user = null;
         state.session.roles = [];
      },
      setApiError(state, action: PayloadAction<AxiosError | null>) {
         state.apiError = action.payload;
      },
      setLanguage(state, action: PayloadAction<SupportedLocalesType>) {
         state.language = action.payload;
      },
      setTitle: {
         reducer(state, action: PayloadAction<string, string, { setAsAppTitle: boolean }>) {
            document.title = action.payload ? `${action.payload} - Brickfolio.de` : 'Brickfolio.de';
            if (action.meta.setAsAppTitle) {
               state.title.line1 = action.payload;
               state.title.line2 = '';
            }
         },
         prepare(payload: string, setAsAppTitle: boolean = true) {
            return { payload, meta: { setAsAppTitle } };
         },
      },
      setAppTitle(state, action: PayloadAction<{ line1?: string; line2?: string | null }>) {
         state.title.line1 = action.payload.line1 ?? '';
         state.title.line2 = action.payload.line2 ?? '';
      },
      showBackButton(state, action: PayloadAction<boolean>) {
         state.showBackButton = action.payload;
      },
      setNewsBadge(state, action: PayloadAction<number>) {
         state.badges.news = action.payload;
      },
      setChangeRequests(state, action: PayloadAction<Partial<LegoSetChangeRequest>[]>) {
         state.notifications.changeRequests = action.payload;
      },
      setActiveTab(state, action: PayloadAction<AppState['activeTab']>) {
         state.activeTab = action.payload;
      },
   },
});

export const {
   setLoggedIn,
   updateSessionUser,
   setLoggedOut,
   setApiError,
   setLanguage,
   setTitle,
   setAppTitle,
   showBackButton,
   setNewsBadge,
   setChangeRequests,
   setActiveTab,
} = appSlice.actions;
export default appSlice.reducer;
