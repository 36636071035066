import React, { ReactNode } from 'react';
import { ButtonVariant } from 'react-bootstrap/types';
import { isMobile } from 'react-device-detect';
import Icon from '@mdi/react';
import { mdiShieldStarOutline } from '@mdi/js';
import { LoadingButton } from './LoadingButton';
import { LinkButton } from './LinkButton';

interface Props {
   /**
    * Action to execute when clicked.
    */
   onClick?: () => Promise<void>;
   /**
    * Route target when clicked.
    */
   to?: string;
   /**
    * Variant of the button.
    */
   variant?: ButtonVariant;
   /**
    * Styling of the dropdown item.
    */
   className?: string;
   /**
    * Content of the dropdown item.
    */
   children: ReactNode;
}

/**
 * Creates a styled button for an administrativ action.
 */
export const AdminButton = (props: Props) => {
   if (props.to) {
      return (
         <LinkButton
            variant={props.variant}
            to={props.to}
            block={isMobile}
            size={isMobile ? 'lg' : undefined}
            className={`fst-italic d-flex align-items-center justify-content-center ${props.className}`}
         >
            <Icon
               path={mdiShieldStarOutline}
               className="me-1"
               color="#FFD700"
               size={isMobile ? 1 : 0.75}
            />
            {props.children}
         </LinkButton>
      );
   }

   return (
      <LoadingButton
         variant={props.variant}
         block={isMobile}
         size={isMobile ? 'lg' : undefined}
         className={`fst-italic d-flex align-items-center justify-content-center ${props.className}`}
         onClick={props.onClick}
      >
         <Icon path={mdiShieldStarOutline} className="me-1" color="#FFD700" size={0.75} />
         {props.children}
      </LoadingButton>
   );
};
