import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import React, { useCallback, useEffect, useState } from 'react';
import { Container, Form } from 'react-bootstrap';
import { Formik, FormikErrors } from 'formik';
import ReactMde from 'react-mde';
import { isMobile } from 'react-device-detect';
import { showBackButton } from '../../redux/appSlice';
import { ConfirmDeleteDialog } from '../../components/dialogs/ConfirmDeleteDialog';
import { ButtonPanel, ResponsiveButton } from '../../components/molecules';
import { BlogPostEntry } from '../../components/blog/BlogPostEntry';
import {
   useAppDispatch,
   useIdFromParams,
   useMemorizedIntl,
   useSession,
   useValidationErrors,
} from '../../hooks';
import { BlogPost } from '../../types/api';
import { BlogPostModel } from '../../models/BlogPostModel';
import { formatDate, pageLinks } from '../../utils';
import { GenericControl, SubmitButton, TextControl } from '../../components/forms';
import { DropdownControl } from '../../components/forms/DropdownControl';
import { PageTitle } from '../../components/organisms/PageTitle';

export const BlogPostEditPage = () => {
   const intl = useMemorizedIntl();
   const navigate = useNavigate();
   const dispatch = useAppDispatch();
   const blogPostId = useIdFromParams();
   const { sessionUser } = useSession();
   const validationErrors = useValidationErrors();
   const [blogPost, setBlogPost] = useState<Partial<BlogPost>>({});
   const [showDeleteDialog, setShowDeleteDialog] = useState(false);

   useEffect(() => {
      (async () => {
         const newBlogPost = {
            id: 0,
            user_id: sessionUser?.id,
            user_name: sessionUser?.name,
            subject: 'Neuer Beitrag',
            status: 'draft',
         } as Partial<BlogPost>;

         const bp = blogPostId ? await BlogPostModel.get(blogPostId) : newBlogPost;
         setBlogPost(bp ?? newBlogPost);

         dispatch(showBackButton(true));
      })();
   }, [blogPostId, sessionUser, dispatch]);

   const handleSubmitForm = useCallback(
      async (values: Partial<BlogPost>) => {
         values.status = values.status ?? 'draft';
         const retVal = values.id
            ? await BlogPostModel.update(values)
            : await BlogPostModel.insert(values);

         navigate(pageLinks.blogPostDetail(retVal));
      },
      [navigate]
   );

   const handleDelete = useCallback(async () => {
      if (blogPost.id) {
         blogPost.status = 'deleted';
         await BlogPostModel.update(blogPost);
         setShowDeleteDialog(false);
         navigate('/blog');
      }
   }, [blogPost, navigate]);

   return (
      <>
         <Container>
            <PageTitle
               title={intl.formatMessage(
                  {
                     id: 'blog-post-edit.title',
                     defaultMessage: 'Blog - {subject}',
                  },
                  { subject: blogPost.subject }
               )}
               appTitle={blogPost.subject}
               subtitle={intl.formatMessage({
                  id: 'blog-post-edit.app-title',
                  defaultMessage: 'Blog',
               })}
               hidden
            />
            <div>
               <Formik
                  onSubmit={handleSubmitForm}
                  initialValues={blogPost}
                  enableReinitialize
                  validate={values => {
                     setBlogPost(values);
                     const errors: FormikErrors<Partial<BlogPost>> = {};

                     if (!values.subject) errors.subject = validationErrors.required;
                     if (!values.body) errors.body = validationErrors.required;

                     return errors;
                  }}
               >
                  {formik => (
                     <Form
                        noValidate
                        onSubmit={e => {
                           e.preventDefault();
                           formik.handleSubmit();
                        }}
                     >
                        <TextControl
                           formik={formik}
                           model="blog_post"
                           name="subject"
                           type="compact"
                        />
                        <DropdownControl
                           formik={formik}
                           model="blog_post"
                           name="status"
                           options={[
                              {
                                 label: intl.formatMessage({
                                    id: 'blog-post-edit.form.status.draft',
                                    defaultMessage: 'Entwurf',
                                 }),
                                 value: 'draft',
                              },
                              {
                                 label: intl.formatMessage({
                                    id: 'blog-post-edit.form.status.published',
                                    defaultMessage: 'Veröffentlicht',
                                 }),
                                 value: 'published',
                              },
                              {
                                 label: intl.formatMessage({
                                    id: 'blog-post-edit.form.status.deleted',
                                    defaultMessage: 'Gelöscht',
                                 }),
                                 value: 'deleted',
                              },
                           ]}
                           type="compact"
                        />

                        <GenericControl
                           formik={formik}
                           model="blog_post"
                           name="body"
                           type="compact"
                        >
                           <div className="w-100">
                              <ReactMde
                                 value={formik.values.body}
                                 onChange={text => formik.setFieldValue('body', text)}
                                 disablePreview
                              />
                           </div>
                        </GenericControl>

                        <ButtonPanel isFormFooter>
                           <ResponsiveButton
                              variant="danger"
                              onClick={() => setShowDeleteDialog(true)}
                              disabled={formik.isSubmitting}
                              hidden={!formik.values.id}
                           >
                              <FormattedMessage id="button.delete" defaultMessage="Löschen" />
                           </ResponsiveButton>
                           <ResponsiveButton
                              variant="secondary"
                              onClick={() => navigate(-1)}
                              hidden={isMobile}
                           >
                              <FormattedMessage id="button.cancel" defaultMessage="Abbrechen" />
                           </ResponsiveButton>
                           <SubmitButton formik={formik}>
                              <FormattedMessage id="button.save" defaultMessage="Speichern" />
                           </SubmitButton>
                        </ButtonPanel>
                     </Form>
                  )}
               </Formik>
            </div>
            <h1 className="mt-5">
               <FormattedMessage id="blog-post-edit.preview" defaultMessage="Vorschau" />
            </h1>
            <div>
               <BlogPostEntry post={blogPost} previewMode />
            </div>
         </Container>

         <ConfirmDeleteDialog
            show={showDeleteDialog}
            onClose={() => setShowDeleteDialog(false)}
            onDelete={handleDelete}
         >
            <p>
               <FormattedMessage
                  id="dialog.delete-blog-post.text"
                  defaultMessage="Möchtest du den Beitrag vom {date} wirklich löschen?"
                  values={{
                     date: formatDate(blogPost.publish_date, 'L LTS'),
                  }}
               />
            </p>
         </ConfirmDeleteDialog>
      </>
   );
};
