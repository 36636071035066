import { FormattedMessage } from 'react-intl';
import React, { useCallback } from 'react';
import { toast } from 'react-toastify';
import { Form, Modal } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';
import { Formik, FormikErrors } from 'formik';
import { BlogPostCommentModel } from '../../models/BlogPostCommentModel';
import { BlogPostComment } from '../../types/api';
import { ButtonPanel, ResponsiveButton } from '../molecules';
import { SubmitButton, TextareaControl } from '../forms';
import { useMemorizedIntl, useValidationErrors } from '../../hooks';

interface ReportDialogProps {
   comment: BlogPostComment;
   show: boolean;
   onClose: () => void;
   refresh: () => Promise<void>;
}

export const ReportDialog = (props: ReportDialogProps) => {
   const intl = useMemorizedIntl();
   const validationErrors = useValidationErrors();

   const handleSubmitForm = useCallback(
      async (values: BlogPostComment) => {
         values.is_reported = true;
         await BlogPostCommentModel.update(values);
         await props.refresh();
         props.onClose();
         toast.success(
            intl.formatMessage({
               id: 'dialog.blog-post-comment.report.success-toast.text',
               defaultMessage: 'Du hast den Kommentar erfolgreich gemeldet.',
            })
         );
      },
      [intl, props]
   );

   return (
      <Modal show={props.show} onHide={props.onClose} centered={isMobile}>
         <Formik
            onSubmit={handleSubmitForm}
            initialValues={props.comment}
            enableReinitialize
            validate={values => {
               const errors: FormikErrors<Partial<BlogPostComment>> = {};

               if (!values.report_reason) errors.report_reason = validationErrors.required;

               return errors;
            }}
         >
            {formik => (
               <Form
                  noValidate
                  onSubmit={e => {
                     e.preventDefault();
                     formik.handleSubmit();
                  }}
               >
                  <Modal.Header closeButton>
                     <Modal.Title>
                        <FormattedMessage
                           id="dialog.blog-post-comment.report.title"
                           defaultMessage="Kommentar melden"
                        />
                     </Modal.Title>
                  </Modal.Header>

                  <Modal.Body>
                     <TextareaControl
                        formik={formik}
                        name="report_reason"
                        type="compact"
                        placeholder={intl.formatMessage({
                           id: 'blog.blog-post-comment.report.form.report_reason.placeholder',
                           defaultMessage: 'Grund für die Meldung eingeben...',
                        })}
                        rows={5}
                     />
                  </Modal.Body>

                  <Modal.Footer>
                     <ButtonPanel>
                        <ResponsiveButton variant="secondary" onClick={props.onClose}>
                           <FormattedMessage id="button.close" defaultMessage="Schließen" />
                        </ResponsiveButton>
                        <SubmitButton formik={formik}>
                           <FormattedMessage id="button.report" defaultMessage="Melden" />
                        </SubmitButton>
                     </ButtonPanel>
                  </Modal.Footer>
               </Form>
            )}
         </Formik>
      </Modal>
   );
};
