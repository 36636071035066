import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Table } from 'react-bootstrap';
import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer';
import { FormattedMessage } from 'react-intl';
import Icon from '@mdi/react';
import cloneDeep from 'lodash/cloneDeep';
import set from 'lodash/set';
import { toast } from 'react-toastify';
import { mdiClose } from '@mdi/js';
import {
   ButtonPanel,
   ContentWithPlaceholder,
   LoadingButton,
   ResponsiveButton,
} from '../../../../../components/molecules';
import { ContentLoader } from '../../../../../components/atoms';
import { Colors } from '../../../../../components/Colors';
import { LegoSet, LegoSetChangeRequest, User } from '../../../../../types/api';
import { LegoSetChangeRequestModel } from '../../../../../models/LegoSetChangeRequestModel';
import { useIdFromParams, useMemorizedIntl, useModelTranslation } from '../../../../../hooks';
import { LegoSetModel } from '../../../../../models/LegoSetModel';
import { formatDate } from '../../../../../utils';
import { UserModel } from '../../../../../models/UserModel';
import { PageTitle } from '../../../../../components/organisms/PageTitle';
import { LegoSetPropertyChange } from '../../../../../types/LegoSetPropertyChange';

export const LegoSetChangeRequestEditAdminPage = () => {
   const navigate = useNavigate();
   const intl = useMemorizedIntl();
   const id = useIdFromParams();
   const modelTranslation = useModelTranslation('lego_set');
   const [changeRequest, setChangeRequest] = useState<LegoSetChangeRequest | null>(null);
   const [legoSet, setLegoSet] = useState<LegoSet | null>(null);
   const [user, setUser] = useState<User | null>(null);
   const [propertyChanges, setPropertyChanges] = useState<LegoSetPropertyChange[] | null>(null);

   useEffect(() => {
      (async () => {
         if (!id) return;

         const request = await LegoSetChangeRequestModel.get(id);
         const originalObj = await LegoSetModel.get(request?.set_id ?? 0);
         if (!request || !originalObj) return;

         setUser(await UserModel.get(request.user_id));
         setChangeRequest(request);
         setLegoSet(originalObj);
         setPropertyChanges(JSON.parse(request.json_data) as LegoSetPropertyChange[]);
      })();
   }, [id]);

   const handleSave = useCallback(async () => {
      if (!legoSet || !propertyChanges || !changeRequest) return;

      // Übernahme der Stammdaten
      const obj: LegoSet = cloneDeep(legoSet);
      propertyChanges.forEach(pc => {
         set(obj, pc.name, pc.newValue);
      });
      await LegoSetModel.update(obj);

      changeRequest.status = 'accepted';
      await LegoSetChangeRequestModel.update(changeRequest);

      toast.success(
         intl.formatMessage({
            id: 'view-legoset-change-request.accept-toast.text',
            defaultMessage: 'Die Änderungen wurde erfolgreich gespeichert.',
         })
      );

      navigate(-1);
   }, [changeRequest, intl, legoSet, navigate, propertyChanges]);

   const rejectRequest = useCallback(async () => {
      if (!changeRequest) return;

      changeRequest.status = 'rejected';
      await LegoSetChangeRequestModel.update(changeRequest);

      toast.success(
         intl.formatMessage({
            id: 'view-legoset-change-request.reject-toast.text',
            defaultMessage: 'Der Antrag wurde erfolgreich abgelehnt.',
         })
      );

      navigate(-1);
   }, [changeRequest, intl, navigate]);

   return (
      <Container>
         <PageTitle
            title={`Änderungsantrag für ${legoSet?.number}: ${legoSet?.name}`}
            appTitle="Änderungsantrag"
            subtitle={`${legoSet?.number}: ${legoSet?.name}`}
         />
         <ContentWithPlaceholder isLoading={legoSet === null} width="50%">
            <p className="text-muted">
               <FormattedMessage
                  id="view-legoset-change-request.page-description"
                  defaultMessage="Eingereicht von <em>{user}</em> am <em>{date}</em>."
                  values={{
                     user: user?.name,
                     date: formatDate(changeRequest?.x_date_created, 'L LTS'),
                  }}
               />
            </p>
         </ContentWithPlaceholder>
         {!propertyChanges ? (
            <ContentLoader />
         ) : (
            <>
               <Table hover className="flat">
                  <thead>
                     <tr>
                        <th className="w-25">Eigenschaft</th>
                        <th>Änderung</th>
                        <th style={{ width: '1%' }} aria-label="Aktionen" />
                     </tr>
                  </thead>
                  <tbody>
                     {propertyChanges.map(c => (
                        <tr key={c.name}>
                           <td className="align-middle">
                              {modelTranslation?.[c.name]?.title ?? c.name}
                           </td>
                           <td className="inner-table align-middle">
                              <ReactDiffViewer
                                 oldValue={(() => {
                                    let v = `${c.currentValue}`;
                                    if (c.currentValueDisplay) v += ` (${c.currentValueDisplay})`;
                                    return v;
                                 })()}
                                 newValue={(() => {
                                    let v = `${c.newValue}`;
                                    if (c.newValueDisplay) v += ` (${c.newValueDisplay})`;
                                    return v;
                                 })()}
                                 splitView={false}
                                 showDiffOnly={false}
                                 useDarkTheme
                                 hideLineNumbers
                                 compareMethod={DiffMethod.LINES}
                              />
                           </td>
                           <td className="text-end align-middle">
                              <LoadingButton
                                 variant="link"
                                 onClick={() => {
                                    setPropertyChanges(changes =>
                                       changes !== null ? changes?.filter(ch => ch !== c) : changes
                                    );
                                 }}
                                 size="sm"
                              >
                                 <Icon
                                    path={mdiClose}
                                    color={Colors.danger}
                                    size={1}
                                    title="Entfernen"
                                 />
                              </LoadingButton>
                           </td>
                        </tr>
                     ))}
                  </tbody>
               </Table>
               <ButtonPanel isFormFooter>
                  <ResponsiveButton variant="danger" onClick={rejectRequest}>
                     <FormattedMessage id="button.reject" defaultMessage="Ablehnen" />
                  </ResponsiveButton>
                  <ResponsiveButton variant="secondary" onClick={() => navigate(-1)}>
                     <FormattedMessage id="button.cancel" defaultMessage="Abbrechen" />
                  </ResponsiveButton>
                  <ResponsiveButton onClick={handleSave}>
                     <FormattedMessage id="button.accept" defaultMessage="Akzeptieren" />
                  </ResponsiveButton>
               </ButtonPanel>
            </>
         )}
      </Container>
   );
};
