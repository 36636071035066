import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { LanguageDropdown } from './LanguageDropdown';
import { VersionNumber } from '../atoms';

export const PageFooter = () => (
   <footer id="footer">
      <Container className="pt-0">
         <Row>
            <Col className="pt-3" lg="6" md="6" xs="12">
               <h4>
                  <FormattedMessage
                     id="footer.report-improvement"
                     defaultMessage="Fehler/Feature/Feedback melden"
                  />
               </h4>
               <div className="ps-2">
                  <a href="mailto:feedback@brickfolio.de">feedback@brickfolio.de</a>
               </div>

               <h4 className="pt-3">
                  <FormattedMessage id="footer.donate.title" defaultMessage="Spenden" />
               </h4>
               <div className="ps-2">
                  <FormattedMessage
                     id="footer.donate.text"
                     defaultMessage="{contact} für Spende"
                     values={{
                        patreon: (
                           <a
                              href="https://www.patreon.com/brickfolio"
                              target="_blank"
                              rel="noopener noreferrer"
                           >
                              Patreon
                           </a>
                        ),
                        contact: (
                           <a href="mailto:info@brickfolio.de?subject=Hey%2C%20Ich%20m%C3%B6chte%20deine%20coole%20Seite%20gerne%20unterst%C3%BCtzen&body=Hey%2C%0A%0Aich%20finde%20deine%20Seite%20echt%20cool%20und%20m%C3%B6chte%20diese%20unterst%C3%BCtzen.%20Leider%20habe%20ich%20kein%20Patreon%2C%20kannst%20du%20mir%20daher%20deine%20PayPal-Adresse%2FIBAN%20nennen%3F%0A%0ALG">
                              <FormattedMessage id="footer.contact" defaultMessage="Kontakt" />
                           </a>
                        ),
                     }}
                  />
               </div>
            </Col>
            <Col className="pt-3" lg="4" md="6" xs="12">
               <h4>
                  <FormattedMessage id="footer.links.title" defaultMessage="Links" />
               </h4>
               <div className="ps-2">
                  <Link to="/imprint">
                     <FormattedMessage
                        id="footer.imprint"
                        defaultMessage="Impressum & Datenschutz"
                     />
                  </Link>
                  <a
                     className="d-block"
                     href="https://forum.brickfolio.de"
                     target="_blank"
                     rel="noopener noreferrer"
                  >
                     <FormattedMessage id="footer.forum" defaultMessage="Forum" />
                  </a>
                  <Link to="/blog" className="d-block">
                     <FormattedMessage id="footer.news-and-blog" defaultMessage="News & Blog" />
                  </Link>
                  <Link to="/faq" className="d-block">
                     <FormattedMessage id="footer.faq" defaultMessage="FAQ" />
                  </Link>
                  <span className="d-block">
                     <Link to="/changelog">
                        <FormattedMessage id="footer.changelog" defaultMessage="Changelog" />
                     </Link>
                     &nbsp;-&nbsp;
                     <VersionNumber />
                  </span>
               </div>
            </Col>
            <Col className="pt-3" lg="2" md="6" xs="12">
               <h4>
                  <FormattedMessage id="footer.language" defaultMessage="Sprache" />
               </h4>
               <div className="ps-2">
                  <LanguageDropdown />
               </div>
            </Col>
         </Row>
      </Container>
   </footer>
);
