import { Form } from 'react-bootstrap';
import React from 'react';
import { FormikProps } from 'formik';
import { FormUtils } from './FormUtils';

interface Props<T> {
   formik: Pick<FormikProps<T>, 'isValid' | 'errors' | 'touched' | 'submitCount'>;
   name: keyof T;
}

export const FormFieldError = <T,>({ name, formik }: Props<T>) => {
   if (FormUtils.isInvalid(formik, name))
      return <Form.Text className="text-danger">{formik.errors[name]}</Form.Text>;
   return null;
};
