import React, { CSSProperties, ReactNode } from 'react';
import { Card as BsCard } from 'react-bootstrap';
import Icon from '@mdi/react';
import { mdiChevronRight } from '@mdi/js/commonjs/mdi';
import { Colors } from '../Colors';

interface Props {
   /**
    * Additional classes for the card itself.
    */
   classNameCard?: string;
   /**
    * Additional classes for the card body.
    */
   classNameBody?: string;
   /**
    * Additional style for the card body.
    */
   styleBody?: CSSProperties;
   /**
    * The content of the card.
    */
   children: ReactNode;
   /**
    * The orientation of the content.
    */
   orientation?: 'row' | 'column';
   /**
    * The action which will be executed when clicked on the card.
    */
   onPress?: () => void;
   /**
    * If `true` a chevron will be visible on the right.
    */
   chevron?: boolean;
   noPadding?: boolean;
}

export const Card = ({
   classNameCard = '',
   classNameBody = '',
   styleBody,
   children,
   orientation = 'row',
   onPress,
   chevron = false,
   noPadding = false,
}: Props) => (
   <BsCard
      className={`mobile my-2 ${onPress ? 'cursor-pointer' : ''} ${classNameCard}`}
      onClick={onPress}
   >
      <BsCard.Body
         className={`d-flex ${noPadding ? 'p-0' : 'p-2'} ${classNameBody}`}
         style={styleBody}
      >
         <div className={`flex-fill d-flex flex-${orientation}`}>{children}</div>
         {chevron && (
            <div className="d-flex align-items-center">
               <Icon path={mdiChevronRight} color={Colors.link} size={1} />
            </div>
         )}
      </BsCard.Body>
   </BsCard>
);
