import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { LegoSetsGridList } from '../../../components/organisms/LegoSetsGridList';
import { showBackButton } from '../../../redux/appSlice';
import { useAppDispatch, useMemorizedIntl } from '../../../hooks';
import { Label } from '../../../types/api';
import { LabelModel } from '../../../models/LabelModel';
import { getInventoryByLabel } from '../../../redux/pagesSlice';
import { ContentLoader } from '../../../components/atoms';
import { PageTitle } from '../../../components/organisms/PageTitle';
import { PageNotFoundPage } from '../../PageNotFoundPage';

export const LegoSetsByLabelPage = () => {
   const intl = useMemorizedIntl();
   const dispatch = useAppDispatch();
   const [label, setLabel] = useState<Label | null | undefined>(undefined);
   const routeParams = useParams<{ name?: string }>();

   useEffect(() => {
      (async () => {
         dispatch(showBackButton(true));
         setLabel((await LabelModel.list({ name_key: routeParams.name }))[0] ?? null);
      })();
   }, [routeParams.name, dispatch]);

   useEffect(() => {
      (async () => {
         if (!label) return;

         dispatch(getInventoryByLabel(label.id));
      })();
   }, [dispatch, label]);

   if (label === undefined) return <ContentLoader />;
   if (label === null) return <PageNotFoundPage />;

   return (
      <Container>
         <PageTitle
            title={intl.formatMessage(
               {
                  id: 'label.headline',
                  defaultMessage: 'Sets mit dem Label: {name}',
               },
               {
                  name: label.name,
               }
            )}
            appTitle={label.name}
            subtitle={intl.formatMessage({
               id: 'label.title',
               defaultMessage: 'Label',
            })}
         />
         <LegoSetsGridList hideOrdering />
      </Container>
   );
};
