import { SingleApiObject } from './BaseTypes';

export const ROLE_USER = 'user';
export const ROLE_ADMIN = 'admin';

export interface User extends SingleApiObject {
   sub: string;
   name: string;
   public_name: string | null;
   email: string;
   locale: string;
   display_language: string | null;
   picture_url: string;
   enabled: boolean;
   last_logon: string | null;
   type: 'user' | 'admin';
   notification_wanted_enabled: boolean;
   blog_updates_enabled: boolean;
   registration_completed: boolean;
   last_blog_visit: string | null;
   home_screen: string | number;

   roles: string[];
}
