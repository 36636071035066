import { FormattedMessage } from 'react-intl';
import React, { useCallback } from 'react';
import { ConfirmDeleteDialog } from './ConfirmDeleteDialog';
import { invalidateInventory, reloadLegoSet } from '../../redux/pagesSlice';
import { Inventory } from '../../types/api';
import { InventoryModel } from '../../models/InventoryModel';
import { formatDate } from '../../utils';
import { useAppDispatch } from '../../hooks';
import { ImageUploadModel } from '../../models/ImageUploadModel';

interface Props {
   show: boolean;
   inventory: Partial<Inventory>;
   onClose: () => void;
   afterDelete: () => void;
}

export const ConfirmInventoryDeleteDialog = (props: Props) => {
   const dispatch = useAppDispatch();

   const handleDelete = useCallback(async () => {
      // Löschen der Uploads für das zu löschende Inventar
      const images = await ImageUploadModel.list({ inventory_id: props.inventory.id });
      await Promise.all(images.map(async image => ImageUploadModel.delete(image)));

      // Löschen des Inventars selbst
      await InventoryModel.delete(props.inventory);
      dispatch(reloadLegoSet(props.inventory.set_id));
      dispatch(invalidateInventory());
      props.afterDelete();
   }, [dispatch, props]);

   return (
      <ConfirmDeleteDialog show={props.show} onClose={props.onClose} onDelete={handleDelete}>
         <p>
            <FormattedMessage
               id="dialog.delete-inventory.text"
               defaultMessage="Möchtest du das Set mit dem Kauf vom <strong>{date}</strong> mit dem Preis von <strong>{price}</strong> wirklich aus dem Inventar löschen?"
               values={{
                  date: formatDate(props.inventory?.date, 'L'),
                  price: `${props.inventory?.price} €`,
               }}
            />
         </p>
      </ConfirmDeleteDialog>
   );
};
