import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Chart } from 'react-google-charts';
import {
   GoogleDataTableColumn,
   GoogleDataTableColumnType,
   GoogleDataTableRow,
} from 'react-google-charts/dist/types';
import { Button, ButtonToolbar, Col, Dropdown, Row } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';
import Icon from '@mdi/react';
import { mdiReload } from '@mdi/js';
import isEqual from 'lodash/isEqual';
import moment from 'moment';
import { round } from 'lodash';
import merge from 'lodash/merge';
import { ChartLoader, InlineLoader } from '../../../components/atoms';
import { Colors } from '../../../components/Colors';
import { ROLE_ADMIN } from '../../../types/api';
import { LegoSetModel } from '../../../models/LegoSetModel';
import { ShowIfRole } from '../../../components/molecules/ShowIfRole';
import { updatePriceDateFromSource } from '../../../utils/ModelUtils';
import { useAppSelector, useMemorizedIntl } from '../../../hooks';
import { notEmpty } from '../../../utils';

interface Props {
   legoSetId?: number;
}

export const LegoSetPriceChart = ({ legoSetId }: Props) => {
   const intl = useMemorizedIntl();
   const stateLanguage = useAppSelector(s => s.app.language);
   const [isLoading, setLoading] = useState(true);
   const [rows, setRows] = useState<GoogleDataTableRow[]>([]);
   const [columns, setColumns] = useState<GoogleDataTableColumn[]>([]);
   const [monthInPast, setMonthInPast] = useState(6);
   const [priceDataIsLoading, setPriceDataIsLoading] = useState(false);
   const [priceRange, setPriceRange] = useState<{ min: number; max: number } | null>(null);

   const chartTimeRanges: { title: string; monthInPast: number }[] = useMemo(
      () => [
         {
            title: intl.formatMessage({ id: 'lego-set.chart.all', defaultMessage: 'Alles' }),
            monthInPast: -1,
         },
         {
            title: intl.formatMessage({ id: 'lego-set.chart.12-m', defaultMessage: '12 Monate' }),
            monthInPast: 12,
         },
         {
            title: intl.formatMessage({ id: 'lego-set.chart.6-m', defaultMessage: '6 Monate' }),
            monthInPast: 6,
         },
         {
            title: intl.formatMessage({ id: 'lego-set.chart.1-m', defaultMessage: '1 Monat' }),
            monthInPast: 1,
         },
      ],
      [intl]
   );

   const loadChartData = useCallback(async () => {
      const now = moment();
      if (monthInPast > 0) now.add(monthInPast * -1, 'month');
      else now.add(-12, 'month');

      const data = await LegoSetModel.getPriceData(legoSetId ?? 0, now);

      const dataColumns: GoogleDataTableColumn[] = [];
      const dataRows: GoogleDataTableRow[] = [];

      // Verarbeitung der Metadaten (Zeile 1 & 2)
      const columnNames = data[0];
      const columnDataTypes = data[1];
      for (let i = 0; i < columnNames.length; i += 1) {
         let columnName = columnNames[i] as string;
         // Da wir unten die Werte umrechnen um Preisbereich zu zeichnen, benennen wir die ersten beiden Spalten um
         if (i === 1) {
            columnName = intl.formatMessage({
               id: 'chart.lego-set-price.legend.min',
               defaultMessage: 'Min.',
            });
         }
         if (i === 2) {
            columnName = intl.formatMessage({
               id: 'chart.lego-set-price.legend.max',
               defaultMessage: 'Max.',
            });
         }
         dataColumns.push({
            type: columnDataTypes[i] as GoogleDataTableColumnType,
            label: columnName,
         });
      }

      // Metadaten entfernen
      data.splice(0, 2);

      // Nutzdaten verarbeiten
      const dataLength = data.length;
      let overallMinValue: number | null = null;
      let overallMaxValue: number | null = null;

      data.forEach((elem: any, index: number) => {
         const [date, priceIdealo, priceBricklink, priceLego, ...inventory] = elem;
         const r = [];
         r.push(new Date(date));
         overallMinValue = Math.min(
            ...[overallMinValue, priceIdealo, priceBricklink, priceLego, ...inventory].filter(
               notEmpty
            )
         );
         overallMaxValue = Math.max(
            ...[overallMaxValue, priceIdealo, priceBricklink, priceLego, ...inventory].filter(
               notEmpty
            )
         );

         // Wir erzeugen aus den beiden Marktwerten ein Konstrukt von Werten für ein Area-Chart mit einem gefüllten
         //    Bereich nur in der Mitte. Dazu werden zwei Area-Charts gestapelt. Das Untere hat eine weiße/transparente
         //    Füllung und ist daher unsichtbar, das Obere hat dann die sichtbare Füllung und erzeugt somit den Preis-Korridor.
         if (priceIdealo !== null || priceBricklink !== null) {
            // Durch die Verwendung des jeweiligen anderen Preises sorgen wir dafür, dass wenn ein Wert `null` ist,
            //    nicht als lowerPoint = 0 verwendet wird. So ist der lowerPoint der Wert des einen vorhandenen Preises.
            //    Dadurch entsteht einfach nur eine Linie und kein Bereich von 0 bis stackedPoint.
            const lowerPoint = Math.min(
               priceIdealo ?? priceBricklink,
               priceBricklink ?? priceIdealo
            );
            const stackedPoint = Math.max(priceIdealo, priceBricklink) - lowerPoint;
            r.push({ v: lowerPoint, f: `${round(lowerPoint, 2)} \u20AC` });
            r.push({
               v: round(stackedPoint, 2),
               f: `${round(lowerPoint + stackedPoint, 2)} \u20AC`,
            });
         } else {
            // Da keine Daten vorliegen, fügen wir null-Werte hinzu, da Math.max/min 0 als Wert sonst erzeugt
            r.push({ v: null });
            r.push({ v: null });
         }

         // Wert für den Lego-UVP
         if (index <= dataLength || index >= data.length - dataLength)
            r.push({ v: priceLego, f: `${round(priceLego, 2)} \u20AC` });
         else r.push({ v: null });

         // Nun alle anderen Spalten verarbeiten
         for (let i = 0; i < inventory.length; i += 1) {
            if (index <= dataLength || index >= data.length - dataLength)
               r.push({ v: inventory[i], f: `${round(inventory[i], 2)} \u20AC` });
            else r.push({ v: null });
         }

         dataRows.push(r);
      });
      // Aktualisierung des Charts mit den Daten
      if (!isEqual(columns, dataColumns)) setColumns(dataColumns);

      setRows(dataRows);
      setPriceRange({ min: overallMinValue ?? 0, max: overallMaxValue ?? 0 });
      setLoading(false);
   }, [monthInPast, legoSetId, columns, intl]);

   const reloadPriceData = useCallback(async () => {
      if (!legoSetId) return;

      setPriceDataIsLoading(true);
      try {
         await updatePriceDateFromSource(legoSetId);
         await loadChartData();
      } finally {
         setPriceDataIsLoading(false);
      }
   }, [loadChartData, legoSetId]);

   useEffect(() => {
      (async () => {
         await loadChartData();
      })();
   }, [loadChartData]);

   const chartOptions = useMemo(() => {
      let options = chartOptionsPreset;
      if (isMobile) {
         options = merge(options, { legend: { position: 'none' }, chartArea: { bottom: 20 } });
      }

      if (priceRange) {
         options = merge(options, {
            vAxis: {
               viewWindowMode: 'explicit',
               viewWindow: {
                  min: Math.max(0, priceRange.min - 25),
                  max: priceRange.max + 25,
               },
            },
         });
      }

      return options;
   }, [priceRange]);

   return (
      <>
         <Row>
            <Col xs="12" className={`pb-2 d-flex ${isMobile ? 'justify-content-center' : ''}`}>
               <ButtonToolbar className="gap-1">
                  <ShowIfRole roles={[ROLE_ADMIN]}>
                     <Button
                        variant="secondary"
                        className="d-flex justify-content-center align-items-center"
                        title={intl.formatMessage({
                           id: 'lego-set.chart.reload-data',
                           defaultMessage: 'Statistiken abrufen',
                        })}
                        onClick={reloadPriceData}
                        disabled={priceDataIsLoading}
                     >
                        {priceDataIsLoading ? (
                           <InlineLoader show />
                        ) : (
                           <Icon path={mdiReload} color={Colors.white} size={0.75} />
                        )}
                     </Button>
                  </ShowIfRole>
                  <Dropdown>
                     <Dropdown.Toggle variant="secondary">
                        {chartTimeRanges.find(r => r.monthInPast === monthInPast)?.title ??
                           chartTimeRanges[0].title}
                     </Dropdown.Toggle>
                     <Dropdown.Menu>
                        {chartTimeRanges.map(r => (
                           <Dropdown.Item
                              as={Button}
                              key={r.monthInPast}
                              active={r.monthInPast === monthInPast}
                              onClick={() => setMonthInPast(r.monthInPast)}
                           >
                              {r.title}
                           </Dropdown.Item>
                        ))}
                     </Dropdown.Menu>
                  </Dropdown>
               </ButtonToolbar>
            </Col>
         </Row>
         <Row>
            <Col xs="12">
               <div
                  style={{
                     display: 'flex',
                     maxWidth: '56.25rem',
                     height: isMobile ? '10rem' : '22rem',
                  }}
               >
                  {isLoading ? (
                     <ChartLoader />
                  ) : (
                     <Chart
                        width="100%"
                        height={isMobile ? '10rem' : '22rem'}
                        chartType="ComboChart"
                        loader={<ChartLoader />}
                        data={[columns, ...rows]}
                        options={chartOptions}
                        legendToggle
                        chartLanguage={stateLanguage}
                     />
                  )}
               </div>
            </Col>
         </Row>
      </>
   );
};

const chartOptionsPreset = {
   backgroundColor: '#303030',
   seriesType: 'line',
   curveType: 'function',
   isStacked: true,
   colors: [
      '#3366CC',
      '#3366CC',
      '#FF9900',
      '#DC3912',
      '#109618',
      '#990099',
      '#3B3EAC',
      '#0099C6',
      '#DD4477',
      '#66AA00',
      '#B82E2E',
   ],
   legend: {
      position: 'bottom',
      textStyle: {
         color: '#FFF',
      },
   },
   vAxis: {
      textStyle: {
         color: '#FFF',
      },
      gridlines: {
         color: Colors.secondary,
      },
      format: '#.## \u20AC',
   },
   hAxis: {
      textStyle: {
         color: '#FFF',
      },
      gridlines: {
         color: Colors.secondary,
         count: 5,
      },
   },
   chartArea: {
      top: 10,
      right: 10,
      bottom: 60,
      left: 50,
   },
   lineDashStyle: [2, 6],
   animation: {
      duration: 1000,
      easing: 'out',
      startup: true,
   },
   series: {
      0: {
         type: 'area',
         areaOpacity: 0,
         visibleInLegend: false,
         lineDashStyle: [1, 0],
         annotations: {
            boxStyle: {
               // Color of the box outline.
               stroke: '#888',
               // Thickness of the box outline.
               strokeWidth: 1,
               // x-radius of the corner curvature.
               rx: 10,
               // y-radius of the corner curvature.
               ry: 10,
               // Attributes for linear gradient fill.
               gradient: {
                  // Start color for gradient.
                  color1: '#fbf6a7',
                  // Finish color for gradient.
                  color2: '#33b679',
                  // Where on the boundary to start and
                  // end the color1/color2 gradient,
                  // relative to the upper left corner
                  // of the boundary.
                  x1: '0%',
                  y1: '0%',
                  x2: '100%',
                  y2: '100%',
                  // If true, the boundary for x1,
                  // y1, x2, and y2 is the box. If
                  // false, it's the entire chart.
                  useObjectBoundingBoxUnits: true,
               },
            },
         },
      },
      1: {
         type: 'area',
         areaOpacity: 0.1,
         lineDashStyle: [1, 0],
         visibleInLegend: false,
      },
      2: {
         lineDashStyle: [1, 0],
      },
   },
};
