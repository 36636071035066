import React, { useEffect } from 'react';
import { BrowserView } from 'react-device-detect';
import { useAppDispatch } from '../../hooks';
import { setAppTitle, setTitle } from '../../redux/appSlice';

interface Props {
   title: string;
   children?: React.ReactNode;
   appTitle?: string;
   subtitle?: string;
   hidden?: boolean;
}

export const PageTitle = ({ title, children, appTitle, subtitle, hidden = false }: Props) => {
   const dispatch = useAppDispatch();

   useEffect(() => {
      dispatch(setTitle(title));
   }, [dispatch, title]);

   useEffect(() => {
      dispatch(setAppTitle({ line1: appTitle ?? title, line2: subtitle }));
   }, [dispatch, title, appTitle, subtitle]);

   if (hidden) return null;

   return (
      <BrowserView>
         <h1>{children ?? title}</h1>
      </BrowserView>
   );
};
