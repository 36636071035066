import React from 'react';
import { Table } from 'react-bootstrap';

interface Props {
   children: React.ReactNode;
}

export const SimpleTable = ({ children }: Props) => (
   <Table className="mb-0">
      <tbody>{children}</tbody>
   </Table>
);

interface PropsRow {
   title: React.ReactNode;
   children: React.ReactNode;
}

SimpleTable.Row = ({ title, children }: PropsRow) => (
   <tr>
      <th className="align-middle text-nowrap">{title}</th>
      <td className="align-middle">{children}</td>
   </tr>
);
