import { FormattedMessage } from 'react-intl';
import React, { useCallback } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';
import { Formik, FormikErrors } from 'formik';
import { BlogPostCommentModel } from '../../models/BlogPostCommentModel';
import { BlogPostComment } from '../../types/api';
import { ButtonPanel, ResponsiveButton } from '../molecules';
import { SubmitButton, TextareaControl } from '../forms';
import { useMemorizedIntl, useValidationErrors } from '../../hooks';

interface ReplyDialogProps {
   comment: BlogPostComment;
   show: boolean;
   onClose: () => void;
   refresh: () => Promise<void>;
}

export const ReplyDialog = (props: ReplyDialogProps) => {
   const intl = useMemorizedIntl();
   const validationErrors = useValidationErrors();

   const handleSubmitForm = useCallback(
      async (values: Partial<BlogPostComment>) => {
         values.blog_post_id = props.comment.blog_post_id;
         values.parent_comment_id = props.comment.id;
         await BlogPostCommentModel.insert(values);
         await props.refresh();
         props.onClose();
      },
      [props]
   );

   return (
      <Modal show={props.show} onHide={props.onClose} centered={isMobile}>
         <Formik
            onSubmit={handleSubmitForm}
            initialValues={{ comment: '' }}
            enableReinitialize
            validate={values => {
               const errors: FormikErrors<Partial<BlogPostComment>> = {};

               if (!values.comment || values.comment.length === 0)
                  errors.comment = validationErrors.required;

               return errors;
            }}
         >
            {formik => (
               <Form
                  noValidate
                  onSubmit={e => {
                     e.preventDefault();
                     formik.handleSubmit();
                  }}
               >
                  <Modal.Header closeButton>
                     <Modal.Title>
                        <FormattedMessage
                           id="dialog.blog-post-comment.reply.title"
                           defaultMessage="Auf Kommentar antworten"
                        />
                     </Modal.Title>
                  </Modal.Header>

                  <Modal.Body>
                     <TextareaControl
                        formik={formik}
                        name="comment"
                        type="compact"
                        placeholder={intl.formatMessage({
                           id: 'blog.blog-post-comment.form.comment.placeholder',
                           defaultMessage: 'Kommentar schreiben...',
                        })}
                        rows={5}
                     />
                  </Modal.Body>

                  <Modal.Footer>
                     <ButtonPanel>
                        <ResponsiveButton variant="secondary" onClick={props.onClose}>
                           <FormattedMessage id="button.cancel" defaultMessage="Abbrechen" />
                        </ResponsiveButton>
                        <SubmitButton formik={formik}>
                           <FormattedMessage
                              id="button.submit-comment"
                              defaultMessage="Abschicken"
                           />
                        </SubmitButton>
                     </ButtonPanel>
                  </Modal.Footer>
               </Form>
            )}
         </Formik>
      </Modal>
   );
};
