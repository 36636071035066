import React from 'react';
import { useSession } from '../../hooks';

interface Props {
   children: React.ReactNode;
   roles: string[];
}

export const ShowIfRole = ({ children, roles }: Props) => {
   const { sessionRoles } = useSession();

   // User hat min. einer der benötigten Rollen? -> Children anzeigen, sonst nicht
   // eslint-disable-next-line react/jsx-no-useless-fragment
   if (children && sessionRoles.findIndex(r => roles.includes(r)) >= 0) return <>{children}</>;
   return null;
};
