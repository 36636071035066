// Angepasst von https://github.com/codeAdrian/react-use-scroll-indicator
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

export type ScrollIndicatorInput = {
   onElement?: boolean;
   precision?: number;
   initialValue?: number;
};

export type ScrollIndicatorApi = {
   activeListener: () => void;
   setScrollState: (scrolled: number) => void;
};

export type ScrollIndicatorState = {
   value: number;
   targetElement?: (node: any) => void;
};

export type ScrollIndicatorOutput = [ScrollIndicatorState, ScrollIndicatorApi];

export type ScrollIndicatorHook = (options?: ScrollIndicatorInput) => ScrollIndicatorOutput;

export const INITIAL_ON_ELEMENT = false;
export const INITIAL_PRECISION = 2;
export const INITIAL_VALUE = 0;

export const VALUE_MAX = 100;
export const VALUE_MIN = 0;

export const PRECISION_BASE = 10;

export const useScrollIndicator: ScrollIndicatorHook = (options = {}) => {
   const {
      onElement = INITIAL_ON_ELEMENT,
      precision = INITIAL_PRECISION,
      initialValue = INITIAL_VALUE,
   } = options;

   const roundFactor = PRECISION_BASE ** precision;

   const [value, setValue] = useState(initialValue);
   const targetElement = useRef<any>(null);
   const [refChangedCounter, setRefChangedCounter] = useState(0);
   const setTargetElement = useCallback(node => {
      setRefChangedCounter(r => r + 1);
      targetElement.current = node;
   }, []);

   const handleValue = useCallback(
      (scrolled: number) => {
         const val = Math.round(scrolled * roundFactor) / roundFactor;
         setValue(Math.max(Math.min(val, VALUE_MAX), VALUE_MIN));
      },
      [roundFactor]
   );

   const handleElementScroll = useCallback(() => {
      const { scrollHeight, clientHeight, scrollTop } = targetElement.current;
      handleValue((scrollTop / (scrollHeight - clientHeight)) * VALUE_MAX);
   }, [handleValue]);

   const handlePageScroll = useCallback(() => {
      const { scrollHeight, clientHeight, scrollTop } = document.documentElement;
      const winScroll = document.body.scrollTop || scrollTop;
      handleValue((winScroll / (scrollHeight - clientHeight)) * VALUE_MAX);
   }, [handleValue]);

   const listener = useMemo(
      () => (onElement ? handleElementScroll : handlePageScroll),
      [onElement, handleElementScroll, handlePageScroll]
   );

   useEffect(() => {
      if (onElement) {
         targetElement.current?.addEventListener('scroll', listener);
         if (targetElement.current) listener();

         return () => {
            targetElement.current?.removeEventListener('scroll', listener);
         };
      }
      window.addEventListener('scroll', listener);
      return () => {
         window.removeEventListener('scroll', listener);
      };
   }, [targetElement, listener, onElement, refChangedCounter]);

   const state: ScrollIndicatorState = {
      value,
      ...(onElement ? { targetElement: setTargetElement } : null),
   };

   const api: ScrollIndicatorApi = useMemo(
      () => ({
         activeListener: listener,
         setScrollState: handleValue,
      }),
      [listener, handleValue]
   );

   return [state, api];
};
