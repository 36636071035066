import React from 'react';
import { isMobile } from 'react-device-detect';
import { LoadingButton, LoadingButtonProps } from './LoadingButton';
import { LinkButton } from './LinkButton';

interface Props extends LoadingButtonProps {
   /**
    * Optional link target. When set a {@LinkButton} is generated.
    */
   to?: string;
}

/**
 * Creates a button which will be rendered differently on desktop and mobile view.
 * On `desktop` the button has normal size and wraps around it's content.
 * On `mobile` the button is larger and stretch to 100% width (block mode).
 */
export const ResponsiveButton = ({ hidden = false, to, ...buttonProps }: Props) => {
   if (hidden) return null;

   if (to) {
      return (
         <LinkButton {...buttonProps} to={to} block={isMobile} size={isMobile ? 'lg' : undefined} />
      );
   }

   return <LoadingButton {...buttonProps} block={isMobile} size={isMobile ? 'lg' : undefined} />;
};
