import React from 'react';
import ApiService from '../../services/ApiService';
import { Image } from './Image';
import { LegoSet } from '../../types/api';

interface Props {
   legoSet?: Partial<Pick<LegoSet, 'number' | 'name'>>;
   thumbnail?: boolean;
}

export const LegoSetImage = ({ legoSet, thumbnail = false }: Props) => {
   const imageName = `${legoSet?.number}${thumbnail ? '-thumbnail' : ''}.jpg`;

   return (
      <Image
         url={`${ApiService.apiEndpoint}/static/images/${imageName}`}
         style={{
            width: '100%',
            height: '100%',
            aspectRatio: '1',
            objectFit: 'contain',
         }}
         loading={!legoSet}
         alt={legoSet?.name}
         title={legoSet?.name}
      />
   );
};
