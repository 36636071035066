import React from 'react';
import { Container } from 'react-bootstrap';
import { useAppDispatch, useMemorizedIntl } from '../../hooks';
import { PageTitle } from '../../components/organisms/PageTitle';
import { SearchForm } from '../../components/organisms/SearchForm';

export const SearchPage = () => {
   const intl = useMemorizedIntl();
   const dispatch = useAppDispatch();

   return (
      <Container>
         <PageTitle
            title={intl.formatMessage({
               id: 'search.title',
               defaultMessage: 'Suche nach Lego-Sets',
            })}
         />
         <SearchForm focus />
      </Container>
   );
};
