import React, { useCallback, useEffect, useMemo } from 'react';
import reduce from 'lodash/reduce';
import { useParams } from 'react-router-dom';
import { Container, Spinner } from 'react-bootstrap';
import PullToRefresh from 'react-simple-pull-to-refresh';
import { FormattedMessage } from 'react-intl';
import { getInventoryByType } from '../../../redux/pagesSlice';
import { LegoSetsGridList } from '../../../components/organisms/LegoSetsGridList';
import { setActiveTab, showBackButton } from '../../../redux/appSlice';
import { InventoryButtonGroup } from './InventoryButtonGroup';
import { useAppDispatch, useAppSelector, useMemorizedIntl } from '../../../hooks';
import { InventoryType } from '../../../types/api';
import { PageTitle } from '../../../components/organisms/PageTitle';

export const MyInventoryPage = () => {
   const dispatch = useAppDispatch();
   const intl = useMemorizedIntl();
   const routeParams = useParams<{ inventoryType?: InventoryType }>();
   const inventoryType = routeParams.inventoryType ?? 'bought';
   const { entities, loadedBy, isStale } = useAppSelector(s => s.pages.inventory);

   const loadLegoSets = useCallback(async () => {
      await dispatch(getInventoryByType(inventoryType));
   }, [dispatch, inventoryType]);

   useEffect(() => {
      dispatch(showBackButton(false));
      dispatch(setActiveTab('Inventory'));
   }, [dispatch]);

   useEffect(() => {
      if (loadedBy !== inventoryType || isStale) loadLegoSets().then();
   }, [loadedBy, loadLegoSets, inventoryType, isStale]);

   const handleRefresh = useCallback(async () => {
      await loadLegoSets();
   }, [loadLegoSets]);

   const pageTitle = useMemo(() => {
      switch (inventoryType) {
         case 'wanted':
            return {
               title: intl.formatMessage({
                  id: 'inventory.wanted.headline',
                  defaultMessage: 'Meine Wunschliste',
               }),
               subtitle: intl.formatMessage(
                  {
                     id: 'inventory.headline.subtitle',
                     defaultMessage:
                        '{count, plural, =0 {# Lego-Sets} one {# Lego-Set} other {# Lego-Sets}}',
                  },
                  {
                     count: reduce(entities, (sum, s) => sum + s.statistics.wanted.count, 0),
                  }
               ),
            };
         case 'bought':
            return {
               title: intl.formatMessage({
                  id: 'inventory.bought.headline',
                  defaultMessage: 'Mein Inventar',
               }),
               subtitle: intl.formatMessage(
                  {
                     id: 'inventory.headline.subtitle',
                     defaultMessage:
                        '{count, plural, =0 {# Lego-Sets} one {# Lego-Set} other {# Lego-Sets}}',
                  },
                  {
                     count: reduce(entities, (sum, s) => sum + s.statistics.bought.count, 0),
                  }
               ),
            };
         case 'sold':
            return {
               title: intl.formatMessage({
                  id: 'inventory.sales.headline',
                  defaultMessage: 'Meine Verkäufe',
               }),
               subtitle: intl.formatMessage(
                  {
                     id: 'inventory.headline.subtitle',
                     defaultMessage:
                        '{count, plural, =0 {# Lego-Sets} one {# Lego-Set} other {# Lego-Sets}}',
                  },
                  {
                     count: reduce(entities, (sum, s) => sum + s.statistics.sold.count, 0),
                  }
               ),
            };
         default:
            return { title: '', subtitle: '' };
      }
   }, [inventoryType, intl, entities]);

   return (
      <PullToRefresh
         onRefresh={handleRefresh}
         pullDownThreshold={100}
         maxPullDownDistance={130}
         refreshingContent={<Spinner className="mt-4" animation="border" role="status" />}
         pullingContent={
            <div className="d-flex flex-fill justify-content-center mt-2">
               <p>
                  <FormattedMessage id="pull-to-refresh" defaultMessage="Ziehe zum Aktualisieren" />{' '}
               </p>
            </div>
         }
      >
         <Container>
            <PageTitle title={pageTitle.title} subtitle={pageTitle.subtitle} />
            <InventoryButtonGroup activeTab={inventoryType} />
            <LegoSetsGridList inventoryType={inventoryType} onRefresh={handleRefresh} />
         </Container>
      </PullToRefresh>
   );
};
