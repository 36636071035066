import { Form } from 'react-bootstrap';
import React from 'react';
import { ControlProps, GenericControl } from './GenericControl';
import { InfoTooltip } from '../molecules/InfoTooltip';
import { Colors } from '../Colors';
import { useModelTranslation } from '../../hooks';

export interface CheckControlProps<T> extends ControlProps<T> {
   /**
    * When `true` the component is disabled.
    */
   disabled?: boolean;
}

/**
 * Renders a responsive form control of type checkbox with validation errors.
 */
export const CheckControl = <T,>({
   disabled,
   label,
   description,
   model,
   ...props
}: CheckControlProps<T>) => {
   const modelTranslations = useModelTranslation(model);

   return (
      <GenericControl {...props}>
         <Form.Check
            type="switch"
            id={`switch-${props.name as string}`}
            label={
               <>
                  {modelTranslations?.[props.name as string]?.title ?? label}
                  {(modelTranslations?.[props.name as string]?.description ?? description) && (
                     <InfoTooltip
                        text={
                           modelTranslations?.[props.name as string]?.description ??
                           description ??
                           ''
                        }
                     />
                  )}
               </>
            }
            className="me-2 cursor-pointer d-inline-block"
            color={Colors.success}
            checked={(props.formik.values[props.name] as unknown as boolean) ?? false}
            disabled={props.formik.isSubmitting || disabled}
            onChange={() =>
               props.formik.setFieldValue(
                  props.name as unknown as string,
                  !props.formik.values[props.name]
               )
            }
            onBlur={props.formik.handleBlur}
            isInvalid={
               !!props.formik.errors[props.name] &&
               ((!props.formik.isValid && props.formik.submitCount > 0) ||
                  !!props.formik.touched[props.name])
            }
         />
      </GenericControl>
   );
};
