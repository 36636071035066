import { Classnames, Field, Translations } from 'react-querybuilder';
import { IntlShape } from 'react-intl/src/types';
import orderBy from 'lodash/orderBy';
import { isMobile } from 'react-device-detect';
import { Category, Label } from '../../types/api';

export const controlClassnames: Partial<Classnames> = {
   /* Group-Buttons */
   addRule: 'btn btn-primary btn-sm me-1',
   addGroup: 'btn btn-secondary btn-sm me-1',
   removeGroup: 'btn btn-danger btn-sm',

   /* Rule */
   rule: isMobile ? 'flex-column' : 'flex-row',
   fields: 'form-control form-control-sm',
   operators: 'form-control form-control-sm mx-md-1 my-1 my-md-0',
   value: 'form-control form-control-sm',
   removeRule: 'btn btn-danger btn-sm ms-md-1 mt-1 mt-md-0',

   /* Group */
   combinators: 'form-control form-control-sm',
};

export const translations = (intl: IntlShape): Partial<Translations> => ({
   fields: {
      title: intl.formatMessage({ id: 'query-builder.elements.fields', defaultMessage: 'Felder' }), // Fields
   },
   operators: {
      title: intl.formatMessage({
         id: 'query-builder.elements.operators',
         defaultMessage: 'Operatoren',
      }), // Operators
   },
   value: {
      title: intl.formatMessage({ id: 'query-builder.elements.value', defaultMessage: 'Wert' }), // Value
   },
   removeRule: {
      label: 'X',
      title: intl.formatMessage({
         id: 'query-builder.elements.remove-rule',
         defaultMessage: 'Regel entfernen',
      }), // Remove rule
   },
   removeGroup: {
      label: 'X',
      title: intl.formatMessage({
         id: 'query-builder.elements.remove-group',
         defaultMessage: 'Gruppe entfernen',
      }), // Remove group
   },
   addRule: {
      label: intl.formatMessage({
         id: 'query-builder.elements.add-rule.label',
         defaultMessage: 'Regel hinzufügen',
      }), // + Rule
      title: intl.formatMessage({
         id: 'query-builder.elements.add-rule.title',
         defaultMessage: 'Regel hinzufügen',
      }), // Add rule
   },
   addGroup: {
      label: intl.formatMessage({
         id: 'query-builder.elements.add-group.label',
         defaultMessage: 'Gruppe hinzufügen',
      }), // + Group
      title: intl.formatMessage({
         id: 'query-builder.elements.add-group.title',
         defaultMessage: 'Gruppe hinzufügen',
      }), // Add group
   },
   combinators: {
      title: intl.formatMessage({
         id: 'query-builder.elements.combinators',
         defaultMessage: 'Kombinatoren',
      }), // Combinators
   },
   notToggle: {
      label: intl.formatMessage({
         id: 'query-builder.elements.not-toggle.label',
         defaultMessage: 'Gruppe invertieren',
      }),
      title: intl.formatMessage({
         id: 'query-builder.elements.not-toggle.title',
         defaultMessage: 'Gruppe invertieren',
      }), // Invert this group
   },
});

// Felder müssen auch im Backend und MyFiltersPage.tsx beim Mapping angepasst werden!
export const fields = (intl: IntlShape, categories: Category[], labels: Label[]): Field[] => {
   const stringOperators = [
      {
         name: '=',
         label: intl.formatMessage({
            id: 'query-builder.operators.is-equal',
            defaultMessage: 'ist gleich',
         }),
      },
      {
         name: '!=',
         label: intl.formatMessage({
            id: 'query-builder.operators.is-not-equal',
            defaultMessage: 'ist ungleich',
         }),
      },
   ];

   const numberOperators = [
      ...stringOperators,
      {
         name: '>',
         label: intl.formatMessage({
            id: 'query-builder.operators.is-greater-than',
            defaultMessage: 'ist größer als',
         }),
      },
      {
         name: '>=',
         label: intl.formatMessage({
            id: 'query-builder.operators.is-greater-or-equal-than',
            defaultMessage: 'ist gleich oder größer als',
         }),
      },
      {
         name: '<',
         label: intl.formatMessage({
            id: 'query-builder.operators.is-lower-than',
            defaultMessage: 'ist kleiner als',
         }),
      },
      {
         name: '<=',
         label: intl.formatMessage({
            id: 'query-builder.operators.is-lower-or-equal-than',
            defaultMessage: 'ist gleich oder kleiner als',
         }),
      },
   ];

   const dateOperators = [...numberOperators];

   const booleanOperators = [
      {
         name: '=',
         label: intl.formatMessage({
            id: 'query-builder.operators.is-equal',
            defaultMessage: 'ist gleich',
         }),
      },
   ];

   return [
      /* Stammdaten vom Lego-Set */
      {
         name: 'set_number',
         label: intl.formatMessage({
            id: 'query-builder.fields.set_number',
            defaultMessage: 'Set-Nummer',
         }),
         operators: stringOperators,
      },
      {
         name: 'set_parts',
         label: intl.formatMessage({
            id: 'query-builder.fields.set_parts',
            defaultMessage: 'Teile',
         }),
         inputType: 'number',
         operators: numberOperators,
      },
      {
         name: 'category_id',
         label: intl.formatMessage({
            id: 'query-builder.fields.category_id',
            defaultMessage: 'Kategorie',
         }),
         operators: stringOperators,
         valueEditorType: 'select',
         values: orderBy(categories, 'name').map(c => ({ name: `${c.id}`, label: c.name })),
      },
      {
         name: 'set_price',
         label: intl.formatMessage({ id: 'query-builder.fields.set_price', defaultMessage: 'UVP' }),
         inputType: 'number',
         operators: numberOperators,
      },
      {
         name: 'set_is_gwp',
         label: intl.formatMessage({
            id: 'query-builder.fields.set_is_gwp',
            defaultMessage: 'Kostenlose Zugabe bei Kauf',
         }),
         operators: booleanOperators,
         valueEditorType: 'select',
         values: [
            {
               name: '1',
               label: intl.formatMessage({ id: 'common.yes', defaultMessage: 'Ja' }),
            },
            {
               name: '0',
               label: intl.formatMessage({ id: 'common.no', defaultMessage: 'Nein' }),
            },
         ],
      },
      {
         name: 'set_retired',
         label: intl.formatMessage({
            id: 'query-builder.fields.set_retired',
            defaultMessage: 'EOL',
         }),
         operators: booleanOperators,
         valueEditorType: 'select',
         values: [
            {
               name: '1',
               label: intl.formatMessage({ id: 'common.yes', defaultMessage: 'Ja' }),
            },
            {
               name: '0',
               label: intl.formatMessage({ id: 'common.no', defaultMessage: 'Nein' }),
            },
         ],
      },
      {
         name: 'set_publish_date',
         label: intl.formatMessage({
            id: 'query-builder.fields.set_publish_date',
            defaultMessage: 'Verkauft ab (Jahr)',
         }),
         inputType: 'number',
         operators: numberOperators,
      },
      {
         name: 'set_weight',
         label: intl.formatMessage({
            id: 'query-builder.fields.set_weight',
            defaultMessage: 'Gewicht',
         }),
         inputType: 'number',
         operators: numberOperators,
      },

      /* Stammdaten vom Inventar */
      {
         name: 'date',
         label: intl.formatMessage({
            id: 'query-builder.fields.date',
            defaultMessage: 'Kaufdatum',
         }),
         inputType: 'date',
         operators: dateOperators,
      },
      {
         name: 'sold_date',
         label: intl.formatMessage({
            id: 'query-builder.fields.sold_date',
            defaultMessage: 'Verkaufsdatum',
         }),
         inputType: 'date',
         operators: dateOperators,
      },
      {
         name: 'price',
         label: intl.formatMessage({
            id: 'query-builder.fields.price',
            defaultMessage: 'Kaufpreis',
         }),
         inputType: 'number',
         operators: numberOperators,
      },
      {
         name: 'sold_price',
         label: intl.formatMessage({
            id: 'query-builder.fields.sold_price',
            defaultMessage: 'Verkaufspreis',
         }),
         inputType: 'number',
         operators: numberOperators,
      },
      {
         name: 'type',
         label: intl.formatMessage({
            id: 'query-builder.fields.type',
            defaultMessage: 'Inventar-Typ',
         }),
         operators: stringOperators,
         valueEditorType: 'select',
         values: [
            {
               name: 'wanted',
               label: intl.formatMessage({
                  id: 'query-builder.fields.type.wanted',
                  defaultMessage: 'Wunschliste',
               }),
            },
            {
               name: 'bought',
               label: intl.formatMessage({
                  id: 'query-builder.fields.type.bought',
                  defaultMessage: 'Gekauft',
               }),
            },
            {
               name: 'sold',
               label: intl.formatMessage({
                  id: 'query-builder.fields.type.sold',
                  defaultMessage: 'Verkauft',
               }),
            },
         ],
      },
      {
         name: 'label_id',
         label: intl.formatMessage({
            id: 'query-builder.fields.label_id',
            defaultMessage: 'Label',
         }),
         operators: stringOperators,
         valueEditorType: 'select',
         values: orderBy(labels, 'name').map(l => ({ name: `${l.id}`, label: l.name })),
      },

      /* Statistiken */
      {
         name: 'avg_market_value',
         label: intl.formatMessage({
            id: 'query-builder.fields.avg_market_value',
            defaultMessage: 'Ø Marktwert',
         }),
         inputType: 'number',
         operators: numberOperators,
      },
      {
         name: 'avg_profit',
         label: intl.formatMessage({
            id: 'query-builder.fields.avg_profit',
            defaultMessage: 'Ø Gewinn (€)',
         }),
         inputType: 'number',
         operators: numberOperators,
      },
      {
         name: 'avg_profit_percentage',
         label: intl.formatMessage({
            id: 'query-builder.fields.avg_profit_percentage',
            defaultMessage: 'Ø Gewinn (%)',
         }),
         inputType: 'number',
         operators: numberOperators,
      },
      {
         name: 'holding_time',
         label: intl.formatMessage({
            id: 'query-builder.fields.holding_time',
            defaultMessage: 'Haltedauer (Tage)',
         }),
         inputType: 'number',
         operators: numberOperators,
      },
   ];
};
