import React, { CSSProperties, ReactNode } from 'react';
import Icon from '@mdi/react';
import { mdiShieldStarOutline } from '@mdi/js';
import { Dropdown } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

interface Props {
   /**
    * Action to execute when clicked.
    */
   onClick?: React.MouseEventHandler<HTMLElement>;
   /**
    * Route target when clicked.
    */
   to?: string;
   /**
    * Styling of the dropdown item.
    */
   style?: CSSProperties;
   /**
    * Content of the dropdown item.
    */
   children: ReactNode;
}

/**
 * Creates a styled dropdown item for an administrative action.
 */
export const AdminDropdownItem = (props: Props) => {
   if (props.to) {
      return (
         <LinkContainer to={props.to}>
            <Dropdown.Item
               className="fst-italic d-flex align-items-center justify-content-start"
               style={props.style}
               onClick={props.onClick}
            >
               <Icon path={mdiShieldStarOutline} className="me-1" color="#FFD700" size={0.75} />
               {props.children}
            </Dropdown.Item>
         </LinkContainer>
      );
   }

   return (
      <Dropdown.Item
         className="fst-italic d-flex align-items-center justify-content-start"
         style={props.style}
         onClick={props.onClick}
      >
         <Icon path={mdiShieldStarOutline} className="me-1" color="#FFD700" size={0.75} />
         {props.children}
      </Dropdown.Item>
   );
};
