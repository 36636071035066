import { Moment } from 'moment';
import { BaseModel } from './BaseModel';
import { LegoSet } from '../types/api';
import ApiService from '../services/ApiService';
import { ChartDataType } from '../types/ChartDataType';
import { IApiResponse } from '../types/IApiResponse';

class LegoSetModelClass extends BaseModel<LegoSet> {
   public getName(): string {
      return 'lego_set';
   }

   public async getPriceData(legoSetId: number, startDate: Moment): Promise<ChartDataType> {
      if (!legoSetId) return [];

      try {
         const response = await ApiService.http.get<IApiResponse<(string | number)[]>>(
            `stats/lego_set/${legoSetId}/priceChart?start=${startDate.format('YYYY-MM-DD')}`
         );
         return response.data.data;
      } catch (error) {
         ApiService.handleError(error);
         return Promise.reject(error);
      }
   }

   public async search(
      text: string,
      orderBy?: keyof LegoSet,
      orderDirection?: 'ASC' | 'DESC',
      limit?: number
   ): Promise<LegoSet[]> {
      return super.search(text, orderBy, orderDirection, limit);
   }
}
export const LegoSetModel = new LegoSetModelClass();
