import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { InventoryType } from '../types/api';
import { LegoSetWithStats } from '../types/api/LegoSetWithStats';
import ApiService from '../services/ApiService';
import { IApiResponse } from '../types/IApiResponse';
import { LegoSetModel } from '../models/LegoSetModel';

interface PagesState {
   inventory: {
      entities?: LegoSetWithStats[];
      loading: boolean;
      loadedBy?: InventoryType | 'label' | 'category' | 'search';
      isStale: boolean;
   };
   legoSet: {
      loading: boolean;
      legoSet: LegoSetWithStats | null;
   };
}

const initialState: PagesState = {
   inventory: {
      entities: undefined,
      loading: false,
      loadedBy: undefined,
      isStale: true,
   },
   legoSet: {
      loading: false,
      legoSet: null,
   },
};

export const getInventoryByType = createAsyncThunk(
   'pages/getInventoryByType',
   async (inventoryType: InventoryType) => {
      try {
         return (
            await ApiService.http.get<IApiResponse<LegoSetWithStats>>(
               `inventory/byInventoryType/${inventoryType}`
            )
         ).data.data;
      } catch (error) {
         ApiService.handleError(error);
         return Promise.reject(error);
      }
   }
);
export const getInventoryByLabel = createAsyncThunk(
   'pages/getInventoryByLabel',
   async (labelId: number) => {
      try {
         return (
            await ApiService.http.get<IApiResponse<LegoSetWithStats>>(
               `inventory/byLabel/${labelId}`
            )
         ).data.data;
      } catch (error) {
         ApiService.handleError(error);
         return Promise.reject(error);
      }
   }
);
export const getInventoryByCategory = createAsyncThunk(
   'pages/getInventoryByCategory',
   async (categoryId: number) => {
      try {
         return (
            await ApiService.http.get<IApiResponse<LegoSetWithStats>>(
               `inventory/byCategory/${categoryId}`
            )
         ).data.data;
      } catch (error) {
         ApiService.handleError(error);
         return Promise.reject(error);
      }
   }
);

export const getInventoryBySearch = createAsyncThunk(
   'pages/getInventoryBySearch',
   async (searchText?: string) => {
      if (!searchText) return [];
      try {
         return (
            await ApiService.http.get<IApiResponse<LegoSetWithStats>>(
               `inventory/bySearch?q=${encodeURIComponent(searchText)}`
            )
         ).data.data;
      } catch (error) {
         ApiService.handleError(error);
         return Promise.reject(error);
      }
   }
);

const loadLegoSetWithStats = async (legoSetId?: number): Promise<LegoSetWithStats | null> => {
   if (!legoSetId) return null;

   try {
      const legoSet = (
         await ApiService.http.get<IApiResponse<LegoSetWithStats>>(
            `inventory/byLegoSets/${legoSetId}`
         )
      ).data.data[0];
      if (!legoSet) return null;

      return legoSet;
   } catch (error) {
      ApiService.handleError(error);
      return Promise.reject(error);
   }
};

export const reloadLegoSet = createAsyncThunk('pages/reloadLegoSet', async (legoSetId?: number) =>
   loadLegoSetWithStats(legoSetId)
);

export const loadLegoSetByNumber = createAsyncThunk(
   'pages/loadLegoSetByNumber',
   async (legoSetNumber?: string) => {
      if (!legoSetNumber) return null;

      const legoSets = await LegoSetModel.list({ number: legoSetNumber });
      if (legoSets.length !== 1) return null;

      return loadLegoSetWithStats(legoSets[0].id);
   }
);

const pagesSlice = createSlice({
   name: 'pages',
   initialState,
   reducers: {
      invalidateInventory(state) {
         state.inventory.isStale = true;
      },
   },
   extraReducers: builder => {
      builder.addCase(getInventoryByType.pending, state => {
         state.inventory.loading = true;
      });
      builder.addCase(getInventoryByType.fulfilled, (state, action) => {
         state.inventory.entities = action.payload;
         state.inventory.loading = false;
         state.inventory.loadedBy = action.meta.arg;
         state.inventory.isStale = false;
      });
      builder.addCase(getInventoryByType.rejected, state => {
         state.inventory.loading = false;
      });

      builder.addCase(getInventoryByLabel.pending, state => {
         state.inventory.loading = true;
      });
      builder.addCase(getInventoryByLabel.fulfilled, (state, action) => {
         state.inventory.entities = action.payload;
         state.inventory.loading = false;
         state.inventory.loadedBy = 'label';
         state.inventory.isStale = false;
      });
      builder.addCase(getInventoryByLabel.rejected, state => {
         state.inventory.loading = false;
      });

      builder.addCase(getInventoryByCategory.pending, state => {
         state.inventory.loading = true;
      });
      builder.addCase(getInventoryByCategory.fulfilled, (state, action) => {
         state.inventory.entities = action.payload;
         state.inventory.loading = false;
         state.inventory.loadedBy = 'category';
         state.inventory.isStale = false;
      });
      builder.addCase(getInventoryByCategory.rejected, state => {
         state.inventory.loading = false;
      });

      builder.addCase(getInventoryBySearch.pending, state => {
         state.inventory.loading = true;
      });
      builder.addCase(getInventoryBySearch.fulfilled, (state, action) => {
         state.inventory.entities = action.payload;
         state.inventory.loading = false;
         state.inventory.loadedBy = 'search';
         state.inventory.isStale = false;
      });
      builder.addCase(getInventoryBySearch.rejected, state => {
         state.inventory.loading = false;
      });

      builder.addCase(reloadLegoSet.pending, state => {
         state.legoSet.loading = true;
      });
      builder.addCase(reloadLegoSet.fulfilled, (state, action) => {
         state.legoSet.loading = false;
         state.legoSet.legoSet = action.payload;
      });
      builder.addCase(reloadLegoSet.rejected, state => {
         state.legoSet.loading = false;
         state.legoSet.legoSet = null;
      });

      builder.addCase(loadLegoSetByNumber.pending, state => {
         state.legoSet.loading = true;
         state.legoSet.legoSet = null;
      });
      builder.addCase(loadLegoSetByNumber.fulfilled, (state, action) => {
         state.legoSet.loading = false;
         state.legoSet.legoSet = action.payload;
      });
      builder.addCase(loadLegoSetByNumber.rejected, state => {
         state.legoSet.loading = false;
         state.legoSet.legoSet = null;
      });
   },
});

export const { invalidateInventory } = pagesSlice.actions;

export default pagesSlice.reducer;
