import { Form, InputGroup } from 'react-bootstrap';
import React from 'react';
import { ControlProps, GenericControl } from './GenericControl';
import { FormUtils } from './FormUtils';
import { useModelTranslation } from '../../hooks';

export interface TextControlProps<T> extends ControlProps<T> {
   /**
    * The placeholder text for the form control.
    */
   placeholder?: string;
   /**
    * When `true` the component is disabled.
    */
   disabled?: boolean;
   readOnly?: boolean;
   /**
    * The input type of the text input field.
    */
   inputType?: string;

   append?: string;
   prepend?: string;
   min?: number;
   suggestions?: string[] | null;
}

/**
 * Renders a responsive form control of type text with validation errors.
 */
export const TextControl = <T,>({
   placeholder,
   disabled,
   inputType = 'text',
   append,
   prepend,
   min,
   readOnly,
   suggestions = [],
   ...props
}: TextControlProps<T>) => {
   const modelTranslation = useModelTranslation(props.model);

   const listId = `${props.name as string}-list`;

   return (
      <GenericControl {...props}>
         <InputGroup>
            {prepend && <InputGroup.Text>{prepend}</InputGroup.Text>}
            <Form.Control
               type={inputType}
               name={props.name as string}
               placeholder={
                  readOnly
                     ? ''
                     : modelTranslation?.[props.name as string]?.placeholder ??
                       modelTranslation?.[props.name as string]?.title ??
                       placeholder
               }
               value={(props.formik.values[props.name] as unknown as string) ?? ''}
               onChange={props.formik.handleChange}
               onBlur={props.formik.handleBlur}
               isInvalid={FormUtils.isInvalid(props.formik, props.name)}
               disabled={props.formik.isSubmitting || disabled}
               readOnly={readOnly}
               min={min}
               list={(suggestions?.length ?? 0) > 0 ? listId : undefined}
            />
            {(suggestions?.length ?? 0) > 0 && (
               <datalist id={listId}>
                  {suggestions?.map(s => (
                     <option key={s} value={s}>
                        {s}
                     </option>
                  ))}
               </datalist>
            )}
            {append && <InputGroup.Text>{append}</InputGroup.Text>}
         </InputGroup>
      </GenericControl>
   );
};
