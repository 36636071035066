import ApiService from '../services/ApiService';
import { IApiResponse } from '../types/IApiResponse';
import { InventoryWithStats } from '../types/api/InventoryWithStats';

export const getInventoryByFilter = async (filterId: number): Promise<InventoryWithStats[]> => {
   try {
      return (
         await ApiService.http.get<IApiResponse<InventoryWithStats>>(`inventory/filter/${filterId}`)
      ).data.data;
   } catch (error) {
      ApiService.handleError(error);
      return [];
   }
};

export const updatePriceDateFromSource = async (setId: number): Promise<void> => {
   try {
      await ApiService.http.get(`admin/updatePriceDateFromSource?id=${setId}`);
   } catch (error) {
      ApiService.handleError(error);
      return Promise.reject(error);
   }
};

export const getAdminStats = async (): Promise<[string, number][]> => {
   try {
      const response = await ApiService.http.get<IApiResponse<[string, number]>>('admin/overview');
      return response.data.data;
   } catch (error) {
      ApiService.handleError(error);
      return Promise.reject(error);
   }
};
