export const Colors = {
   blue: '#375a7f',
   indigo: '#6610f2',
   purple: '#6f42c1',
   pink: '#e83e8c',
   red: '#e74c3c',
   orange: '#fd7e14',
   yellow: '#f39c12',
   green: '#00bc8c',
   teal: '#20c997',
   cyan: '#3498db',
   /* Weiß auf 87% transparenz "abdunkeln", damit es wegen des dunklen Hintergrunds besser lesbar ist  */
   white: '#FFFFFFDE',
   gray: '#888',
   gray_dark: '#303030',
   primary: '#375a7f',
   secondary: '#444',
   success: '#00bc8c',
   info: '#3498db',
   warning: '#f39c12',
   danger: '#e74c3c',
   light: '#adb5bd',

   link: '#00bc8c', // aktuell gleiche wie green und success
   background: '#222',

   good: '#0c8465',
   bad: '#b14135',

   lego: {
      coral: '#FF698F',
      bright_pink: '#E4ADC8',
      dark_pink: '#C870A0',
      magenta: '#923978',
      purple: '#81007B',
      lavender: '#E1D5ED',
      medium_lavender: '#AC78BA',
      dark_purple: '#3F3691',
      sand_blue: '#6074A1',
      blue: '#0055BF',
      dark_blue: '#0A3463',
      medium_blue: '#5A93DB',
      bright_light_blue: '#9FC3E9',
      dark_azure: '#078BC9',
      medium_azure: '#36AEBF',
      dark_turquoise: '#008F9B',
      black: '#05131D',
      dark_green: '#184632',
      medium_green: '#73DCA1',
      green: '#237841',
      bright_green: '#4B9F4A',
      light_gray: '#9BA19D',
      sand_green: '#A0BCAC',
      light_green: '#C2DAB8',
      yellowish_green: '#DFEEA5',
      lime: '#BBE90B',
      bright_light_yellow: '#FFF03A',
      olive_green: '#9B9A5A',
      dark_gray: '#6D6E5C',
      yellow: '#F2CD37',
      dark_brown: '#352100',
      bright_light_orange: '#F8BB3D',
      tan: '#E4CD9E',
      dark_orange: '#A95500',
      orange: '#FE8A18',
      medium_dark_flesh: '#CC702A',
      light_flesh: '#F6D7B3',
      dark_tan: '#958A73',
      flesh: '#D09168',
      brown: '#583927',
      reddish_brown: '#582A12',
      red: '#C91A09',
      dark_red: '#720E0F',
      sand_red: '#D67572',
      white: '#FFFFFF',
      light_bluish_gray: '#A0A5A9',
      dark_bluish_gray: '#6C6E68',
   },
};
