import React from 'react';
import { isMobile } from 'react-device-detect';

interface Props {
   /**
    * The buttons inside the panel.
    */
   children?: React.ReactNode;
   /**
    * The alignment of the buttons in desktop mode.
    */
   align?: 'start' | 'center' | 'end';
   /**
    * If `true` the button panel is styled as a form footer (not dialog footer).
    */
   isFormFooter?: boolean;
   /**
    * Additional css classes.
    */
   className?: string;
}

/**
 * Create a panel for buttons based on the desktop/mobile context.
 * On `browser` the panel is horizontal. On `mobile` the panel is vertical.
 */
export const ButtonPanel = ({
   children,
   align = 'end',
   isFormFooter = false,
   className = '',
}: Props) => {
   let panelClass = isMobile ? 'button-panel-mobile' : 'button-panel';
   if (isFormFooter) panelClass += ' form-footer';

   return <div className={`justify-content-${align} ${panelClass} ${className}`}>{children}</div>;
};
