import React, { ReactElement, useCallback, useMemo } from 'react';
import { Chart } from 'react-google-charts';
import merge from 'lodash/merge';
import { usePriceTrend } from '../../../utils';
import { ChartLoader } from '../../atoms';
import { Colors } from '../../Colors';
import { Popover } from '../../molecules';
import { useAppSelector, useMemorizedIntl } from '../../../hooks';
import { useChartData } from '../../../hooks/useChartData';
import { ChartDataType } from '../../../types/ChartDataType';

interface Props {
   /**
    * The history of the market value of a lego set.
    */
   marketValueHistory: ChartDataType;
   /**
    * Defines the color of the trend arrow (good = green, bad = red).
    */
   type?: 'higherIsBetter' | 'lowerIsBetter' | 'neutral';
   children: ReactElement;
}

export const PriceTrendChartPopover = ({
   marketValueHistory,
   type = 'higherIsBetter',
   children,
}: Props) => {
   const intl = useMemorizedIntl();
   const stateLanguage = useAppSelector(s => s.app.language);
   const trend = usePriceTrend(marketValueHistory);

   const columnNames = useMemo(() => ['Datum', 'Preis'], []);
   const dataTypes = useMemo(() => ['date', 'number'], []);
   const { rows, columns, comboChartOptions } = useChartData({
      preloadedChartData: marketValueHistory,
      columnNames: columnNames,
      columnTypes: dataTypes,
      theme: 'mini',
   });

   const getChartColor = useCallback(
      (trendDirection: 'up' | 'down') => {
         if (type === 'neutral') return Colors.primary;

         if (trendDirection === 'up')
            return type === 'higherIsBetter' ? Colors.success : Colors.danger;
         return type === 'higherIsBetter' ? Colors.danger : Colors.success;
      },
      [type]
   );

   const chartOptions = useMemo(() => {
      let chartColor = '#3366CC';
      if (trend && trend < -0.1) chartColor = getChartColor('down');
      else if (trend && trend > 0.1) chartColor = getChartColor('up');

      return merge(comboChartOptions, {
         colors: [chartColor],
         vAxis: {
            textPosition: 'out',
            textStyle: {
               color: Colors.gray,
            },
         },
         hAxis: {
            textPosition: 'out',
            textStyle: {
               color: Colors.gray,
            },
         },
         chartArea: {
            left: 40,
            bottom: 30,
         },
      });
   }, [trend, getChartColor, comboChartOptions]);

   const priceCart = useMemo(
      () => (
         <div style={{ display: 'flex' }}>
            <Chart
               width="15rem"
               height="8rem"
               chartType="ComboChart"
               loader={<ChartLoader width="15rem" height="8rem" />}
               data={[columns, ...rows]}
               legendToggle
               options={chartOptions}
               chartLanguage={stateLanguage}
            />
         </div>
      ),
      [columns, rows, chartOptions, stateLanguage]
   );

   return (
      <Popover
         popoverTitle={intl.formatMessage({
            id: 'inventory.grid.card.stats-popover.title',
            defaultMessage: 'Preisverlauf',
         })}
         popoverContent={priceCart}
      >
         {children}
      </Popover>
   );
};
