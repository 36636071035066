import { Field } from 'react-querybuilder';
import React, { useCallback, useEffect, useState } from 'react';
import { Button, FormControl } from 'react-bootstrap';
import { nanoid } from 'nanoid';
import isEqual from 'lodash/isEqual';
import { FormattedMessage } from 'react-intl';
import { useMemorizedIntl } from '../../hooks';

export interface SortingDefinition {
   id: string;
   field: string;
   direction: 'asc' | 'desc';
}

interface Props {
   fields: Field[];
   query?: SortingDefinition[];
   onSortingChange?: (json: SortingDefinition[], text: string) => void;
}

export const SortingBuilder = ({ fields, query, onSortingChange }: Props) => {
   const [sortDefinition, setSortDefinition] = useState(query ?? []);

   const flattenSortingDefinition = (sortingDef: SortingDefinition[]) =>
      sortingDef.map(s => `${s.field} ${s.direction.toUpperCase()}`).join(', ');

   useEffect(() => {
      // onChange nur aufrufen, wenn es ein Unterschied in der Definition zwischen außen und innen gibt
      //    ansonsten entsteht ein Loop
      // console.log(isEqual(query, sortDefinition), JSON.stringify(query), JSON.stringify(sortDefinition));
      if (!isEqual(query, sortDefinition))
         onSortingChange?.(sortDefinition, flattenSortingDefinition(sortDefinition));
   }, [sortDefinition, onSortingChange, query]);

   const handleSortingChange = useCallback((sorting: SortingDefinition) => {
      setSortDefinition(v => [...v.map(s => (s.id !== sorting.id ? s : sorting))]);
   }, []);

   return (
      <div>
         <Button
            size="sm"
            onClick={() => {
               setSortDefinition([
                  ...sortDefinition,
                  { id: nanoid(), field: '', direction: 'asc' },
               ]);
            }}
         >
            <FormattedMessage id="sorting-builder.add-field" defaultMessage="Feld hinzufügen" />
         </Button>
         {sortDefinition.map(s => (
            <Row
               key={s.id}
               fields={fields}
               sorting={s}
               onChange={handleSortingChange}
               onRemove={() => {
                  setSortDefinition(sortingDef => [...sortingDef.filter(d => d.id !== s.id)]);
               }}
            />
         ))}
      </div>
   );
};

interface RowProps {
   fields: Field[];
   sorting: SortingDefinition;
   onChange: (sorting: SortingDefinition) => void;
   onRemove: () => void;
}

const Row = ({ fields, sorting, onChange, onRemove }: RowProps) => {
   const intl = useMemorizedIntl();
   const [field, setField] = useState(sorting.field);
   const [direction, setDirection] = useState(sorting.direction);

   useEffect(() => {
      if (sorting.field !== field || sorting.direction !== direction) {
         onChange({
            id: sorting.id,
            field: field,
            direction: direction,
         });
      }
   }, [sorting, field, direction, onChange]);

   return (
      <fieldset>
         <div className="d-flex flex-row justify-content-start mt-2">
            <FormControl
               as="select"
               size="sm"
               className="w-auto"
               onChange={e => setField(e.target.value)}
               value={field}
            >
               <option value="" disabled>
                  {intl.formatMessage({
                     id: 'sorting-builder.options.select',
                     defaultMessage: 'Bitte wählen',
                  })}
               </option>
               {fields.map(f => (
                  <option key={f.id} value={f.name}>
                     {f.label}
                  </option>
               ))}
            </FormControl>
            <FormControl
               as="select"
               size="sm"
               className="mx-1 w-auto"
               onChange={e => setDirection(e.target.value === 'asc' ? 'asc' : 'desc')}
               value={direction}
            >
               <option value="asc">
                  {intl.formatMessage({
                     id: 'sorting-builder.options.sort-asc',
                     defaultMessage: 'Aufsteigend',
                  })}
               </option>
               <option value="desc">
                  {intl.formatMessage({
                     id: 'sorting-builder.options.sort-desc',
                     defaultMessage: 'Absteigend',
                  })}
               </option>
            </FormControl>
            <Button
               variant="danger"
               size="sm"
               title={intl.formatMessage({
                  id: 'sorting-builder.remove-field',
                  defaultMessage: 'Feld entfernen',
               })}
               onClick={onRemove}
            >
               X
            </Button>
         </div>
      </fieldset>
   );
};
