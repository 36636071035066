import DatePicker from 'react-datepicker';
import React, { forwardRef } from 'react';
import { Form } from 'react-bootstrap';
import moment from 'moment';
import { useAppSelector } from '../../../hooks';

import './DateInput.scss';

interface Props {
   value: string;
   onChange: (dateAsISOString: string) => void;
   onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
   disabled?: boolean;
   isInvalid?: boolean;
   className?: string;
}

export const DateInput = ({ value, onChange, onBlur, disabled, isInvalid, className }: Props) => {
   const stateLanguage = useAppSelector(s => s.app.language);

   // eslint-disable-next-line @typescript-eslint/no-shadow,react/no-unstable-nested-components
   const CustomInput = forwardRef((props: any, ref: React.Ref<HTMLButtonElement>) => (
      <Form.Control ref={ref} type="text" {...props} isInvalid={isInvalid} />
   ));

   return (
      <DatePicker
         className={className}
         selected={value && moment(value).isValid() ? moment(value).toDate() : undefined}
         dateFormat="P"
         locale={stateLanguage}
         onChange={date => onChange(moment(date).toISOString())}
         onBlur={onBlur}
         calendarStartDay={1}
         disabled={disabled}
         popperPlacement="bottom"
         placeholderText={moment().creationData().locale.longDateFormat('L')}
         customInput={<CustomInput />}
      />
   );
};
