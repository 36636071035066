import { Container, Form } from 'react-bootstrap';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Formik } from 'formik';
import moment from 'moment';
import { handleSubmit, SoldSetForm, useValidationHandler } from '../../../forms/SoldSetForm';
import { showBackButton } from '../../../redux/appSlice';
import { ColoredValue } from '../../../components/atoms';
import { ConfirmDeleteDialog } from '../../../components/dialogs/ConfirmDeleteDialog';
import { invalidateInventory, reloadLegoSet } from '../../../redux/pagesSlice';
import { ButtonPanel, ResponsiveButton } from '../../../components/molecules';
import { Inventory, LegoSet, SoldSet } from '../../../types/api';
import { useAppDispatch, useIdFromParams } from '../../../hooks';
import { InventoryModel } from '../../../models/InventoryModel';
import { SoldSetModel } from '../../../models/SoldSetModel';
import { formatDate } from '../../../utils';
import { SubmitButton } from '../../../components/forms';
import { LegoSetModel } from '../../../models/LegoSetModel';
import { PageTitle } from '../../../components/organisms/PageTitle';

export const EditSaleMobilePage = () => {
   const inventoryId = useIdFromParams();
   const dispatch = useAppDispatch();
   const navigate = useNavigate();
   const handleValidate = useValidationHandler();
   const [legoSet, setLegoSet] = useState<LegoSet | null>(null);
   const [inventory, setInventory] = useState<Inventory | null>(null);
   const [soldSet, setSoldSet] = useState<Partial<SoldSet>>({});
   const [showDeleteDialog, setShowDeleteDialog] = useState(false);

   useEffect(() => {
      dispatch(showBackButton(true));
   });

   useEffect(() => {
      (async () => {
         const i = await InventoryModel.get(inventoryId);
         const ls = await LegoSetModel.get(i?.set_id ?? 0);
         if (!i || !ls) return;

         setLegoSet(ls);
         setInventory(i);
         const soldSets = await SoldSetModel.list({ inventory_id: i.id });

         if (soldSets.length === 1) {
            // Bearbeiten eines bestehenden Kaufes
            setSoldSet(soldSets[0]);
         } else {
            // Anlage eines neuen Verkaufes
            setSoldSet({ date: moment().toISOString() });
         }
      })();
   }, [inventoryId]);

   const handleSubmitForm = useCallback(
      async (values: Partial<SoldSet>) => {
         if (!inventory) return;

         await handleSubmit(values, inventory);
         navigate(-1);
      },
      [inventory, navigate]
   );

   const handleDelete = useCallback(async () => {
      if (!inventory) return;

      await SoldSetModel.delete(soldSet);

      inventory.type = 'bought';
      await InventoryModel.update(inventory);
      dispatch(reloadLegoSet(inventory.set_id));
      dispatch(invalidateInventory());

      navigate(-1);
   }, [dispatch, inventory, navigate, soldSet]);

   return (
      <Container>
         <PageTitle
            title={`${legoSet?.number ?? ''}: ${legoSet?.name ?? ''}`}
            appTitle={legoSet?.number}
            subtitle={legoSet?.name}
            hidden
         />
         <ConfirmDeleteDialog
            show={showDeleteDialog}
            onClose={() => setShowDeleteDialog(false)}
            onDelete={handleDelete}
         >
            <p>
               <FormattedMessage
                  id="dialog.delete-sale.text"
                  defaultMessage="Möchtest du den Verkauf vom {date} in Höhe von {price} wirklich löschen?"
                  values={{
                     date: formatDate(soldSet.date, 'L'),
                     price: <ColoredValue type="currency" value={soldSet.price} />,
                  }}
               />
            </p>
         </ConfirmDeleteDialog>
         <Formik
            onSubmit={handleSubmitForm}
            initialValues={soldSet}
            enableReinitialize
            validate={handleValidate}
         >
            {formik => (
               <Form
                  noValidate
                  onSubmit={e => {
                     e.preventDefault();
                     formik.handleSubmit();
                  }}
               >
                  <>
                     <SoldSetForm formik={formik} />
                     <ButtonPanel className="mt-4">
                        <SubmitButton formik={formik}>
                           <FormattedMessage id="button.save" defaultMessage="Speichern" />
                        </SubmitButton>
                        <ResponsiveButton
                           variant="danger"
                           onClick={() => setShowDeleteDialog(true)}
                           disabled={formik.isSubmitting}
                        >
                           <FormattedMessage id="button.delete" defaultMessage="Löschen" />
                        </ResponsiveButton>
                     </ButtonPanel>
                  </>
               </Form>
            )}
         </Formik>
      </Container>
   );
};
