import React from 'react';
import Zoom from 'react-medium-image-zoom';
import { isMobile } from 'react-device-detect';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { Link } from 'react-router-dom';
import Icon from '@mdi/react';
import { mdiPencil } from '@mdi/js';
import { ColoredValue, SkeletonPlaceholder } from '../../../components/atoms';
import { Colors } from '../../../components/Colors';
import { SimpleTable } from '../../../components/molecules/SimpleTable';
import { LegoSetImage } from '../../../components/molecules/LegoSetImage';
import { LegoSetWithStats } from '../../../types/api/LegoSetWithStats';
import { ROLE_ADMIN } from '../../../types/api';
import { Card, LinkButton } from '../../../components/molecules';
import { ShowIfRole } from '../../../components/molecules/ShowIfRole';
import BricklinkIcon from '../../../assets/img/bricklink.svg';
import LegoIcon from '../../../assets/img/lego.png';
import BricksetIcon from '../../../assets/img/brickset.png';
import IdealoIcon from '../../../assets/img/idealo.png';
import { useCache, useMemorizedIntl } from '../../../hooks';
import { formatDate, pageLinks } from '../../../utils';

interface Props {
   legoSet?: LegoSetWithStats;
}

export const LegoSetPropertiesTable = ({ legoSet }: Props) => (
   <Card orientation="column" noPadding>
      <div className="lego-set-image">
         <div className="image-sidebar">
            <LinksSideBar legoSet={legoSet} />
         </div>
         <div className="d-flex flex-fill flex-column justify-content-center align-items-center">
            <Zoom zoomMargin={4}>
               <div
                  className="justify-content-center align-items-center"
                  style={{ height: isMobile ? '10rem' : '15rem' }}
               >
                  <LegoSetImage legoSet={legoSet} />
               </div>
            </Zoom>
            <small className="image-copyright">&copy; by brickset.com</small>
         </div>
         <div className="image-sidebar">
            <ShowIfRole roles={[ROLE_ADMIN]}>
               <LinkButton
                  to={`/admin/legoSets/${legoSet?.id}`}
                  className="d-flex align-items-center justify-content-center"
                  size="sm"
                  variant="secondary"
               >
                  <Icon path={mdiPencil} color={Colors.white} size={0.75} />
               </LinkButton>
            </ShowIfRole>
         </div>
      </div>

      <div className="px-2 pb-2">
         <Properties legoSet={legoSet} />
      </div>
   </Card>
);

const LinksSideBar = ({ legoSet }: Pick<Props, 'legoSet'>) => {
   const intl = useMemorizedIntl();
   const legoSetName = `${legoSet?.number}: ${legoSet?.name}`;

   return (
      <>
         {legoSet?.lego_url && (
            <a href={legoSet.lego_url} target="_blank" rel="noopener noreferrer">
               <img
                  src={LegoIcon}
                  alt="Lego"
                  title={intl.formatMessage(
                     {
                        id: 'lego-set.properties.links.lego.title',
                        defaultMessage: 'Öffne {name} bei Lego.com',
                     },
                     { name: legoSetName }
                  )}
                  width={24}
                  height={24}
               />
            </a>
         )}
         {legoSet?.idealo_url && (
            <a href={legoSet.idealo_url} target="_blank" rel="noopener noreferrer">
               <img
                  src={IdealoIcon}
                  alt="Idealo"
                  title={intl.formatMessage(
                     {
                        id: 'lego-set.properties.links.idealo.title',
                        defaultMessage: 'Öffne {name} bei Idealo.de',
                     },
                     { name: legoSetName }
                  )}
                  width={24}
                  height={24}
               />
            </a>
         )}
         {legoSet?.bricklink_url && (
            <a href={legoSet.bricklink_url} target="_blank" rel="noopener noreferrer">
               <img
                  src={BricklinkIcon}
                  alt="Bricklink"
                  title={intl.formatMessage(
                     {
                        id: 'lego-set.properties.links.bricklink.title',
                        defaultMessage: 'Öffne {name} bei Bricklink.com',
                     },
                     { name: legoSetName }
                  )}
                  width={24}
                  height={24}
               />
            </a>
         )}
         {legoSet?.brickset_id && (
            <a
               href={`https://brickset.com/sets/${legoSet.brickset_id}`}
               target="_blank"
               rel="noopener noreferrer"
            >
               <img
                  src={BricksetIcon}
                  alt="Brickset"
                  title={intl.formatMessage(
                     {
                        id: 'lego-set.properties.links.brickset.title',
                        defaultMessage: 'Öffne {name} bei Brickset.com',
                     },
                     { name: legoSetName }
                  )}
                  width={24}
                  height={24}
               />
            </a>
         )}
      </>
   );
};

const Properties = ({ legoSet }: Pick<Props, 'legoSet'>) => {
   const { categories } = useCache();

   if (!legoSet) {
      return (
         <SimpleTable>
            {[...Array(6).keys()].map(i => (
               <SimpleTable.Row key={i} title={<SkeletonPlaceholder width="50%" />}>
                  <SkeletonPlaceholder width="80%" />
               </SimpleTable.Row>
            ))}
         </SimpleTable>
      );
   }

   return (
      <>
         <SimpleTable>
            <SimpleTable.Row
               title={
                  <FormattedMessage
                     id="lego-set.properties.category.title"
                     defaultMessage="Kategorie"
                  />
               }
            >
               <Link
                  to={pageLinks.legoSetsByCategory(
                     categories.find(c => c.id === legoSet?.category_id)
                  )}
               >
                  {legoSet.category_name}
               </Link>
            </SimpleTable.Row>
            <SimpleTable.Row
               title={
                  <FormattedMessage
                     id="lego-set.properties.lego-status.title"
                     defaultMessage="Status"
                  />
               }
            >
               {legoSet.status_by_lego}
            </SimpleTable.Row>
            <SimpleTable.Row
               title={
                  <FormattedMessage id="lego-set.properties.lego-rrp.title" defaultMessage="UVP" />
               }
            >
               {legoSet.is_gwp && (
                  <span className="fst-italic">
                     <FormattedMessage
                        id="lego-set.properties.lego-rrp.gwp"
                        defaultMessage="Kostl. Zugabe bei Bestellung"
                     />
                  </span>
               )}
               {!legoSet.is_gwp && legoSet.lego_url && (
                  <a href={legoSet.lego_url} target="_blank" rel="noopener noreferrer">
                     <ColoredValue type="currency" value={legoSet.price} />
                  </a>
               )}
               {!legoSet.is_gwp && !legoSet.lego_url && '-'}
            </SimpleTable.Row>
            <SimpleTable.Row
               title={
                  <FormattedMessage id="lego-set.properties.parts.title" defaultMessage="Teile" />
               }
            >
               <FormattedNumber value={legoSet.parts} />
            </SimpleTable.Row>
            <SimpleTable.Row
               title={
                  <FormattedMessage
                     id="lego-set.properties.weight.title"
                     defaultMessage="Gewicht"
                  />
               }
            >
               {legoSet.weight ? (
                  <FormattedNumber value={legoSet.weight} style="unit" unit="gram" />
               ) : (
                  <span>-</span>
               )}
            </SimpleTable.Row>
            <SimpleTable.Row
               title={
                  <FormattedMessage
                     id="lego-set.properties.sold-from.title"
                     defaultMessage="Verkauft ab"
                  />
               }
            >
               {formatDate(legoSet.publish_date, 'L')}
            </SimpleTable.Row>
         </SimpleTable>
         <div className="mt-2 py-1 d-flex small text-muted justify-content-center">
            <Link to={`/legoSets/${legoSet?.number}/requestChange`}>
               <FormattedMessage
                  id="lego-set.properties.request-change"
                  defaultMessage="Klicke hier um die Daten zu korrigieren."
               />
            </Link>
         </div>
      </>
   );
};
