import { Card, Dropdown } from 'react-bootstrap';
import React, { useCallback, useMemo } from 'react';
import Icon from '@mdi/react';
import { mdiDotsHorizontal, mdiEmoticonHappyOutline, mdiLinkVariant } from '@mdi/js';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import remarkGfm from 'remark-gfm';
import ReactMarkdown from 'react-markdown';
import remarkGemoji from 'remark-gemoji';
import { HashLink } from 'react-router-hash-link';
import { BlogPost, BlogPostReactionType, ROLE_ADMIN } from '../../types/api';
import { useMemorizedIntl, useSession } from '../../hooks';
import { AdminDropdownItem, LinkButton } from '../molecules';
import { Colors } from '../Colors';
import { BlogPostModel } from '../../models/BlogPostModel';
import { BlogPostReactionModel } from '../../models/BlogPostReactionModel';
import { formatDate, pageLinks } from '../../utils';

import './BlogPostEntry.scss';

interface Props {
   post: Partial<BlogPost>;
   previewMode?: boolean;
   onRefresh?: () => Promise<void>;
   showGoToBlogPostButton?: boolean;
}

interface IReaction {
   id: string;
   icon: string;
   label: string;
   count: number;
}

export const BlogPostEntry = ({
   post,
   previewMode = false,
   onRefresh,
   showGoToBlogPostButton = false,
}: Props) => {
   const intl = useMemorizedIntl();
   const { sessionUser, sessionRoles } = useSession();

   const reactions: IReaction[] = useMemo(
      () => [
         {
            id: 'thumbs_up',
            icon: '👍',
            label: intl.formatMessage({
               id: 'blog.blog-post.reaction.thumbs-up',
               defaultMessage: 'Daumen hoch',
            }),
            count: post?.thumbs_up_count ?? 0,
         },
         {
            id: 'thumbs_down',
            icon: '👎',
            label: intl.formatMessage({
               id: 'blog.blog-post.reaction.thumbs-down',
               defaultMessage: 'Daumen runter',
            }),
            count: post?.thumbs_down_count ?? 0,
         },
         {
            id: 'laugh',
            icon: '😄',
            label: intl.formatMessage({
               id: 'blog.blog-post.reaction.laugh',
               defaultMessage: 'Lachen',
            }),
            count: post?.laugh_count ?? 0,
         },
         {
            id: 'hooray',
            icon: '🎉',
            label: intl.formatMessage({
               id: 'blog.blog-post.reaction.hooray',
               defaultMessage: 'Hurra',
            }),
            count: post?.hooray_count ?? 0,
         },
         {
            id: 'confused',
            icon: '😕',
            label: intl.formatMessage({
               id: 'blog.blog-post.reaction.confused',
               defaultMessage: 'Verwirrt',
            }),
            count: post?.confused_count ?? 0,
         },
         {
            id: 'heart',
            icon: '❤️',
            label: intl.formatMessage({
               id: 'blog.blog-post.reaction.heart',
               defaultMessage: 'Herz',
            }),
            count: post?.heart_count ?? 0,
         },
         {
            id: 'rocket',
            icon: '🚀',
            label: intl.formatMessage({
               id: 'blog.blog-post.reaction.rocket',
               defaultMessage: 'Rakete',
            }),
            count: post?.rocket_count ?? 0,
         },
         {
            id: 'eyes',
            icon: '👀',
            label: intl.formatMessage({
               id: 'blog.blog-post.reaction.eyes',
               defaultMessage: 'Augen',
            }),
            count: post?.eyes_count ?? 0,
         },
      ],
      [intl, post]
   );

   const handleSendNotifications = useCallback(async () => {
      if (post.id) {
         try {
            await BlogPostModel.sendEmailNotifications(post.id);
            await onRefresh?.();
            toast.success(
               intl.formatMessage({
                  id: 'blog.blog-post.notifications-send.success-toast.text',
                  defaultMessage: 'Die Email-Benachrichtigungen wurden erfolgreich versendet.',
               })
            );
         } catch (e) {
            // eslint-disable-next-line no-console
            console.log(e);
            toast.error(
               intl.formatMessage({
                  id: 'blog.blog-post.notifications-send.error-toast.text',
                  defaultMessage: 'Die Email-Benachrichtigungen wurden nicht versendet.',
               })
            );
         }
      }
   }, [intl, onRefresh, post.id]);

   const handleReaction = useCallback(
      async (reaction: BlogPostReactionType) => {
         const currentReaction = await BlogPostReactionModel.list({
            blog_post_id: post.id,
            user_id: sessionUser?.id ?? 0,
         });

         if (currentReaction.length > 0) {
            // Aktualisiere bestehenden Eintrag
            await BlogPostReactionModel.update({
               ...currentReaction[0],
               reaction: reaction,
            });
         } else {
            // Erstelle einen neuen Eintrag
            await BlogPostReactionModel.insert({
               blog_post_id: post.id,
               reaction: reaction,
               user_id: sessionUser?.id ?? 0,
            });
         }
         await onRefresh?.();
      },
      [onRefresh, post.id, sessionUser?.id]
   );

   return (
      <Card className={`w-100 blog-post blog-post-${post.status}`}>
         <Card.Header
            className="ps-2 pe-1 py-1 d-flex justify-content-between align-items-center text-muted"
            style={{ minHeight: '3rem' }}
         >
            <span>
               {post.publish_date ? (
                  <FormattedMessage
                     id="blog.blog-post.subtitle.published-from"
                     defaultMessage="<strong>{user}</strong> veröffentlichte am {date}"
                     values={{
                        user: post.user_name,
                        date: formatDate(post.publish_date, 'L LTS'),
                     }}
                  />
               ) : (
                  <FormattedMessage
                     id="blog.blog-post.subtitle.not-published"
                     defaultMessage="Unveröffentlicht"
                  />
               )}
            </span>
            {previewMode === false && sessionRoles.includes(ROLE_ADMIN) && (
               <Dropdown>
                  <Dropdown.Toggle variant="secondary" className="px-1 title-button">
                     <Icon path={mdiDotsHorizontal} size={1} color={Colors.white} />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                     {post.status === 'published' && !post.email_notification_send && (
                        <AdminDropdownItem onClick={() => handleSendNotifications()}>
                           <FormattedMessage
                              id="button.send-emails"
                              defaultMessage="Email-Benachrichtigung versenden"
                           />
                        </AdminDropdownItem>
                     )}
                     <AdminDropdownItem to={`/blog/${post.id}/edit`}>
                        <FormattedMessage id="button.edit" defaultMessage="Bearbeiten" />
                     </AdminDropdownItem>
                  </Dropdown.Menu>
               </Dropdown>
            )}
         </Card.Header>
         <Card.Body className="pb-0">
            <Card.Title>
               {(post.subject ?? '').length === 0 ? (
                  <h2 className="fst-italic">
                     <FormattedMessage
                        id="blog.blog-post.subject.no-title"
                        defaultMessage="Kein Titel vorhanden"
                     />
                  </h2>
               ) : (
                  <span
                     style={{ textDecoration: post.status === 'deleted' ? 'line-through' : 'auto' }}
                  >
                     <h2 className="blog-post-header" style={{ textAlign: 'start' }}>
                        {post.subject}
                     </h2>
                     <LinkButton
                        to={pageLinks.blogPostDetail(post)}
                        variant="link"
                        className="p-0 ms-1 vertical-align-text-bottom"
                        title={intl.formatMessage({
                           id: 'blog.blog-post.link-text',
                           defaultMessage: 'Permanenter Link',
                        })}
                     >
                        <Icon path={mdiLinkVariant} color={Colors.link} size="2rem" />
                     </LinkButton>
                  </span>
               )}
            </Card.Title>
            <Card.Subtitle className="text-muted">
               <HashLink to={pageLinks.blogPostDetail(post, 'comments')}>
                  <FormattedMessage
                     id="blog.blog-post.subtitle.comments_count"
                     defaultMessage="{count, plural, =0 {# Kommentare} one {# Kommentar} other {# Kommentare}}"
                     values={{ count: post.comment_count ?? 0 }}
                  />
               </HashLink>
            </Card.Subtitle>
            {post.status !== 'deleted' ? (
               <Card.Text as="div" className="pt-3">
                  {(post.body ?? '').length === 0 ? (
                     <span className="fst-italic">
                        <FormattedMessage
                           id="blog.blog-post.body.no-title"
                           defaultMessage="Kein Beitragstext vorhanden"
                        />
                     </span>
                  ) : (
                     <>
                        <ReactMarkdown remarkPlugins={[remarkGfm, remarkGemoji]}>
                           {post.body ?? ''}
                        </ReactMarkdown>
                        {showGoToBlogPostButton && (
                           <LinkButton to={pageLinks.blogPostDetail(post)} className="mb-4">
                              <FormattedMessage
                                 id="blog.blog-post.btn-entry-and-comments"
                                 defaultMessage="Zum Beitrag und Kommentare"
                              />
                           </LinkButton>
                        )}
                     </>
                  )}
               </Card.Text>
            ) : (
               <div className="pb-3" />
            )}
         </Card.Body>
         <Card.Footer
            className="p-0 d-flex justify-content-start"
            style={{ backgroundColor: Colors.background }}
         >
            {reactions.map(
               r =>
                  r.count > 0 && (
                     <div key={r.id} className="reaction-tile">
                        {r.icon}&nbsp;&nbsp;{r.count}
                     </div>
                  )
            )}
            {sessionUser !== null && (
               <Dropdown>
                  <Dropdown.Toggle
                     as="div"
                     className="reaction-tile px-3 h-100 title-button cursor-pointer"
                  >
                     <Icon
                        className="select-reaction"
                        path={mdiEmoticonHappyOutline}
                        size={0.75}
                        color={Colors.secondary}
                     />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                     <Dropdown.Header>
                        <FormattedMessage
                           id="blog.blog-post.pick-your-reaction"
                           defaultMessage="Wähle deine Reaktion"
                        />
                     </Dropdown.Header>
                     {reactions.map(r => (
                        <Dropdown.Item
                           key={r.id}
                           onClick={() => handleReaction(r.id as unknown as BlogPostReactionType)}
                        >
                           {r.icon}&nbsp;&nbsp;{r.label}
                        </Dropdown.Item>
                     ))}
                  </Dropdown.Menu>
               </Dropdown>
            )}
         </Card.Footer>
      </Card>
   );
};
