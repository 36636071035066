import { Button, ButtonGroup, Col, Dropdown, Form, Row } from 'react-bootstrap';
import Icon from '@mdi/react';
import {
   mdiFilterOutline,
   mdiFormatListGroup,
   mdiMenuDown,
   mdiRefresh,
   mdiSort,
   mdiSortAlphabeticalAscending,
   mdiSortAlphabeticalDescending,
   mdiSortCalendarAscending,
   mdiSortCalendarDescending,
   mdiSortNumericAscending,
   mdiSortNumericDescending,
} from '@mdi/js';
import { FormattedMessage } from 'react-intl';
import orderBy from 'lodash/orderBy';
import uniqBy from 'lodash/uniqBy';
import uniq from 'lodash/uniq';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import debounce from 'lodash/debounce';
import { Colors } from '../../Colors';
import { InventoryType } from '../../../types/api';
import { useAppSelector, useCache, useMemorizedIntl } from '../../../hooks';

interface Props {
   inventoryType?: InventoryType;
   hideOrdering?: boolean;
   lockedCategoryId?: number;
   onRefresh?: () => Promise<void>;
}
export interface SortOrderState {
   property: string;
   asc: boolean;
}

export interface FilterState {
   text: string;
   categoryId: number;
   legoStatus: string;
}

export interface LocationState {
   filter: FilterState;
   sortBy: SortOrderState;
   groupByLegoSet: boolean;
}

export const defaultOrderBy: SortOrderState = {
   property: 'number',
   asc: true,
};

export const SearchAndFilterBar = (props: Props) => {
   const intl = useMemorizedIntl();
   const navigate = useNavigate();
   const location = useLocation();
   const locationState = location.state as LocationState;
   const { categories } = useCache();
   const { entities: legoSets } = useAppSelector(s => s.pages.inventory);
   const [searchText, setSearchText] = useState(locationState?.filter.text ?? '');
   const [filterLegoStatus, setFilterLegoStatus] = useState(locationState?.filter.legoStatus ?? '');
   const [sortBy, setSortBy] = useState<SortOrderState>({
      property: locationState?.sortBy?.property ?? defaultOrderBy.property,
      asc: locationState?.sortBy?.asc ?? defaultOrderBy.asc,
   });
   const [filterCategory, setFilterCategory] = useState(
      props.lockedCategoryId ?? locationState?.filter.categoryId ?? 0
   );
   const [groupByLegoSet, setGroupByLegoSet] = useState(locationState?.groupByLegoSet ?? true);
   const [showFilter, setShowFilter] = useState(false);

   useEffect(() => {
      if (props.lockedCategoryId !== undefined) setFilterCategory(props.lockedCategoryId);
   }, [props.lockedCategoryId]);

   const updateLocationState = useMemo(
      () =>
         debounce((pathname: string, state: LocationState) => {
            navigate(pathname, {
               replace: true,
               state: state,
            });
         }, 200),
      [navigate]
   );

   useEffect(() => {
      updateLocationState(location.pathname, {
         filter: {
            text: searchText,
            categoryId: filterCategory,
            legoStatus: filterLegoStatus,
         },
         sortBy: sortBy,
         groupByLegoSet: groupByLegoSet,
      });
   }, [
      navigate,
      location.pathname,
      searchText,
      filterCategory,
      filterLegoStatus,
      sortBy,
      groupByLegoSet,
      updateLocationState,
   ]);

   const showGroupByMessage = (byLegoSet: boolean) => {
      toast.clearWaitingQueue({ containerId: 'toggleDisplayType' });
      toast.dark(
         byLegoSet ? (
            <FormattedMessage
               id="inventory.grid.display.grid"
               defaultMessage="Anzeige gruppiert nach LEGO Set"
            />
         ) : (
            <FormattedMessage
               id="inventory.grid.display.table"
               defaultMessage="Anzeige einzeln nach Inventar"
            />
         ),
         {
            containerId: 'toggleDisplayType',
            position: 'bottom-center',
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            closeButton: false,
            style: { justifyContent: 'center' },
         }
      );
   };

   const handleOrderChange = useCallback(
      (property: string, asc: boolean) => {
         if (props.hideOrdering) return;

         const val = {
            property: property,
            asc: sortBy.property === property ? !sortBy.asc : asc,
         };
         setSortBy(val);
      },
      [props.hideOrdering, sortBy.asc, sortBy.property]
   );

   const sortValues = useMemo((): { value: string; title: string; icons: string[] }[] => {
      const options = [
         {
            value: 'number',
            title: intl.formatMessage({
               id: 'inventory.grid.sort.number',
               defaultMessage: 'Nummer',
            }),
            icons: [mdiSortNumericAscending, mdiSortNumericDescending],
         },
         {
            value: 'name',
            title: intl.formatMessage({
               id: 'inventory.grid.sort.name',
               defaultMessage: 'Name',
            }),
            icons: [mdiSortAlphabeticalAscending, mdiSortAlphabeticalDescending],
         },
      ];

      if (props.inventoryType !== 'wanted') {
         options.push(
            ...[
               {
                  value: 'profit',
                  title: intl.formatMessage({
                     id: 'inventory.grid.sort.profit',
                     defaultMessage: 'Gewinn (€)',
                  }),
                  icons: [mdiSortNumericAscending, mdiSortNumericDescending],
               },
               {
                  value: 'profitPercentage',
                  title: intl.formatMessage({
                     id: 'inventory.grid.sort.profit-percentage',
                     defaultMessage: 'Gewinn (%)',
                  }),
                  icons: [mdiSortNumericAscending, mdiSortNumericDescending],
               },
               {
                  value: 'date',
                  title:
                     props.inventoryType === 'sold'
                        ? intl.formatMessage({
                             id: 'inventory.grid.sort.sold-date',
                             defaultMessage: 'Verkaufsdatum',
                          })
                        : intl.formatMessage({
                             id: 'inventory.grid.sort.date',
                             defaultMessage: 'Datum',
                          }),
                  icons: [mdiSortCalendarAscending, mdiSortCalendarDescending],
               },
            ]
         );
      }

      if (props.inventoryType === 'sold' && !groupByLegoSet) {
         options.push({
            value: `statistics.holdingTimeInDays`,
            title: intl.formatMessage({
               id: 'inventory.grid.sort.holding-time',
               defaultMessage: 'Haltedauer',
            }),
            icons: [mdiSortNumericAscending, mdiSortNumericDescending],
         });
      }

      return options;
   }, [groupByLegoSet, intl, props.inventoryType]);

   const sortByIcon = useMemo((): string => {
      const icons = sortValues.find(kv => kv.value === sortBy.property)?.icons;
      if (!icons) return mdiSort;

      return icons[sortBy.asc ? 0 : 1];
   }, [sortBy.asc, sortBy.property, sortValues]);

   return (
      <>
         <Row className="mb-2">
            <Col className="d-flex align-items-center gap-1">
               <Button
                  variant="secondary"
                  active={groupByLegoSet}
                  className="d-flex align-items-center gap-1"
                  onClick={() => {
                     showGroupByMessage(!groupByLegoSet);
                     setGroupByLegoSet(v => !v);
                  }}
                  title={intl.formatMessage({
                     id: 'inventory.grid.group-by-lego-set',
                     defaultMessage: 'Gruppiert nach LEGO Set',
                  })}
               >
                  <Icon
                     path={mdiFormatListGroup}
                     color={groupByLegoSet ? Colors.success : Colors.white}
                     size={1}
                  />
                  <span className={`d-none d-lg-inline ${groupByLegoSet ? 'text-success' : ''}`}>
                     <FormattedMessage
                        id="inventory.grid.group-by-lego-set"
                        defaultMessage="Gruppiert nach LEGO Set"
                     />
                  </span>
               </Button>
               <Button
                  active={showFilter}
                  className="d-flex align-items-center gap-1"
                  title={intl.formatMessage({
                     id: 'inventory.filter.btn',
                     defaultMessage: 'Filtern',
                  })}
                  onClick={() => setShowFilter(v => !v)}
               >
                  <Icon
                     path={mdiFilterOutline}
                     color={showFilter ? Colors.success : Colors.white}
                     size={1}
                  />
                  <span className={`d-none d-lg-inline ${showFilter ? 'text-success' : ''}`}>
                     <FormattedMessage id="inventory.filter.btn" defaultMessage="Filtern" />
                  </span>
               </Button>
               {props.onRefresh && (
                  <Button
                     className="d-flex align-items-center gap-1"
                     title={intl.formatMessage({
                        id: 'inventory.refresh.btn',
                        defaultMessage: 'Aktualisieren',
                     })}
                     onClick={async () => props.onRefresh?.()}
                  >
                     <Icon path={mdiRefresh} color={Colors.white} size={1} />
                  </Button>
               )}
            </Col>
            <Col xs={6} lg={4} xl={3} className="d-flex align-items-center">
               {(props.hideOrdering ?? false) || (
                  <ButtonGroup className="w-100">
                     <Button
                        style={{ flex: 0 }}
                        variant="secondary"
                        title={intl.formatMessage({
                           id: 'inventory.grid.sort.headline',
                           defaultMessage: 'Sortierung',
                        })}
                        onClick={() => handleOrderChange(sortBy.property, !sortBy.asc)}
                     >
                        <Icon path={sortByIcon} color={Colors.white} size={1} />
                     </Button>
                     <Dropdown as={ButtonGroup} className="dropdown-filter">
                        <Dropdown.Toggle style={{ width: '6.25rem' }} variant="secondary">
                           <span>{sortValues.find(kv => kv.value === sortBy.property)?.title}</span>
                           <Icon path={mdiMenuDown} color={Colors.white} size={1} />
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="scrollable w-100">
                           {sortValues.map(kv => (
                              <Dropdown.Item
                                 key={kv.value}
                                 onClick={() => {
                                    const prop = kv.value;
                                    handleOrderChange(prop, prop === 'number' || prop === 'name');
                                 }}
                                 active={kv.value === sortBy.property}
                              >
                                 {kv.title}
                              </Dropdown.Item>
                           ))}
                        </Dropdown.Menu>
                     </Dropdown>
                  </ButtonGroup>
               )}
            </Col>
         </Row>
         <Row className={`grid-filter ${showFilter ? 'visible' : ''}`}>
            <Col xs={12} className="d-flex">
               <span className="spacer-line my-2" />
            </Col>
            <Col xs={12} lg={4} xl={3} className="mb-2">
               <Form.Control
                  type="text"
                  className="d-inline-block"
                  placeholder={intl.formatMessage({
                     id: 'inventory.grid.filter.search-text',
                     defaultMessage: 'Suche nach Name & Nummer',
                  })}
                  value={searchText}
                  onChange={e => setSearchText(e.target.value)}
               />
            </Col>
            <Col xs={6} lg={4} xl={3} className="mb-2">
               <Dropdown className="dropdown-filter">
                  <Dropdown.Toggle
                     variant={filterCategory === 0 ? 'outline-secondary' : 'success'}
                     className={filterCategory === 0 ? 'text-light' : ''}
                     disabled={props.lockedCategoryId !== undefined}
                  >
                     <span>
                        {filterCategory === 0 ? (
                           <FormattedMessage
                              id="inventory.grid.filter.category.all"
                              defaultMessage="Alle Kategorien"
                           />
                        ) : (
                           categories.find(c => c.id === filterCategory)?.name ?? ''
                        )}
                     </span>
                     <Icon path={mdiMenuDown} color={Colors.white} size={1} />
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="scrollable">
                     <Dropdown.Item onClick={() => setFilterCategory(0)}>
                        <FormattedMessage
                           id="inventory.grid.filter.category.all"
                           defaultMessage="Alle Kategorien"
                        />
                     </Dropdown.Item>
                     <Dropdown.Divider />
                     {orderBy(
                        uniqBy(
                           (legoSets ?? []).map(s => ({
                              id: s.category_id,
                              name: s.category_name,
                           })),
                           c => c.id
                        ),
                        'name'
                     ).map(s => (
                        <Dropdown.Item
                           key={s.id}
                           onClick={() => setFilterCategory(s.id)}
                           active={filterCategory === s.id}
                        >
                           {s.name}
                        </Dropdown.Item>
                     ))}
                  </Dropdown.Menu>
               </Dropdown>
            </Col>
            <Col xs={6} lg={4} xl={3} className="mb-2">
               <Dropdown className="dropdown-filter">
                  <Dropdown.Toggle
                     variant={filterLegoStatus === '' ? 'outline-secondary' : 'success'}
                     className={filterCategory === 0 ? 'text-light' : ''}
                  >
                     <span>
                        {filterLegoStatus === '' ? (
                           <FormattedMessage
                              id="inventory.grid.filter.status.all"
                              defaultMessage="Alle Sets"
                           />
                        ) : (
                           filterLegoStatus
                        )}
                     </span>
                     <Icon path={mdiMenuDown} color={Colors.white} size={1} />
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="scrollable">
                     <Dropdown.Item onClick={() => setFilterLegoStatus('')}>
                        <FormattedMessage
                           id="inventory.grid.filter.status.all"
                           defaultMessage="Alle Sets"
                        />
                     </Dropdown.Item>
                     <Dropdown.Divider />
                     {uniq((legoSets ?? []).map(s => s.status_by_lego ?? ''))
                        .filter(s => s !== '')
                        .sort()
                        .map(s => (
                           <Dropdown.Item
                              key={s}
                              onClick={() => setFilterLegoStatus(s)}
                              active={filterLegoStatus === s}
                           >
                              {s}
                           </Dropdown.Item>
                        ))}
                  </Dropdown.Menu>
               </Dropdown>
            </Col>
         </Row>
      </>
   );
};
