import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { ContentLoader } from '../components/atoms';
import { useAppDispatch, useMemorizedIntl, useSession } from '../hooks';
import { setTitle } from '../redux/appSlice';

export const PageNotFoundPage = () => {
   const intl = useMemorizedIntl();
   const dispatch = useAppDispatch();
   const { loginState } = useSession();

   useEffect(() => {
      dispatch(
         setTitle(
            loginState === 'unknown'
               ? intl.formatMessage({
                    id: 'error-page.404.loading',
                    defaultMessage: 'Bitte warten...',
                 })
               : intl.formatMessage({
                    id: 'error-page.404.title',
                    defaultMessage: 'Seite nicht gefunden',
                 })
         )
      );
   }, [dispatch, intl, loginState]);

   return (
      <Container>
         {loginState === 'unknown' ? (
            <ContentLoader />
         ) : (
            <>
               <div className="text-center" style={{ fontSize: 150 }}>
                  404
               </div>
               <div className="text-center">
                  <FormattedMessage
                     id="error-page.404.text"
                     defaultMessage="Die aufgerufene Seite existiert nicht."
                  />
               </div>
            </>
         )}
      </Container>
   );
};
