import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import { formatDate, notEmpty } from '../../../utils';
import { ButtonPanel, Card, ResponsiveButton } from '../../../components/molecules';
import {
   IconPropertyGroup,
   IconPropertyItem,
} from '../../../components/molecules/IconPropertyGroup';
import { ColoredValue } from '../../../components/atoms';
import { LegoSetWithStats } from '../../../types/api/LegoSetWithStats';
import { SimpleTable } from '../../../components/molecules/SimpleTable';
import { useMemorizedIntl } from '../../../hooks';

interface Props {
   legoSet?: LegoSetWithStats;
}
export const PriceCard = ({ legoSet }: Props) => {
   const intl = useMemorizedIntl();
   const [showDetails, setShowDetails] = useState(false);
   const mvIdealo = legoSet?.statistics.currentMarketValueBySource.find(
      mv => mv.source === 'idealo'
   );
   const mvBricklink = legoSet?.statistics.currentMarketValueBySource.find(
      mv => mv.source === 'bricklink'
   );
   const oldestMV = Math.min(
      ...[
         moment(mvIdealo?.date).toDate().getTime(),
         moment(mvBricklink?.date).toDate().getTime(),
      ].filter(notEmpty)
   );

   return (
      <>
         <Card orientation="column" onPress={() => setShowDetails(true)} chevron>
            <IconPropertyGroup>
               <IconPropertyItem
                  label={intl.formatMessage({
                     id: 'lego-set.price-card.min',
                     defaultMessage: 'MIN',
                  })}
               >
                  <ColoredValue
                     type="currency"
                     value={legoSet?.statistics.currentMarketValue.min}
                  />
               </IconPropertyItem>
               <IconPropertyItem
                  label={intl.formatMessage({
                     id: 'lego-set.price-card.avg',
                     defaultMessage: 'AVG',
                  })}
               >
                  <ColoredValue
                     type="currency"
                     value={legoSet?.statistics.currentMarketValue.avg}
                  />
               </IconPropertyItem>
               <IconPropertyItem
                  label={intl.formatMessage({
                     id: 'lego-set.price-card.max',
                     defaultMessage: 'MAX',
                  })}
               >
                  <ColoredValue
                     type="currency"
                     value={legoSet?.statistics.currentMarketValue.max}
                  />
               </IconPropertyItem>
            </IconPropertyGroup>
            <small className="mt-2 text-muted fst-italic text-center">
               Stand: {formatDate(new Date(oldestMV), 'L')}
            </small>
         </Card>
         <Modal show={showDetails} onHide={() => setShowDetails(false)} centered={isMobile}>
            <Modal.Header closeButton>
               <Modal.Title>
                  <FormattedMessage
                     id="dialog.price-card.title"
                     defaultMessage="Details zum Marktwert"
                  />
               </Modal.Title>
            </Modal.Header>
            <Modal.Body>
               <SimpleTable>
                  <SimpleTable.Row
                     title={
                        <FormattedMessage
                           id="lego-set.price-card.lego-rrp.title"
                           defaultMessage="UVP"
                        />
                     }
                  >
                     {legoSet?.is_gwp && (
                        <span className="fst-italic">
                           <FormattedMessage
                              id="lego-set.price-card.lego-rrp.gwp"
                              defaultMessage="Kostl. Zugabe bei Bestellung"
                           />
                        </span>
                     )}
                     {!legoSet?.is_gwp && legoSet?.lego_url && (
                        <a href={legoSet.lego_url} target="_blank" rel="noopener noreferrer">
                           <ColoredValue type="currency" value={legoSet.price} />
                        </a>
                     )}
                     {!legoSet?.is_gwp && !legoSet?.lego_url && '-'}
                  </SimpleTable.Row>
                  <SimpleTable.Row
                     title={
                        <FormattedMessage
                           id="lego-set.price-card.idealo.title"
                           defaultMessage="Idealo"
                        />
                     }
                  >
                     {mvIdealo ? (
                        <>
                           <a
                              href={legoSet?.idealo_url ?? undefined}
                              target="_blank"
                              rel="noopener noreferrer"
                           >
                              <ColoredValue type="currency" value={mvIdealo.price} />
                           </a>
                           <small className="text-muted ms-1">
                              <FormattedMessage
                                 id="lego-set.price-card.idealo.value-from-date"
                                 defaultMessage="vom {date}"
                                 values={{
                                    date: formatDate(mvIdealo.date, 'L'),
                                 }}
                              />
                           </small>
                        </>
                     ) : (
                        <span className="fst-italic text-muted">
                           <FormattedMessage
                              id="lego-set.price-card.no-value-in-6-month"
                              defaultMessage="Kein Wert seit 6 Monaten"
                           />
                        </span>
                     )}
                  </SimpleTable.Row>
                  <SimpleTable.Row
                     title={
                        <FormattedMessage
                           id="lego-set.price-card.bricklink.title"
                           defaultMessage="Bricklink"
                        />
                     }
                  >
                     {mvBricklink ? (
                        <>
                           <a
                              href={legoSet?.bricklink_url ?? undefined}
                              target="_blank"
                              rel="noopener noreferrer"
                           >
                              <ColoredValue type="currency" value={mvBricklink.price} />
                           </a>
                           <small className="text-muted ms-1">
                              <FormattedMessage
                                 id="lego-set.price-card.bricklink.value-from-date"
                                 defaultMessage="vom {date}"
                                 values={{
                                    date: formatDate(mvBricklink.date, 'L'),
                                 }}
                              />
                           </small>
                        </>
                     ) : (
                        <span className="fst-italic text-muted">
                           <FormattedMessage
                              id="lego-set.price-card.no-value-in-6-month"
                              defaultMessage="Kein Wert seit 6 Monaten"
                           />
                        </span>
                     )}
                  </SimpleTable.Row>
                  <SimpleTable.Row
                     title={
                        <FormattedMessage
                           id="lego-set.price-card.avg-price.title"
                           defaultMessage="Mittel"
                        />
                     }
                  >
                     <ColoredValue
                        type="currency"
                        value={legoSet?.statistics.currentMarketValue.avg}
                     />
                  </SimpleTable.Row>
               </SimpleTable>
            </Modal.Body>

            <Modal.Footer>
               <ButtonPanel>
                  <ResponsiveButton variant="secondary" onClick={() => setShowDetails(false)}>
                     <FormattedMessage id="button.close" defaultMessage="Schließen" />
                  </ResponsiveButton>
               </ButtonPanel>
            </Modal.Footer>
         </Modal>
      </>
   );
};
