import { FormattedMessage } from 'react-intl';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { CommentForm } from './CommentForm';
import { CommentCard } from './CommentCard';
import { ResponsiveButton } from '../molecules';
import { ContentLoader } from '../atoms';
import { useSession } from '../../hooks';
import { BlogPostComment, ROLE_ADMIN } from '../../types/api';
import { BlogPostCommentModel } from '../../models/BlogPostCommentModel';

interface Props {
   blogPostId: number;
}

export const BlogPostComments = ({ blogPostId }: Props) => {
   const { sessionUser, sessionRoles, loginState } = useSession();
   const [comments, setComments] = useState<BlogPostComment[]>();
   const [showCommentForm, setShowCommentForm] = useState(false);

   const loadComments = useCallback(async () => {
      setComments(await BlogPostCommentModel.list({ blog_post_id: blogPostId }));
   }, [blogPostId]);

   useEffect(() => {
      (async () => {
         // Nur für angemeldete Benutzer werden die Kommentare geladen
         if (loginState !== 'loggedIn') return;

         await loadComments();
      })();
   }, [blogPostId, sessionUser, loadComments, loginState]);

   const handleNewComment = useCallback(async () => {
      await loadComments();
      setShowCommentForm(false);
   }, [loadComments]);

   const printTree = useCallback(
      (scopeComments: BlogPostComment[]) =>
         scopeComments.map(c => {
            const childComments = comments?.filter(c2 => c2.parent_comment_id === c.id) ?? [];
            const commentIsVisible =
               sessionRoles.includes(ROLE_ADMIN) || (!c.is_reported && !c.is_deleted);

            return (
               <Fragment key={c.id}>
                  {commentIsVisible && (
                     <CommentCard key={c.id} comment={c} onRefresh={loadComments} />
                  )}
                  {childComments.length > 0 && (
                     <div className={commentIsVisible ? 'ps-4' : ''}>
                        {printTree(childComments)}
                     </div>
                  )}
               </Fragment>
            );
         }),
      [comments, loadComments, sessionRoles]
   );

   return (
      <div id="comments">
         <div className="mt-5">
            <h1>
               <FormattedMessage
                  id="blog-post-show.comments.headline"
                  defaultMessage="Kommentare ({count})"
                  values={{ count: comments?.filter(c => !c.is_deleted && !c.is_reported).length }}
               />
            </h1>
         </div>
         {loginState !== 'loggedIn' && (
            <em>
               <FormattedMessage
                  id="blog-post-show.comments.only-visible-to-users"
                  defaultMessage="Die Kommentare werden nur für angemeldete Benutzer angezeigt."
               />
            </em>
         )}
         {loginState === 'loggedIn' && !comments && <ContentLoader className="pt-4" />}
         {loginState === 'loggedIn' && comments && (
            <>
               <div className="pb-4 pt-2">
                  {showCommentForm ? (
                     <CommentForm
                        blogPostId={blogPostId}
                        afterSubmit={handleNewComment}
                        onCancel={() => setShowCommentForm(false)}
                     />
                  ) : (
                     <ResponsiveButton variant="success" onClick={() => setShowCommentForm(true)}>
                        <FormattedMessage
                           id="button.add-comment"
                           defaultMessage="Kommentar schreiben"
                        />
                     </ResponsiveButton>
                  )}
               </div>
               {comments.length === 0 ? (
                  <em>
                     <FormattedMessage
                        id="blog-post-show.comments.no-comments"
                        defaultMessage="Es existieren noch keine Kommentare."
                     />
                  </em>
               ) : (
                  printTree(comments.filter(c => c.parent_comment_id === null))
               )}
            </>
         )}
      </div>
   );
};
