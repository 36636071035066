import { Accordion, Card, Container } from 'react-bootstrap';
import React, { Fragment, useEffect, useMemo } from 'react';
import { BrowserView, MobileView } from 'react-device-detect';
import Icon from '@mdi/react';
import { mdiMenuRight } from '@mdi/js/commonjs/mdi';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import remarkGemoji from 'remark-gemoji';
import { showBackButton } from '../../redux/appSlice';
import { Colors } from '../../components/Colors';
import { useAppDispatch, useMemorizedIntl } from '../../hooks';
import { PageTitle } from '../../components/organisms/PageTitle';

interface QuestionAndAnswer {
   question: string;
   answer: string;
}

export const FaqPage = () => {
   const dispatch = useAppDispatch();
   const intl = useMemorizedIntl();

   useEffect(() => {
      dispatch(showBackButton(true));
   }, [dispatch]);

   // TODO: Übersetzungen
   const faq: QuestionAndAnswer[] = useMemo(
      () => [
         {
            question: 'Was kann ich hier eigentlich machen?',
            answer:
               '[Brickfolio.de](https://brickfolio.de) ermöglicht dir als Lego-Investor einen Überblick über dein aktuelles Investment zu behalten. Du kannst gekaufte Sets deinem Inventar hinzufügen und über Statistiken entscheiden, wann ein guter Zeitpunkt zum verkaufen ist.',
         },
         {
            question: 'Kann man sich auch ohne Google-Account anmelden?',
            answer:
               'Eine Anmeldung ohne Google-Account ist nicht möglich.\n\nIn der Software-Entwicklung wird zu häufig das Rad neu erfunden und nicht auf bestehende Lösungen gesetzt. Gerade beim Thema Login kann man durch eine eigene Implementierung mehr Fehler machen als es am Ende ein Nutzen bringt. So ließt man leider zu häufig, dass mal wieder ein Anbieter gehackt wurde und die Datenbank mit den Anmeldedaten geklaut wurde. \n\nGemäß dem Motto \n\n>_Die sichersten Daten sind die, die man nicht besitzt._\n\n wurde auf eine Registrierung via E-Mail und Passwort und damit der Speicherung dieser Daten verzichtet.',
         },
         {
            question: 'Wie kann ich meinen Account löschen?',
            answer:
               'Das Löschen des Accounts ist aktuell nur durch eine E-Mail an [info@brickfolio.de](mailto:info@brickfolio.de) möglich.',
         },
         {
            question: 'Ich habe einen Fehler gefunden!!1elf',
            answer:
               'Oh je, dass sollte natürlich nicht passieren. Schreib am besten eine Mail an [info@brickfolio.de](mailto:info@brickfolio.de), in der du folgende Fragen kurz beantwortest:\n\n* Was hast du wo gemacht?\n* Was ist passiert?\n* Was hätte (deiner Meinung nach) passieren sollen?',
         },
         {
            question: 'Mir fehlt eine wichtige Funktion.',
            answer:
               'Wunderbar! Ideen für neue coole Funktionen sind immer gesucht. Schreib mir am besten an [info@brickfolio.de](mailto:info@brickfolio.de).',
         },
         {
            question: 'Ich sehe ja gar keine Werbung hier. Wie finanzierst du das alles hier?',
            answer:
               'Da ich selber keine Werbung auf Webseiten sehen möchte, erspare ich euch das hier. Nicht desto trotz fallen natürlich Kosten für den Betrieb und die Weiterentwicklung an.\n\nDie Kosten halten sich bisher mit ca. 15€ pro Monat in Grenzen und werden bisher primär durch mich persönlich getragen.\n\nSofern du meine Arbeit finanziell unterstützen möchtest, so schreib mir einfach eine kurze Email an [info@brickfolio.de](mailto:info@brickfolio.de).',
         },
         {
            question: 'Wer bist du?',
            answer:
               'Ich bin ein begeisterter Softwareentwickler mit über 15 Jahren Erfahrung. :blush:',
         },
         {
            question: 'Warum entwickelst und betreibst du diese Seite?',
            answer:
               'Ich habe Spaß an der Softwareentwicklung und neuen Herausforderungen. Diese zwei Aspekte kann ich bei der Entwicklung und dem Betrieb meiner ersten Webseite verbinden.',
         },
         {
            question: 'Muss ich die Gewinne von meinen Verkäufen versteuern?',
            answer:
               'Die Gewinne aus dem Kauf und Verkauf von Lego muss unter Umständen versteuert werden. Wende dich dazu am besten an deinen Steuerberater.',
         },
         {
            question: 'Warum werden Texte nicht gegendert?',
            answer:
               'Für die bessere Lesbarkeit der Texte und aus Gründen der formalen Vereinfachung wird die männliche Sprachform benutzt. Selbstverständlich sollen sich alle [33+ Geschlechter (stand März 2020)](https://www.lifehacker.com.au/2020/03/explained-the-33-gender-identities-recognised-by-the-2016-australian-sex-survey/) angesprochen fühlen.',
         },
      ],
      []
   );

   return (
      <Container>
         <PageTitle
            title={intl.formatMessage({
               id: 'faq.title',
               defaultMessage: 'FAQ',
            })}
         />
         <BrowserView>
            <br />
            <br />
            {faq.map(o => (
               <Fragment key={o.question}>
                  <h4>
                     <Icon path={mdiMenuRight} color={Colors.white} size={1} />
                     {o.question}
                  </h4>
                  <div className="pb-4 ps-5">
                     <ReactMarkdown remarkPlugins={[remarkGfm, remarkGemoji]}>
                        {o.answer}
                     </ReactMarkdown>
                  </div>
               </Fragment>
            ))}
         </BrowserView>
         <MobileView className="pt-1">
            <Accordion flush>
               {faq.map((o, i) => (
                  <Accordion.Item as={Card} key={o.question} eventKey={`${i}`}>
                     <Accordion.Button as={Card.Header}>{o.question}</Accordion.Button>
                     <Accordion.Body as={Card.Body} eventKey={`${i}`}>
                        <ReactMarkdown remarkPlugins={[remarkGfm, remarkGemoji]}>
                           {o.answer}
                        </ReactMarkdown>
                     </Accordion.Body>
                  </Accordion.Item>
               ))}
            </Accordion>
         </MobileView>
      </Container>
   );
};
