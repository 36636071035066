import React from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { BadgeButton, BadgeButtonProps } from './BadgeButton';

interface Props extends BadgeButtonProps {
   /**
    * The target route to navigate.
    */
   to: string;
}

/**
 * Creates a button which navigates to the provided route.
 */
export const LinkButton = ({ to, ...props }: Props) => (
   <LinkContainer to={to}>
      <BadgeButton {...props} />
   </LinkContainer>
);
