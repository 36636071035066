import React from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import {
   Badge,
   Button,
   ButtonGroup,
   Card,
   Container,
   Dropdown,
   Nav,
   Navbar,
} from 'react-bootstrap';
import orderBy from 'lodash/orderBy';
import { FormattedMessage } from 'react-intl';
import { mdiBellOutline } from '@mdi/js';
import Icon from '@mdi/react';
import { AdminDropdownItem, ButtonPanel, LinkButton } from '../molecules';
import { AddLegoSetDialog } from '../dialogs/AddLegoSetDialog';
import { GoogleSignInButton } from '../atoms';
import { Colors } from '../Colors';
import { CustomDropDown } from '../molecules/CustomDropDown';
import { useNotifications } from '../../utils/useNotifications';
import { useCache, useHomeRoute, useSession } from '../../hooks';
import { Category, ROLE_ADMIN } from '../../types/api';
import { ShowIfRole } from '../molecules/ShowIfRole';
import { SearchForm } from './SearchForm';

import './NavBar.scss';
import { pageLinks } from '../../utils';

export const NavBar = () => {
   const notifications = useNotifications();
   const { signOut, sessionUser, loginState } = useSession();
   const { homeRoutePath } = useHomeRoute();

   return (
      <Navbar bg="primary" expand="xl" className="navbar-dark">
         <Container fluid>
            <LinkContainer to={homeRoutePath || '/'}>
               <Navbar.Brand>
                  <img
                     alt=""
                     src="./logo192.png"
                     width="30"
                     height="30"
                     className="d-inline-block align-top brand-logo"
                  />
                  &nbsp;Brickfolio.de
               </Navbar.Brand>
            </LinkContainer>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
               {loginState !== 'loggedIn' && <Nav className="me-auto navbar-left" />}
               {loginState === 'loggedIn' && (
                  <>
                     <Nav className="me-auto navbar-left">
                        <Dropdown as={ButtonGroup}>
                           <LinkButton to={pageLinks.inventory('bought')} variant="primary">
                              <FormattedMessage
                                 id="navbar.my-inventory"
                                 defaultMessage="Mein Inventar"
                              />
                           </LinkButton>

                           <Dropdown.Toggle split variant="primary" />

                           <Dropdown.Menu>
                              <LinkContainer to={pageLinks.inventory('sold')}>
                                 <Dropdown.Item>
                                    <FormattedMessage
                                       id="navbar.my-sales"
                                       defaultMessage="Meine Verkäufe"
                                    />
                                 </Dropdown.Item>
                              </LinkContainer>
                              <LinkContainer to={pageLinks.inventory('wanted')}>
                                 <Dropdown.Item>
                                    <FormattedMessage
                                       id="navbar.my-wishlist"
                                       defaultMessage="Meine Wunschliste"
                                    />
                                 </Dropdown.Item>
                              </LinkContainer>
                              <Dropdown.Divider />
                              <LinkContainer to="/inventory/search">
                                 <Dropdown.Item>
                                    <FormattedMessage
                                       id="navbar.my-inventory-filters"
                                       defaultMessage="Meine Filter"
                                    />
                                 </Dropdown.Item>
                              </LinkContainer>
                              <Dropdown.Divider />
                              <LinkContainer to="/stats">
                                 <Dropdown.Item>
                                    <FormattedMessage
                                       id="navbar.statistics"
                                       defaultMessage="Statistiken"
                                    />
                                 </Dropdown.Item>
                              </LinkContainer>
                           </Dropdown.Menu>
                        </Dropdown>
                        <CategoriesDropDownList />
                        <LabelsDropDownList />
                        <AddLegoSetDialog>
                           {({ onPress }) => (
                              <Button variant="success" onClick={onPress}>
                                 <FormattedMessage
                                    id="button.add-lego-set"
                                    defaultMessage="Neues Set anlegen"
                                 />
                              </Button>
                           )}
                        </AddLegoSetDialog>
                     </Nav>
                     <SearchForm style={{ width: '15rem' }} />
                  </>
               )}
               <Nav className="navbar-right">
                  {loginState !== 'loggedIn' ? (
                     <GoogleSignInButton />
                  ) : (
                     <>
                        <CustomDropDown className="me-2">
                           <CustomDropDown.Trigger style={{ border: 'none', position: 'relative' }}>
                              <Icon path={mdiBellOutline} color={Colors.white} size={1} />
                              {notifications.length > 0 && (
                                 <Badge
                                    pill
                                    bg="warning"
                                    style={{
                                       position: 'absolute',
                                       top: '0.25rem',
                                       right: '0.25rem',
                                    }}
                                 >
                                    {notifications.length}
                                 </Badge>
                              )}
                           </CustomDropDown.Trigger>
                           <CustomDropDown.Content
                              style={{ width: '25rem' }}
                              className="scrollable-lg"
                           >
                              {notifications.map(n => (
                                 <Card key={n.id} className="my-1">
                                    <Card.Header className="p-2 d-flex align-items-center">
                                       <Icon
                                          path={n.icon}
                                          color={Colors.white}
                                          size={0.75}
                                          className="me-2"
                                       />
                                       {n.title}
                                    </Card.Header>
                                    <Card.Body className="p-1 pb-2">
                                       <div className="py-2 px-3">{n.message}</div>
                                       {n.navigateTo && (
                                          <ButtonPanel>
                                             <LinkButton
                                                to={n.navigateTo}
                                                variant="primary"
                                                size="sm"
                                             >
                                                {n.buttonLabel}
                                             </LinkButton>
                                          </ButtonPanel>
                                       )}
                                    </Card.Body>
                                 </Card>
                              ))}
                              {notifications.length === 0 && (
                                 <em>Es existieren keine Benachrichtigungen</em>
                              )}
                           </CustomDropDown.Content>
                        </CustomDropDown>
                        <Dropdown>
                           <Dropdown.Toggle variant="primary">{sessionUser?.name}</Dropdown.Toggle>
                           <Dropdown.Menu>
                              <ShowIfRole roles={[ROLE_ADMIN]}>
                                 <AdminDropdownItem to="/admin">
                                    <FormattedMessage
                                       id="navbar.admin.overview"
                                       defaultMessage="Administration"
                                    />
                                 </AdminDropdownItem>
                              </ShowIfRole>
                              <LinkContainer to="/user/profile">
                                 <Dropdown.Item>
                                    <FormattedMessage id="navbar.profile" defaultMessage="Profil" />
                                 </Dropdown.Item>
                              </LinkContainer>
                              <ShowIfRole roles={[ROLE_ADMIN]}>
                                 <Dropdown.Divider />
                              </ShowIfRole>
                              <Dropdown.Item onClick={() => signOut()}>
                                 <FormattedMessage id="navbar.logout" defaultMessage="Abmelden" />
                              </Dropdown.Item>
                           </Dropdown.Menu>
                        </Dropdown>
                     </>
                  )}
               </Nav>
            </Navbar.Collapse>
         </Container>
      </Navbar>
   );
};

const CategoriesDropDownList = () => {
   const { categories } = useCache();

   return (
      <Dropdown>
         <Dropdown.Toggle variant="primary">
            <FormattedMessage id="navbar.categories" defaultMessage="Kategorien" />
         </Dropdown.Toggle>
         <Dropdown.Menu className="scrollable-lg">
            <LinkContainer to="/categories">
               <Dropdown.Item>
                  <FormattedMessage
                     id="navbar.dropdown.all-categories"
                     defaultMessage="Alle Kategorien"
                  />
               </Dropdown.Item>
            </LinkContainer>
            <Dropdown.Divider />
            {orderBy(categories, ['name'], ['asc']).map((c: Category) => (
               <LinkContainer key={c.id} to={pageLinks.legoSetsByCategory(c)}>
                  <Dropdown.Item>
                     <span className="text-nowrap">{c.name}</span>
                     <Badge className="badge-lg ms-2" pill bg="secondary">
                        {c.legoSets.length}
                     </Badge>
                  </Dropdown.Item>
               </LinkContainer>
            ))}
         </Dropdown.Menu>
      </Dropdown>
   );
};

const LabelsDropDownList = () => {
   const { labels } = useCache();

   return (
      <Dropdown>
         <Dropdown.Toggle variant="primary">
            <FormattedMessage id="navbar.labels" defaultMessage="Labels" />
         </Dropdown.Toggle>
         <Dropdown.Menu className="scrollable-lg">
            {orderBy(labels, ['name'], ['asc'])
               .filter(l => l.lego_sets.length + l.lego_sets_by_user.length > 0)
               .map(l => (
                  <LinkContainer key={l.id} to={pageLinks.legoSetsByLabel(l)}>
                     <Dropdown.Item>
                        <span className="text-nowrap">{l.name}</span>
                        <Badge className="badge-lg ms-2" pill bg="secondary">
                           {l.lego_sets.length + l.lego_sets_by_user.length}
                        </Badge>
                     </Dropdown.Item>
                  </LinkContainer>
               ))}
         </Dropdown.Menu>
      </Dropdown>
   );
};
