import React, { CSSProperties } from 'react';
import { useImage } from 'react-image';
import { ContentLoader } from '../atoms';
import DeadIcon from '../../assets/img/dead.svg';

interface Props {
   /**
    * Die URL zu dem Bild.
    */
   url: string;
   /**
    * If `true` a loading animation will be displayed.
    */
   loading?: boolean;
   /**
    * The alternative text for the image.
    */
   alt?: string;
   /**
    * The title for the image.
    */
   title?: string;
   /**
    * Additional styles for the image.
    */
   style?: CSSProperties;
}

export const Image = ({ loading = false, ...imgProps }: Props) => {
   // Dies ist notwendig, da die URL während des Ladens ungültig sein kann. Bei einer später gültigen URL
   // wird anschließend das Bild nicht mehr korrekt geladen. Daher geben wir direkt ein Loader aus, sobald
   // der Parent mitteilt, dass der Inhalt noch am Laden ist.
   if (loading) return <ContentLoader style={imgProps.style} />;
   return <ImageComponent key={imgProps.url} {...imgProps} />;
};

/**
 * Renders an image with loading animation
 */
const ImageComponent = ({ url = '', loading = false, alt = '', style, ...imgProps }: Props) => {
   const { src, isLoading } = useImage({
      srcList: loading ? '' : url,
      useSuspense: false,
   });

   // Zeigt eine Loading-Animation an, während das Bild geladen wird
   if (isLoading) return <ContentLoader style={style} />;

   // Zeigt das Bild an, sofern es komplett geladen wurde oder ein Fehlerbild
   return <img src={src ?? DeadIcon} alt={alt} style={style} {...imgProps} />;
};
