import React, { CSSProperties, useMemo } from 'react';
import { Spinner } from 'react-bootstrap';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { Colors } from '../Colors';

interface InlineLoaderProps {
   /**
    * If true, the loader will be shown.
    */
   show: boolean;
   /**
    * Additional styles.
    */
   style?: CSSProperties;
}

/**
 * Creates a small loader to use in inline with text (e.g. Buttons).
 */
export const InlineLoader = ({ show, style }: InlineLoaderProps) =>
   show ? (
      <Spinner
         style={style}
         as="span"
         animation="border"
         size="sm"
         role="status"
         aria-hidden="true"
      />
   ) : null;

interface ContentLoaderProps {
   className?: string;
   style?: CSSProperties;
}

/**
 * Creates a styled spinner as loading animation for page content or parts if it.
 */
export const ContentLoader = ({ className, style = { height: '12.5rem' } }: ContentLoaderProps) => (
   <div className={`d-flex align-items-center justify-content-center ${className}`} style={style}>
      <Spinner animation="border" role="status" variant="success" />
   </div>
);

interface ChartLoaderProps {
   width?: string;
   height?: string;
}

/**
 * Skeleton loading animation for a chart.
 */
export const ChartLoader = ({ width, height }: ChartLoaderProps) => {
   const style = useMemo(
      () => ({
         width,
         height,
      }),
      [width, height]
   );
   return (
      <div className="chart-loader" style={style}>
         <Spinner animation="border" role="status" variant="light" />
      </div>
   );
};

type SkeletonPlaceholderProps = {
   width?: number | string;
   height?: number | string;
};

export const SkeletonPlaceholder = ({
   width = '6.25rem',
   height = '1.25rem',
}: SkeletonPlaceholderProps) => (
   <SkeletonTheme baseColor={Colors.secondary} highlightColor={Colors.background}>
      <Skeleton style={{ margin: 0 }} width={width} height={height} containerClassName="lh-1" />
   </SkeletonTheme>
);
