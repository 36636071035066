import { Formik } from 'formik';
import { Form, FormCheck, Modal } from 'react-bootstrap';
import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { invalidateInventory, reloadLegoSet } from '../../redux/pagesSlice';
import { InventoryForm, useValidationHandler } from '../../forms/InventoryForm';
import { ButtonPanel, ResponsiveButton } from '../molecules';
import { Inventory, LegoSet } from '../../types/api';
import { InventoryModel } from '../../models/InventoryModel';
import { LegoSetModel } from '../../models/LegoSetModel';
import { SubmitButton } from '../forms';
import { useAppDispatch, useMemorizedIntl } from '../../hooks';

interface Props {
   show: boolean;
   setId: number;
   inventory: Partial<Inventory>;
   onClose: () => void;
}

export const EditInventoryDialog = (props: Props) => {
   const dispatch = useAppDispatch();
   const intl = useMemorizedIntl();
   const handleValidate = useValidationHandler();
   const [currentInventory, setCurrentInventory] = useState<Partial<Inventory>>(props.inventory);
   const [legoSet, setLegoSet] = useState<LegoSet | null>(null);
   const [createAnotherEntry, setCreateAnotherEntry] = useState(false);

   useEffect(() => {
      (async () => {
         setCurrentInventory(props.inventory);
         if (props.inventory.set_id) setLegoSet(await LegoSetModel.get(props.inventory.set_id));
      })();
   }, [props.inventory]);

   const handleSubmitForm = useCallback(
      async (values: Partial<Inventory>) => {
         let isInsert = false;
         if (!values.id) {
            isInsert = true;
            values.set_id = props.setId;
            await InventoryModel.insert(values);
         } else {
            await InventoryModel.update(values);
         }

         dispatch(reloadLegoSet(values.set_id));
         dispatch(invalidateInventory());

         if (createAnotherEntry) {
            // Zurücksetzen des Inventars, sodass es erneut gespeichert werden kann
            // Aber wir übernehmen ein paar Eigenschaften
            setCurrentInventory(values);
         } else {
            props.onClose();
         }

         if (isInsert) {
            toast.success(
               intl.formatMessage({
                  id: 'dialog.edit-inventory.insert-success-toast',
                  defaultMessage: 'Das LEGO-Set wurde erfolgreich deinem Inventar hinzugefügt.',
               })
            );
         } else {
            toast.success(
               intl.formatMessage({
                  id: 'dialog.edit-inventory.update-success-toast',
                  defaultMessage: 'Der Eintrag wurde erfolgreich aktualisiert.',
               })
            );
         }
      },
      [createAnotherEntry, dispatch, intl, props]
   );

   return (
      <Modal show={props.show} onHide={props.onClose} size="lg">
         <Formik
            onSubmit={handleSubmitForm}
            initialValues={currentInventory}
            enableReinitialize
            validate={handleValidate}
         >
            {formik => (
               <Form
                  noValidate
                  onSubmit={e => {
                     e.preventDefault();
                     formik.handleSubmit();
                  }}
               >
                  <Modal.Header closeButton>
                     <Modal.Title>
                        {!formik.values.id ? (
                           <FormattedMessage
                              id="dialog.edit-inventory.title.new"
                              defaultMessage="Neues Set zum Inventar hinzufügen"
                           />
                        ) : (
                           <FormattedMessage
                              id="dialog.edit-inventory.title.edit"
                              defaultMessage="Set im Inventar bearbeiten"
                           />
                        )}
                     </Modal.Title>
                  </Modal.Header>

                  <Modal.Body>
                     <InventoryForm formik={formik} legoSet={legoSet} />
                  </Modal.Body>

                  <Modal.Footer>
                     <ButtonPanel>
                        {!formik.values.id && (
                           <FormCheck>
                              <FormCheck.Input
                                 id="create-another-entry"
                                 type="checkbox"
                                 name="enabled"
                                 checked={createAnotherEntry}
                                 onChange={(e: any) => setCreateAnotherEntry(e.target.checked)}
                              />
                              <FormCheck.Label htmlFor="create-another-entry">
                                 <FormattedMessage
                                    id="dialog.edit-inventory.create-another-entry"
                                    defaultMessage="Weiteren Eintrag erstellen"
                                 />
                              </FormCheck.Label>
                           </FormCheck>
                        )}
                        <ResponsiveButton
                           variant="secondary"
                           onClick={() => {
                              setCreateAnotherEntry(false);
                              props.onClose();
                           }}
                        >
                           <FormattedMessage id="button.close" defaultMessage="Schließen" />
                        </ResponsiveButton>
                        <SubmitButton formik={formik}>
                           <FormattedMessage id="button.save" defaultMessage="Speichern" />
                        </SubmitButton>
                     </ButtonPanel>
                  </Modal.Footer>
               </Form>
            )}
         </Formik>
      </Modal>
   );
};
