import React from 'react';
import { SkeletonPlaceholder } from '../atoms';

interface Props {
   /**
    * If `true` a skeleton loader is shown, otherwise the content.
    */
   isLoading?: boolean;
   /**
    * The width of the skeleton loader (default: 100px).
    */
   width?: number | string;

   /**
    * The height of the skeleton loader (default: normal).
    */
   size?: 'h1' | 'normal' | 'small';
   /**
    * The real content.
    */
   children?: React.ReactNode;
}

/**
 * If {@link Props.isLoading} is `true` a skeleton loader is shown, otherwise the content.
 */
export const ContentWithPlaceholder = (props: Props) => {
   const getHeight = () => {
      switch (props.size) {
         case 'h1':
            return '3.5rem';
         case 'small':
            return '1rem';
         case 'normal':
         default:
            return '1.5rem';
      }
   };

   if (props.isLoading === true) {
      return <SkeletonPlaceholder width={props.width ?? '6rem'} height={getHeight()} />;
   }

   // eslint-disable-next-line react/jsx-no-useless-fragment
   return <>{props.children}</>;
};
