import { configureStore } from '@reduxjs/toolkit';
import appReducer from './appSlice';
import dataSlice from './dataSlice';
import pagesSlice from './pagesSlice';

const store = configureStore({
   reducer: {
      app: appReducer,
      pages: pagesSlice,
      data: dataSlice,
   },
   devTools: process.env.NODE_ENV === 'development',
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
