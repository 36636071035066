import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { FormattedMessage } from 'react-intl';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { Button } from 'react-bootstrap';
import CreatableSelect from 'react-select/creatable';
import { ActionMeta, MultiValue, OnChangeValue, StylesConfig } from 'react-select';
import orderBy from 'lodash/orderBy';
import { useAppDispatch, useCache, useMemorizedIntl, useSession } from '../../../hooks';
import { Label, SetHasLabelByUser } from '../../../types/api';
import { SetHasLabelByUserModel } from '../../../models/SetHasLabelByUserModel';
import { LegoSetWithStats } from '../../../types/api/LegoSetWithStats';
import { getSelectStyles, notEmpty, pageLinks } from '../../../utils';
import { ButtonPanel, ResponsiveButton } from '../../../components/molecules';
import { LabelModel } from '../../../models/LabelModel';
import { removeLabel, upsertLabel } from '../../../redux/dataSlice';

interface Props {
   legoSet?: LegoSetWithStats;
}

export const Tags = ({ legoSet }: Props) => {
   const dispatch = useAppDispatch();
   const { sessionUser } = useSession();
   const { labels } = useCache();
   const [isLoading, setLoading] = useState(true);
   const [labelsByUser, setLabelsByUser] = useState<SetHasLabelByUser[]>([]);
   const [isEditMode, setEditMode] = useState(false);

   const loadLabelsAssignedByUser = useCallback(async () => {
      if (!legoSet) return;

      setLabelsByUser(
         await SetHasLabelByUserModel.list({
            set_id: legoSet.id,
            user_id: sessionUser?.id ?? 0,
         })
      );
   }, [legoSet, sessionUser?.id]);

   useEffect(() => {
      (async () => {
         setLoading(true);
         await loadLabelsAssignedByUser();
         setLoading(false);
      })();
   }, [loadLabelsAssignedByUser]);

   const handleSaveTags = useCallback(
      async (values: MultiValue<LabelOption>) => {
         if (!legoSet) return;

         try {
            /**
             * Anlage und Zuweisung von neu angelegten Labels
             */
            // eslint-disable-next-line no-underscore-dangle
            const newCreatedLabels = values.filter(v => v.__isNew__);
            const newLabels: (Label | null)[] = [];
            for (let i = 0; i < newCreatedLabels.length; i += 1) {
               const label = newCreatedLabels[i];

               const insertedLabel = await LabelModel.insert({
                  name: label.label,
               });
               await SetHasLabelByUserModel.insert({
                  user_id: sessionUser?.id,
                  set_id: legoSet?.id,
                  label_id: insertedLabel.id,
               });

               // Notwendig, da sich die Counter bzgl. Set-Zuweisungen geändert haben
               newLabels.push(await LabelModel.get(insertedLabel.id));
            }

            /**
             * Zuweisung von bestehenden, neu ausgewählten Labels
             */
            const newAssignedExistingLabels = values
               // eslint-disable-next-line no-underscore-dangle
               .filter(v => !v.isFixed && !v.__isNew__)
               .filter(v => labelsByUser.every(l => l.label_id !== v.value));
            for (let i = 0; i < newAssignedExistingLabels.length; i += 1) {
               const label = newAssignedExistingLabels[i];
               if (typeof label.value === 'number') {
                  await SetHasLabelByUserModel.insert({
                     user_id: sessionUser?.id,
                     set_id: legoSet?.id,
                     label_id: label.value,
                  });
                  // Notwendig, da sich die Counter bzgl. Set-Zuweisungen geändert haben
                  newLabels.push(await LabelModel.get(label.value));
               }
            }

            /**
             * Entfernen von Label-Zuweisungen
             */
            const removedLabels = labelsByUser.filter(shl =>
               values.every(v => v.value !== shl.label_id)
            );
            for (let i = 0; i < removedLabels.length; i += 1) {
               const labelAssignment = removedLabels[i];
               await SetHasLabelByUserModel.delete(labelAssignment);
               // Notwendig, da sich die Counter bzgl. Set-Zuweisungen geändert haben. Wir müssen hier aber über
               //    `list()` gehen, da es sein kann, dass wir nach dem Entfernen das Label nicht mehr sehen, da
               //    wir kein Set dem Label mehr zugeordnet haben.
               const changedLabel = await LabelModel.list({ id: labelAssignment.label_id });
               if (changedLabel.length === 1) newLabels.push(changedLabel[0]);
               else dispatch(removeLabel({ id: labelAssignment.label_id }));
            }

            if (newLabels.length > 0) dispatch(upsertLabel(newLabels.filter(notEmpty)));

            await loadLabelsAssignedByUser();
         } finally {
            setEditMode(false);
         }
      },
      [dispatch, labelsByUser, legoSet, loadLabelsAssignedByUser, sessionUser?.id]
   );

   const handleCancel = useCallback(() => {
      setEditMode(false);
   }, []);

   return (
      <div className="tags">
         {isLoading && (
            <>
               <SkeletonTheme baseColor="#444" highlightColor="#222">
                  <section>
                     <Skeleton width="6rem" height="1.75rem" style={{ margin: 0 }} />
                  </section>
               </SkeletonTheme>
               <SkeletonTheme baseColor="#444" highlightColor="#222">
                  <section>
                     <Skeleton width="7.5rem" height="1.75rem" style={{ margin: 0 }} />
                  </section>
               </SkeletonTheme>
               <SkeletonTheme baseColor="#444" highlightColor="#222">
                  <section>
                     <Skeleton width="8rem" height="1.75rem" style={{ margin: 0 }} />
                  </section>
               </SkeletonTheme>
            </>
         )}
         {!isLoading && !isEditMode && (
            <>
               {legoSet?.labels.map(labelId => (
                  <LinkContainer
                     key={`setLabel_${labelId}`}
                     to={pageLinks.legoSetsByLabel(labels.find(l => l.id === labelId))}
                     className="no-underline"
                  >
                     <Button variant="secondary" size="sm">
                        {labels.find(l => l.id === labelId)?.name}
                     </Button>
                  </LinkContainer>
               ))}
               {labelsByUser.map(l => (
                  <LinkContainer
                     key={`setLabelByUser_${l.id}`}
                     to={pageLinks.legoSetsByLabel(labels.find(o => o.id === l.label_id))}
                     className="no-underline"
                  >
                     <Button variant="primary" size="sm">
                        {l.label_name}
                     </Button>
                  </LinkContainer>
               ))}
               <Button variant="outline-info" size="sm" onClick={() => setEditMode(true)}>
                  {labelsByUser.length === 0 ? (
                     <FormattedMessage id="button.add-labels" defaultMessage="Labels hinzufügen" />
                  ) : (
                     <FormattedMessage id="button.edit-labels" defaultMessage="Labels bearbeiten" />
                  )}
               </Button>
            </>
         )}
         {!isLoading && isEditMode && (
            <TagsMultiSelect
               setHasLabel={legoSet?.labels}
               setHasLabelByUser={labelsByUser}
               onSave={handleSaveTags}
               onCancel={handleCancel}
            />
         )}
      </div>
   );
};

const orderOptions = (values: readonly LabelOption[]) =>
   values.filter(v => v.isFixed).concat(values.filter(v => !v.isFixed));

interface LabelOption {
   value: number | string;
   label: string;
   isFixed?: boolean;
   __isNew__?: boolean;
}

interface TagsMultiSelectProps {
   setHasLabel?: number[];
   setHasLabelByUser: SetHasLabelByUser[];
   onSave: (values: MultiValue<LabelOption>) => Promise<void>;
   onCancel: () => void;
}

const TagsMultiSelect = ({
   setHasLabel = [],
   setHasLabelByUser,
   onSave,
   onCancel,
}: TagsMultiSelectProps) => {
   const intl = useMemorizedIntl();
   const { labels } = useCache();
   const [value, setValue] = useState<MultiValue<LabelOption>>([]);

   const labelOptions = useMemo(
      () =>
         orderBy(
            labels.map(
               l =>
                  ({
                     value: l.id,
                     label: l.name,
                     isFixed: setHasLabel.includes(l.id),
                  } as LabelOption)
            ),
            'label'
         ),
      [labels, setHasLabel]
   );

   useEffect(() => {
      setValue(
         orderOptions(
            labelOptions.filter(
               l => l.isFixed || setHasLabelByUser.some(shl => shl.label_id === l.value)
            )
         )
      );
   }, [labelOptions, setHasLabelByUser]);

   const handleChange = (
      newValues: OnChangeValue<LabelOption, true>,
      actionMeta: ActionMeta<LabelOption>
   ) => {
      let values = newValues;

      switch (actionMeta.action) {
         case 'remove-value':
         case 'pop-value':
            if (actionMeta.removedValue.isFixed) {
               return;
            }
            break;
         case 'clear':
            values = labelOptions.filter(v => v.isFixed);
            break;
         case 'create-option':
            values = values.map(v => ({ ...v, label: v.label.trim() }));
            break;
         default:
            break;
      }

      values = orderOptions(values);
      setValue(values);
   };

   const selectInputStyles = useMemo(
      () =>
         ({
            ...getSelectStyles(),
            container: base => ({ ...base, width: '100%' }),
            multiValue: (base, state) =>
               state.data.isFixed ? { ...base, backgroundColor: 'gray' } : base,
            multiValueLabel: (base, state) =>
               state.data.isFixed
                  ? { ...base, fontWeight: 'bold', color: 'white', paddingRight: 6 }
                  : base,
            multiValueRemove: (base, state) =>
               state.data.isFixed ? { ...base, display: 'none' } : base,
         } as StylesConfig<LabelOption, true>),
      []
   );

   return (
      <div className="flex-fill d-flex flex-column flex-md-row align-items-stretch align-items-md-center gap-2">
         <CreatableSelect
            value={value}
            options={labelOptions}
            isClearable={value.some(v => !v.isFixed)}
            onChange={handleChange}
            styles={selectInputStyles}
            placeholder={intl.formatMessage({
               id: 'dialog.add-label.input-placeholder',
               defaultMessage: 'Mit Eingabe beginnen...',
            })}
            formatCreateLabel={v => (
               <FormattedMessage
                  id="react-select.create-option"
                  defaultMessage='Erstelle "{value}"'
                  values={{ value: v.trim() }}
               />
            )}
            isMulti
            closeMenuOnSelect={false}
         />
         <ButtonPanel className="flex-fill">
            <ResponsiveButton variant="secondary" onClick={onCancel}>
               <FormattedMessage id="button.cancel" defaultMessage="Abbrechen" />
            </ResponsiveButton>
            <ResponsiveButton variant="primary" onClick={() => onSave(value)}>
               <FormattedMessage id="button.save" defaultMessage="Speichern" />
            </ResponsiveButton>
         </ButtonPanel>
      </div>
   );
};
