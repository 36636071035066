import { BaseModel } from './BaseModel';
import { User } from '../types/api';
import ApiService from '../services/ApiService';
import { IApiResponse } from '../types/IApiResponse';
import { UserNotifications } from '../types/UserNotifications';

class UserModelClass extends BaseModel<User> {
   public getName(): string {
      return 'user';
   }

   public async authenticate(token?: string): Promise<User> {
      const response = await ApiService.http.post<IApiResponse<User>>('auth', { token: token });
      return response.data.data[0];
   }

   public async logout(): Promise<void> {
      await ApiService.http.get<IApiResponse<void>>('auth/logout');
   }

   public async nameIsUnique(name: string): Promise<boolean> {
      try {
         const response = await ApiService.http.get<IApiResponse<boolean>>(
            `data/${this.getName()}/nicknameIsUnique?name=${name}`
         );
         return response.data.data[0];
      } catch (error) {
         ApiService.handleError(error);
         return Promise.reject(error);
      }
   }

   public async getNotifications() {
      try {
         const response = await ApiService.http.get<IApiResponse<UserNotifications>>(
            'notifications'
         );
         return response.data.data[0];
      } catch (error) {
         ApiService.handleError(error);
         return Promise.reject(error);
      }
   }
}
export const UserModel = new UserModelClass();
