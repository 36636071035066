import { Col, Row } from 'react-bootstrap';
import orderBy from 'lodash/orderBy';
import React, { useMemo } from 'react';
import flatten from 'lodash/flatten';
import { Link, useLocation } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { FormattedMessage } from 'react-intl';
import { notEmpty, pageLinks } from '../../../utils';
import { LegoSetCard } from '../LegoSetCard';
import { useAppSelector } from '../../../hooks';
import { defaultOrderBy, LocationState, SearchAndFilterBar } from './SearchAndFilterBar';
import { InventoryType, LegoSet } from '../../../types/api';

import './LegoSetsGridList.scss';

interface Props {
   inventoryType?: InventoryType;
   hideOrdering?: boolean;
   lockedCategoryId?: number;
   onRefresh?: () => Promise<void>;
}

export const LegoSetsGridList = (props: Props) => {
   const location = useLocation();
   const { filter, sortBy, groupByLegoSet } = (location.state as LocationState) ?? {
      filter: {},
      sortBy: defaultOrderBy,
      groupByLegoSet: true,
   };
   const { entities: legoSets, loading } = useAppSelector(s => s.pages.inventory);

   const inventory = useMemo(() => {
      if (!legoSets || groupByLegoSet) return [];

      // Wandelt LegoSets mit 1-n Inventories zu n Inventories um
      return flatten(legoSets.map(s => s.inventory)).filter(notEmpty);
   }, [groupByLegoSet, legoSets]);

   const filterLegoSets = (set: LegoSet) =>
      (!filter?.text ||
         set.number.toLowerCase().indexOf(filter?.text.toLowerCase()) >= 0 ||
         set.name.toLowerCase().indexOf(filter?.text.toLowerCase()) >= 0) &&
      (!filter?.categoryId || set.category_id === filter?.categoryId) &&
      (!filter?.legoStatus ||
         set.status_by_lego?.toLowerCase() === filter?.legoStatus.toLowerCase());

   const getSortByProperty = (key: string) => {
      switch (key) {
         case 'name':
         case 'number':
            return groupByLegoSet ? `${key}` : `legoSet.${key}`;
         case 'profit':
         case 'profitPercentage':
            return groupByLegoSet
               ? `statistics.${props.inventoryType}.${key}`
               : `statistics.${key}`;
         case 'date':
            return groupByLegoSet ? `statistics.${props.inventoryType}.date` : 'date';
         default:
            return key;
      }
   };

   return (
      <>
         <SearchAndFilterBar
            inventoryType={props.inventoryType}
            hideOrdering={props.hideOrdering}
            lockedCategoryId={props.lockedCategoryId}
            onRefresh={props.onRefresh}
         />
         {loading && <ListLoader showAsGrid={groupByLegoSet} inventoryType={props.inventoryType} />}
         {!loading && (legoSets?.length ?? 0) === 0 && (
            <EmptyListMessage inventoryType={props.inventoryType} />
         )}
         {!loading && (legoSets?.length ?? 0) > 0 && (
            <Row>
               {groupByLegoSet
                  ? orderBy(
                       legoSets,
                       getSortByProperty(sortBy.property),
                       sortBy.asc ? 'asc' : 'desc'
                    )
                       .filter(filterLegoSets)
                       .map(s => (
                          <Col
                             key={s.id}
                             xs={12}
                             sm={isMobile ? 12 : 6}
                             lg={isMobile ? 12 : 4}
                             xl={isMobile ? 12 : 3}
                          >
                             <LegoSetCard displayContext={props.inventoryType} legoSet={s} />
                          </Col>
                       ))
                  : orderBy(
                       inventory,
                       getSortByProperty(sortBy.property),
                       sortBy.asc ? 'asc' : 'desc'
                    )
                       .filter(inv => filterLegoSets(inv.legoSet))
                       .map(i => (
                          <Col key={i.id} xs={12}>
                             <LegoSetCard inventory={i} displayContext="inventory" />
                          </Col>
                       ))}
            </Row>
         )}
      </>
   );
};

interface ListLoaderProps extends Pick<Props, 'inventoryType'> {
   showAsGrid?: boolean;
}
const ListLoader = ({ showAsGrid = false, inventoryType }: ListLoaderProps) => {
   if (showAsGrid)
      return (
         <Row>
            {[...Array(8).keys()].map(i => (
               <Col
                  key={i}
                  xs={12}
                  sm={isMobile ? 12 : 6}
                  lg={isMobile ? 12 : 4}
                  xl={isMobile ? 12 : 3}
               >
                  <LegoSetCard displayContext={inventoryType} />
               </Col>
            ))}
         </Row>
      );

   return (
      <>
         {[...Array(8).keys()].map(i => (
            <LegoSetCard key={i} displayContext={inventoryType} />
         ))}
      </>
   );
};

const EmptyListMessage = ({ inventoryType }: Pick<Props, 'inventoryType'>) => {
   if (inventoryType === 'wanted') {
      return (
         <Row className="mt-5">
            <Col xs={12} className="mb-4 h2 text-center">
               <FormattedMessage
                  id="inventory.wanted.empty.headline"
                  defaultMessage="Du hast noch keine Lego-Sets auf der Wunschliste!"
               />
            </Col>
            <Col xs={12} className="mb-2 text-center">
               <FormattedMessage
                  id="inventory.wanted.empty.text1"
                  defaultMessage="Öffne ein Lego-Set um dieses auf deine Wunschliste zu setzen. Du kannst {searchLink} oder über {categoriesLink} alle LegoSets auflisten lassen."
                  values={{
                     searchLink: (
                        <Link to="/search">
                           <FormattedMessage
                              id="inventory.wanted.empty.text1.search-link"
                              defaultMessage="nach einem speziellen Lego-Sets suchen"
                           />
                        </Link>
                     ),
                     categoriesLink: (
                        <Link to="/categories">
                           <FormattedMessage
                              id="inventory.wanted.empty.text1.categories-link"
                              defaultMessage="die Kategorien"
                           />
                        </Link>
                     ),
                  }}
               />
            </Col>
            <Col xs={12} className=" text-center">
               <FormattedMessage
                  id="inventory.wanted.empty.text2"
                  defaultMessage="Es sind nicht automatisch alle Lego-Sets verfügbar. Solltest du Eines nicht finden, so lege es an."
               />
            </Col>
         </Row>
      );
   }
   if (inventoryType === 'bought') {
      return (
         <Row className="mt-5">
            <Col xs={12} className="mb-4 h2 text-center">
               <FormattedMessage
                  id="inventory.bought.empty.headline"
                  defaultMessage="Du hast noch keine Lego-Sets im Inventar!"
               />
            </Col>
            <Col xs={12} className="mb-2 text-center">
               <FormattedMessage
                  id="inventory.bought.empty.text1"
                  defaultMessage="Öffne ein Lego-Set um dieses deinem Inventar hinzuzufügen. Du kannst {searchLink} oder über {categoriesLink} alle LegoSets auflisten lassen."
                  values={{
                     searchLink: (
                        <Link to="/search">
                           <FormattedMessage
                              id="inventory.bought.empty.text1.search-link"
                              defaultMessage="nach einem speziellen Lego-Sets suchen"
                           />
                        </Link>
                     ),
                     categoriesLink: (
                        <Link to="/categories">
                           <FormattedMessage
                              id="inventory.bought.empty.text1.categories-link"
                              defaultMessage="die Kategorien"
                           />
                        </Link>
                     ),
                  }}
               />
            </Col>
            <Col xs={12} className=" text-center">
               <FormattedMessage
                  id="inventory.bought.empty.text2"
                  defaultMessage="Es sind nicht automatisch alle Lego-Sets verfügbar. Solltest du Eines nicht finden, so lege es an."
               />
            </Col>
         </Row>
      );
   }
   if (inventoryType === 'sold') {
      return (
         <Row className="mt-5">
            <Col xs={12} className="mb-4 h2 text-center">
               <FormattedMessage
                  id="inventory.sold.empty.headline"
                  defaultMessage="Du hast noch keine Lego-Sets verkauft!"
               />
            </Col>
            <Col xs={12} className="mb-2 text-center">
               <FormattedMessage
                  id="inventory.sold.empty.text1"
                  defaultMessage="Öffne ein Lego-Set aus {inventoryBoughtLink}, um es als Verkauft zu markieren."
                  values={{
                     inventoryBoughtLink: (
                        <Link to={pageLinks.inventory('bought')}>
                           <FormattedMessage
                              id="inventory.sold.empty.text1.inventory-bought-link"
                              defaultMessage="deinem Inventar"
                           />
                        </Link>
                     ),
                  }}
               />
            </Col>
         </Row>
      );
   }

   return null;
};
