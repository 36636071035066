import { Button, Container, Dropdown } from 'react-bootstrap';
import Icon from '@mdi/react';
import { mdiCog, mdiMenuDown, mdiRefresh } from '@mdi/js';
import React, { useCallback, useEffect, useState } from 'react';
import orderBy from 'lodash/orderBy';
import { FormattedMessage } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import { LegoSetCard } from '../../../components/organisms/LegoSetCard';
import { LinkButton } from '../../../components/molecules';
import { SortingDefinition } from '../../../components/querybuilder/SortingBuilder';
import { Colors } from '../../../components/Colors';
import { InventoryFilter } from '../../../types/api';
import { InventoryFilterModel } from '../../../models/InventoryFilterModel';
import { useAppDispatch, useMemorizedIntl, useSession } from '../../../hooks';
import { getInventoryByFilter } from '../../../utils/ModelUtils';
import { InventoryWithStats } from '../../../types/api/InventoryWithStats';
import { PageTitle } from '../../../components/organisms/PageTitle';
import { setActiveTab } from '../../../redux/appSlice';

interface LocationState {
   selectedFilterId: number | null;
   inventoryList: InventoryWithStats[] | null;
}

export const MyFiltersPage = () => {
   const intl = useMemorizedIntl();
   const navigate = useNavigate();
   const location = useLocation();
   const dispatch = useAppDispatch();
   const { sessionUser } = useSession();
   const locationState = (location.state as LocationState) ?? {
      selectedFilterId: null,
      inventoryList: null,
   };
   const [filters, setFilters] = useState<InventoryFilter[]>([]);
   const [isLoading, setLoading] = useState(false);

   useEffect(() => {
      dispatch(setActiveTab('InventorySearch'));
   }, [dispatch]);

   useEffect(() => {
      (async () => {
         setFilters(await InventoryFilterModel.list({ user_id: sessionUser?.id ?? 0 }));
      })();
   }, [sessionUser?.id]);

   const loadInventoryBasedOnFilter = useCallback(
      async (filterId: number | null, force = false) => {
         if (!filterId || (!force && locationState.selectedFilterId === filterId)) return;

         setLoading(true);
         navigate(location.pathname, {
            replace: true,
            state: {
               selectedFilterId: filterId,
               inventoryList: await getInventoryByFilter(filterId),
            },
         });
         setLoading(false);
      },
      [location.pathname, locationState.selectedFilterId, navigate]
   );

   useEffect(() => {
      (async () => {
         await loadInventoryBasedOnFilter(locationState.selectedFilterId);
      })();
   }, [loadInventoryBasedOnFilter, locationState.selectedFilterId]);

   const setFilter = async (filter: InventoryFilter) => {
      if (filter.id === locationState.selectedFilterId) return;

      await loadInventoryBasedOnFilter(filter.id);
   };

   const getOrderColumns = useCallback((): string[] => {
      try {
         const filter = filters.find(f => f.id === locationState.selectedFilterId);
         const sortingDef: SortingDefinition[] = filter?.order_condition
            ? JSON.parse(filter.order_condition)
            : [];
         return sortingDef.map(s => {
            switch (s.field) {
               /* Fields from LegoSet */
               case 'set_number':
                  return 'legoSet.number';
               case 'set_parts':
                  return 'legoSet.parts';
               case 'category_id':
                  return 'legoSet.category_id';
               case 'set_price':
                  return 'legoSet.price';
               case 'set_retired':
                  return 'legoSet.retired';
               case 'set_publish_date':
                  return 'legoSet.publish_date';
               case 'set_weight':
                  return 'legoSet.weight';
               /* Statistics */
               case 'avg_market_value':
                  return 'statistics.avgMarketValue';
               case 'avg_profit':
                  return 'statistics.profit';
               case 'avg_profit_percentage':
                  return 'statistics.profitPercentage';
               case 'holding_time':
                  return 'statistics.holdingTimeInDays';
               default:
                  return s.field;
            }
         });
      } catch (error) {
         // eslint-disable-next-line no-console
         console.error(error);
         return [];
      }
   }, [filters, locationState.selectedFilterId]);

   const getOrderDirections = useCallback((): ('desc' | 'asc')[] => {
      try {
         const filter = filters.find(f => f.id === locationState.selectedFilterId);
         const sortingDef: SortingDefinition[] = filter?.order_condition
            ? JSON.parse(filter.order_condition)
            : [];
         return sortingDef.map(s => s.direction);
      } catch (error) {
         // eslint-disable-next-line no-console
         console.error(error);
         return [];
      }
   }, [filters, locationState.selectedFilterId]);

   return (
      <Container>
         <PageTitle
            title={intl.formatMessage({
               id: 'my-inventory-filters.title',
               defaultMessage: 'Inventar filtern',
            })}
            appTitle={intl.formatMessage({
               id: 'my-inventory-filters.app-title',
               defaultMessage: 'Inventar-Filter',
            })}
         />
         <div className="d-flex flex-row align-items-center mb-3">
            <Dropdown className="dropdown-filter">
               <Dropdown.Toggle variant="secondary">
                  <span>
                     {!locationState.selectedFilterId ? (
                        <FormattedMessage
                           id="my-inventory-filters.select-filter"
                           defaultMessage="Filter auswählen"
                        />
                     ) : (
                        filters.find(f => f.id === locationState.selectedFilterId)?.name
                     )}
                  </span>
                  <Icon path={mdiMenuDown} color={Colors.white} size={1} />
               </Dropdown.Toggle>

               <Dropdown.Menu className="scrollable w-100">
                  {filters.map(f => (
                     <Dropdown.Item
                        key={f.id}
                        onClick={() => setFilter(f)}
                        active={f.id === locationState.selectedFilterId}
                     >
                        {f.name}
                     </Dropdown.Item>
                  ))}
               </Dropdown.Menu>
            </Dropdown>
            <LinkButton
               to="/inventory/search/manage"
               variant="secondary"
               className="d-flex align-items-center justify-content-center ms-1"
            >
               <Icon path={mdiCog} color={Colors.white} size={1} />
            </LinkButton>
            <Button
               variant="primary"
               className="d-flex align-items-center justify-content-center ms-1"
               onClick={() => loadInventoryBasedOnFilter(locationState.selectedFilterId, true)}
               disabled={!locationState.selectedFilterId}
            >
               <Icon path={mdiRefresh} color={Colors.white} size={1} />
            </Button>
         </div>
         {isLoading &&
            [...new Array(4).keys()].map(i => <LegoSetCard key={i} displayContext="inventory" />)}
         {!isLoading &&
            (locationState.inventoryList?.length ?? 0) > 0 &&
            orderBy(locationState.inventoryList, getOrderColumns(), getOrderDirections()).map(i => (
               <LegoSetCard key={i.id} inventory={i} displayContext="inventory" />
            ))}
         {!isLoading &&
            locationState.selectedFilterId &&
            (locationState.inventoryList?.length ?? 0) === 0 && (
               <div className="d-flex justify-content-center fst-italic mt-2">
                  <FormattedMessage
                     id="my-inventory-filters.no-results"
                     defaultMessage="Mit dem Filter wurden keine Sets gefunden."
                  />
               </div>
            )}
      </Container>
   );
};
