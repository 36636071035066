import React from 'react';
import { DateInput } from './controls';
import { ControlProps, GenericControl } from './GenericControl';
import { FormUtils } from './FormUtils';

export interface Props<T> extends ControlProps<T> {
   className?: string;
}

export const DateControl = <T,>({ className, ...props }: Props<T>) => (
   <GenericControl {...props}>
      <DateInput
         className={className}
         value={props.formik.values[props.name] as unknown as string}
         onChange={dateAsISOString =>
            props.formik.setFieldValue(props.name as string, dateAsISOString)
         }
         onBlur={() => props.formik.handleBlur(props.name)}
         disabled={props.formik.isSubmitting}
         isInvalid={FormUtils.isInvalid(props.formik, props.name)}
      />
   </GenericControl>
);
