import React from 'react';
import { ContentLoader } from '../components/atoms';
import { PageNotFoundPage } from '../pages/PageNotFoundPage';
import { useAppSelector } from '../hooks';

interface RequireAuthProps {
   neededRoles?: string[];
   publicElement?: JSX.Element;
   children: JSX.Element;
}

export const RequireAuth = ({ children, neededRoles, publicElement }: RequireAuthProps) => {
   const loginState = useAppSelector(s => s.app.session.loginState);
   const sessionRoles = useAppSelector(s => s.app.session.roles);

   if (loginState === 'unknown') return <ContentLoader />;

   const shouldRenderPage = () => {
      // Nicht angemeldet? -> 404
      if (loginState !== 'loggedIn') return false;

      // Angemeldet und keine Rollen benötigt oder Rolle vorhanden -> OK
      if (!neededRoles || sessionRoles.some(r => neededRoles.includes(r))) return true;

      // sonst 404
      return false;
   };

   const getUnauthorizedComponent = () => publicElement ?? <PageNotFoundPage />;

   return shouldRenderPage() ? children : getUnauthorizedComponent();
};
