import { useAppSelector } from './useAppSelector';

export const useCache = () => {
   const { labels, categories } = useAppSelector(s => s.data);

   return {
      labels,
      categories,
   };
};
