import { useEffect, useMemo, useState } from 'react';
import { useSession } from './useSession';
import { useMemorizedIntl } from './useMemorizedIntl';
import { pageLinks } from '../utils';

export const useHomeRoute = () => {
   const intl = useMemorizedIntl();
   const { sessionUser } = useSession();
   const [homeRoutePath, setHomeRoutePath] = useState<string | null>(null);

   const homeScreenOptions = useMemo(
      () => [
         {
            label: intl.formatMessage({
               id: 'user.profile.settings.home_screen.options.my_wanted',
               defaultMessage: 'Meine Wunschliste',
            }),
            value: 'inventory_wanted',
            toPath: pageLinks.inventory('wanted'),
         },
         {
            label: intl.formatMessage({
               id: 'user.profile.settings.home_screen.options.my_inventory',
               defaultMessage: 'Mein Inventar',
            }),
            value: 'inventory_bought',
            toPath: pageLinks.inventory('bought'),
         },
         {
            label: intl.formatMessage({
               id: 'user.profile.settings.home_screen.options.my_sales',
               defaultMessage: 'Meine Verkäufe',
            }),
            value: 'inventory_sold',
            toPath: pageLinks.inventory('sold'),
         },
         {
            label: intl.formatMessage({
               id: 'user.profile.settings.home_screen.options.inventory_filter',
               defaultMessage: 'Inventar-Filter',
            }),
            value: 'inventory_filter',
            toPath: '/inventory/search',
         },
         {
            label: intl.formatMessage({
               id: 'user.profile.settings.home_screen.options.statistics',
               defaultMessage: 'Statistiken',
            }),
            value: 'statistics',
            toPath: '/stats',
         },
      ],
      [intl]
   );

   useEffect(() => {
      const foundEntry = homeScreenOptions.find(h => h.value === sessionUser?.home_screen);
      if (foundEntry) {
         setHomeRoutePath(foundEntry.toPath);
      } else {
         setHomeRoutePath(null);
      }
   }, [homeScreenOptions, sessionUser?.home_screen]);

   return { homeRoutePath, homeScreenOptions };
};
