import React, { useCallback, useEffect, useState } from 'react';
import { Accordion, Card, Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import groupBy from 'lodash/groupBy';
import { EditImageUploadDialog } from './EditImageUploadDialog';
import { UploadedImageCard } from '../organisms/UploadedImageCard';
import { ContentLoader } from '../atoms';
import { ButtonPanel, ResponsiveButton } from '../molecules';
import { ImageUpload } from '../../types/api';
import { ImageUploadModel } from '../../models/ImageUploadModel';
import { useSession } from '../../hooks';

interface Props {
   inventoryId: number;
   show: boolean;
   onClose: () => void;
}

export const ImageUploadsDialog = (props: Props) => {
   const { sessionUser } = useSession();
   const [images, setImages] = useState<ImageUpload[]>([]);
   const [isLoading, setLoading] = useState(true);
   const [uploadToEdit, setUploadToEdit] = useState<ImageUpload>();
   const [showEditDialog, setShowEditDialog] = useState(false);

   const loadImages = useCallback(async () => {
      setLoading(true);
      setImages(
         await ImageUploadModel.list({
            inventory_id: props.inventoryId,
            user_id: sessionUser?.id ?? 0,
         })
      );
      setLoading(false);
   }, [props.inventoryId, sessionUser]);

   useEffect(() => {
      (async () => {
         await loadImages();
      })();
   }, [loadImages]);

   const handleEditUpload = useCallback(async (imageUpload: ImageUpload) => {
      setUploadToEdit(imageUpload);
      setShowEditDialog(true);
   }, []);

   return (
      <>
         <Modal show={props.show && !showEditDialog} onHide={props.onClose} size="lg">
            <Modal.Header closeButton>
               <Modal.Title>
                  <FormattedMessage id="dialog.uploads.title" defaultMessage="Bilder" />
               </Modal.Title>
            </Modal.Header>

            <Modal.Body>
               {isLoading ? (
                  <ContentLoader />
               ) : (
                  <>
                     <ResponsiveButton
                        onClick={() => {
                           setUploadToEdit(undefined);
                           setShowEditDialog(true);
                        }}
                        style={{ marginLeft: 1, marginBottom: '0.25rem' }}
                     >
                        <FormattedMessage
                           id="button.add-image"
                           defaultMessage="Neues Bild hinzufügen"
                        />
                     </ResponsiveButton>
                     <Accordion flush>
                        {Object.entries(groupBy(images, 'section')).map(([name, image]) => (
                           <Accordion.Item as={Card} key={name} eventKey={`section_${name}`}>
                              <Accordion.Button as={Card.Header}>
                                 {name.length === 0 ? (
                                    <FormattedMessage
                                       id="dialog.uploads.section.none"
                                       defaultMessage="Ohne"
                                    />
                                 ) : (
                                    name
                                 )}
                              </Accordion.Button>
                              <Accordion.Body as={Card.Body} eventKey={`section_${name}`}>
                                 <div
                                    style={{
                                       display: 'flex',
                                       flexWrap: 'wrap',
                                       justifyContent: 'space-evenly',
                                    }}
                                 >
                                    {image.map(i => (
                                       <UploadedImageCard
                                          key={i.id}
                                          image={i}
                                          onEdit={handleEditUpload}
                                       />
                                    ))}
                                 </div>
                              </Accordion.Body>
                           </Accordion.Item>
                        ))}
                     </Accordion>
                  </>
               )}
            </Modal.Body>

            <Modal.Footer>
               <ButtonPanel>
                  <ResponsiveButton variant="secondary" onClick={props.onClose}>
                     <FormattedMessage id="button.close" defaultMessage="Schließen" />
                  </ResponsiveButton>
               </ButtonPanel>
            </Modal.Footer>
         </Modal>
         <EditImageUploadDialog
            showDialog={showEditDialog}
            inventoryId={props.inventoryId}
            imageToEdit={uploadToEdit}
            onDialogClose={() => setShowEditDialog(false)}
            onSubmit={async () => {
               await loadImages();
               setShowEditDialog(false);
            }}
            onDelete={async () => {
               await loadImages();
               setShowEditDialog(false);
            }}
         />
      </>
   );
};
