import { useNavigate } from 'react-router-dom';
import React, { useCallback, useEffect, useState } from 'react';
import { Formik, FormikErrors } from 'formik';
import { Col, Container, Form, Row, Tab, Table, Tabs } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { AxiosError } from 'axios';
import { ColoredValue, ContentLoader } from '../../../../components/atoms';
import { showBackButton } from '../../../../redux/appSlice';
import { ButtonPanel, ResponsiveButton } from '../../../../components/molecules';
import { useAppDispatch, useIdFromParams, useValidationErrors } from '../../../../hooks';
import { Inventory, User } from '../../../../types/api';
import { UserModel } from '../../../../models/UserModel';
import { InventoryModel } from '../../../../models/InventoryModel';
import { formatDate } from '../../../../utils';
import { CheckControl, SubmitButton, TextControl } from '../../../../components/forms';
import { DropdownControl } from '../../../../components/forms/DropdownControl';
import { PageTitle } from '../../../../components/organisms/PageTitle';

export const UserEditAdminPage = () => {
   const userId = useIdFromParams();
   const navigate = useNavigate();
   const dispatch = useAppDispatch();
   const validationErrors = useValidationErrors();
   const [user, setUser] = useState<User | null>(null);
   const [inventory, setInventory] = useState<Inventory[]>([]);

   useEffect(() => {
      (async () => {
         dispatch(showBackButton(true));

         try {
            const u = await UserModel.get(userId);
            setUser(u);
            setInventory(await InventoryModel.list({ user_id: userId }));
         } catch (err) {
            const error = err as AxiosError;
            if (error.response?.status === 404) {
               navigate('/404', { replace: true });
            }
         }
      })();
   }, [dispatch, navigate, userId]);

   const handleSubmitForm = useCallback(
      async (values: User) => {
         await UserModel.update(values);

         navigate('/admin/users');
      },
      [navigate]
   );

   if (!user) return <ContentLoader />;

   return (
      <Container>
         <PageTitle
            title={`Benutzer - ${user?.name}`}
            appTitle={user.name}
            subtitle="Administration > Benutzer"
            hidden
         />
         <Row>
            <Col>
               <Tabs>
                  <Tab eventKey="data" title="Stammdaten" className="ps-0 pe-0">
                     <Formik
                        onSubmit={handleSubmitForm}
                        initialValues={user}
                        enableReinitialize
                        validate={values => {
                           const errors: FormikErrors<User> = {};

                           if (!values.name) errors.name = validationErrors.required;

                           return errors;
                        }}
                     >
                        {formik => (
                           <Form
                              noValidate
                              onSubmit={e => {
                                 e.preventDefault();
                                 formik.handleSubmit();
                              }}
                           >
                              <Container>
                                 <Row>
                                    <Col xl="6" xs="12">
                                       <fieldset>
                                          <legend>Stammdaten</legend>
                                          <TextControl
                                             formik={formik}
                                             model="user"
                                             name="public_name"
                                          />
                                          <DropdownControl
                                             formik={formik}
                                             model="user"
                                             name="type"
                                             options={[
                                                {
                                                   label: 'Normaler Benutzer',
                                                   value: 'user',
                                                },
                                                {
                                                   label: 'Administrator',
                                                   value: 'admin',
                                                },
                                             ]}
                                          />
                                          <TextControl
                                             formik={formik}
                                             model="user"
                                             name="display_language"
                                          />
                                          <TextControl
                                             formik={formik}
                                             model="user"
                                             name="last_logon"
                                             disabled
                                          />

                                          <CheckControl
                                             formik={formik}
                                             model="user"
                                             name="enabled"
                                          />
                                          <CheckControl
                                             formik={formik}
                                             model="user"
                                             name="notification_wanted_enabled"
                                          />
                                          <CheckControl
                                             formik={formik}
                                             model="user"
                                             name="blog_updates_enabled"
                                          />
                                          <CheckControl
                                             formik={formik}
                                             model="user"
                                             name="registration_completed"
                                          />
                                       </fieldset>
                                    </Col>
                                    <Col xl="6" xs="12">
                                       <fieldset>
                                          <legend>Google-Account</legend>
                                          <TextControl
                                             formik={formik}
                                             model="user"
                                             name="sub"
                                             disabled
                                          />

                                          <TextControl
                                             formik={formik}
                                             model="user"
                                             name="name"
                                             disabled
                                          />

                                          <TextControl
                                             formik={formik}
                                             model="user"
                                             name="email"
                                             disabled
                                          />

                                          <TextControl
                                             formik={formik}
                                             model="user"
                                             name="locale"
                                             disabled
                                          />

                                          <TextControl
                                             formik={formik}
                                             model="user"
                                             name="picture_url"
                                             disabled
                                          />
                                       </fieldset>
                                    </Col>
                                 </Row>
                              </Container>
                              <ButtonPanel isFormFooter>
                                 <ResponsiveButton variant="secondary" onClick={() => navigate(-1)}>
                                    <FormattedMessage
                                       id="button.cancel"
                                       defaultMessage="Abbrechen"
                                    />
                                 </ResponsiveButton>
                                 <SubmitButton formik={formik}>
                                    <FormattedMessage id="button.save" defaultMessage="Speichern" />
                                 </SubmitButton>
                              </ButtonPanel>
                           </Form>
                        )}
                     </Formik>
                  </Tab>
                  <Tab eventKey="inventory" title="Inventar" className="ps-0 pe-0">
                     <Table striped className="flat">
                        <thead>
                           <tr>
                              <th>LegoSet</th>
                              <th className="text-center">Kaufdatum</th>
                              <th className="text-center">Kaufpreis</th>
                              <th className="text-center">Type</th>
                              <th className="text-center">Verkaufsdatum</th>
                              <th className="text-center">Verkaufspreis</th>
                           </tr>
                        </thead>
                        <tbody>
                           {inventory.map(i => (
                              <tr key={i.id}>
                                 <td>{i.set_name}</td>
                                 <td className="text-center">{formatDate(i.date, 'L')}</td>
                                 <td className="text-center">
                                    <ColoredValue type="currency" value={i.price} />
                                 </td>
                                 <td className="text-center">{i.type}</td>
                                 <td className="text-center">
                                    {(i.sold_price ?? 0) === 0 ? '' : formatDate(i.sold_date, 'L')}
                                 </td>
                                 <td className="text-center">
                                    {(i.sold_price ?? 0) > 0 && (
                                       <ColoredValue type="currency" value={i.sold_price} />
                                    )}
                                 </td>
                              </tr>
                           ))}
                        </tbody>
                     </Table>
                  </Tab>
               </Tabs>
            </Col>
         </Row>
      </Container>
   );
};
