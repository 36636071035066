import React, { useMemo } from 'react';
import { Chart } from 'react-google-charts';
import merge from 'lodash/merge';
import { ChartLoader } from '../atoms';
import ApiService from '../../services/ApiService';
import { useChartData } from '../../hooks/useChartData';
import { useAppSelector, useMemorizedIntl } from '../../hooks';
import { ComboChartTheme } from '../../types/ChartDataType';

interface Props {
   /**
    * The url part to load the data.
    */
   url: string;
   /**
    * The number format to use for the values (e.g. #.##).
    */
   numberFormat?: string;
   /**
    * The unit to display behind the value (e.g. €).
    */
   unit?: string;
   /**
    * Width of the chart. (e.g. 100%, 20rem)
    */
   width?: string;
   /**
    * Height of the chart. (e.g. 100%, 20rem)
    */
   height?: string;
   /**
    * The theme to display the chart.
    *
    * `default`: A semi large chart.
    * `mini`: Only shows the graph without any legend/axis.
    */
   theme?: ComboChartTheme;
   /**
    * Additional options. These options override the already provided options and theme options.
    */
   options?: {};
}

/**
 * Creates a combo chart (multiple lines).
 */
export const ComboChart = ({
   url,
   numberFormat,
   unit,
   width = '100%',
   height = '20rem',
   theme,
   options = {},
}: Props) => {
   const intl = useMemorizedIntl();
   const stateLanguage = useAppSelector(s => s.app.language);
   const columnNames = useMemo(
      () => [
         intl.formatMessage({
            id: 'chart.combo-generic.legend.date',
            defaultMessage: 'Datum',
         }),
         intl.formatMessage({
            id: 'chart.combo-generic.legend.value',
            defaultMessage: 'Wert',
         }),
      ],
      [intl]
   );
   const { isLoading, rows, columns, comboChartOptions } = useChartData({
      url: `${ApiService.apiEndpoint}/${url}`,
      columnNames: columnNames,
      unit: unit,
      numberFormat: numberFormat,
      theme: theme,
   });
   const chartOptions = useMemo(
      () => merge(comboChartOptions, options),
      [comboChartOptions, options]
   );

   const wrapperStyle = useMemo(
      () => ({
         width,
         height,
      }),
      [width, height]
   );

   // TODO: Alle Charts ggf. zusammenziehen auf eine Implementierung und eine Schnittstelle an Daten
   return (
      <div className="d-flex" style={wrapperStyle}>
         {isLoading ? (
            <ChartLoader />
         ) : (
            <Chart
               width={width}
               height={height}
               chartType="ComboChart"
               loader={<ChartLoader />}
               data={[columns, ...rows]}
               legendToggle
               options={chartOptions}
               chartLanguage={stateLanguage}
            />
         )}
      </div>
   );
};
