import RQueryBuilder, { Controls, Field, RuleGroupType } from 'react-querybuilder';
import React, { useCallback, useMemo } from 'react';
import * as QBConfig from './Config';
import { NotToggle } from './NotToggle';
import { ValueEditor } from './ValueEditor';

import './index.scss';
import { useMemorizedIntl } from '../../hooks';

interface Props {
   fields: Field[];
   queryAsString?: string;
   onQueryChange: (query: RuleGroupType) => void;
}

export const QueryBuilder = ({ fields, queryAsString, onQueryChange }: Props) => {
   const intl = useMemorizedIntl();

   const controlElements: Partial<Controls> = useMemo(
      () => ({
         notToggle: NotToggle,
         valueEditor: ValueEditor,
      }),
      []
   );

   const convertJsonToQuery = useCallback((json: string | undefined) => {
      let query: RuleGroupType | undefined;

      if (json) query = JSON.parse(json);

      return query;
   }, []);

   return (
      <RQueryBuilder
         fields={fields}
         query={convertJsonToQuery(queryAsString)}
         onQueryChange={onQueryChange}
         translations={QBConfig.translations(intl)}
         showCombinatorsBetweenRules
         controlElements={controlElements}
         controlClassnames={QBConfig.controlClassnames}
         showNotToggle
      />
   );
};
