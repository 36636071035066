import { Container } from 'react-bootstrap';
import React, { useCallback, useEffect } from 'react';
import { Formik } from 'formik';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { showBackButton, updateSessionUser } from '../../redux/appSlice';
import { FormPartPublicName, useValidation } from '../../components/parts/FormPartPublicName';
import { useAppDispatch, useHomeRoute, useMemorizedIntl, useSession } from '../../hooks';
import { UserModel } from '../../models/UserModel';
import { User } from '../../types/api';
import { PageNotFoundPage } from '../PageNotFoundPage';
import { CheckControl, SubmitButton } from '../../components/forms';
import { PageTitle } from '../../components/organisms/PageTitle';
import { DropdownControl } from '../../components/forms/DropdownControl';

export const ProfilePage = () => {
   const intl = useMemorizedIntl();
   const dispatch = useAppDispatch();
   const { sessionUser } = useSession();
   const { homeScreenOptions } = useHomeRoute();
   const handleValidate = useValidation();

   useEffect(() => {
      dispatch(showBackButton(true));
   }, [dispatch]);

   const handleSubmitForm = useCallback(
      async (values: User) => {
         const user = await UserModel.update(values);
         dispatch(updateSessionUser(user));
         toast.success(
            intl.formatMessage({
               id: 'user.profile.success-toast.text',
               defaultMessage: 'Die Einstellungen wurden erfolgreich gespeichert.',
            })
         );
      },
      [dispatch, intl]
   );

   if (!sessionUser) return <PageNotFoundPage />;

   return (
      <Container>
         <PageTitle
            title={intl.formatMessage({
               id: 'user.profile.headline',
               defaultMessage: 'Profil-Einstellungen',
            })}
         />
         <Formik
            onSubmit={handleSubmitForm}
            initialValues={sessionUser}
            enableReinitialize
            validate={handleValidate}
         >
            {formik => (
               <form
                  noValidate
                  onSubmit={e => {
                     e.preventDefault();
                     formik.handleSubmit();
                  }}
               >
                  <h4>
                     <FormattedMessage
                        id="user.profile.settings.headline"
                        defaultMessage="Allgemeine Einstellungen"
                     />
                  </h4>
                  <div className="ps-2">
                     <FormPartPublicName formik={formik} />
                     <DropdownControl
                        formik={formik}
                        model="user"
                        name="home_screen"
                        type="compact"
                        isClearable
                        options={homeScreenOptions}
                     />
                  </div>

                  <h4 className="pt-4">
                     <FormattedMessage
                        id="user.profile.notifications.headline"
                        defaultMessage="Benachrichtigungen"
                     />
                  </h4>
                  <div className="ps-2">
                     <CheckControl
                        formik={formik}
                        model="user"
                        name="notification_wanted_enabled"
                        type="compact"
                     />
                     <CheckControl
                        formik={formik}
                        model="user"
                        name="blog_updates_enabled"
                        type="compact"
                     />
                  </div>
                  <br />
                  <SubmitButton formik={formik}>
                     <FormattedMessage
                        id="button.save-changes"
                        defaultMessage="Änderungen speichern"
                     />
                  </SubmitButton>
               </form>
            )}
         </Formik>
      </Container>
   );
};
