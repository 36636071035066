import React, { useEffect, useState } from 'react';
import { Col, Container, Form, ListGroup, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Icon from '@mdi/react';
import { mdiChevronRight } from '@mdi/js/commonjs/mdi';
import { ComboChart } from '../../components/organisms';
import { Colors } from '../../components/Colors';
import { getAdminStats } from '../../utils/ModelUtils';
import { PageTitle } from '../../components/organisms/PageTitle';
import { useAppDispatch } from '../../hooks';
import { showBackButton } from '../../redux/appSlice';
import { LOCALSTORAGE_SHOW_SAFE_AREAS } from '../../utils/constants';

const tableDefinition: { [key: string]: { title: string; to?: string } } = {
   user: {
      title: 'Benutzer',
      to: '/admin/users',
   },
   lego_set: {
      title: 'Lego-Sets',
      to: '/admin/legoSets',
   },
   category: {
      title: 'Kategorien',
      to: '/admin/categories',
   },
   label: {
      title: 'Labels',
      to: '/admin/labels',
   },
   inventory: {
      title: 'Inventar',
   },
   sold_set: {
      title: 'Verkaufte Sets',
   },
   image_upload: {
      title: 'Bilder',
   },
   set_import: {
      title: 'Importe',
      to: '/admin/imports',
   },
   lego_set_change_request: {
      title: 'Änderungsanträge',
      to: '/admin/legoSets/changeRequests',
   },
};

export const OverviewAdminPage = () => {
   const dispatch = useAppDispatch();
   const [stats, setStats] = useState<[string, number][]>([]);
   const [showSafeAreas, setShowSafeAreas] = useState(
      !!localStorage.getItem(LOCALSTORAGE_SHOW_SAFE_AREAS)
   );

   useEffect(() => {
      (async () => {
         dispatch(showBackButton(true));
         setStats(await getAdminStats());
      })();
   }, [dispatch]);

   return (
      <Container>
         <PageTitle title="Administration" />
         <ListGroup>
            {stats.map(([table, count]) => (
               <ListGroup.Item
                  key={table}
                  as={Link}
                  to={tableDefinition[table]?.to ?? '/admin'}
                  className="d-flex flex-row"
                  action={!!tableDefinition[table]?.to}
               >
                  <div className="flex-fill d-flex flex-column">
                     <h4 style={{ wordBreak: 'break-word' }}>{tableDefinition[table]?.title}</h4>
                     <small className="text-muted">
                        {count} {count === 1 ? 'Eintrag' : 'Einträge'}
                     </small>
                  </div>
                  <ComboChart
                     url={`admin/historyChart/${table}`}
                     theme="mini"
                     height="4rem"
                     width="10rem"
                     unit=""
                     options={{
                        colors: [Colors.primary],
                     }}
                  />
                  {tableDefinition[table]?.to ? (
                     <div className="d-flex align-items-center">
                        <Icon path={mdiChevronRight} color={Colors.link} size={1} />
                     </div>
                  ) : (
                     <div style={{ marginRight: '1.3125rem' }} />
                  )}
               </ListGroup.Item>
            ))}
         </ListGroup>
         <Row className="mt-4">
            <Col>
               <Form.Check
                  type="switch"
                  id="switch-safe-area-debug"
                  label="Show safe areas"
                  className="me-2 cursor-pointer d-inline-block"
                  color={Colors.success}
                  checked={showSafeAreas}
                  onChange={() => {
                     const newValue = !showSafeAreas;
                     setShowSafeAreas(newValue);
                     if (newValue) localStorage.setItem(LOCALSTORAGE_SHOW_SAFE_AREAS, '1');
                     else localStorage.removeItem(LOCALSTORAGE_SHOW_SAFE_AREAS);
                     window.location.reload();
                  }}
               />
            </Col>
         </Row>
      </Container>
   );
};
