import React, { useCallback, useEffect } from 'react';
import { Container, Form } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { FormPartPublicName, useValidation } from '../../components/parts/FormPartPublicName';
import { updateSessionUser } from '../../redux/appSlice';
import { useAppDispatch, useMemorizedIntl, useSession } from '../../hooks';
import { User } from '../../types/api';
import { PageNotFoundPage } from '../PageNotFoundPage';
import { UserModel } from '../../models/UserModel';
import { SubmitButton } from '../../components/forms';
import { PageTitle } from '../../components/organisms/PageTitle';

export const CompleteRegistrationPage = () => {
   const intl = useMemorizedIntl();
   const navigate = useNavigate();
   const dispatch = useAppDispatch();
   const { sessionUser } = useSession();
   const handleValidate = useValidation();

   useEffect(() => {
      // Wenn die Registrierung abgeschlossen ist, kann diese Seite nicht mehr aufgerufen werden
      if (sessionUser?.registration_completed === true) navigate('/', { replace: true });
   }, [sessionUser, navigate]);

   const handleSubmitForm = useCallback(
      async (values: User) => {
         const user = await UserModel.update(values);
         dispatch(updateSessionUser(user));
         navigate('/', { replace: true });
      },
      [dispatch, navigate]
   );

   if (!sessionUser) return <PageNotFoundPage />;

   return (
      <Container>
         <PageTitle
            title={intl.formatMessage({
               id: 'complete-registration.headline',
               defaultMessage: 'Registrierung abschließen',
            })}
         />
         <p>
            <FormattedMessage
               id="complete-registration.description"
               defaultMessage="Bevor du Brickfolio.de verwenden kannst, musst du noch ein paar Einstellungen vornehmen."
            />
         </p>
         <Formik
            onSubmit={handleSubmitForm}
            initialValues={sessionUser}
            validate={handleValidate}
            enableReinitialize
         >
            {formik => (
               <Form
                  noValidate
                  onSubmit={e => {
                     e.preventDefault();
                     formik.handleSubmit();
                  }}
               >
                  <FormPartPublicName formik={formik} />

                  <SubmitButton formik={formik}>
                     <FormattedMessage
                        id="button.complete-registration"
                        defaultMessage="Registrierung abschließen"
                     />
                  </SubmitButton>
               </Form>
            )}
         </Formik>
      </Container>
   );
};
