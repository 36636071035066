import { useNavigate } from 'react-router-dom';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Formik } from 'formik';
import {
   Badge,
   Container,
   Dropdown,
   Form,
   ListGroup,
   Modal,
   Tab,
   Table,
   Tabs,
} from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { AxiosError } from 'axios';
import { isMobile } from 'react-device-detect';
import { Variant } from 'react-bootstrap/esm/types';
import { toast } from 'react-toastify';
import { mdiArrowRightThin } from '@mdi/js';
import Icon from '@mdi/react';
import {
   ColoredValue,
   ContentLoader,
   InlineLoader,
   SkeletonPlaceholder,
} from '../../../../components/atoms';
import { Pagination } from '../../../../components/organisms/Pagination';
import { ConfirmDeleteDialog } from '../../../../components/dialogs/ConfirmDeleteDialog';
import { showBackButton } from '../../../../redux/appSlice';
import { ButtonPanel, Card, ResponsiveButton } from '../../../../components/molecules';
import { LegoSetForm, useValidationHandler } from '../../../../forms/LegoSetForm';
import { Inventory, LegoSet, LogMessage, User } from '../../../../types/api';
import { useAppDispatch, useIdFromParams } from '../../../../hooks';
import { LegoSetModel } from '../../../../models/LegoSetModel';
import { InventoryModel } from '../../../../models/InventoryModel';
import { MarketValueModel } from '../../../../models/MarketValueModel';
import { LogMessageModel } from '../../../../models/LogMessageModel';
import { formatDate } from '../../../../utils';
import { SubmitButton } from '../../../../components/forms';
import { PageTitle } from '../../../../components/organisms/PageTitle';
import { IApiResponse } from '../../../../types/IApiResponse';
import ApiService from '../../../../services/ApiService';
import { useLegoSetChangeRequest } from '../../../../hooks/useLegoSetChangeRequest';
import { UserModel } from '../../../../models/UserModel';

export const LegoSetEditAdminPage = () => {
   const legoSetId = useIdFromParams();
   const navigate = useNavigate();
   const dispatch = useAppDispatch();
   const handleValidate = useValidationHandler();
   const { createRequest } = useLegoSetChangeRequest();
   const [users, setUsers] = useState<User[]>([]);
   const [legoSet, setLegoSet] = useState<Partial<LegoSet> | null>();
   const [inventory, setInventory] = useState<Inventory[]>([]);
   const [isDeleting, setDeleting] = useState(false);
   const [showDeleteDialog, setShowDeleteDialog] = useState(false);
   const [isLoadingLegoSetData, setLoadingLegoSetData] = useState(false);

   useEffect(() => {
      (async () => {
         dispatch(showBackButton(true));

         try {
            setLegoSet(await LegoSetModel.get(legoSetId));
            setUsers(await UserModel.list());
         } catch (err) {
            const error = err as AxiosError;
            if (error.response?.status === 404) {
               navigate('/404', { replace: true });
            }
         }
      })();
   }, [legoSetId, dispatch, navigate]);

   const handleSubmitForm = useCallback(
      async (values: Partial<LegoSet>) => {
         if (values.id === 0) {
            await LegoSetModel.insert(values);
         } else {
            await LegoSetModel.update(values);
         }

         navigate(-1);
      },
      [navigate]
   );

   const handleDelete = useCallback(async () => {
      if (!legoSet || !legoSet.id) return;

      setDeleting(true);

      try {
         await LegoSetModel.delete(legoSet);
         setShowDeleteDialog(false);
         navigate(-1);
      } finally {
         setDeleting(false);
      }
   }, [legoSet, navigate]);

   const handleTabSelection = useCallback(
      async (tabKey: string | null) => {
         switch (tabKey) {
            case 'inventory':
               setInventory(await InventoryModel.list({ set_id: legoSet?.id ?? 0 }));
               break;
            case 'marketValues':
               break;
            default:
               break;
         }
      },
      [legoSet]
   );

   const createChangeRequest = async (source: 'brickset') => {
      try {
         setLoadingLegoSetData(true);

         if (!legoSet) return;

         const response = await ApiService.http.get<IApiResponse<LegoSet>>(
            `admin/legoSetFromExternalSource?id=${legoSet.id}&source=${source}`
         );
         const changedLegoSet = response.data.data[0];
         if (!changedLegoSet) return;

         const request = await createRequest(legoSet, changedLegoSet);
         if (!request) {
            toast.warning('Es wurden keine Änderungen festgestellt.');

            return;
         }

         navigate(`/admin/legoSets/changeRequests/${request.id}`);
      } catch (error) {
         ApiService.handleError(error);
         return await Promise.reject(error);
      } finally {
         setLoadingLegoSetData(false);
      }
   };

   if (!legoSet) return <ContentLoader />;

   // @ts-ignore
   return (
      <Container>
         <PageTitle
            title={`LegoSet - ${legoSet.number}`}
            appTitle={legoSet.name}
            subtitle="Administration > LegoSet"
            hidden
         />
         <Tabs onSelect={handleTabSelection}>
            <Tab eventKey="data" title="Stammdaten" className="p-0">
               <Dropdown className="mt-3">
                  <Dropdown.Toggle className="d-flex align-items-center gap-1">
                     <InlineLoader show={isLoadingLegoSetData} />
                     <span>Daten von extern laden</span>
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                     <Dropdown.Item onClick={() => createChangeRequest('brickset')}>
                        Brickset
                     </Dropdown.Item>
                     <Dropdown.Item onClick={() => createChangeRequest('brickset')}>
                        Bricklink
                     </Dropdown.Item>
                  </Dropdown.Menu>
               </Dropdown>
               <Formik
                  onSubmit={handleSubmitForm}
                  initialValues={legoSet}
                  enableReinitialize
                  validate={handleValidate}
               >
                  {formik => (
                     <Form
                        noValidate
                        onSubmit={e => {
                           e.preventDefault();
                           formik.handleSubmit();
                        }}
                     >
                        <LegoSetForm formik={formik} displayMode="admin" />
                        <ButtonPanel isFormFooter>
                           <ResponsiveButton
                              variant="danger"
                              isLoading={isDeleting}
                              onClick={() => setShowDeleteDialog(true)}
                              hidden={!legoSet?.id}
                           >
                              <FormattedMessage id="button.delete" defaultMessage="Löschen" />
                           </ResponsiveButton>
                           <ResponsiveButton variant="secondary" onClick={() => navigate(-1)}>
                              <FormattedMessage id="button.cancel" defaultMessage="Abbrechen" />
                           </ResponsiveButton>
                           <SubmitButton formik={formik}>
                              <FormattedMessage id="button.save" defaultMessage="Speichern" />
                           </SubmitButton>
                        </ButtonPanel>
                     </Form>
                  )}
               </Formik>
            </Tab>
            <Tab eventKey="inventory" title="Inventar" className="p-0 pt-2" disabled={!legoSet.id}>
               <ListGroup>
                  {inventory.map(i => (
                     <ListGroup.Item key={i.id} className="d-flex flex-column">
                        <span className="d-flex flex-row gap-2 align-items-center">
                           <span className="fs-5">{users.find(u => u.id === i.user_id)?.name}</span>
                           {i.type === 'wanted' && <Badge bg="secondary">Wunschliste</Badge>}
                           {i.type === 'bought' && <Badge>Inventar</Badge>}
                           {i.type === 'sold' && <Badge bg="success">Verkauft</Badge>}
                        </span>
                        <span className="text-muted">
                           {i.type === 'wanted' && (
                              <span>
                                 Wunsch:
                                 <ColoredValue type="currency" value={i.price} />
                              </span>
                           )}
                           {i.type === 'bought' && (
                              <span>
                                 <ColoredValue type="currency" value={i.price} /> (
                                 {formatDate(i.date, 'L')})
                              </span>
                           )}
                           {i.type === 'sold' && (
                              <span>
                                 <ColoredValue type="currency" value={i.price} /> (
                                 {formatDate(i.date, 'L')})
                                 <Icon path={mdiArrowRightThin} size={1} />
                                 <ColoredValue type="currency" value={i.sold_price} /> (
                                 {formatDate(i.sold_date, 'L')})<span className="mx-2">•</span>
                                 <ColoredValue
                                    type="currency"
                                    value={(i.sold_price ?? 0) - i.price}
                                    isDiff
                                 />
                              </span>
                           )}
                        </span>
                     </ListGroup.Item>
                  ))}
               </ListGroup>
            </Tab>
            <Tab
               eventKey="marketValues"
               title="Marktpreise"
               className="p-0 pt-2"
               disabled={!legoSet.id}
            >
               <MarketValueTab setId={legoSet.id ?? 0} />
            </Tab>
            <Tab eventKey="logs" title="Logs" className="p-0 pt-2" disabled={!legoSet.id}>
               <LogsTab setId={legoSet.id ?? 0} />
            </Tab>
         </Tabs>
         <ConfirmDeleteDialog
            show={showDeleteDialog}
            onClose={() => setShowDeleteDialog(false)}
            onDelete={handleDelete}
         >
            <p>
               Möchten Sie das LegoSet <strong>{legoSet?.name}</strong> wirklich löschen?
            </p>
         </ConfirmDeleteDialog>
      </Container>
   );
};

const MarketValueTab = ({ setId }: { setId: number }) => {
   const modelFilter = useMemo(() => ({ set_id: setId }), [setId]);

   return (
      <Pagination
         model={MarketValueModel}
         modelFilter={modelFilter}
         initialPageSize={25}
         orderColumn="date"
         orderDesc
      >
         {({ items }) => (
            <Table hover className="flat">
               <thead>
                  <tr>
                     <th>Datum</th>
                     <th>Quelle</th>
                     <th>Preis</th>
                  </tr>
               </thead>
               <tbody>
                  {items === null
                     ? [...Array(5).keys()].map(i => (
                          <tr key={i}>
                             <td>
                                <SkeletonPlaceholder />
                             </td>
                             <td>
                                <SkeletonPlaceholder />
                             </td>
                             <td>
                                <SkeletonPlaceholder />
                             </td>
                          </tr>
                       ))
                     : items.map(mv => (
                          <tr key={mv.id}>
                             <td>{formatDate(mv.date, 'L')}</td>
                             <td>{mv.source}</td>
                             <td>
                                <ColoredValue type="currency" value={mv.price} />
                             </td>
                          </tr>
                       ))}
               </tbody>
            </Table>
         )}
      </Pagination>
   );
};

const LogsTab = ({ setId }: { setId: number }) => {
   const modelFilter = useMemo(() => ({ set_id: setId }), [setId]);
   const [logEntry, setLogEntry] = useState<LogMessage | null>(null);

   const getBadgeLevelBackground = (level: string): Variant => {
      switch (level.toLowerCase()) {
         case 'debug':
            return 'primary';
         case 'info':
            return 'secondary';
         case 'warn':
            return 'warning';
         case 'error':
            return 'danger';
         default:
            return 'secondary';
      }
   };

   return (
      <>
         <Pagination
            model={LogMessageModel}
            modelFilter={modelFilter}
            orderColumn="x_date_created"
            orderDesc
            initialPageSize={50}
         >
            {({ items }) =>
               items === null ? (
                  <ContentLoader />
               ) : (
                  items.map(l => (
                     <Card
                        key={l.id}
                        classNameCard={`card-clickable logMessage-${l.level.toLowerCase()}`}
                        onPress={() => setLogEntry(l)}
                        chevron
                     >
                        <div className="d-flex flex-column w-100">
                           <div className="d-flex align-items-center gap-2">
                              <span>{`${formatDate(l.x_date_created, 'L LTS')}`}</span>
                              <Badge bg={getBadgeLevelBackground(l.level)}>
                                 {l.level.toLowerCase()}
                              </Badge>
                           </div>
                           <div>
                              <span>{l.tag}</span>
                           </div>
                           <span className="mb-0 fst-italic text-muted line-clamp-3">
                              {l.message}
                           </span>
                        </div>
                     </Card>
                  ))
               )
            }
         </Pagination>
         <Modal show={!!logEntry} onHide={() => setLogEntry(null)} centered={isMobile} size="lg">
            <Modal.Header closeButton>
               <Modal.Title>{`${formatDate(logEntry?.x_date_created, 'L LTS')}`}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
               <pre>{`${logEntry?.level}: ${logEntry?.tag}`}</pre>
               <pre style={{ whiteSpace: 'pre-wrap' }}>{logEntry?.message}</pre>
            </Modal.Body>

            <Modal.Footer>
               <ButtonPanel>
                  <ResponsiveButton variant="secondary" onClick={() => setLogEntry(null)}>
                     <FormattedMessage id="button.close" defaultMessage="Schließen" />
                  </ResponsiveButton>
               </ButtonPanel>
            </Modal.Footer>
         </Modal>
      </>
   );
};
