import React, { useCallback } from 'react';
import NumberFormat from 'react-number-format';

interface Props {
   /**
    * The type of the value
    */
   type: 'currency' | 'percentage';
   /**
    * Optional class name for the wrapping view.
    */
   className?: string;
   /**
    * The value to be displayed.
    */
   value?: number | null;
   /**
    * If `true` negative values are shown red and positive values green.
    */
   useColor?: boolean;
   /**
    * If `true` positiv values will have a plus sign before its value like negative ones.
    */
   isDiff?: boolean;
   /**
    * If `true` a colored value will always be displayed red.
    */
   isNegative?: boolean;
   /**
    * Specifies how many numbers after the decimal point are to be displayed.
    */
   decimalScale?: number;
}

/**
 * Prints a formatted currency with optional color for positiv/negative values.
 */
export const ColoredValue = ({
   type,
   className = '',
   value,
   useColor = false,
   isDiff = false,
   isNegative = false,
   decimalScale = 2,
}: Props) => {
   let cs = '';
   if (useColor) {
      if (isNegative) cs = isNegative ? 'bad' : 'good';
      else cs = (value ?? 0) < 0 ? 'bad' : 'good';
   }

   const renderValue = useCallback(() => {
      let numberProps = {};

      switch (type) {
         case 'currency':
            numberProps = {
               ...numberProps,
               suffix: ' €',
            };
            break;
         case 'percentage':
            numberProps = {
               ...numberProps,
               suffix: ' %',
            };
            break;
         default:
            // nothing to do
            break;
      }

      return (
         <NumberFormat
            value={value}
            decimalScale={decimalScale}
            fixedDecimalScale
            thousandSeparator="."
            decimalSeparator=","
            displayType="text"
            {...numberProps}
         />
      );
   }, [decimalScale, type, value]);

   if (value === undefined || value === null) return null;

   return (
      <span className={`colored-value ${cs} ${className ?? ''}`}>
         {isDiff && (value ?? 0) > 0 && '+'}
         {renderValue()}
      </span>
   );
};
