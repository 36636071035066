import { FormattedMessage } from 'react-intl';
import React from 'react';
import { Badge } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';
import { ColoredValue } from '../../atoms';
import { ContentWithPlaceholder, PriceTrendIcon } from '../../molecules';
import { LegoSetWithStats } from '../../../types/api/LegoSetWithStats';
import { PriceTrendChartPopover } from './PriceTrendChartPopover';

interface Props {
   legoSet: LegoSetWithStats;
}

export const CardBodyBought = ({ legoSet }: Props) => {
   const boughtStats = legoSet.statistics.bought;
   const mv = legoSet.statistics.currentMarketValue;

   return isMobile ? (
      <>
         <div
            className="h4 mb-0 d-flex align-items-center overflow-ellipsis gap-1"
            style={{ flex: 1 }}
         >
            <ColoredValue type="currency" value={boughtStats.profit} useColor isDiff />
            {boughtStats.profitPercentage !== null && (
               <>
                  <span className="text-muted mx-1">/</span>
                  <ColoredValue
                     type="percentage"
                     value={boughtStats.profitPercentage}
                     decimalScale={1}
                     useColor
                     isDiff
                  />
               </>
            )}
            <PriceTrendIcon
               marketValueHistory={legoSet.statistics.avgMarketValueHistory}
               size={1}
            />
         </div>
         <small className="overflow-ellipsis text-muted fst-italic">
            <span>
               <FormattedMessage
                  id="app.inventory.grid.card.invest-price"
                  defaultMessage="EKP: {price}"
                  values={{
                     price: <ColoredValue type="currency" value={boughtStats.invest} />,
                  }}
               />
            </span>
            <span className="mx-1">•</span>
            <span>
               {mv.min !== mv.max && <span>{'Ø '}</span>}
               <ColoredValue type="currency" value={mv.avg} />
            </span>
         </small>
      </>
   ) : (
      <>
         <span className="pb-2 d-flex justify-content-between align-items-center">
            <span className="text-truncate small fst-italic">
               <ContentWithPlaceholder isLoading={!legoSet} width="5rem">
                  {legoSet.status_by_lego || <>&nbsp;</>}
               </ContentWithPlaceholder>
            </span>
            {boughtStats.count >= 2 && (
               <Badge bg="info" className="h6 mb-0">{`${boughtStats.count}x`}</Badge>
            )}
         </span>
         <span className="card-body-price">
            <ColoredValue
               className="h3"
               type="currency"
               value={boughtStats.profit}
               useColor
               isDiff
            />
            <PriceTrendChartPopover marketValueHistory={legoSet.statistics.avgMarketValueHistory}>
               <PriceTrendIcon
                  marketValueHistory={legoSet.statistics.avgMarketValueHistory}
                  size={1.5}
               />
            </PriceTrendChartPopover>
         </span>
         <span className="card-body-price-sub">
            <ColoredValue
               type="percentage"
               value={boughtStats.profitPercentage}
               decimalScale={1}
               useColor
               isDiff
            />
         </span>
         <span className="flex-fill" />
         <span className="small">
            {mv.min === mv.max ? (
               <ColoredValue type="currency" value={mv.avg} />
            ) : (
               <FormattedMessage
                  id="inventory.grid.card.bought.avg-market-price"
                  defaultMessage="Ø {avg} ({min} - {max})"
                  values={{
                     avg: <ColoredValue type="currency" value={mv.avg} />,
                     min: <ColoredValue type="currency" value={mv.min} />,
                     max: <ColoredValue type="currency" value={mv.max} />,
                  }}
               />
            )}
         </span>
      </>
   );
};
