import { useNavigate } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import React, { useCallback, useEffect, useState } from 'react';
import groupBy from 'lodash/groupBy';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { FormattedMessage } from 'react-intl';
import { showBackButton } from '../../../redux/appSlice';
import { UploadedImageCard } from '../../../components/organisms/UploadedImageCard';
import { EditImageUploadDialog } from '../../../components/dialogs/EditImageUploadDialog';
import { ResponsiveButton } from '../../../components/molecules';
import { ImageUpload, Inventory, LegoSet } from '../../../types/api';
import { ImageUploadModel } from '../../../models/ImageUploadModel';
import { useAppDispatch, useIdFromParams, useSession } from '../../../hooks';
import { InventoryModel } from '../../../models/InventoryModel';
import { LegoSetModel } from '../../../models/LegoSetModel';
import { ContentLoader } from '../../../components/atoms';
import { PageTitle } from '../../../components/organisms/PageTitle';

export const InventoryPicturesMobilePage = () => {
   const inventoryId = useIdFromParams();
   const { sessionUser } = useSession();
   const navigate = useNavigate();
   const dispatch = useAppDispatch();
   const [inventory, setInventory] = useState<Inventory>();
   const [legoSet, setLegoSet] = useState<LegoSet>();
   const [uploadedImages, setUploadedImages] = useState<ImageUpload[]>();
   const [uploadToEdit, setUploadToEdit] = useState<ImageUpload>();
   const [showEditDialog, setShowEditDialog] = useState(false);

   const loadImages = useCallback(async () => {
      if (!inventory) return;

      setUploadedImages(
         await ImageUploadModel.list({ inventory_id: inventory.id, user_id: sessionUser?.id ?? 0 })
      );
   }, [inventory, sessionUser?.id]);

   useEffect(() => {
      (async () => {
         dispatch(showBackButton(true));
         const inventoryObj = await InventoryModel.get(inventoryId);
         const legoSetObj = await LegoSetModel.get(inventoryObj?.set_id ?? 0);
         if (!inventoryObj || !legoSetObj) {
            navigate('/404', { replace: true });
            return;
         }

         setInventory(inventoryObj);
         setLegoSet(legoSetObj);
      })();
   }, [inventoryId, navigate, dispatch]);

   useEffect(() => {
      (async () => {
         await loadImages();
      })();
   }, [loadImages]);

   const handleEditUpload = useCallback(async (imageUpload: ImageUpload) => {
      setUploadToEdit(imageUpload);
      setShowEditDialog(true);
   }, []);

   if (!legoSet || !inventory) return <ContentLoader />;

   if (!uploadedImages) {
      return (
         <Container>
            <SkeletonTheme baseColor="#444" highlightColor="#222">
               <section>
                  <Skeleton width="15.5rem" height="3rem" style={{ margin: 0 }} />
               </section>
            </SkeletonTheme>
            <div className="pt-4 d-flex flex-wrap justify-content-around">
               <SkeletonTheme baseColor="#444" highlightColor="#222">
                  <section>
                     <Skeleton width="8rem" height="10rem" style={{ margin: 0 }} />
                  </section>
               </SkeletonTheme>
               <SkeletonTheme baseColor="#444" highlightColor="#222">
                  <section>
                     <Skeleton width="8rem" height="10rem" style={{ margin: 0 }} />
                  </section>
               </SkeletonTheme>
            </div>
         </Container>
      );
   }

   return (
      <Container>
         <PageTitle
            title={`${legoSet.number}: ${legoSet.name}`}
            appTitle={legoSet.number}
            subtitle={legoSet.name}
            hidden
         />
         <ResponsiveButton
            variant="success"
            className="my-3"
            onClick={() => {
               setUploadToEdit(undefined);
               setShowEditDialog(true);
            }}
         >
            <FormattedMessage id="button.add-image" defaultMessage="Neues Bild hinzufügen" />
         </ResponsiveButton>
         {Object.entries(groupBy(uploadedImages, 'section')).map(([name, images]) => (
            <div key={`group_${name}`}>
               <h2>{name}</h2>
               <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-evenly' }}>
                  {images.map(i => (
                     <UploadedImageCard key={i.id} image={i} onEdit={handleEditUpload} />
                  ))}
               </div>
            </div>
         ))}
         {uploadedImages.length === 0 && (
            <div className="small fst-italic text-center">
               <FormattedMessage
                  id="uploads.no-images"
                  defaultMessage="Es existieren keine Bilder für dieses Set in deinem Inventar. Klicke auf 'Neues Bild hinzufügen' um Bilder hochzuladen."
               />
            </div>
         )}
         <EditImageUploadDialog
            showDialog={showEditDialog}
            inventoryId={inventoryId}
            imageToEdit={uploadToEdit}
            onDialogClose={() => setShowEditDialog(false)}
            onSubmit={async () => {
               await loadImages();
               setShowEditDialog(false);
            }}
            onDelete={async () => {
               await loadImages();
               setShowEditDialog(false);
            }}
         />
      </Container>
   );
};
