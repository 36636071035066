import React, { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { showBackButton } from '../../redux/appSlice';
import { BarChart } from '../../components/organisms/BarChart';
import { useAppDispatch, useMemorizedIntl } from '../../hooks';
import { PageTitle } from '../../components/organisms/PageTitle';

/*
Neuer Ansatz:
   - Zeitlicher Verlauf meines Inventars (nur bought) mit Wert
   - Balkendiagramm reale Gewinne je Monat (Analog Parquet-App)
   - Balkendiagramm Einkauf/Verkauf je Monat (Analog Parquet-App)
   - Zwei Donuts-Charts, die sich umschalten lassen
      - Invest/Verkauf nach Kategorie
      - Prozentualer Gewinn Inventar/Verkauf nach Kategorie
 */
export const Statistics2Page = () => {
   const dispatch = useAppDispatch();
   const intl = useMemorizedIntl();

   useEffect(() => {
      dispatch(showBackButton(false));
   }, [dispatch]);

   return (
      <Container>
         <PageTitle
            title={intl.formatMessage({
               id: 'statistics.title',
               defaultMessage: 'Statistiken',
            })}
         />
         <Row>
            <Col className="bg-secondary p-3" style={{ borderRadius: 6 }}>
               <h5 className="d-flex justify-content-center">Einkauf/Verkauf</h5>
               <BarChart url="stats/getPurchaseAndSale" />
            </Col>
         </Row>
      </Container>
   );
};
